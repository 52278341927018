import React, {useRef, useState} from "react";
import {User} from "@Types/User";
import {Button} from "primereact/button";
import {Line} from "@Components/UI/Line";
import {CustomTabView} from "@Components/CustomTabView";
import {UpdateUser} from "@Pages/pages/components/UserInformation/UpdateUser";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";
import {UserSessions} from "@Pages/pages/components/UserInformation/UserSessions";
import { UserNotes } from '@Pages/pages/components/UserInformation/UserNotes';
import {useToast} from "@Hooks/toast";


export type UserInformationProps = {
	user: User,
	onQuit: () => void
}
export const UserInformation: React.FC<UserInformationProps> = props => {

	const api = useApi();

	const [selectedTab, setSelectedTab] = useState<number>(0);

	const {success} = useToast();
	const [updateUser, loading] = usePromise(async (user: User) => {
		const res = await api.user_call_update({
			user
		});
		if (res.result !== "ok") throw new Error(res.result);
	}, {
		pending: "Mise à jour de l'utilisateur en cours",
		success: "Utilisateur Modifié !",
	})

	const updateFormRef = useRef<React.ElementRef<typeof UpdateUser>>(null);

	const copyToClipboard = async (content: string) => {
		try {
			await navigator.clipboard.writeText(content);
			success("Copié dans le presse-papier");
		} catch (e) {
			console.error(e);
		}

	}

	return (
		<div className="UserInformation">
			<div className="he-header--h3 gray-900 mb-4 flex align-items-center">
				Fiche apprenant: {props.user.firstname} {props.user.lastname}
				<div className={"ml-3 he-paragraph--small gray-400 cursor-pointer"} onClick={() => copyToClipboard(props.user.user_id)}>
					{props.user.user_id}
					<i className={" ml-2 pi pi-copy"}/>
				</div>
			</div>
			<CustomTabView selected={selectedTab} style={{marginBottom: 14}} onChange={t => setSelectedTab(t)} items={[{
				label: "Informations générales",
			}, {
				label: "Historique des sessions"
			}, {
				label: "Notes et commentaires"
			}]}/>

			{
				selectedTab === 0 && <UpdateUser user={props.user} ref={updateFormRef} onUpdateUser={user => updateUser(user)}/>
			}
			{
				selectedTab === 1 && <UserSessions user={props.user} />
			}
			{
				selectedTab === 2 && <UserNotes user={props.user}/>
			}
			<Line height={1} className="my-4 bg-gray-200"/>
			<div className="flex justify-content-end mt-4">
				<Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" loading={loading}
				        onClick={props.onQuit}/>
				{
					selectedTab === 0 &&
					<Button loading={loading} className="he-button--primary--md" label={"Modifier et sauvegarder"}
							icon="pi pi-save"
							iconPos="left"
							onClick={() => {
								console.log("Clicked", selectedTab);

								if (selectedTab === 0) {
									console.log("submiting");
									console.log(updateFormRef.current);
									updateFormRef.current?.submit();
								}
							}}/>
				}
			</div>
		</div>
	)
}
