import { CustomActionDropdown } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { CustomTable } from '@Components/CustomTable';
import { UserBadge } from '@Components/UI/UserBadge';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { BanUser } from '@Pages/pages/components/BanUser';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { SessionParticipant, SessionTimeReport } from '@Types/Session';
import { downloadFromURL } from '@Utils/download.utils';
import { applyFilters, fuzzSearch } from '@Utils/search.utils';
import _ from 'lodash';
import { DateTime, Duration, Interval } from 'luxon';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { collectionGroup, getDocs } from 'firebase/firestore';
import { firestore } from '@Utils/config/firebase';
import { generateGlobalStatistics } from '@Utils/satisfactionUtils';
import { Satisfaction } from '@Types/Satisfaction';
import { TieredMenu } from 'primereact/tieredmenu';

type ExtendedParticipant = SessionParticipant & {
    elapsed_time?: number;
    last_activity?: number;
    progress?: number;
    session_id: string;
    unique: string;
};
export type StatisticsProps = Record<string, never>;
export const Statistics: React.FC<StatisticsProps> = () => {
    const { formations, sessions, getUserProgressInSession } = useContext(BackofficeContext);
    const groupedRef = useRef<TieredMenu>(null);
    const ref = useRef<TieredMenu>(null);

    const [groupedSelection, setGroupedSelection] = useState<ExtendedParticipant[]>([]);

    const [banUserVisible, setBanUserVisible] = useState<boolean>(false);
    const getSessionTitle = (session_id: string) => {
        const session = sessions.find((s) => s.session_id === session_id);
        if (!session) return ' Erreur ';
        const formation = formations.find((f) => f.formation_id === session.formation_id);
        return formation?.title || 'Erreur';
    };

    const [downloadUserAttestation] = usePromise(
        async (user_id: string, session_id: string) => {
            //
            // const res = await api.session_call_getAttestation({
            // 	session_id: session_id,
            // 	user_ids: [user_id]
            // });
            //
            // if (res.result !== "ok") throw new Error(res.result);
            //
            // const anchor = document.createElement('a');
            // anchor.href = res.url;
            // anchor.download = `Attestation-DPC-${user_id}.zip`;
            // anchor.click();
        },
        {
            pending: 'Génération en cours, cette opération peut prendre du temps...',
            success: 'Génération terminée, le téléchargement va démarrer',
        }
    );

    const api = useApi();

    const [downloadUsersLog] = usePromise(
        async (user_ids: string[], session_id) => {
            const res = await api.session_call_getUserLogs({
                session_id: session_id,
                unit_ids: sessions.find((s) => s.session_id === session_id)?.unitsConfig.map((u) => u.unit_id) || [],
                user_ids,
            });

            if (res.result !== 'ok') throw new Error(res.result);
            await downloadFromURL(res.url);
        },
        {
            pending: 'Génération des logs en cours, cette opération peut prendre du temps...',
            success: 'Logs créer le téléchargement va démarrer',
        }
    );

    const groupedOptions = useMemo(
        () => [
            {
                label: 'Télécharger les logs',
                icon: 'pi pi-download',
                action: async () => {
                    for (const select of groupedSelection) {
                        await downloadUsersLog([select.participant_id], select.session_id);
                    }
                },
            },
        ],
        [groupedSelection]
    );

    const [selection, setSelection] = useState<ExtendedParticipant>();

    const { meta } = useUser(true);

    const options = useMemo(
        () =>
            !selection?.blocked && ['admin', 'author'].includes(meta.role)
                ? [
                      ...(selection && selection.attestation_access
                          ? [
                                {
                                    label: 'Télécharger l’attestation de suivi DPC',
                                    icon: 'pi pi-download',
                                    action: () => {
                                        if (selection)
                                            downloadUserAttestation(selection.participant_id, selection.session_id);
                                    },
                                },
                            ]
                          : []),
                      {
                          label: 'Télécharger les logs',
                          icon: 'pi pi-download',
                          action: () => {
                              if (selection) downloadUsersLog([selection.participant_id], selection.session_id);
                          },
                      },
                  ]
                : [
                      {
                          label: "Débloquer l'apprenant",
                          icon: 'pi pi-check-circle',
                          action: () => setBanUserVisible(true),
                      },
                  ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, participant: ExtendedParticipant) => {
        setSelection(participant);
        ref.current?.toggle(e);
    };

    const ParticipantDropdown = (participant: ExtendedParticipant) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, participant)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const [reports] = useState<{
        [session_id: string]: { [participant_id: string]: SessionTimeReport };
    }>({});

    const todoParticipant: { [k: string]: ExtendedParticipant[] } = {};

    const participants = useMemo<ExtendedParticipant[]>(() => {
        const mapped = Object.entries(todoParticipant).map(([k, v]) =>
            v.map((p) =>
                reports?.[k]?.[p.participant_id]
                    ? {
                          ...p,
                          last_activity: reports[k][p.participant_id].last_activity,
                          elapsed_time: reports[k][p.participant_id].total,
                          progress: getUserProgressInSession(p.participant_id, [], k),
                          session_id: k,
                          unique: k + '/' + p.participant_id,
                      }
                    : {
                          ...p,
                          session_id: k,
                          unique: k + '/' + p.participant_id,
                      }
            )
        );

        return _.flatten(mapped);
    }, [reports, getUserProgressInSession]);

    // const api = useApi();
    //
    // useEffect(() => {
    // 	for (const session of sessions) {
    // 		// api.session_call_usersReport({
    // 		// 	session_id: session.session_id,
    // 		// 	user_ids: participants.map(p => p.participant_id)
    // 		// }).then(res => {
    // 		// 	if (res.result === "ok") {
    // 		// 		setReports(produce(prev => {
    // 		// 			prev[session.session_id] = res.sessions
    // 		// 			return prev
    // 		// 		}))
    // 		// 	}
    // 		// })
    // 	}
    //
    // }, [sessions]);

    const [filter] = useState<string>('');
    const [startInf, setStartInf] = useState<any>();
    const [startSup, setStartSup] = useState<any>();
    const [minProgress, setMinProgress] = useState<number | null>(null);
    const [maxProgress, setMaxProgress] = useState<number | null>(null);
    const [minTime, setMinTime] = useState<number | null>(null);
    const [maxTime, setMaxTime] = useState<number | null>(null);

    const filterByInterval = (inf: Date, sup: Date) => {
        return (item: ExtendedParticipant) => {
            if (!item.last_activity) return false;

            const startD = DateTime.fromMillis(item.last_activity);
            if (!!inf && !!sup) {
                const infD = DateTime.fromJSDate(inf);
                const supD = DateTime.fromJSDate(sup);
                const interval = Interval.fromDateTimes(infD, supD);
                return interval.contains(startD);
            }
            if (!inf && !!sup) {
                const supD = DateTime.fromJSDate(sup);
                return startD.diff(supD, 'second').get('second') <= 0;
            }
            if (!!inf && !sup) {
                const infD = DateTime.fromJSDate(inf);
                return startD.diff(infD, 'second').get('second') >= 0;
            }
            return true;
        };
    };

    const filterByRange = (
        inf?: number | null,
        sup?: number | null,
        itemProcess?: (item: ExtendedParticipant) => undefined | number
    ) => {
        return (item: ExtendedParticipant) => {
            const value = itemProcess?.(item);
            if (!!inf && !!sup && value) {
                return inf <= value && value <= sup;
            }
            if (!inf && !!sup && value) {
                return value <= sup;
            }
            if (!!inf && !sup && value) {
                return value >= inf;
            }
            return true;
        };
    };

    const filtered = useMemo<(ExtendedParticipant & { title: string })[]>(() => {
        const items = fuzzSearch(participants, filter, ['firstname', 'lastname'], true);

        return applyFilters(items, [
            startInf || startSup ? filterByInterval(startInf, startSup) : undefined,
            minProgress || maxProgress
                ? filterByRange(minProgress, maxProgress, (item) =>
                      item.progress && !isNaN(+item.progress) ? +item.progress : undefined
                  )
                : undefined,
            minTime || maxTime
                ? filterByRange(minTime, maxTime, (item) =>
                      item.elapsed_time ? item.elapsed_time / 1000 / 60 : undefined
                  )
                : undefined,
        ]).map((s) => {
            return {
                ...s,
                title: getSessionTitle(s.session_id),
            };
        });
    }, [filter, participants, startInf, startSup, minProgress, maxProgress, minTime, maxTime]);

    const [loading, setLoading] = useState<boolean>(false);

    const onExportGlobal = async () => {
        setLoading(true);
        const participants = await getDocs(collectionGroup(firestore.db, 'participants'));

        const participantsWithSatisfaction = participants.docs
            .filter((p) => Boolean(p.data().satisfaction))
            .map((p) => p.data() as SessionParticipant & { satisfaction: Satisfaction });

        await generateGlobalStatistics(participantsWithSatisfaction);
        setLoading(false);
    };

    return (
        <ContentLayout
            title={'Statistiques globales'}
            subtitle={
                'Vous pouvez consulter l’avancement de chaque apprenant dans chaque session de formation dans laquelle il est inscrit'
            }
        >
            {selection && banUserVisible && (
                <CustomDialog onHide={() => setBanUserVisible(false)}>
                    <BanUser
                        onQuit={() => setBanUserVisible(false)}
                        session_id={selection.session_id}
                        participant={selection}
                    />
                </CustomDialog>
            )}

            <CustomActionDropdown options={groupedOptions} ref={groupedRef} />
            <CustomActionDropdown options={options} ref={ref} />
            {/*{groupedSelection.length > 0 && <CustomDialog visible={sendMessageVisible} onHide={() => setSendMessageVisible(false)}>*/}
            {/*    <SendMessage onQuit={() => setSendMessageVisible(false)} session={props.session} participants={groupedSelection} />*/}
            {/*</CustomDialog>}*/}
            <CustomTable
                dataKey={'unique'}
                values={filtered}
                selection={groupedSelection}
                onSelectionChange={(sel) => {
                    setGroupedSelection(sel);
                }}
                header={{
                    title: 'Apprenants inscrits à la session',
                    actions: [
                        <Button
                            className={'he-button--primary-nf--xs'}
                            icon={'pi pi-file-excel'}
                            label={'Exporter les statistiques globales'}
                            onClick={onExportGlobal}
                            disabled={loading}
                            loading={loading}
                        />,
                    ],
                    filters: [
                        <div className="flex align-items-center he-paragraph--regular inter gray-600 mr-3">
                            Avancement
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Min"
                                value={minProgress}
                                onChange={(e) => setMinProgress(e)}
                            />
                            à
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Max"
                                value={maxProgress}
                                onChange={(e) => setMaxProgress(e)}
                            />
                        </div>,
                        <div className="flex align-items-center he-paragraph--regular inter gray-600">
                            Temps passé (minutes) :
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Min"
                                value={minTime}
                                onChange={(e) => setMinTime(e)}
                            />
                            à
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Max"
                                value={maxTime}
                                onChange={(e) => setMaxTime(e)}
                            />
                        </div>,
                        <div className="flex align-items-center he-paragraph--regular inter gray-600">
                            Dernière activité
                            <Calendar
                                className="w-6rem mx-2"
                                placeholder="jj/mm/aaaa"
                                value={startInf}
                                onChange={(e) => setStartInf(e.value)}
                                dateFormat="dd/mm/yy"
                            />
                            à
                            <Calendar
                                className="w-6rem mx-2"
                                placeholder="jj/mm/aaaa"
                                value={startSup}
                                onChange={(e) => setStartSup(e.value)}
                                dateFormat="dd/mm/yy"
                            />
                        </div>,
                    ],
                }}
                columns={[
                    {
                        header: 'Nom/Prénom',
                        field: 'firstname',
                        filter: {
                            type: 'sort',
                            field: 'firstname',
                        },
                        body: (item) => <UserBadge user={item} email={false} />,
                    },
                    {
                        header: 'Session de formation',
                        filter: {
                            type: 'sort',
                            field: 'title' as any,
                        },
                        body: (item) => <div>{(item as any).title}</div>,
                    },
                    {
                        header: 'Avancement',
                        filter: {
                            type: 'sort',
                            field: 'progress',
                        },
                        body: (item) => (
                            <div>
                                {item.progress === -1
                                    ? ' - '
                                    : new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                          item.progress || 0
                                      )}{' '}
                                %
                            </div>
                        ),
                    },
                    {
                        header: 'Temps passé',
                        filter: {
                            type: 'sort',
                            field: 'elapsed_time',
                        },
                        body: (item) => (
                            <div>
                                {item.elapsed_time ? Duration.fromMillis(item.elapsed_time).toFormat('hh:mm:ss') : '-'}
                            </div>
                        ),
                    },
                    {
                        header: 'Dernière activité',
                        filter: {
                            type: 'sort',
                            field: 'last_activity',
                        },
                        body: (item) => (
                            <div>
                                {item.last_activity
                                    ? DateTime.fromMillis(item.last_activity).toFormat('dd/MM/yyyy')
                                    : '-'}
                            </div>
                        ),
                    },
                    {
                        header: 'Statut',
                        filter: {
                            type: 'select',
                            filterOptions: () => [
                                {
                                    label: 'Bloqué',
                                    value: true,
                                },
                                {
                                    label: 'Actif',
                                    value: false,
                                },
                            ],
                            field: 'blocked',
                            searchInput: false,
                            filterItemTemplate: (item) => <div>{item.label}</div>,
                        },
                        body: (item) =>
                            !item.blocked ? (
                                <Badge value="ACTIF" severity="success" />
                            ) : (
                                <Badge value="BLOQUÉ" severity="warning" />
                            ),
                    },
                    {
                        body: ['admin', 'author'].includes(meta.role) ? ParticipantDropdown : () => null,
                    },
                ]}
            />
        </ContentLayout>
    );
};
