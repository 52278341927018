import React, {useState} from "react";
import {Button} from "primereact/button";
import styled from "styled-components";
import {usePromise} from "@Hooks/promise";
import {createUserSchema} from "@Schemas/user.schema";
import {ValidationError} from "yup";
import {User} from "@Types/User";
import {VirtualScroller} from "primereact/virtualscroller";
import { useApi } from "@Hooks/api";
import {Session} from "@Types/Session";
import {parse} from "csv-parse/sync";

const UserListContainer = styled.textarea`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  height: min-content;
  text-align: center;
  resize: none;
  border-radius: 8px;
	height: 50px;
  
  &::placeholder {
    display: block;
    text-align: center;
  }
`

const UserListDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  width: 100%;
`

export type ImportParticipantsProps = {
	onQuit: () => void,
	session: Session
}
export const ImportParticipants: React.FC<ImportParticipantsProps> = props => {

	const [userList, setUserList] = useState<User[]>();
	const api = useApi();

	const [importUserList, loading] = usePromise(async (users: User[]) => {
		const res = await api.participant_call_import({
			participants: users,
			session_id: props.session.session_id
		});

		if (res.result !== "ok") throw new Error(res.result);

		props.onQuit();
	}, {
		pending: "importation des utilisateurs en cours...",
		success: "Utilisateurs importés !"
	})

	const [errors, setErrors] = useState<string[][]>([]);

	const parseGooglePaste = (raw: string) => {


		const lines: string[] = parse(raw, {
			delimiter: "\t",
			trim: true
		});

		const formatted: User[] = lines.map(line => {
			const [lastname, firstname, RPPS, email, address, phone, job, exercise_mode] = line
			return {
				role: "user",
				user_id: "",
				verified: false,
				firstname,
				lastname,
				RPPS,
				email,
				address,
				job,
				phone,
				exercise_mode,
				accept_mailings: 3
			}
		})


		const allErrors = [];

		for (const [i, user] of formatted.entries()) {
			try {
				createUserSchema.validateSync(user, {
					abortEarly: false,
					stripUnknown: true
				});
			} catch (err: unknown) {
				if (err instanceof ValidationError) {
					allErrors.push(err.inner.map(data => `ligne ${i+1} - ${data.path} : ${data.errors.join(',')}`));
				}
			}
		}

		setErrors(allErrors);
		setUserList(formatted);
	}

	const loadClipboardData = async () => {
		const content = await navigator.clipboard.readText();
		parseGooglePaste(content)
	}

	return (
		<div className="w-full h-full">
			<div className="he-header--h3 inter mb-4">
				Importer des utilisateurs
			</div>
			<div className="he-paragraph--regular gray-500 mb-3">
				Liste des utilisateurs
			</div>
			{
				errors.length === 0 && !userList && <>
                    <div className="flex justify-content-center align-items-center flex-column">
                        <Button
                            label="Cliquer pour coller la liste d' utilisateurs"
                            className="he-button--secondary-variant--md mr-2 px-5 w-max"
                            onClick={loadClipboardData}
                        />
                        <div className="he-header--h3 gray-400 my-2">
                            ou
                        </div>
                    </div>
                    <UserListContainer
                        className="w-full"
                        placeholder="Coller ici la liste d’utilisateur provenant de Google Sheet"
                        onPaste={(e) => parseGooglePaste(e.clipboardData.getData("text/plain"))}
                    />
                </>
			}
			{
				errors.length === 0 && userList && <UserListDivContainer>
                    <VirtualScroller
                      items={userList}
                        itemSize={[28]}
                        scrollHeight={"400px"}
                        itemTemplate={(user, options) => <div className={`flex align-items-center white-space-nowrap he-paragraph--regular gray-600 py-2 px-2 ${options.index%2 === 0 ? "bg-gray-100" : ""} `} style={{height: 28}}>
	                        <div className="mr-2" style={{minWidth: "200px"}}>{user.lastname}</div>
	                        <div className="mr-2" style={{minWidth: "200px"}}>{user.firstname}</div>
	                        <div className="mr-2" style={{minWidth: "100px"}}>{user.RPPS}</div>
	                        <div className="mr-2" style={{minWidth: "300px"}}>{user.email}</div>
	                        <div className="mr-2" style={{minWidth: "450px"}}>{user.address}</div>
	                        <div className="mr-2" style={{minWidth: "100px"}}>{user.phone}</div>
	                        <div className="mr-2" style={{minWidth: "100px"}}>{user.job}</div>
	                        <div className="mr-2" style={{minWidth: "100px"}}>{user.exercise_mode}</div>
						</div>}
                    /></UserListDivContainer>
			}
			{
				errors.length > 0 && <div>
                    <div></div>
					{
						errors.map((errorGroup, i) =>
							errorGroup.map((error, j) =>
								<div className="he-paragraph--small color-red" key={i + "" + j}>{error}</div>
							)
						)
					}
                </div>
			}
			<div className="flex justify-content-end align-items-center mt-4">
				{
					errors.length === 0 && userList &&
                    <div className="mr-auto he-paragraph--regular primary-100">
						{userList.length} lignes importées
                    </div>
				}
				<Button className="he-button--secondary-nfb--md mr-2" label="Annuler" loading={loading}
				        onClick={props.onQuit}/>
				<Button loading={loading} className="he-button--primary--md"
				        disabled={errors.length > 0 || !userList}
				        label={"Importer les utilisateurs"}
				        icon={"pi pi-upload"}
				        onClick={() => userList && importUserList(userList)}/>
			</div>
		</div>
	)
}
