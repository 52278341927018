import React from "react";
import {Line} from "@Components/UI/Line";
import {SessionParticipant} from "@Types/Session";
import {Button} from "primereact/button";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {classNames} from "primereact/utils";

export type BanUserProps = {
	onQuit: () => void;
	session_id: string;
	participant: SessionParticipant;
}
export const BanUser: React.FC<BanUserProps> = props => {

	const api = useApi();

	const [banUser, loading] = usePromise(async () => {
		const res = await api.participant_call_update({
			session_id: props.session_id,
			participant: {
				...props.participant,
				blocked: !props.participant.blocked
			}
		});

		if (res.result !== "ok") throw new Error(res.result);

		props.onQuit();
	})

	return (
		<div>
			<div className="he-header--h3">
				{props.participant.blocked ?  "Débloquer un apprenant" : "Bloquer un apprenant"}
			</div>
			<div className="flex mt-4 he-paragraph--regular">
				{props.participant.blocked ?  `
				Souhaitez-vous débloquer l’apprenant pour la session de formation ?
				L’apprenant aura de nouveau accès à la session de formation dans son extranet.
				` : `
				Souhaitez-vous bloquer l’apprenant pour la session de formation ?
				L’apprenant n’aura plus accès à la session de formation dans son extranet : il ne pourra plus suivre la formation ni télécharger les documents associés.
				`}
			</div>
			<Line height={1} className={"bg-gray-200 my-4"} />
			<div className="flex justify-content-end mt-4">
				<Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" loading={loading}
				        onClick={props.onQuit}/>
				<Button loading={loading} className={classNames("he-button--primary--md", !props.participant.blocked && "p-button-danger")} icon={props.participant.blocked ? "pi pi-check-circle" : "pi pi-ban"} label={props.participant.blocked? "Débloquer" : "Bloquer"}
				        onClick={banUser}/>
			</div>
		</div>
	)
}
