import { CustomTabView } from '@Components/CustomTabView';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Formation } from '@Types/Formation';
import { ScheduledEvent } from '@Types/ScheduledEvent';
import { Session } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { EventCard } from './components/EventCard';
import { ContentLayout } from './layout/ContentLayout';
import { Button } from 'primereact/button';
import { RelanceDialog } from '@Pages/pages/Relances/RelanceDialog';

type ExtendedEvent = ScheduledEvent & { session: Session & { formation: Formation } };

export const Relances: FC = () => {
    const { sessions, formations } = useContext(BackofficeContext);

    const [events, setEvents] = useState<ExtendedEvent[]>([]);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    useEffect(() => {
        if (!sessions) return;
        return onSnapshot(collection(firestore.db, 'events'), (snap) => {
            const scheduledEvents = snap.docs.map((doc) => doc.data() as ScheduledEvent);
            const scheduledEventExtended = scheduledEvents
                .map((event) => {
                    const session = sessions.find((session) => session.session_id === event.session_id);
                    if (!session) return null;

                    const sessionExtended = {
                        ...session,
                        formation: formations.find((formation) => formation.formation_id === session.formation_id),
                    };
                    return {
                        ...event,
                        session: sessionExtended,
                    };
                })
                .filter((e) => !!e?.session?.formation);

            setEvents(scheduledEventExtended as ExtendedEvent[]);
        });
    }, [sessions, formations]);

    const [selectedTab, setselectedTab] = useState<number>(0);

    const tabs = [
        {
            label: 'Toutes les relances',
        },
        {
            label: 'Relances programmées',
        },
        {
            label: 'Relances récurrentes',
        },
    ];

    const [filter, setFilter] = useState<string>('');

    const filteredAndGroupedEvents = useMemo(() => {
        const filteredEventsByTab = events.filter((e) =>
            selectedTab === 0
                ? true
                : selectedTab === 1
                ? e.type === 'single' || e.type === 'virtual_class'
                : e.type === 'recurring'
        );
        const filteredEventsByFormation = filteredEventsByTab.filter((e) =>
            e.session.formation.title.toLowerCase().includes(filter.toLowerCase())
        );
        const groupedByFormation = _.groupBy(
            filteredEventsByFormation,
            (event) => event.session.formation.formation_id
        );

        return groupedByFormation;
    }, [selectedTab, events, filter]);

    return (
        <ContentLayout
            title={'Relances automatiques'}
            subtitle={"Vous pouvez consulter l'ensemble des relances programmées et récurrentes"}
        >
            {dialogVisible && <RelanceDialog onHide={() => setDialogVisible(false)} />}
            <div className="flex justify-content-end">
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText
                        style={{ width: 260 }}
                        value={filter}
                        onChange={(e) => setFilter(e.currentTarget.value)}
                        placeholder="Rechercher une formation"
                    />
                </span>
                <Button
                    className="he-button--primary--md ml-3"
                    style={{ height: 40 }}
                    onClick={() => setDialogVisible(true)}
                >
                    <i className="pi pi-clock" /> Nouvelle relance
                </Button>
            </div>

            <CustomTabView selected={selectedTab} items={tabs} onChange={setselectedTab} />
            <Accordion className="mt-4">
                {_.entries(filteredAndGroupedEvents).map(([formation_id, events], i) => {
                    const eventsGroupedBySession = _.groupBy(events, (event) => event.session.session_id);

                    return (
                        <AccordionTab
                            key={formation_id}
                            tabIndex={i}
                            header={
                                formations.find((f) => f.formation_id === formation_id)?.title || 'Aucune formation'
                            }
                        >
                            {_.sortBy(
                                _.entries(eventsGroupedBySession),
                                ([s_id, se]) => se[0]?.session.session_custom_id
                            ).map(([session_id, sessionEvents]) => {
                                return (
                                    <Fieldset
                                        toggleable
                                        key={session_id}
                                        legend={'Session ' + sessionEvents[0]?.session.session_custom_id}
                                        className="mb-3"
                                    >
                                        {_.sortBy(sessionEvents, 'date').map((event) => {
                                            return <EventCard event={event} key={event.event_id} />;
                                        })}
                                    </Fieldset>
                                );
                            })}
                        </AccordionTab>
                    );
                })}
            </Accordion>
        </ContentLayout>
    );
};
