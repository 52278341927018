import { useEffect, useState } from "react";

export function useResource<T>(fetcher: () => Promise<T>, defaultValue: T) {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T>(defaultValue);
    const [error, setError] = useState<any>(null);

    const loadData = () => {
        setLoading(true);
        fetcher()
            .then(setData)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadData();
    }, []);

    return {
        data,
        error,
        loading,
        refresh: loadData,
    };
}
