import React from "react";
import {Answer} from "@Types/Quizz";
import {RadioButton} from "primereact/radiobutton";
import {
	AnswerWrapper
} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/components/AnswerWrapper";
import {AnswerItem} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/components/AnswerItem";
import {
	ImageAnswer
} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/components/ImageAnswer";

export type UCQProps = {
	answers: Answer[];
	is_image?: boolean;
	onSelect?: (selection: number[]) => void;
	selection?: number[];
	is_done?: boolean;
}

export const UCQ: React.FC<UCQProps> = (props) => {

	const onSelectItem = (index: number) => {
		if (!props.onSelect || props.is_done) return;

		if (props.selection?.includes(index))
			props.onSelect(props.selection.filter(i => i !== index));
		else
			props.onSelect([index]);
	}

	return (
		<AnswerWrapper is_image={props.is_image}>
			{props.answers.map((answer, i) => {
				return <AnswerItem is_correct={answer.is_response}
				                   className="flex w-full h-full align-items-center cursor-pointer" key={i}
				                   onClick={() => onSelectItem(i)}>
					{
						props.is_image ?
							<ImageAnswer
								answer={answer}
								index={i}
								element={<RadioButton className="mr-2" checked={answer.is_response}/>}
							/>
							:
							<>
								<RadioButton className="mr-2" checked={answer.is_response}/>
								<span className="he-paragraph--regular gray-900">{answer.text_or_image}</span>
							</>
					}

				</AnswerItem>
			})}
		</AnswerWrapper>
	)
}
