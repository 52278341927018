import { mixed, number, object, SchemaOf, string } from 'yup';
import { User } from '@Types/User';

export const createUserSchema: SchemaOf<Omit<User, 'user_id' | 'verified' | 'progress'>> = object().shape({
    email: string().email().required(),
    firstname: string().required(),
    lastname: string().required(),
    role: mixed().oneOf(['author', 'admin', 'user', 'animator']).notRequired(),
    RPPS: string().required(),
    address: string().required(),
    job: string().required(),
    exercise_mode: string().required(),
    accept_mailings: number().min(0).max(4).required(),
    phone: string().required(),
});
