import React, {useState} from "react";
import {ForumNotification} from "@Types/Notification";
import {useResource} from "@Hooks/useResource";
import {Message, Thread} from "@Types/Message";
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import styled from "styled-components";
import {uuidToTag} from "@Pages/pages/Formations/FormationDetails/Forum/utils/messages.utils";
import {DateTime} from "luxon";
import _ from "lodash";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {v4} from "uuid";

export type ThreadProps = {
    notification: ForumNotification,
    onClose: () => void;
}
export const ThreadViewer: React.FC<ThreadProps> = (props) => {

    const {
        data: thread,
        loading,
        refresh
    } = useResource<Thread | null>(async () => {
        const messageRef = await getDoc(doc(firestore.db, 'formations', props.notification.formation_id, "messages", props.notification.message_id));
        const messageData = messageRef.data() as Message;

        const repliesRef = await getDocs(query(collection(firestore.db, 'formations', props.notification.formation_id, "messages"), where("replyTo", "==", props.notification.message_id)));

        const replies = _.sortBy(repliesRef.docs.map(doc => doc.data() as Message), "created_at");

        return {
            message: messageData,
            replies
        }
    }, null);

    const [response, setResponse] = useState<string>("");
    const onSendResponse = async () => {
        try {
            const message: Message = {
                message_id: v4(),
                content: response,
                likes: [],
                mentions: [],
                user_id: "0000",
                username: "Health Events",
                created_at: DateTime.now().toISO(),
                replyTo: thread?.message.message_id || null,
                formation_id: props.notification.formation_id,
                updated_at: DateTime.now().toISO()
            };

            await firestore.collection(`formations/${props.notification.formation_id}/messages`).set(message.message_id, message);
            setResponse("");
            refresh();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="w-full flex flex-column gap-3">
            <div className="flex justify-content-start align-items-center gap-2">
                <div className="he-header--h3">Fil de discussion</div>
                {loading && <i className="pi pi-spin pi-spinner"/>}
                <i className="pi cursor-pointer pi-times-circle ml-auto" onClick={() => props.onClose()}/>
            </div>
            {thread && (
                <>
                    <div className="he-paragraph--xs gray-600">Message d'origine</div>
                    <MessageContainer>
                        <div className="username">{thread.message.username}#{uuidToTag(thread.message.user_id)}</div>
                        <div className="msg">
                            {thread.message.content}
                        </div>
                        <div className="time flex gap-2 align-items-center">
                            <span>{DateTime.fromISO(thread.message.created_at).toFormat("dd/MM/yy HH:mm:ss")}</span>
                            {thread.message.likes.length > 0 && <span>{thread.message.likes.length} <i className="pi pi-thumbs-up" style={{fontSize: 10}}/></span>}
                        </div>
                    </MessageContainer>
                    <div className="he-paragraph--xs gray-600">Réponses</div>
                    {thread.replies.length === 0 && <div className="he-paragraph--xs gray-400">Aucune réponse</div>}
                    {thread.replies.map(reply => (
                        <MessageContainer key={reply.message_id}>
                            <div className="username">{reply.username}#{uuidToTag(reply.user_id)}</div>
                            <div className="msg">
                                {reply.content}
                            </div>
                            <div className="time flex gap-2 align-items-center">
                                <span>{DateTime.fromISO(reply.created_at).toFormat("dd/MM/yy HH:mm:ss")}</span>
                                {reply.likes.length > 0 && <span>{reply.likes.length} <i className="pi pi-thumbs-up" style={{fontSize: 10}}/></span>}
                            </div>
                        </MessageContainer>
                    ))}
                    <div className="flex justify-content-center align-items-center gap-3">
                        <InputTextarea
                            placeholder="Saisir la réponse ici"
                            value={response}
                            onChange={e => setResponse(e.target.value)}
                            style={{height: 110}}
                            onKeyDown={e => {
                                if (e.key === "Enter" && e.ctrlKey) {
                                    e.preventDefault();
                                    onSendResponse();
                                }
                            }}
                        />
                        <Button className={"he-button--secondary-variant--xs--rounded"} style={{width: 36}} onClick={() => onSendResponse()}>
                            <i className={"pi pi-send"}/>
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}


const MessageContainer = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    gap: 8px;
    border-left: 4px solid #ccc;

    & > .username {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 4px;
    }

    & > .msg {
        font-size: 0.9rem;
        white-space: pre-line;
    }
    
    & > .time {
        font-size: 0.7rem;
        color: #666;
        margin-top: 4px;
    }
`
