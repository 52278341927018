import {useNavigate, useSearchParams} from "react-router-dom";
import {useFormik} from "formik";
import {confirmAccountSchema} from "@Schemas/auth.schema";
import {CustomInput} from "@Components/CustomInput";
import {Button} from "primereact/button";
import styled from "styled-components";
import {PasswordCheck} from "@Components/PasswordCheck";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";

export type RegisterFormProps = Record<string, never>
export const RegisterForm: React.FC<RegisterFormProps> = () => {

	const navigate = useNavigate();
	const [params] = useSearchParams();
	const api = useApi();

	const [onRegister, loading] = usePromise(async (values: { password: string }) => {
		try {
			const code = params.get('code');
			if (code) {
				const result = await api.user_call_confirm({
					password: values.password,
					code
				});
				if (result.result !== "ok") throw new Error(result.result);
				navigate('/login');
			}
		} catch (e) {
			console.table(e);
			throw e;
		}
	}, {
		success: "Vous vous êtes correctement inscrit !",
		pending: "Finalisation de votre inscription en cours..."
	});

	const formik = useFormik<{ password: string }>({
		initialValues: {
			password: "",
		},
		validationSchema: confirmAccountSchema,
		onSubmit: onRegister
	});


	function onGoBack() {
		navigate('/login');
	}

	return (
		<div className="w-full h-full flex flex-column justify-content-center align-items-center">
			<FormWrapper className="bg-white">
				<div className="he-header--h1 primary-900 mb-3 text-center">
					Inscription
				</div>
				<div className="he-paragraph--regular gray-600 text-center mb-6">
					Terminez votre inscription en saisissant votre mot de passe ci-dessous, puis accédez à votre espace
					apprenant.
				</div>
				<CustomInput
					field={"password"}
					formik={formik}
					label={"Mot de passe"}
					placeholder={"Mot de passe"}
					type="password"
					showInlineError={false}
					className={"mb-3"}
				/>
				<PasswordCheck password={formik.values.password}/>
				<Button
					className="mb-3 he-button--primary--md"
					label="Terminer mon inscription"
					onClick={() => formik.submitForm()}
					disabled={loading}
					loading={loading}/>
				<div className="text-center gray-500 he-paragraph--regular--underlined">
					<span className="cursor-pointer" onClick={onGoBack}>Vous avez déjà un compte ? Connectez-vous</span>
				</div>
			</FormWrapper>
		</div>
	)
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 450px;
  height: auto;
  padding: 28px;
`;
