import React from "react";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {CustomInput} from "@Components/CustomInput";
import {forgottenPasswordSchema} from "@Schemas/auth.schema";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";

export type ForgottenPasswordProps = Record<string, never>
export const ForgottenPassword: React.FC<ForgottenPasswordProps> = () => {

	const navigate = useNavigate();

	const api = useApi();
	const [resetPassword, loading] = usePromise(async (values: { email: string }) => {
		try {
			const res = await api.user_call_reset({
				email: values.email
			});
			if (res.result !== "ok") throw new Error(res.result);
			navigate('/login');
		} catch (e: unknown) {
			console.table(e);
			throw e;
		}
	}, {
		pending: "Envoi du lien de réinitialisation en cours...",
		success: "Demande envoyée !",
		error: err => err.message
	});

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: forgottenPasswordSchema,
		onSubmit: resetPassword
	});


	function onGoBack() {
		navigate('/login');
	}

	return (
		<div className="w-full h-full flex flex-column justify-content-center align-items-center">
			<FormWrapper className="bg-white">
				<div className="he-header--h1 primary-900 mb-3 text-center">
					Mot de passe oublié
				</div>
				<div className="he-paragraph--regular gray-600 text-center mb-6">
					Indiquez votre adresse e-mail pour recevoir un mail de réinitilisation de votre mote de passe.
				</div>
				<CustomInput
					field={"email"}
					formik={formik}
					label={"Adresse e-mail"}
					placeholder={"Adresse e-mail"}
					icon={"pi pi-at"}
					iconPos={"right"}
					className={"mb-5"}
				/>
				<Button
					className="he-button--primary--md w-full mb-3"
					onClick={() => {
						console.log(formik.errors);
						formik.submitForm();
					}}
					loading={loading}
					disabled={loading}
				>
					<i className={"pi pi-refresh"}/> Réinitialiser mon mot de passe
				</Button>
				<Button
					className="he-button--secondary-variant-nfb--xs"
					onClick={onGoBack}
					disabled={loading}
				>
					<i className={"pi pi-arrow-left"}/> Retour à la connexion
				</Button>
			</FormWrapper>
		</div>
	)
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 450px;
  height: auto;
  padding: 28px;
`;
