import React, {PropsWithChildren} from "react";
import {Dialog} from "primereact/dialog";
import styled from "styled-components";

const StyledDialog = styled(Dialog)<{width?: number}>`
  max-width: ${props => props.width || 600}px;
  width: calc(100% - 56px);
  padding: 28px;
  border-radius: 10px !important;
  background: #fff;
  .p-dialog-content {
    padding: 0 !important;
    border-radius: 0 0 0 0 !important;
  }
	.p-dialog-header {
		display: none;
	}
`;

export type CustomDialogProps = {
	onHide: () => void;
	width?: number;
	closable?: boolean;
}
export const CustomDialog: React.FC<PropsWithChildren<CustomDialogProps>> = props => {

    return (
		<StyledDialog
			closable={props.closable}
			visible
			onHide={props.onHide}
			width={props.width}
			dismissableMask
		>
			{props.children}
		</StyledDialog>
    )
}
