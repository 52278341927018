import * as React from 'react';

interface Props {
    children: React.ReactNode;
}


export const Maintenance: React.FC<Props> = (props) => {

    return (
        <>

                {props.children}
          
        </>
    )
}
