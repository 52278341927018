import React, {useRef, useState} from "react";
import {Activity, VideoActivity as VideoActivityModel} from "@Types/Activity";
import ReactPlayer from "react-player";

export type VideoActivityProps = {
	activity: Activity & VideoActivityModel;
};
export const VideoActivity: React.FC<VideoActivityProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(true);

	const ref = useRef<ReactPlayer>(null);

	return (
		<div className="w-full h-full">
			<div className="he-header--h1 gray-900">{props.activity.title}</div>

			{loading && (
				<div
					className="flex justify-content-center align-items-center mt-5"
					style={{height: 250, width: 700}}
				>
					<i
						className="pi pi-spin pi-spinner gray-300"
						style={{fontSize: "3rem"}}
					/>
				</div>
			)}
			{ props.activity.video_link && <ReactPlayer
				ref={ref}
				progressInterval={30_000}
				url={props.activity.video_link}
				onReady={(a) => {
					setLoading(false)
				}}
				width={"700px"}
				style={{opacity: loading ? 0 : 1}}
				className="mt-5"
				controls
			/> }
		</div>
	);
};
