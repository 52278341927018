import { createRoot } from 'react-dom/client';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './utils/theme/theme.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { enableMapSet } from 'immer';
import { Error } from './Error';
import { Maintenance } from '@Components/Maintenance';
import { FirebaseProvider } from '@Context/FirebaseContext';
import { DevPanel } from '@Components/DevPanel';
import { addLocale } from 'primereact/api';

enableMapSet();
addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
    monthNamesShort: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
    today: "Aujourd'hui",
    clear: 'Effacer',
});

const root = createRoot(document.getElementById('root')!);

root.render(
    <Error>
        <FirebaseProvider>
            <DevPanel />
            <Maintenance>
                <BrowserRouter>
                    <div className="he-bg--primary w-full h-full flex justify-content-center">
                        <ToastContainer />
                        <App />
                    </div>
                </BrowserRouter>
            </Maintenance>
        </FirebaseProvider>
    </Error>
);
