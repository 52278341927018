import { Line } from '@Components/UI/Line';
import { SessionParticipant } from '@Types/Session';
import { classNames } from 'primereact/utils';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding: 16px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    overflow: hidden;
`;

const Title = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #475467;
`;

const Label = styled.div`
    color: var(--gray-800, #1D2939);
    font-size: 14px;
    font-family: Roboto;
    line-height: 130%;
`

export default function AnswerDetails(props: {
    answers: {
        answer: string;
        participant: SessionParticipant;
    }[],
    label?: string;
}) {

    const [showAllResults, setshowAllResults] = useState<boolean>(false);

    const shownResults = useMemo(() => {
        return showAllResults ? props.answers : props.answers.slice(0, 5);
    }, [props.answers, showAllResults]);


    return (
        <div className="w-full">
            {props.label && <Label className="mb-2">{props.label}</Label>}
        <Container className="w-full">
            <Title>Réponses textuelles</Title>
            <Line height={1} className="bg-gray-300 my-2" />
            {props.answers.length > 0 ? (
                shownResults.map((answer, i, t) => <div key={`${answer.participant.participant_id}_${i}`}>
                    <AnswserText>{answer.answer}</AnswserText>
                    <div className="mt-1">
                        <div className="he-paragraph--small color-blue">
                            {answer.participant.lastname} {answer.participant.firstname} 
                        </div>
                    </div>
                    {i < t.length-1 && <Line height={1} className="bg-gray-200 my-2" />}
                </div>)
            ) : (
                <div className="he-paragraph--regular gray-400">Aucune réponse</div>
            )}
            {
                props.answers.length > 5 &&
                <>
                    <Line height={1} className="bg-gray-300 my-3" />
                    <div className="he-paragraph--small color-blue cursor-pointer" onClick={() => setshowAllResults(prev => !prev)} >
                        <i className={classNames("pi cursor-pointer mr-2", showAllResults ? "pi-angle-up" : "pi-angle-right")} />
                        {showAllResults ? "Réduire" : "Voir toutes les réponses"}
                    </div>
                </>
            }
        </Container>
        </div>
    );
}

const AnswserText = styled.div`
    color: var(--gray-900, #101828);
    font-size: 14px;
    font-family: Inter;
    font-style: italic;
    line-height: 120%;
`