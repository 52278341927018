import * as React from 'react';
import {Button} from "primereact/button";
import styled from "styled-components";
import {ConfirmDialog} from "primereact/confirmdialog";
import {createBatch, sessionsRef, usersRef} from "@Utils/config/firebase";
import {DateTime} from "luxon";
import {InputText} from "primereact/inputtext";
import {Accordion, AccordionTab} from "primereact/accordion";
import {useState} from "react";
import {useApi} from "@Hooks/api";


interface Props {
	label: string;
}

export const FunctionItem1: React.FC<Props> = (props) => {

	/* STATES */


	/* EFFECTS */

	/* EVENT HANDLERS */

	const api = useApi();

	const invertUserFirstnameLastname = async () => {
		const users = await usersRef.where("role", "==", "user").where("verified", "==", false).get();

		const batch = createBatch();

		const updated = [];

		for (const user of users) {

			const data = {
				...user,
				firstname: user.lastname,
				lastname: user.firstname
			};
			updated.push(data);
			batch.set(usersRef.doc(user.user_id), data)
		}

		await batch.commit();

	}


	const updateSessionEndDate = async () => {

		const sessions = await sessionsRef.getAll();

		const batch = createBatch();

		for (const sessionRef of sessions.docs) {
			const session = sessionRef.data();
			const date = DateTime.fromISO(session.end_date, {zone: "Europe/Paris"}).set({hour: 23, minute: 59}).toISO();
			if (date) {
				session.end_date = date;
				batch.set(sessionRef.ref, session);
			}
		}

		await batch.commit();

	}


	const [user, setUser] = useState<string>("");
	const [formation, setFormation] = useState<string>("");
	const [session, setSession] = useState<string>("");
	const [unit, setUnit] = useState<string>("");


	const removeUserSignature = async () => {
		await api.dev_call_resetUnit({
			unit_id: unit,
			user_id: user,
			formation_id: formation,
			session_id: session
		})
	}


	/* RENDERING */

	return (
		<ItemHolder className={"component-FunctionItem flex"}>
			<Accordion className="w-full">
				<AccordionTab header="Section 1">
					<Button
						className={"w-full p-button-sm"}
						label="Invert Firstname Lastname"
						onClick={() => {
							invertUserFirstnameLastname()
						}}
					/>
					<Button
						className={"w-full mt-3 p-button-sm"}
						label="Update session end date"
						onClick={() => {
							updateSessionEndDate()
						}}
					/>
				</AccordionTab>
				<AccordionTab header="Reset user unit">
					<div className="flex flex-column mt-3 w-full" style={{gap: 8}}>
						<InputText placeholder="User ID" className="w-full" value={user} onChange={t => setUser(t.target.value)}/>
						<InputText placeholder="Formation ID" className="w-full" value={formation} onChange={t => setFormation(t.target.value)}/>
						<InputText placeholder="Session ID" className="w-full" value={session} onChange={t => setSession(t.target.value)}/>
						<InputText placeholder="Unit ID" className="w-full" value={unit} onChange={t => setUnit(t.target.value)}/>
					</div>
					<Button label="Réinitialiser l'unité" className="mt-2" onClick={removeUserSignature}/>
				</AccordionTab>
			</Accordion>
			<ConfirmDialog/>
		</ItemHolder>
	);

};


const ItemHolder = styled.article`
  display: flex;
  align-items: center;
`;
