import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import {
    Column,
    ColumnBodyOptions,
    ColumnFilterApplyTemplateOptions,
    ColumnFilterClearTemplateOptions,
    ColumnFilterElementTemplateOptions,
    ColumnProps,
} from 'primereact/column';
import styled from 'styled-components';
import { FilterMatchMode, locale, PrimeReactProvider } from 'primereact/api';
import _ from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { produce } from 'immer';
import { classNames } from 'primereact/utils';

type Header<T extends Record<string, any>> = {
    title?: string;
    actions?: Array<
        React.ReactElement | ((items: T[], reset: () => void, filter: (items: T[]) => void) => React.ReactElement)
    >;
    filters?: Array<
        React.ReactElement | ((items: T[], reset: () => void, filter: (items: T[]) => void) => React.ReactElement)
    >;
};

type Filter<T extends Record<string, any>> = {
    type: 'sort' | 'select';
    field: keyof T extends string ? keyof T : never;
    filterOptions?: (items: T[]) => Array<{ label: string; value: any }>;
    filterItemTemplate?: (item: { label: string; value: any }) => React.ReactElement;
    searchInput?: boolean;
};

type CustomColumn<T extends Record<string, any>> = {
    header?: string;
    body: (data: T, options: ColumnBodyOptions) => React.ReactElement | null;
    filter?: Filter<T>;
    size?: number;
} & Omit<ColumnProps, 'filter' | 'body'>;

type Table<T extends Record<string, any>> = {
    header?: Header<T>;
    selection?: T[];
    loading?: boolean;
    className?: string;
    onSelectionChange?: (item: T[]) => void;
    dataKey?: keyof T;
    columns: CustomColumn<T>[];
    values: T[];
    onRowClick?: {
        click?: (item: T) => void;
        ctrlClick?: (item: T) => void;
    };
    rowHover?: boolean;
    rowClassName?: string;
    defaultRowsDisplayed?: number;
    expandTemplate?: (data: T) => React.ReactElement;
    paginator?: false;
};

const DatatableHeader = styled.div`
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #d0d5dd;
`;

const MoreFiltersContainer = styled.div`
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #d0d5dd;
`;

locale('fr');

export type CustomTableProps<T extends Record<string, any>> = Table<T>;
export const CustomTable = function <T extends Record<string, any>>(props: CustomTableProps<T>) {
    const [filteredItems, setFilteredItems] = useState<T[]>(props.values);

    const [expandedRows, setExpandedRows] = useState<any>(null);

    useEffect(() => {
        resetFilters();
    }, [props.values]);

    const resetFilters = () => {
        setFilteredItems(props.values);
    };

    const applyTemplate = (options: ColumnFilterApplyTemplateOptions) => {
        return (
            <Button onClick={options.filterApplyCallback} className="he-button--primary--xs">
                Appliquer
            </Button>
        );
    };
    const clearTemplate = (options: ColumnFilterClearTemplateOptions) => {
        return (
            <Button
                label="Effacer"
                onClick={options.filterClearCallback}
                className="he-button--secondary-variant-nfb--xs"
            />
        );
    };

    const filters = useMemo<DataTableFilterMeta>(() => {
        return _.fromPairs(
            props.columns
                .filter((c) => c.filter?.type === 'select')
                .map((c) => [
                    c.filter!.field,
                    {
                        value: null,
                        matchMode: FilterMatchMode.IN,
                    },
                ])
        );
    }, [props.columns]);

    const filterTemplate = (filterOptions: Filter<T>) => (options: ColumnFilterElementTemplateOptions) =>
        filterOptions.type === 'select' ? (
            <MultiSelect
                value={options.value}
                options={filterOptions.filterOptions?.(filteredItems)}
                filter={filterOptions.searchInput}
                itemTemplate={filterOptions.filterItemTemplate}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="label"
                placeholder="Tous"
                className="p-column-filter"
            />
        ) : undefined;

    return (
        <div className={classNames('w-full h-full', props.className)}>
            {props.header && props.header.title && (
                <DatatableHeader className="flex flex-column">
                    <div className="flex align-items-center">
                        <div className="inter he-header--h3">{props.header.title}</div>
                        {props.header.actions && (
                            <div className="ml-auto flex">
                                {props.header.actions.map((action, i) => (
                                    <React.Fragment key={i}>
                                        {typeof action === 'function'
                                            ? action(props.values, resetFilters, (items: T[]) => {
                                                  setFilteredItems(items);
                                              })
                                            : action}{' '}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </DatatableHeader>
            )}
            {props.header?.filters && (
                <PrimeReactProvider
                    value={{
                        locale: 'fr',
                    }}
                >
                    <MoreFiltersContainer className="flex align-items-center justify-content-between">
                        {props.header.filters.map((action, i) => (
                            <React.Fragment key={i}>
                                {typeof action === 'function'
                                    ? action(props.values, resetFilters, (items: T[]) => {
                                          setFilteredItems(items);
                                      })
                                    : action}{' '}
                            </React.Fragment>
                        ))}
                    </MoreFiltersContainer>
                </PrimeReactProvider>
            )}
            <DataTable
                emptyMessage="Aucun résultat"
                value={filteredItems || []}
                filters={filters}
                stripedRows
                width={'100%'}
                loading={props.loading}
                expandedRows={props.expandTemplate ? expandedRows : undefined}
                cellSelection={false}
                selection={props.selection || []}
                rowHover={props.rowHover}
                scrollable
                onRowToggle={props.expandTemplate ? (e) => setExpandedRows(e.data) : undefined}
                rowClassName={() => props.rowClassName || {}}
                onRowClick={(data) => {
                    if (data.originalEvent.metaKey || data.originalEvent.ctrlKey) {
                        props.onRowClick?.ctrlClick?.(data.data as T);
                    } else {
                        props.onRowClick?.click?.(data.data as T);
                    }
                }}
                onSelectionChange={(e: any) => props.onSelectionChange?.(e.value)}
                selectionMode={props.selection ? 'checkbox' : null}
                filterDisplay={'menu'}
                dataKey={props.dataKey as string}
                paginator={props.paginator === undefined || props.paginator}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50, 100]}
                rows={props.defaultRowsDisplayed || 10}
                currentPageReportTemplate="Montre résultat {first} à {last} de {totalRecords} résultats"
                removableSort
                rowExpansionTemplate={props.expandTemplate}
            >
                {props.expandTemplate && <Column expander style={{ width: '50px' }} headerStyle={{ width: '50px' }} />}
                {props.selection && (
                    <Column selectionMode="multiple" style={{ width: '30px' }} headerStyle={{ width: '30px' }} />
                )}
                {props.columns.map((column, i) => {
                    let options: ColumnProps = {};
                    switch (column.filter?.type) {
                        case 'sort':
                            options = {
                                sortable: true,
                                sortField: column.filter.field,
                            };
                            break;

                        case 'select':
                            options = {
                                filterElement: filterTemplate(column.filter),
                                filterField: column.filter.field,
                                filter: true,
                                filterClear: clearTemplate,
                                filterMenuStyle: { width: '16rem' },
                                filterApply: applyTemplate,
                                showFilterMatchModes: false,
                            };
                            break;

                        case undefined:
                        default:
                            break;
                    }
                    const customColumn: Omit<CustomColumn<T>, 'filter'> = produce(column, (draft) => {
                        delete draft.filter;
                        return draft;
                    });
                    return (
                        <Column
                            headerStyle={{
                                width: column.size || '200px',
                            }}
                            style={{
                                width: column.size || '200px',
                            }}
                            key={i}
                            headerClassName="he-paragraph--medium gray-600"
                            bodyClassName="he-paragraph--regular gray-600"
                            {...options}
                            {...customColumn}
                        />
                    );
                })}
            </DataTable>
        </div>
    );
};
