import { CustomDialog } from '@Components/CustomDialog';
import { BackofficeContext } from '@Context/BackofficeContext';
import { SessionContext } from '@Context/SessionContext';
import { EditRecurringEvent } from '@Pages/pages/components/EditRecurringEvent';
import { EventCard } from '@Pages/pages/components/EventCard';
import { RecurringEvent, ScheduledEvent } from '@Types/ScheduledEvent';
import { Session } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { isLess } from '@Utils/date.utils';
import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

export type ScheduledEventsProps = {
    session: Session;
};
export const ScheduledEvents: React.FC<ScheduledEventsProps> = (props) => {
    const [events, setEvents] = useState<ScheduledEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<RecurringEvent>();
    const { formations } = useContext(BackofficeContext);
    const { participants } = useContext(SessionContext);

    const sessionExtended = useMemo(() => {
        return {
            ...props.session,
            formation: formations.find((f) => f.formation_id === props.session.formation_id)!,
            participants: participants,
        };
    }, [props.session, formations, participants]);

    useEffect(() => {
        setLoading(true);

        return onSnapshot(
            query(collection(firestore.db, 'events'), where('session_id', '==', props.session.session_id)),
            (data) => {
                setEvents(data.docs.map((d) => d.data() as ScheduledEvent));
                setLoading(false);
            }
        );
    }, [props.session]);

    function onNewRecurringEvent() {
        const eventId = v4();
        setDoc(doc(firestore.db, 'events', eventId), {
            event_id: eventId,
            type: 'recurring',
            mail_type: 0,
            unit_id: '',
            hour: null,
            days: [],
            session_id: props.session.session_id,
            delay: null,
        } satisfies ScheduledEvent);
    }

    return (
        <div className="ScheduledEvents">
            {selectedEvent && (
                <CustomDialog onHide={() => setSelectedEvent(undefined)}>
                    <EditRecurringEvent
                        event={selectedEvent}
                        onQuit={() => setSelectedEvent(undefined)}
                        session={props.session}
                    />
                </CustomDialog>
            )}
            <div className="he-header--h2">Relances programmées</div>
            <div className="flex mt-3 justify-content-center align-items-center">
                {loading ? (
                    <i className="pi pi-spin pi-spinner" />
                ) : (
                    <div className="w-full h-full">
                        {events.filter((e) => e.type !== 'recurring').length === 0 ? (
                            <div className="he-paragraph--small gray-500">
                                Aucune relance programmée pour cette session
                            </div>
                        ) : (
                            events
                                .filter((e) => e.type !== 'recurring')
                                .sort((a, b) => (isLess(a.date, b.date) ? -1 : 1))
                                .map((event) => {
                                    return (
                                        <EventCard
                                            key={event.event_id}
                                            event={{
                                                ...event,
                                                session: sessionExtended,
                                            }}
                                        />
                                    );
                                })
                        )}
                    </div>
                )}
            </div>
            <div className="he-header--h2 mt-5">Relances récurrentes</div>
            <div className="flex mt-3 justify-content-center align-items-center">
                {loading ? (
                    <i className="pi pi-spin pi-spinner" />
                ) : (
                    <div className="w-full h-full">
                        {events.filter((e) => e.type === 'recurring').length > 0 &&
                            events
                                .filter((e) => e.type === 'recurring')
                                .map((event) => {
                                    return (
                                        <EventCard
                                            event={{
                                                ...event,
                                                session: sessionExtended,
                                            }}
                                        />
                                    );
                                })}
                        <div className="cursor-pointer mt-3">
                            <div
                                className="shadow-1 transition-all transition-duration-200 transition-ease-in hover:shadow-2 p-2 border-round-md"
                                onClick={onNewRecurringEvent}
                            >
                                <div className="flex w-full align-items-center justify-content-center gray-500 py-2">
                                    <i className="pi pi-plus" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
