import React, {PropsWithChildren} from "react";
import styled from "styled-components";


const Container = styled.div`
  padding: 32px 20px;
  width: 100%;
  max-width: 1220px;
`;

const ContentContainer = styled.div`
  padding: 28px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
`

export type ContentLayoutProps = {
	title: string;
	subtitle: string;
}
export const ContentLayout: React.FC<PropsWithChildren<ContentLayoutProps>> = props => {
	return (
		<Container className="h-auto">
			<div className="he-header--h1 color-black">
				{props.title}
			</div>
			<div className="mt-2 gray-500 he-paragraph--regular">
				{props.subtitle}
			</div>
			<ContentContainer className="mt-5">
				{props.children}
			</ContentContainer>
		</Container>
	)
}
