import React, {useMemo} from "react";
import {useFormik} from "formik";
import styled from "styled-components";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";

const CustomDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .p-dropdown {
    height: 40px;
    background: #FFFFFF !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 8px !important;
    
    &.p-disabled {
      background: #F2F4F7 !important;
    }
    &-label {
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 115%;
      text-align: center;
    }
    .pi {
      font-size: 12px;
      color: #98A2B3;
    }
  }
`;

export type CustomFormDropdownProps = {
	field?: string;
	formik?: ReturnType<typeof useFormik<any>>;
	label?: string;
	placeholder?: string;
	options: {label: string, value: any}[]
	className?: string | (boolean | string | undefined)[];
	value?: any;
	onChange?: (value: any) => void;
	disabled?: boolean;
	filter?: boolean;
	filterBy?: string;
	showClear?: boolean;
}
export const CustomFormDropdown: React.FC<CustomFormDropdownProps> = props => {

	const defaultProps = useMemo<CustomFormDropdownProps>(() => ({
		className: [],
		...props,
	}), [props]);

    return (
        <CustomDropdownWrapper className={classNames(...(typeof defaultProps.className === "string" ? [defaultProps.className] : (defaultProps.className ?? [])) )}>
			{defaultProps.label && <label className="he-paragraph--regular gray-500 mb-2" htmlFor={defaultProps.field}>{defaultProps.label}</label>}
	        <div
		        className={classNames("w-full")}
	        >
		        <Dropdown
			        id={defaultProps.field}
			        name={defaultProps.field}
			        showClear={props.showClear}
			        options={defaultProps.options}
			        placeholder={defaultProps.placeholder}
					optionValue={"value"}
			        value={(defaultProps.formik && defaultProps.field )? defaultProps.formik.values[defaultProps.field] : defaultProps.value}
			        className="w-full"
			        filter={defaultProps.filter}
			        filterBy={defaultProps.filterBy}
			        disabled={props.disabled}
			        onChange={(event) => {
						console.log(event);
						(defaultProps.field && defaultProps.formik ) ? defaultProps.formik.setFieldValue(defaultProps.field, event.target.value, false) : props.onChange?.(event.target.value)
					}}
		        />
	        </div>
		</CustomDropdownWrapper>
    )
}
