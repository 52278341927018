import React from "react";
import {LeftMenu} from "@Pages/components/LeftMenu";
import {ContentRouter} from "@Pages/ContentRouter";
import styled from "styled-components";

export type HomeLayoutProps = Record<string, never>
export const DefaultLayout: React.FC<HomeLayoutProps> = () => {

	const subject = encodeURIComponent("[A MODIFIER] Résumé court du bug");
	const body = encodeURIComponent(`
Description du bug : 
Description détaillée avec le contexte et des captures d'écran si possible

Comment reproduire le bug :

1. Aller sur ...
2. Cliquer sur ...
3. ...

Page concernée : (url)

Version du navigateur : (chrome, firefox, safari, edge, etc...)

Version de l'OS : (windows, mac, linux, etc...)

Version de l'application : (version de l'application pour le lms, "backoffice" pour le backoffice, "scrapping" pour la récupération des mails)
		`)

    return (
	    <div className="Home w-full h-full flex">
		    <LeftMenu />
		    <ContentRouter />
			<Bug className="he-paragraph--regular" href={`mailto:contact-project+cognimap-esn-projets-health-events-40194750-issue-@incoming.gitlab.com?cc=serviceclient@healthevents.fr&subject=${subject}&body=${body}`}>
				Signaler un bug <i className="pi pi-flag"/>
			</Bug>
	    </div>
    )
}


const Bug = styled.a`
	cursor: pointer;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 12px;
	gap: 8px;
	top: 20px;
	right: 40px;
	width: max-content;
	height: 35px;
	border-radius: 15px;
	background-color: #0a1d5f;
	color: white;
	text-decoration: none;

	i {
		font-size: 14px;
		color: white;
	}
`
