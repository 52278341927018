import React from "react";
import {Line} from "@Components/UI/Line";
import {ModuleViewer} from "@Pages/pages/Formations/components/ModuleViewer";
import {Button} from "primereact/button";

export type DeleteModuleProps = {
	onDelete: () => void;
	onCancel: () => void;
	module_id: string;
}
export const DeleteModule: React.FC<DeleteModuleProps> = props => {

    return (
        <div className="DeleteModule">
	        <div className="he-paragraph--medium gray-900">Supprimer un module</div>
	        <div className="mt-4 he-paragraph--regular inter">Êtes-vous sûr de vouloir supprimer le module suivant de la formation ?</div>
	        <Line height={1} className="my-4 bg-gray-200"/>
	        <ModuleViewer module_id={props.module_id}/>
	        <Line height={1} className="my-4 bg-gray-200"/>
	        <div className="flex justify-content-end">
		        <Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" onClick={props.onCancel}/>
		        <Button className="he-button--primary--md p-button-danger" icon="pi pi-trash" label="Supprimer" onClick={props.onDelete}/>
	        </div>
        </div>
    )
}
