import React, { useContext, useMemo, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Line } from '@Components/UI/Line';
import { User } from '@Types/User';
import { UsersContext } from '@Context/UsersContext';
import { UserBadge } from '@Components/UI/UserBadge';
import { fuzzSearch } from '@Utils/search.utils';
import styled from 'styled-components';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { userRoleMap } from '@Utils/users.utils';
import { CustomInput } from '@Components/CustomInput';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useFormik } from 'formik';
import { createUserSchema } from '@Schemas/user.schema';
import { useApi } from '@Hooks/api';
import { Session } from '@Types/Session';
import { Button } from 'primereact/button';

const UserContainer = styled.div`
    padding: 14px;
    background: #f9fafb;
    border: 1px solid #eaecf0;
    border-radius: 7px;
`;

const FormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, minmax(0, auto));
    grid-gap: 14px;
`;

const HalfItem = styled.div`
    grid-column: span 1;
`;
const FullItem = styled.div`
    grid-column: span 2;
`;

const initialUser: User = {
    user_id: '',
    verified: false,
    RPPS: '',
    lastname: '',
    phone: '',
    email: '',
    firstname: '',
    role: 'user',
    address: '',
    exercise_mode: '',
    accept_mailings: 3,
    job: '',
};

export type AddParticipantProps = {
    onQuit: () => void;
    session: Session;
};
export const AddParticipant: React.FC<AddParticipantProps> = (props) => {
    const { users } = useContext(UsersContext);
    const api = useApi();

    const [filtered, setFiltered] = useState<User[]>([]);

    const [selectedUser, setSelectedUser] = useState<{ label: string; value: string }>();

    const moduleTemplate = (item: { label: string; value: string }) => (
        <UserBadge user={users.find((u) => u.user_id === item.value)!} />
    );

    const selectedDataUser = useMemo(() => {
        return users.find((u) => u.user_id === selectedUser?.value);
    }, [selectedUser]);

    const completeMethod = (query: string) => {
        setFiltered(fuzzSearch(users, query, ['firstname', 'RPPS', 'lastname', 'email']));
    };

    const loggedUser = useUser(true);

    const [importParticipant, loading] = usePromise(
        async (user: User) => {
            const res = await api.participant_call_import({
                session_id: props.session.session_id,
                participants: [user],
            });
            if (res.result !== 'ok') throw new Error(res.result);
            props.onQuit();
        },
        {
            pending: "Inscription de l'utilisateur en cours",
            success: 'Utilisateur inscrit !',
        }
    );

    const userFormik = useFormik({
        initialValues: initialUser,
        validationSchema: createUserSchema,
        onSubmit: importParticipant,
    });

    return (
        <div>
            <div className="he-header--h3">Inscrire apprenant</div>
            <div className="he-paragraph--medium mt-4">Inscrire apprenant</div>
            <div className="he-paragraph--regular gray-500 mt-3">Rechercher un utilisateur</div>
            <AutoComplete
                value={selectedUser}
                virtualScrollerOptions={{
                    items: filtered.map((user) => ({
                        label: `${user.firstname} ${user.lastname}`,
                        value: user.user_id,
                    })),
                    itemSize: 64
                }}
                field="label"
                suggestions={filtered.map((user) => ({
                    label: `${user.firstname} ${user.lastname}`,
                    value: user.user_id,
                }))}
                itemTemplate={moduleTemplate}
                completeMethod={(e) => completeMethod(e.query)}
                onChange={(e) => {
                    setSelectedUser(e.value);
                }}
                placeholder="Entrez le nom"
                className="w-full mt-2"
            />
            {selectedUser?.value && selectedDataUser && (
                <>
                    <UserContainer className="mt-3">
                        <div className={'he-paragraph--medium'}>
                            {selectedDataUser.firstname} {selectedDataUser.lastname}
                        </div>
                        <div>{selectedDataUser.email}</div>
                        <div>RPPS/ADELI: {selectedDataUser.RPPS}</div>
                        <div>{selectedDataUser.job}</div>
                        <div>{selectedDataUser.exercise_mode}</div>
                        <div>{selectedDataUser.address}</div>
                    </UserContainer>
                    <Line height={1} className="bg-gray-200 my-4" />
                </>
            )}
            {!selectedUser && (
                <>
                    <Line height={1} className="bg-gray-200 my-4" />
                    <div className="he-paragraph--medium">D'après un nouvel utilisateur</div>
                    <FormContainer className="w-full mt-3">
                        {/* LINE */}
                        <HalfItem>
                            <CustomFormDropdown
                                disabled={loggedUser.meta.role !== 'admin'}
                                field="role"
                                label="Rôle"
                                formik={userFormik}
                                options={Object.entries(userRoleMap).map(([role, translation]) => ({
                                    label: translation,
                                    value: role,
                                }))}
                            />
                        </HalfItem>
                        <HalfItem />
                        {/* LINE */}
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. Dubois'}
                                field={'lastname'}
                                label={'Nom'}
                                formik={userFormik}
                            />
                        </HalfItem>
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. Camille'}
                                field={'firstname'}
                                label={'Prénom'}
                                formik={userFormik}
                            />
                        </HalfItem>
                        {/* LINE */}
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. camille.dubois@mail.com'}
                                field="email"
                                formik={userFormik}
                                label="Adresse e-mail"
                            />
                        </HalfItem>
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. 06 06 20 15 00'}
                                field="phone"
                                formik={userFormik}
                                label="Numéro de téléphone"
                            />
                        </HalfItem>
                        {/* LINE */}
                        <FullItem>
                            <CustomInput
                                placeholder={'ex. ABCDE000000'}
                                field="RPPS"
                                formik={userFormik}
                                label="Numéro RPPS / ADELI"
                            />
                        </FullItem>
                        {/* LINE */}
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. Médecin'}
                                field={'job'}
                                label={'Profession'}
                                formik={userFormik}
                            />
                        </HalfItem>
                        <HalfItem>
                            <CustomInput
                                placeholder={'ex. Libéral'}
                                field={'exercise_mode'}
                                label={"Mode d'exercice"}
                                formik={userFormik}
                            />
                        </HalfItem>
                        {/* LINE */}
                        <FullItem>
                            <CustomInput
                                placeholder={'ex. 523, Grand Rue - 35000 Rennes'}
                                field="address"
                                formik={userFormik}
                                label="Adresse postale"
                            />
                        </FullItem>
                    </FormContainer>
                </>
            )}
            <div className="flex justify-content-end mt-4">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-2"
                    label="Annuler"
                    loading={loading}
                    onClick={props.onQuit}
                />
                <Button
                    loading={loading}
                    className="he-button--primary--md"
                    icon="pi pi-user-plus"
                    label={"Inscrire l'apprenant"}
                    onClick={() => (selectedDataUser ? importParticipant(selectedDataUser) : userFormik.submitForm())}
                />
            </div>
        </div>
    );
};
