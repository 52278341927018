import { useApi } from '@Hooks/api';
import { useToast } from '@Hooks/toast';
import { VirtualMeeting } from '@Types/Session';
import { Button } from 'primereact/button';
import { useState } from 'react';

type InviteIntervenantProps = {
    onQuit: () => void;
    selectedVirtualClass: VirtualMeeting;
};
export const InviteIntervenant: React.FC<InviteIntervenantProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const { error } = useToast();

    const onSendInvite = async () => {
        setLoading(true);
        try {
            await api.virtualClass_call_inviteIntervenant({
                virtual_class_id: props.selectedVirtualClass.virtual_class_id,
            });
            props.onQuit();
        } catch (err) {
            console.error(err);
            error("Une erreur s'est produite lors de l'envoi de l'invitation");
        } finally {
            setLoading(false);
        }
    };

    const isOkToInvite =
        props.selectedVirtualClass.intervenant &&
        props.selectedVirtualClass.email_intervenant &&
        props.selectedVirtualClass.host_url;

    return (
        <div>
            <div className="he-header--h3">Invitation intervenant</div>
            {isOkToInvite ? (
                <div className="he-paragraph--regular gray-700 mt-3">
                    Cette action va envoyer un email à l'intervenant. <br />
                    <br />
                    Ce mail contiendra un lien pour qu'il puisse se connecter à la plateforme. <br />
                    <br />
                    Souhaitez-vous continuer ?
                </div>
            ) : (
                <div className="he-paragraph--regular gray-700 mt-3">
                    Vous devez renseigner un intervenant, son email et une URL de connexion pour pouvoir envoyer une
                    invitation.
                </div>
            )}

            <div className="flex justify-content-end mt-4">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-2"
                    label={isOkToInvite ? 'Annuler' : 'Retour'}
                    disabled={loading}
                    onClick={props.onQuit}
                />
                {isOkToInvite && (
                    <Button
                        disabled={loading}
                        loading={loading}
                        className="he-button--primary--md"
                        icon="pi pi-user-plus"
                        label={"Envoyer l'invitation"}
                        onClick={onSendInvite}
                    />
                )}
            </div>
        </div>
    );
};
