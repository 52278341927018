import {array, mixed, bool, number, object, string, SchemaOf} from "yup";
import {ActivitySchema} from "@Schemas/activity.schema";
import {Regexes} from "@Utils/regex.utils";
import { Module } from "@Types/Module";

export const ModuleSchema: SchemaOf<Omit<Module, "created_at" | "updated_at">> = object().shape({
	module_id: string().required().matches(Regexes.v4),
	title: string().required(),
	cover_image_url: string().notRequired(),
	description: string().nullable().notRequired(),
	activities: array().of(ActivitySchema),
	estimated_duration: number().min(0).required(),
	estimated_duration_unit: mixed().oneOf(['s', 'j', 'h', 'm']).required(),
	minimal_duration: number().notRequired().nullable(),
	validating_quizz: bool().required().default(false),
	threshold: mixed().when('validating_quizz', {
		is: (data: boolean) => data === true,
		then: number().min(0).required(),
		otherwise: number().min(0).nullable().defined().default(null)
	}),
	quizz_type: mixed().oneOf(['default', 'initial', 'final']).required(),
	minimal_duration_unit: mixed().when('minimal_duration', {
		is: (duration?: number) => duration == undefined,
		then: schema => schema.notRequired().nullable(),
		otherwise: schema => schema.oneOf(['s', 'j', 'h', 'm']).required()
	})
})
