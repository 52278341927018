import React from "react";
import {Session, VirtualMeeting} from "@Types/Session";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {Button} from "primereact/button";
import {Line} from "@Components/UI/Line";

export type DeleteVirtualClassProps = {
	onQuit: () => void;
	session: Session;
	meeting: VirtualMeeting;
}
export const DeleteVirtualClass: React.FC<DeleteVirtualClassProps> = props => {

	const api = useApi();

	const [deleteVirtualRoom, loading] = usePromise(async () => {
		const res = await api.virtualClass_call_delete({
			virtual_class_id: props.meeting.virtual_class_id
		});

		if (res.result !== "ok") throw new Error(res.result);
		props.onQuit();
	});

	return (
		<div className="DeleteVirtualClass">
			<div className="he-header--h3 gray-900">Supprimer une classe virtuelle</div>
			<div className="mt-4 he-paragraph--regular gray-900">
				Êtes-vous sûr de vouloir supprimer la classe virtuelle ?
			</div>
			<Line height={1} className="bg-gray-200 my-4"/>
			<div className="flex justify-content-end">
				<Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" loading={loading}
				        onClick={props.onQuit}/>
				<Button loading={loading} className="he-button--primary--md p-button-danger" icon="pi pi-trash" label={"Supprimer"}
				        onClick={deleteVirtualRoom}/>
			</div>
		</div>
	)
}
