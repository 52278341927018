import styled from 'styled-components';

const Label = styled.div`
    color: var(--gray-800, #1D2939);
    font-size: 14px;
    font-family: Roboto;
    line-height: 130%;
`

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 12px;
    height: 28px;
    position: relative;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
`;

const ProgressBar = styled.div<{ progress: number }>`
    position: absolute;
    height: 28px;
    left: 0px;
    top: 0px;
    background: #ebf4fc;
    border-radius: 0px 20px 20px 0px;
    width: ${(props) => props.progress}%;
`;

export default function ScoreDetails(props: {
    satisfaction: any[];
    label: string;
    answersLabels: { [k: string]: string };
    scale: string[];
}) {
    const groupByNote = props.satisfaction.reduce<{ [key: string]: number }>((acc, note) => {
        const key = note === null ? 'null' : note.toString();
        if (!acc[key]) acc[key] = 0;
        acc[key]++;
        return acc;
    }, props.scale.reduce<{ [key: string]: number }>((acc, note) => ({ ...acc, [note]: 0 }), {}));

    return (
        <div className="w-full flex flex-column">
            <Label>{props.label}</Label>
            {Object.entries(groupByNote).map(([note, count]) => {
                return (
                    <Bar id={note} key={note}>
                        <div className="he-paragraph--regular gray-700" style={{ zIndex: 1000 }}>
                            {props.answersLabels[note.toString()]} &nbsp;{' '}
                            {props.satisfaction.length > 0
                                ? ((count / props.satisfaction.length) * 100).toFixed(0)
                                : ' - '}{' '}
                            %
                        </div>
                        <div className="he-paragraph--regular gray-400" style={{ zIndex: 1000 }}>
                            {' '}
                            {count} réponses
                        </div>
                        <ProgressBar progress={(count / props.satisfaction.length) * 100} />
                    </Bar>
                );
            })}
        </div>
    );
}
