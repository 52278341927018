import React, {useState} from "react";
import {CustomDialog} from "@Components/CustomDialog";
import {doc, setDoc} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {useToast} from "@Hooks/toast";
import {CustomTable} from "@Components/CustomTable";
import {InputText} from "primereact/inputtext";
import {CustomInput} from "@Components/CustomInput";
import {Button} from "primereact/button";
import {sortBy, uniqBy} from "lodash";

const uniqLc = (str: string[]) => sortBy(uniqBy(str, (s) => s.toLowerCase()))

export type NoteTitlesDialogProps = {
    titles: string[];
    onClose: () => void;
}
export const NoteTitlesDialog: React.FC<NoteTitlesDialogProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLine, setSelectedLine] = useState<number | null>(null);
    const [editedWord, setEditedWord] = useState<string>("");
    const [searchFilter, setSearchFilter] = useState<string>("");

    const {success} = useToast();
    const onDelete = async (word: string) => {
        setLoading(true);
        try {
            await setDoc(doc(firestore.db, "settings", "noteTitles"), {
                formations: uniqLc(props.titles.filter(t => t !== word))
            });
            success("Formation supprimée avec succès");
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    const filteredTitles = searchFilter.length > 0 ? props.titles.filter(t => t.toLowerCase().includes(searchFilter.toLowerCase())) : props.titles;
    const onNewFormation = async () => {
        setLoading(true)
        try {
            await setDoc(doc(firestore.db, "settings", "noteTitles"), {
                formations: uniqLc(["00 - Nouvelle Formation", ...props.titles])
            });
            setSearchFilter("");
            success("Nouvelle formation ajoutée avec succès");
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const onSave = async () => {
        if (selectedLine !== null) {
            setLoading(true);
            const updatedTitles = props.titles.map(t => t === props.titles[selectedLine] ? editedWord : t);
            try {
                await setDoc(doc(firestore.db, "settings", "noteTitles"), {
                    formations: uniqLc(updatedTitles)
                });
                success("Titres mis à jour avec succès");
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
                setSelectedLine(null);
                setEditedWord("");
            }
        }
    }

    return (
        <CustomDialog onHide={() => {
            props.onClose();
        }} closable width={900}>
            <div className="flex flex-column gap-2">
                <CustomTable
                    header={{
                        title: "Liste des formations",
                        actions: [
                            () => {
                                return <CustomInput value={searchFilter} onChange={setSearchFilter} icon={"pi pi-search"} placeholder={"Rechercher une formation"}/>
                            },
                            () => {
                                return <Button loading={loading} disabled={loading} className="he-button--secondary--md ml-3" onClick={onNewFormation}>
                                    Nouvelle formation
                                    <i className="pi pi-plus"/>
                                </Button>
                            },
                        ]
                    }}
                    columns={[
                        {
                            header: "Formation",
                            sortable: true,
                            field: "word",
                            body: (word, options) => {
                                const wordIndex = props.titles.findIndex(t => t === word.word);
                                return <div className="flex gap-2 align-items-center">
                                    {
                                        selectedLine !== null && selectedLine === wordIndex ?
                                            <InputText value={editedWord} onChange={(e) => {
                                                setEditedWord(e.target.value);
                                            }}/>
                                            :
                                            <div>{word.word}</div>
                                    }
                                    {
                                        selectedLine !== null && wordIndex === selectedLine ?
                                            <>
                                                <i className={!loading ? "pi pi-save cursor-pointer px-2 primary-600" : "pi pi-spin pi-spinner px-2 gray-600"}
                                                   style={{fontSize: 14}} onClick={onSave}/>
                                                <i className={!loading ? "pi pi-times cursor-pointer px-2 color-red" : "pi pi-spin pi-spinner px-2 gray-600"}
                                                   style={{fontSize: 14}} onClick={() => {
                                                    setSelectedLine(null);
                                                    setEditedWord("");
                                                }}/>
                                            </>
                                            :
                                            <>
                                                <i className="ml-auto pi pi-pencil cursor-pointer px-2 primary-600"
                                                   style={{fontSize: 14}} onClick={() => {
                                                    setSelectedLine(props.titles.findIndex(t => t === word.word));
                                                    setEditedWord(word.word);
                                                }}/>
                                                <i className="pi pi-trash cursor-pointer px-2"
                                                   style={{color: "red", fontSize: 14}}
                                                   onClick={() => onDelete(word.word)}
                                                />
                                            </>

                                    }
                                </div>
                            }
                        },
                    ]}
                    values={filteredTitles.map(t => ({
                        word: t
                    }))}/>
            </div>

        </CustomDialog>
    )
}
