import React, {useState} from "react";
import {Session, SessionParticipant} from "@Types/Session";
import {DateTime} from "luxon";
import { Calendar } from "primereact/calendar";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";

export type UpdateEndDateProps = {
	onQuit: () => void;
	session: Session;
	participants: SessionParticipant[];
}
export const UpdateEndDate: React.FC<UpdateEndDateProps> = props => {

	const [date, setDate] = useState<string | null>(props.participants.length > 1 ? null : (props.participants.length === 1 ? props.participants[0].end_date : props.session.end_date));

	const api = useApi();

	const [updateParticipant, loading] = usePromise(async (date: Date) => {

		for (const participant of props.participants) {
			const endDate = DateTime.fromJSDate(date).setZone("Europe/Paris").endOf("day").toISO();
			if (endDate) {
				const res = await api.participant_call_update({
					participant: {
						...participant,
						end_date: endDate,
					},
					session_id: props.session.session_id
				})

			if (res.result !== "ok") throw new Error(res.result);
			}
		}
		props.onQuit();
	}, {
		pending: "Modification en cours...",
		success: "Modification de la date de fin de session effectuée",
	})

    return (
        <div className="UpdateEndDate">
            <div className="he-header--h3">
	            Prolonger l'accès si l'apprenant a terminé
            </div>
	        <div className="flex justify-content-between mt-4">
		        <div className="flex-1 mr-3">
			        <div className="he-paragraph--regular inter gray-500">Date officielle de fin de session</div>
			        <div className="he-paragraph--regular inter gray-900" style={{marginTop: 18}}>{DateTime.fromISO(props.session.end_date).toFormat("dd/MM/yyyy")}</div>
		        </div>
		        <div className="flex-1">
			        <div className="he-paragraph--regular inter gray-500">Date de fin de session attribuée</div>
			        <Calendar className="mt-2" value={date ? DateTime.fromISO(date).toJSDate() : null} onChange={e => e.value && setDate(DateTime.fromJSDate(e.value).toISO())} dateFormat={"dd/mm/yy"} minDate={new Date(props.session.end_date)}/>
		        </div>
	        </div>
	        <Line height={1} className={"bg-gray-200 my-4"} />
	        <div className="flex justify-content-end mt-4">
		        <Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" loading={loading}
		                onClick={props.onQuit}/>
		        <Button loading={loading} className="he-button--primary--md" icon="pi pi-save" label={"Enregistrer"}
						disabled={date == undefined}
		                onClick={() => date && updateParticipant(DateTime.fromISO(date).toJSDate())}/>
	        </div>
        </div>
    )
}
