import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { Line } from '@Components/UI/Line';
import { UsersContext } from '@Context/UsersContext';
import { usePromise } from '@Hooks/promise';
import { VirtualClassEvent } from '@Types/ScheduledEvent';
import { Session, SessionParticipant, VirtualMeeting } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { ExtendedParticipant } from '../ParticipantList';
import { useApi } from '@Hooks/api';

type Props = {
    onQuit: () => void;
    participants: (SessionParticipant | ExtendedParticipant)[];
    session: Session;
};
export const SendRelanceVirtualClass = (props: Props) => {
    const [date, setDate] = useState<Nullable<Date>>(null);
    const { users } = useContext(UsersContext);
    const [selectedVirtualClass, setSelectedVirtualClass] = useState<string | null>(null);
    const api = useApi();

    const participants = useMemo(() => {
        return props.participants.map((p) => ({
            ...p,
            valid: !p.blocked,
            accept: users.find((u) => u.user_id === p.participant_id)?.accept_mailings,
        }));
    }, [props.participants, users]);

    const [virtualClasses, setVirtualClasses] = useState<VirtualMeeting[]>([]);

    const loadVirtualClasses = useCallback(async () => {
        const res = await getDocs(
            collection(
                firestore.db,
                `formations/${props.session.formation_id}/sessions/${props.session.session_id}/virtual_classes`
            )
        );
        const virtualClasses = res.docs.map((d) => d.data() as VirtualClassEvent);
        setVirtualClasses(virtualClasses);
    }, [props.session.formation_id, props.session.session_id]);

    useEffect(() => {
        loadVirtualClasses();
    }, [loadVirtualClasses]);

    const [onCreateEvent, loading] = usePromise(
        async (
            participants: (SessionParticipant & { valid: boolean })[],
            selectedVirtualClass: string,
            date: Nullable<Date>
        ) => {
            const groupedBySession = _.groupBy(participants, 'session_id');

            for (const [session_id, participants] of Object.entries(groupedBySession)) {
                const id = v4();
                if (date instanceof Date) {
                    const formattedDate = DateTime.fromJSDate(date).setZone('Europe/Paris').toISO();
                    if (formattedDate) {
                        await setDoc(doc(firestore.db, `events/${id}`), {
                            event_id: id,
                            session_id,
                            date: formattedDate,
                            virtual_class_id: selectedVirtualClass,
                            mail_type: 0,
                            content: '',
                            type: 'virtual_class',
                            user_ids: participants.filter((p) => p.valid).map((p) => p.participant_id),
                        } satisfies VirtualClassEvent);
                    }
                }
            }

            props.onQuit();
        },
        {
            pending: 'Programmation des mails en cours...',
            success: 'Les relances ont bien été programmées.',
        }
    );

    const [onSendNow, loadingSend] = usePromise(
        async (participants: (SessionParticipant & { valid: boolean })[], selectedVirtualClass: string) => {
            const groupedBySession = _.groupBy(participants, 'session_id');

            for (const [session_id, participants] of Object.entries(groupedBySession)) {
                // send mail groupé

                await api.virtualClass_call_sendRelance({
                    session_id: session_id,
                    user_ids: participants.filter((p) => p.valid).map((p) => p.participant_id),
                    virtual_class_id: selectedVirtualClass,
                });
            }

            props.onQuit();
        },
        {
            pending: 'Envoi des mails en cours...',
            success: 'Les mails ont correctement étés envoyés',
        }
    );

    const virtualClassOptions = useMemo(() => {
        return virtualClasses
            .filter((v) => !!v.date && !!v.intervenant && !!v.user_url)
            .map((vc) => ({
                label: `Classe virtuelle du ${DateTime.fromISO(vc.date!, { zone: 'Europe/Paris' }).toFormat(
                    'dd/MM/yyyy HH:mm'
                )}`,
                value: vc.virtual_class_id,
            }));
    }, [virtualClasses]);

    return (
        <div>
            <div className="he-header--h3 mb-4">
                Relancer ou programmer une relance pour les apprenants sélectionnés
            </div>

            <div className="he-paragraph--regular gray-900 mb-3">
                <div>Souhaitez-vous relancer les apprenants inscrits sélectionnés par mail ?</div>
                <div className="mt-3">
                    {participants.map((p) => (
                        <li
                            key={p.participant_id}
                            className={
                                p.accept !== 3 && p.accept !== 4
                                    ? 'color-red'
                                    : !p.valid
                                    ? 'gray-300 cursor-default'
                                    : ''
                            }
                        >
                            {p.firstname} {p.lastname}&nbsp;
                        </li>
                    ))}
                </div>
            </div>
            <CustomFormDropdown
                label="Sélectionner une classe virtuelle"
                options={virtualClassOptions}
                value={selectedVirtualClass}
                onChange={(e) => setSelectedVirtualClass(e)}
                placeholder={'Sélectionner une classe virtuelle'}
            />
            <div className="flex">
                <div className={'mr-4'}>
                    <div className="he-paragraph--regular mt-4">Date d'envoi</div>
                    <Calendar
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        showTime
                        placeholder={'ex. 01/01/2023'}
                        dateFormat={'dd/mm/yy'}
                        className={'mt-2'}
                        minDate={new Date()}
                    />
                </div>
            </div>
            <Line height={1} className={'bg-gray-200 my-4'} />
            <div className="flex justify-content-end mt-4">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-3"
                    label="Annuler"
                    loading={loading || loadingSend}
                    onClick={props.onQuit}
                />
                <Button
                    disabled={date == null || selectedVirtualClass == undefined}
                    loading={loading}
                    className="he-button--primary--md mr-3"
                    icon="pi pi-clock"
                    label={'Programmer'}
                    onClick={() => selectedVirtualClass && onCreateEvent(participants, selectedVirtualClass, date)}
                />
                <Button
                    disabled={selectedVirtualClass == undefined}
                    loading={loadingSend}
                    className="he-button--primary--md"
                    icon="pi pi-send"
                    label={'Envoyer'}
                    onClick={() => selectedVirtualClass && onSendNow(participants, selectedVirtualClass)}
                />
            </div>
        </div>
    );
};
