import React, {CSSProperties, useEffect, useMemo} from "react";
import "./CustomTabView.scss";
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";
import {useSearchParams} from "react-router-dom";

export type CustomTabViewProps = {
	className?: string;
	selected: number;
	onChange: (selected: number) => void;
	tab_id?: string;
	style?: CSSProperties;
	items: ({
		label: string;
		badge?: number;
		icon?: string;
		action?: (index: number) => void;
	} | null | undefined)[];
}
export const CustomTabView: React.FC<CustomTabViewProps> = (props) => {

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
	    if (searchParams.get(props.tab_id ?? 'tab')) {
	        props.onChange(parseInt(searchParams.get(props.tab_id ?? 'tab') ?? "0"));
	    }
	}, []);
	const render = useMemo(() => {
		return props.items && props.items.map((item, i) => {
			return item && <div
				className={classNames(
					"CustomTabView-item he-paragraph--regular flex align-items-center cursor-pointer",
					props.selected === i && "CustomTabView-item-selected",
					item.action && "CustomTabView-item-action he-button--primary-nfb--xs"
				)}
				onClick={() => {
					setSearchParams({
						...Object.fromEntries(searchParams.entries()),
						[props.tab_id ?? "tab"]: i.toString()
					})
					item.action ? item.action(i) : props.onChange(i)
				}}
				key={i}>
				{item.icon && <i className={item.icon} />}
				{item.label}
				{item.badge && <Badge value={item.badge} className="ml-2" />}
			</div>
		})
	}, [props.selected, props.items])

	return (
		<div className={classNames("CustomTabView w-full flex", props.className)}>
			{render}
		</div>
	)
}
