import React from "react";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";

export type DeleteUnitProps = {
	onDelete: () => void;
	onCancel: () => void;
	index: number;
}
export const DeleteUnit: React.FC<DeleteUnitProps> = (props) => {

    return (
	    <div>
		    <div className="he-paragraph--medium gray-900">Supprimer une unité</div>
		    <div className="mt-4 he-paragraph--regular inter">Êtes-vous sûr de vouloir supprimer l'unité {props.index+1} ?</div>
		    <Line height={1} className="my-4 bg-gray-200"/>
		    <div className="flex justify-content-end">
			    <Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" onClick={props.onCancel}/>
			    <Button className="he-button--primary--md p-button-danger" icon="pi pi-trash" label="Supprimer" onClick={props.onDelete}/>
		    </div>
	    </div>
    )
}
