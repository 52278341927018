import React, {PropsWithChildren, useEffect, useState} from 'react';
import {FirebaseApp} from "firebase/app";
import {User as FirebaseUser} from "firebase/auth";
import {Roles, User} from "@Types/User";
import {appFirebase, auth, usersRef} from "@Utils/config/firebase";
import {useToast} from "@Hooks/toast";

export interface IFirebaseContext {
    user ?: { firebase?: FirebaseUser, meta?: User },
}

export const FirebaseContext = React.createContext<IFirebaseContext | null>(null)

export const FirebaseProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const [app] = useState<FirebaseApp>(appFirebase);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<IFirebaseContext["user"]>(undefined);
    const [userAuth, setUserAuth] = useState<FirebaseUser | null | undefined>(undefined);

	const {error} = useToast();

    useEffect(() => {
	    return auth.onAuthStateChanged((_userAuth) => {
	        if (_userAuth) {
				_userAuth.getIdTokenResult().then(res => {
					if ((["admin", "author", "animator"] as Array<Roles>).includes(res.claims.role as Roles)) {
						setUserAuth(_userAuth)
					} else {
						error("Accès non autorisé")
						auth.signOut();
						setUserAuth(null);
					}
					setLoading(false);
				})
	        } else {
		        setUser({})
		        setLoading(false);
	        }
        });
    }, [app]);

    useEffect(() => {
        if (userAuth) {
            return usersRef.onSnapshot(userAuth.uid, (user) => {
				const userData = user.data()!;
                setUser({
                    firebase: userAuth,
                    meta: userData
                })
            })
        }
		return () => {};
    }, [userAuth])


	return <FirebaseContext.Provider value={{user}}>
		{!loading && (user !== undefined) && props.children}
	</FirebaseContext.Provider>;

}
