import React, {useState, useEffect} from "react";

export type AsyncProps = Record<string, never>
export function Async<T>(props: {
	default: T,
	promise?: Promise<T>,
	render?: (v: T) => React.ReactElement
}) {

	const [loading, setLoading] = useState<boolean>(false);

	const [resolved, setResolved] = useState<T>(props.default);

	useEffect(() => {
		setLoading(true)
		props.promise?.then(setResolved).catch(e => {
			console.error(e);
		}).finally(() => {
			setLoading(false);
		});
	}, [props.promise]);

	return loading ?
		<i className="pi pi-spin pi-spinner"/> :
		props.render?.(resolved) || <div> - </div>
}
