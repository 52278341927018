import React from "react";
import {CustomInput} from "@Components/CustomInput";
import {TCSActivity} from "@Types/Activity";
import {useFormik} from "formik";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import _ from "lodash";

const defaultScale = [
	"Fortement négatif",
	"Négatif",
	"Neutre",
	"Positif",
	"Fortement positif"
];

export type HypothesisLabelFormProps = {
	tcs: TCSActivity;
	onUpdate: (l: TCSActivity["labels"]) => void;
	onCancel: () => void;
}
export const HypothesisLabelsForm: React.FC<HypothesisLabelFormProps> = props => {

	const hypoFormik = useFormik({
		initialValues: props.tcs.labels || {},
		enableReinitialize: true,
		onSubmit: props.onUpdate
	})

	return (
		<div className="HypothesisForm">
			<div className="he-header--h3 gray-900">Modifier l'hypothèse</div>
			<div className="mt-4">
				<CustomInput label="Hypothèse" field={"hypothesis"} formik={hypoFormik}/>
			</div>
			<div className="mt-3">
				<CustomInput label="Nouvelle information" field={"new_information"} formik={hypoFormik}/>
			</div>
			<div className="mt-4 he-paragraph--medium gray-900">
				Nombre de réponse
			</div>
			<div className="he-paragraph--regular gray-500 mt-2">
				<i className="pi pi-exclamation-circle"/> Indiquez le nombre de réponses données par les experts
			</div>
			<div className="flex flex-column mt-3">
				{_.range(0,5).map((i) => {
					return <CustomInput
						key={i}
						label={(props.tcs.labels as any | undefined)?.[i] || defaultScale[i]}
						value={(hypoFormik.values as any)[i]}
						className="mt-2"
						onChange={e => hypoFormik.setFieldValue(i.toString(), e, false)}
					/>
				})}
			</div>
			<Line height={1} className={"bg-gray-200 my-4"}/>
			<div className="flex justify-content-end">
				<Button className="he-button--secondary-variant-nfb--md mr-3" label="Annuler" onClick={props.onCancel}/>
				<Button className="he-button--primary--md" icon="pi pi-save" label="Enregistrer" onClick={hypoFormik.submitForm}/>
			</div>
		</div>
	)
}
