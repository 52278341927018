import React from "react";
import {Session} from "@Types/Session";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";

export type ArchiveSessionProps = {
	onQuit: () => void;
	session: Session;
}
export const ArchiveSession: React.FC<ArchiveSessionProps> = props => {

	const api = useApi();

	const [onArchive, loading] = usePromise(async () => {
		const res = await api.session_call_update({
			session: {
				...props.session,
				archived: !props.session.archived,
			}
		});

		if (res.result !== "ok") throw new Error(res.result);

		props.onQuit();
	})

	return (
		<div className="ArchiveSession">
			<div className="he-paragraph--medium gray-900">{props.session.archived ? "Désarchiver" : "Archiver"} la session</div>
			<div className="he-paragraph--regular gray-800 mt-4">
				Souhaitez-vous {props.session.archived ? "désarchiver" : "archiver"} la session {props.session.session_custom_id}
			</div>
			<Line height={1} className={"bg-gray-200 my-4"} />
			<div className="flex justify-content-end">
				<Button className="he-button--secondary-variant-nfb--md mr-2" label={"Annuler"} onClick={props.onQuit}/>
				<Button className="he-button--primary--md" icon="pi pi-inbox" label={props.session.archived ? "Désarchiver" : "Archiver"} loading={loading} onClick={onArchive}/>
			</div>
		</div>
	)
}
