import React, {useEffect, useMemo, useState} from "react";
import {Activity, TCSActivity as TCSActivityModel} from "@Types/Activity";
import styled from "styled-components";
import {RadioButton} from "primereact/radiobutton";
import {Resource} from "@Types/Resource";
import {Image} from "primereact/image";
import {getUrlForResource} from "@Utils/resource.utils";
import {Galleria} from "primereact/galleria";
import {TcsScale} from "@Pages/pages/Formations/PreviewFormation/ActivityPreview/QuizzActivity/components/TcsScale";
import { CustomImage } from '@Components/CustomImage';

const DescriptionContainer = styled.div`
  background: #F9FAFB;
  border-radius: 8px;
  padding: 20px;
`;

const QuizzContainer = styled.div`
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 20px;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
  grid-template-rows: auto 1px auto;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-areas: 
		"hypothesis information info info info info info"
		"line line line line line line line"
		". . l0 l1 l2 l3 l4";
`;

const HypothesisContainer = styled.div`
  display: grid;
  grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
  grid-template-rows: minmax(30px, auto);
  column-gap: 8px;
  grid-auto-rows: minmax(30px, auto);
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #EAECF0;
  border-radius: 8px;
`;


const defaults = ["Fortement négatif", "Négatif", "Neutre", "Positif", "Fortement positif"];

const colors = ["#EA2828", "#EAA828", "#EAE828", "#A3CC2E", "#2ECC71"];

const DisplayScore = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
`

const Percent = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 130%;
  color: #667085;
`;

export type TCSActivityProps = {
	activity: Activity & TCSActivityModel,
}

export const TCSActivity: React.FC<TCSActivityProps> = (props) => {


	const color = useMemo(() => {
		const index = Math.floor(100 / 100 * 5);
		return colors[index >= colors.length ? colors.length - 1 : index];
	}, [])

	const itemTemplate = (item: Resource) => {
		return <Image preview src={item?.resource_url} alt={item?.resource_name} width='auto' height={"200px"}
		              className="pb-5"/>
	}

	const thumbnailTemplate = (item: Resource) => {
		return <img src={item.resource_url} alt={item.resource_name} style={{width: 'auto', height: "70px"}}/>
	}

	const [images, setImages] = useState<(null | Resource | undefined)[]>([]);

	useEffect(() => {
		if (props.activity.cover_image_url) {
			Promise.all(props.activity.cover_image_url.map(async (i?: string | null) => {
				if (i) {
					return getUrlForResource(i);
				}
				return null;
			})).then(imgs => {
				setImages(imgs.filter(e => !!e));
			})
		}
	}, [props.activity]);

	const responsiveGalleriaOptions = [
		{
			breakpoint: '768px',
			numVisible: 2
		},
		{
			breakpoint: "450px",
			numVisible: 1
		}
	];

	return (<div className="w-full h-full relative">
			<div className="he-header--h1 gray-900">
				{props.activity.title}
			</div>
			<div className="he-paragraph--small gray-500 text-justify mt-2">
				Les TCS évaluent votre capacité à prendre des décisions pertinentes en situation d’incertitude. Ces
				vignettes sont suivies par des propositions d’options ou d’hypothèses possibles.
				Pour chaque hypothèse, une nouvelle information est proposée : vous devez alors décider si elle
				augmente, diminue ou est sans conséquence sur la pertinence de cette hypothèse.
			</div>
			<DescriptionContainer className="mt-4 z-2 shadow-1">
				<div className="he-paragraph--medium gray-800">Cas clinique</div>
				<div className="he-paragraph--regular-16 gray-600 mt-3">{props.activity.description}</div>
			</DescriptionContainer>
			{
				images.length > 0 &&
				<div className="bg-gray-600 pt-5 mt-3">
					<Galleria circular responsiveOptions={responsiveGalleriaOptions} value={images} numVisible={5}
							  item={itemTemplate} style={{height: "100%"}} thumbnail={thumbnailTemplate}/>
				</div>
			}
			{<QuizzContainer className="mt-3">
                <HeaderContainer>
                    <div style={{gridArea: "hypothesis"}}
                         className="w-full he-paragraph--small primary-700">{props.activity.labels?.hypothesis || "Si vous pensiez à..."}</div>
                    <div style={{gridArea: "information"}}
                         className="he-paragraph--small primary-700">{props.activity.labels?.new_information || "Et que vous trouvez que/qu'..."}</div>
                    <div style={{gridArea: "info"}}
                         className="he-paragraph--small primary-700">{"L’impact de cette information sur votre hypothèse est..."}</div>
                    <div style={{gridArea: "line", borderBottom: "1px solid #EAECF0"}}></div>
					{defaults.map((text, i) => {
						const label = props.activity.labels && props.activity.labels[i as 1 | 2 | 3 | 4 | 0];
						return <div key={i} style={{gridArea: `l${i}`, wordBreak: "break-word"}}
						            className="he-paragraph--xs gray-500 text-center">{label || text}</div>
					})}
                </HeaderContainer>
				{props.activity.hypothesis.map((h, hi) => {
					return <HypothesisContainer className="bg-gray-50 mt-2" key={hi}>
						<div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 pl-3 align-items-center" style={{wordBreak: "break-word"}}>
							{h.initial}
							{h.initial_image && <CustomImage urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)} width="80"/>}
						</div>
						<div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 h-full align-items-center" style={{wordBreak: "break-word"}}>
							{h.information}
							{h.information_image && <CustomImage urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)} width="80"/>}
						</div>
						{h.answers.map((a, ai) => {
							return <div key={ai}
							            className="h-full flex flex-column py-3 align-items-center justify-content-center">
								<RadioButton
								             checked={Math.max(...props.activity.hypothesis[hi].answers) === props.activity.hypothesis[hi].answers[ai]}
								             disabled={true}/>
								<div className="mt-2 he-paragraph--small gray-600">
                                    ({props.activity.hypothesis[hi].answers[ai]})
                                </div>
							</div>
						})}
					</HypothesisContainer>
				})}
            </QuizzContainer>}
			<ScoreContainer className="bg-white mt-3">
                <div className="flex flex-column align-items-center mb-4">
                    <span className="he-paragraph--small gray-500">VOTRE SCORE :</span>
                    <div>
                        <DisplayScore style={{color: color}}
                                      className="roboto">{new Intl.NumberFormat("fr-FR", {maximumFractionDigits: 2}).format(100)} points</DisplayScore>
                        <Percent
                            className="roboto">/100</Percent>
                    </div>
                </div>
                <div className="w-full">
                    <TcsScale score={100} colors={colors}/>
                </div>
            </ScoreContainer>
		</div>)
}
