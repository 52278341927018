import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import logo from "@Assets/images/logo-blue.svg";
import logoSquare from "@Assets/images/logo-square.svg";
import {classNames} from "primereact/utils";
import {useNavigate, useParams} from "react-router-dom";
import {auth, firestore} from "@Utils/config/firebase";
import {useUser} from "@Hooks/firebase";
import {motion} from "framer-motion";
import {ForumNotification} from "@Types/Notification";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {Badge} from "primereact/badge";

type SectionOption = {
	icon: string;
	label: string;
	url: string;
	badge?: number;
	id: string;
}

type Section = {
	label: string;
	options: SectionOption[]
}

const LeftMenuContainer = styled(motion.div)`
	overflow: hidden;
	&.reduced {
		width: 60px;
	}

	&.expanded {
		width: 250px;
	}
	border-right: 1px solid #EAECF0;
`

const OptionContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px;
  ${props => props.selected ? "border: 1px solid #D0D5DD;" : "border: 1px solid transparent;"}
  background: ${props => props.selected ? "#F2F4F7" : "white"};
  border-radius: 6px;
  cursor: pointer;
`

const SectionContainer = styled.div`
	padding: 12px 0;

	&:not(:first-child) {
		border-top: 1px solid #EAECF0;
	}
`;



export type LeftMenuProps = Record<string, never>
export const LeftMenu: React.FC<LeftMenuProps> = () => {

	const params = useParams<{ "*": string }>();
	const navigate = useNavigate();

	const {meta} = useUser(true);
	const [isReduced, setIsReduced] = useState<boolean>(false);

	const [notifications, setNotifications] = useState<ForumNotification[]>([]);

	useEffect(() => {
		return onSnapshot(
			query(
				collection(firestore.db, "notifications"),
				where("status", "==", "Non traité")
			),
			(snapshot) => {
				const data = snapshot.docs.map(doc => doc.data() as ForumNotification);
				setNotifications(data);
			}
		)
	}, []);


	const sections: Section[] = useMemo( () => [
		{
			label: "Sessions",
			options: [
				{
					label: "Sessions de formation",
					id: "sessions",
					icon: "pi pi-calendar",
					url: "/sessions"
				},
				...["admin", "author"].includes(meta.role) ? [{
					label: "Relances automatiques",
					id: "relances",
					icon: "pi pi-send",
					url: "/relances"
				}] : [],
				...["admin", "author"].includes(meta.role) ? [{
					label: "Sessions archivées",
					id: "archived",
					icon: "pi pi-inbox",
					url: "/archived"
				}] : [],
				{
					label: "Statistiques globales",
					id: "statistics",
					icon: "pi pi-chart-bar",
					url: "/statistics"
				},
				...["admin", "author"].includes(meta.role) ? [{
					label: "Forum",
					id: "forum",
					icon: "pi pi-comments",
					badge: notifications.length,
					url: "/forum"
				}] : []
			]
		},
		...["admin", "author"].includes(meta.role) ? [{
			label: "Bibliothèque",
			options: [
				{
					label: "Formations",
					id: "formations",
					icon: "pi pi-database",
					url: "/formations"
				},
				...meta.role === "admin" ? [{
					label: "Modules",
					id: "modules",
					icon: "pi pi-circle-off",
					url: "/modules"
				}] : []
			]
		}] : [],
		{
			label: "Utilisateurs",
			options: [
				{
					label: "Utilisateurs",
					icon: "pi pi-users",
					id: "users",
					url: "/users"
				}
			]
		},
	], [meta, notifications]);
	const onOptionClick = (option: SectionOption) => {
		navigate(option.url);
	}
	const selected = useMemo(() => {
		if (!params["*"] || params["*"] === "") return "sessions";
		const {page} = /^(?<page>\w+)(?:\/.+)?$/.exec(params['*'])?.groups || {}
		return page;
	}, [params])

	return (
		<LeftMenuContainer
			layout
			transition={{
				type: "spring",
				stiffness: 550,
				damping: 30
			}}
			className={classNames("px-3 h-full bg-white flex flex-column align-items-start", isReduced ? "reduced" : "expanded")}
		>
			<div className="mt-3 flex w-full justify-content-end gray-500 cursor-pointer align-items-center">
				<i
					className={
						isReduced ? "pi pi-angle-double-right" :
							"pi pi-angle-double-left"
					}
					onClick={() => setIsReduced(prev => !prev)}
					style={{
						width: 30,
						height: 28,
						textAlign: "center",
						padding: 6,
						borderRadius: 16
					}}
				/>
			</div>
			<div className="mt-4 flex justify-content-center">
				<img src={isReduced ? logoSquare : logo} style={{width: isReduced ? 28 : 185}}/>
			</div>
			<div className="mt-2 w-full">
				{sections.map(section =>
					<SectionContainer key={section.label} className="w-full">
						{!isReduced && <div className="he-paragraph--small-bold gray-900 mb-2">
							{section.label}
						</div>}
						{section.options.map(option =>
							<OptionContainer
								onClick={() => onOptionClick(option)}
								selected={selected === option.id}
								key={option.id}
								className={classNames("mt-1 he-paragraph--small", selected === option.id ? "gray-800" : "gray-500", selected === option.id && "px-1")}
							>
								{
									option.badge ? <CustomBadge severity="danger" title={isReduced ? option.label : undefined} value={option.badge} className="mr-2" /> :
									<i title={isReduced ? option.label : undefined} className={classNames(option.icon, 'mr-2')} style={{fontSize: "10px"}}/>
								}
								{!isReduced && <span>{option.label}</span>}
							</OptionContainer>
						)}
					</SectionContainer>
				)}
			</div>
			<div className="mt-auto mb-4">
				<OptionContainer className="mt-1 he-paragraph--small gray-500">
					<span onClick={() => auth.signOut()}>
						<i className="pi pi-sign-out mr-2"/>
						{!isReduced && "Déconnexion"}
					</span>
				</OptionContainer>
			</div>
		</LeftMenuContainer>
	)
}

const CustomBadge = styled(Badge)`
	height: 16px;
	width: 16px;
	min-height: 16px;
	min-width: 16px;
	font-size: 10px;
	line-height: 16px;
	padding: 0;
`
