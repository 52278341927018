import React from 'react';
import { Session, VirtualMeeting } from '@Types/Session';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { DateTime } from 'luxon';
import { CustomInput } from '@Components/CustomInput';
import { Line } from '@Components/UI/Line';

export type AddVirtualClassProps = {
    onQuit: () => void;
    session: Session;
    meeting: VirtualMeeting;
};
export const UpdateVirtualClass: React.FC<AddVirtualClassProps> = (props) => {
    const api = useApi();

    const isPassed = (meeting?: VirtualMeeting) =>
        !!meeting?.date && Date.now() > DateTime.fromISO(meeting.date).toMillis();

    const [updateMeeting, loading] = usePromise(async (meeting: VirtualMeeting) => {
        if (isPassed(meeting) && props.meeting.date === meeting.date) {
            const res = await api.virtualClass_call_update({
                virtual_class: meeting,
            });

            if (res.result !== 'ok') throw new Error(res.result);
            props.onQuit();
        } else {
            await api.virtualClass_call_delete({
                virtual_class_id: meeting.virtual_class_id,
            });
            const res = await api.virtualClass_call_create({
                virtual_class: meeting,
                session_id: props.session.session_id,
            });
            if (res.result !== 'ok') throw new Error(res.result);
            props.onQuit();
        }
    });

    const meetingFormik = useFormik<VirtualMeeting>({
        initialValues: props.meeting,
        enableReinitialize: true,
        onSubmit: updateMeeting,
    });

    return (
        <div className="AddVirtualClass">
            <div className="he-header--h3 gray-900">Modifier une classe virtuelle</div>
            <div className="mt-4">
                <div className="he-paragraph--regular gray-500">Date et heure</div>
                <Calendar
                    className={'mt-2 w-full'}
                    disabled={isPassed(meetingFormik.values)}
                    value={meetingFormik.values.date ? new Date(meetingFormik.values.date) : undefined}
                    placeholder={'Date et heure de la classe virtuelle'}
                    showTime
                    hideOnDateTimeSelect={true}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => meetingFormik.setFieldValue('date', e.value)}
                />
            </div>
            <div className="mt-3">
                <CustomInputNumber
                    label="Durée"
                    disabled={isPassed(meetingFormik.values)}
                    field="duration"
                    formik={meetingFormik}
                />
            </div>
            <div className="mt-3">
                <CustomInput label="Lien de la classe virtuelle" disabled field="host_url" formik={meetingFormik} />
            </div>
            <div className="mt-3">
                <CustomInput
                    label="Lien de rediffusion"
                    field="replay_url"
                    placeholder={'ex: https://vimeo.com/64760211'}
                    formik={meetingFormik}
                />
            </div>
            <div className="mt-3">
                <CustomInput
                    label="Nom et prénom de l'intervenant"
                    field="intervenant"
                    formik={meetingFormik}
                    placeholder="ex. John Doe"
                />
            </div>
            <div className="mt-3">
                <CustomInput
                    label="Email de l'intervenant"
                    field="email_intervenant"
                    formik={meetingFormik}
                    placeholder="ex. john.doe@example.com"
                />
            </div>
            <Line height={1} className={'bg-gray-200 my-4'} />
            <div className="flex justify-content-end">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-2"
                    label="Annuler"
                    loading={loading}
                    onClick={props.onQuit}
                />
                <Button
                    loading={loading}
                    className="he-button--primary--md"
                    icon="pi pi-save"
                    label={'Modifier la classe virtuelle'}
                    onClick={meetingFormik.submitForm}
                />
            </div>
        </div>
    );
};
