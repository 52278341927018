import React, {useState} from "react";
import {UnitConfig} from "@Types/Unit";
import {InputSwitch} from "primereact/inputswitch";
import {Calendar, CalendarDateTemplateEvent} from "primereact/calendar";
import styled from "styled-components";
import {produce} from "immer";
import {DateTime} from "luxon";
import {CustomInputNumber} from "@Components/CustomInputNumber";
import { copyToClipboard } from "@Utils/misc.utils";
import { useToast } from "@Hooks/toast";

export const CalendarContainer = styled.div`
  position: relative;

  .icon-container {

    position: absolute;
    display: flex;
    height: 100%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    justify-content: center;
    background: #EAECF0;
    align-items: center;
    width: 40px;
    border: 1px solid #D0D5DD;
    border-radius: 0px 6px 6px 0px;

    i {
      font-size: 14px;
    }
  }

`;

export type UnitSettingProps = {
	unitSettings: UnitConfig,
	index: number;
	onUpdateUnit?: (unit: UnitConfig) => void;
	session_id: string;
}
export const UnitSetting: React.FC<UnitSettingProps> = (props) => {

	const [unitSettings, setUnitSettings] = useState<UnitConfig>(props.unitSettings);

	const {info} = useToast();

	const onChange = (newSettings: UnitConfig) => {
		props.onUpdateUnit?.(newSettings);
		setUnitSettings(newSettings);
	};


	const dayTemplate = (date: CalendarDateTemplateEvent) => {
		if (date.selectable) return date.day;
		return <div className="gray-300">
			{date.day}
		</div>
	}


	return (
		<div className="UnitSetting w-full">
			<div className="flex align-items-center">
				<div className="he-header--h4">Unité #{props.index + 1}</div>
				{
					unitSettings.required_signature && <div className="ml-3 he-paragraph--small gray-400 cursor-pointer" onClick={() => copyToClipboard(`https://lms.healthevents.fr/session/${props.session_id}?u=${props.index}&sign`).then(() => {
						info("Lien copié !")
					})}>
						Lien de signature <i className={"pi pi-copy ml-2"}/>
					</div>
				}
				<InputSwitch
					checked={unitSettings.required_signature}
					className="ml-auto mr-2"
					onChange={e => onChange(produce(unitSettings, prev => {
						prev.required_signature = e.value;
						return prev;
					}))}
				/>
				<div className="he-paragraph--regular gray-500">Signature obligatoire</div>
			</div>
			<div className="flex gap-3 mt-3">
				<div className="flex-1">
					<div className="he-paragraph--regular gray-500">Date de début</div>
					<CalendarContainer className="flex align-items-center mt-2 ">
						<Calendar className="w-full"
						          placeholder={"Date d'ouverture de l'unité"}
						          dateFormat={"dd/mm/yy"}
						          locale="fr"
						          value={unitSettings.start_date ? DateTime.fromISO(unitSettings.start_date).toJSDate() : null}
						          onChange={e => onChange(produce(unitSettings, prev => {
							          if (Array.isArray(e.value)) {
								          const [date] = e.value;
								          prev.start_date = DateTime.fromJSDate(date).toISO();
							          }
							          const date = e.value;
							          if (date instanceof Date) {
								          prev.start_date = DateTime.fromJSDate(date).toISO();
							          }
							          if (date == null) {
								          prev.start_date = date;
							          }
							          return prev;
						          }))}/>
						<div className="icon-container">
							<i className="pi pi-calendar gray-400"/>
						</div>
					</CalendarContainer>
				</div>
				<div className="flex-1">
					<div className="he-paragraph--regular gray-500">Date de fin</div>
					<CalendarContainer className="flex align-items-center mt-2 ">
						<Calendar className="w-full"
						          placeholder={"Date de fermeture de l'unité"}
						          dateFormat={"dd/mm/yy"}
						          locale="fr"
						          minDate={unitSettings.start_date ? DateTime.fromISO(unitSettings.start_date).toJSDate() : undefined}
						          value={unitSettings.end_date ? DateTime.fromISO(unitSettings.end_date).toJSDate() : null}
						          dateTemplate={dayTemplate}
						          onChange={e => onChange(produce(unitSettings, prev => {
							          if (Array.isArray(e.value)) {
								          const [date] = e.value;
								          prev.end_date = DateTime.fromJSDate(date).toISO();
							          }
							          const date = e.value;
							          if (date instanceof Date) {
								          prev.end_date = DateTime.fromJSDate(date).toISO();
							          }
							          if (date == null) {
								          prev.end_date = date;
							          }
							          return prev;
						          }))}/>
						<div className="icon-container">
							<i className="pi pi-calendar gray-400"/>
						</div>
					</CalendarContainer>
				</div>
				<div className="flex-1">
					<CustomInputNumber label="Heures non connectées" value={unitSettings.offline_time} placeholder=" - " onChange={e => onChange(produce(unitSettings, prev => {
						prev.offline_time = e || 0;
					}))}/>
				</div>
			</div>
		</div>
	)
}


