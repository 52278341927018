import React, {useState} from "react";
import {CustomDialog} from "@Components/CustomDialog";
import {DeleteModule} from "@Pages/pages/components/DeleteModule";
import {CustomInput} from "@Components/CustomInput";
import {CustomTextEditor} from "@Components/CustomTextEditor";
import {Button} from "primereact/button";
import {CustomInputNumber} from "@Components/CustomInputNumber";
import {RadioGroup} from "@Components/RadioGroup";
import {Module} from "@Types/Module";
import {useFormik} from "formik";
import {UploadButton} from "@Components/UploadButton";
import {usePromise} from "@Hooks/promise";
import {deleteResource, uploadResource} from "@Utils/import.utils";
import { InputSwitch } from "primereact/inputswitch";
import {CustomFormDropdown} from "@Components/CustomFormDropdown";


const durationOptions = [
	{
		label: "Semaine(s)",
		value: "s"
	}, {
		label: "Jours(s)",
		value: "j"
	}, {
		label: "Heure(s)",
		value: "h"
	}, {
		label: "Minute(s)",
		value: "m"
	}
]

export type ModuleInformationProps = {
	module: Module,
	moduleFormik: ReturnType<typeof useFormik<Module>>
}
export const ModuleInformation: React.FC<ModuleInformationProps> = ({module, moduleFormik}) => {

	const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

	const onRemove = async () => {
		const id = moduleFormik.values.cover_image_url
		if (id) await deleteResource(id)
		moduleFormik.setFieldValue('cover_image_url', undefined, false);
	}

	const [onUploadImage, loading] = usePromise(async (file: File) => {
		const id = await uploadResource(file);
		moduleFormik.setFieldValue('cover_image_url', id, false);
	})

	function onClearMinimumDuration() {
		moduleFormik.setFieldValue("minimal_duration_unit", null, false);
		moduleFormik.setFieldValue("minimal_duration", null, false);
	}

	return (
		<div>
			{
				module && deleteDialogVisible &&
                <CustomDialog onHide={() => setDeleteDialogVisible(false)}>
                    <DeleteModule module={module} onQuit={() => setDeleteDialogVisible(false)}/>
                </CustomDialog>
			}
			<div className="he-header--h3 gray-900">Informations du module</div>
			<div className="mt-3">
				<CustomInput field={"title"} formik={moduleFormik} label="Titre du module"/>
			</div>
			<div>
				<CustomTextEditor placeholder={"Description du module"} field={"description"} formik={moduleFormik} label="Description du module"
				                  className="mt-3"/>
			</div>
			<UploadButton
				label="Ajouter une image de couverture"
				className="mt-3 w-max"
				buttonClassName="he-button--primary-nf--xs"
				showPreview
				onRemove={onRemove}
				onChange={onUploadImage}
				loading={loading}
				file_id={moduleFormik.values.cover_image_url}
				accept={["image/png", "image/jpg", "image/jpeg"]}
				icon="pi pi-download"
			/>
			<div className="he-header--h3 gray-900 mt-4">
				Paramètres de quizz
			</div>
			<div className="w-6 mt-2 flex flex-column gap-2">
				<label className="gray-500 he-paragraph--regular">Bloquant</label>
				<div>
					<InputSwitch
						checked={moduleFormik.values.validating_quizz}
						onChange={v => {
							if (!v.value) moduleFormik.setFieldValue("threshold", null, false);

							moduleFormik.setFieldValue("validating_quizz", v.value, false)
						}}
					/>
					<CustomInputNumber disabled={!moduleFormik.values.validating_quizz} field={"threshold"} formik={moduleFormik} label="Choisir un seuil" placeholder="ex. 75%" suffix="%"/>
				</div>
				<label className="gray-500 he-paragraph--regular mt-3">Type de quizz</label>
				<CustomFormDropdown
					placeholder={"Choisir un type de quizz (en cas de FC)"}
					options={[
					{label: "Quizz initial", value: "initial"},
					{label: "Quizz final", value: "final"},
					{label: "Quizz par défaut", value: "default"}
				]} formik={moduleFormik} field={"quizz_type"}/>
			</div>
			<div className="he-header--h3 gray-900 mt-4">
				Durée estimée
			</div>
			<div className="w-6 mt-2">
				<CustomInputNumber field={"estimated_duration"} formik={moduleFormik} label="Choisir une durée"/>
				<RadioGroup className="mt-2" options={durationOptions} name={"estimated_duration_unit"}
				            onChange={v => moduleFormik.setFieldValue("estimated_duration_unit", v, false)}
				            value={moduleFormik.values.estimated_duration_unit}/>
			</div>
			<div className="he-header--h3 gray-900 mt-4 flex">
				Durée minimum
				<div><i className="pi pi-trash color-red ml-2" onClick={onClearMinimumDuration}/></div>
			</div>
			<div className="w-6 mt-2">
				<CustomInputNumber field={"minimal_duration"} formik={moduleFormik} label="Choisir une durée"/>
				<RadioGroup className="mt-2" options={durationOptions} name={"minimal_duration_unit"}
				            onChange={v => {
								if (moduleFormik.values.minimal_duration == null) moduleFormik.setFieldValue("minimal_duration", 0, false);
								moduleFormik.setFieldValue("minimal_duration_unit", v, false)
							}}
				            value={moduleFormik.values.minimal_duration_unit}/>
			</div>
			<div className="mt-5">
				<Button className="he-button--primary-nfb--xs" icon="pi pi-trash" label="Supprimer le module"
				        onClick={() => setDeleteDialogVisible(true)}/>
			</div>
		</div>
	)
}
