export const copyToClipboard = async (content: string) => {
    return navigator.clipboard.writeText(content);
}

function hexToRgb(hex: string) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function relativeLuminance(rgb: { r: number, g: number, b: number }) {
    let sRGB = [rgb.r / 255, rgb.g / 255, rgb.b / 255];
    for (let i = 0; i < sRGB.length; i++) {
        sRGB[i] = (sRGB[i] <= 0.03928) ? sRGB[i] / 12.92 : Math.pow(((sRGB[i] + 0.055) / 1.055), 2.4);
    }
    return 0.2126 * sRGB[0] + 0.7152 * sRGB[1] + 0.0722 * sRGB[2];
}

export function getTextColor(hexColor: string) {
    let rgb = hexToRgb(hexColor);
    if (!rgb) return 'black';
    let luminance = relativeLuminance(rgb);
    return (luminance > 0.5) ? 'black' : 'white';
}
