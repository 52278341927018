import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Users } from '@Pages/pages/Users';
import { Sessions } from '@Pages/pages/Sessions';
import { ArchivedSession } from '@Pages/pages/ArchivedSessions';
import { Statistics } from '@Pages/pages/Statistics';
import { Formations } from '@Pages/pages/Formations';
import { Modules } from '@Pages/pages/Modules';
import { UsersProvider } from '@Context/UsersContext';
import { BackofficeProvider } from '@Context/BackofficeContext';
import { EditModule } from '@Pages/pages/Modules/EditModule';
import { SessionDetails } from '@Pages/pages/Sessions/SessionDetails';
import { Relances } from './pages/Relances';
import { FormationDetails } from './pages/Formations/FormationDetails';
import { Notifications } from '@Pages/pages/Notifications';

export type ContentPageProps = Record<string, never>;

export const ContentRouter: React.FC<ContentPageProps> = () => {
    return (
        <div className="ContentPage h-full flex-1 overflow-auto">
            <UsersProvider>
                <BackofficeProvider>
                    <Routes>
                        <Route path="/" element={<Navigate to={'/sessions'} />} />
                        <Route
                            path="/sessions/*"
                            element={
                                <Routes>
                                    <Route path="/" element={<Sessions />} />
                                    <Route
                                        path="/:session_id/participants"
                                        element={<SessionDetails page="participants" />}
                                    />
                                    <Route path="/:session_id/classes" element={<SessionDetails page="classes" />} />
                                    <Route path="/:session_id/settings" element={<SessionDetails page="settings" />} />
                                    <Route path="/:session_id/units" element={<SessionDetails page="units" />} />
                                    <Route path="/:session_id/events" element={<SessionDetails page="events" />} />
                                    <Route path="/:session_id/*" element={<Navigate to="participants" />} />
                                </Routes>
                            }
                        />
                        <Route path="/archived/*" element={<ArchivedSession />} />
                        <Route path="/statistics/*" element={<Statistics />} />
                        <Route path="/relances/*" element={<Relances />} />
                        <Route path="/forum/*" element={<Notifications />} />
                        <Route
                            path="/formations/*"
                            element={
                                <Routes>
                                    <Route path="/" element={<Formations />} />
                                    <Route path="/:formation_id/*" index element={<FormationDetails />} />
                                </Routes>
                            }
                        />
                        <Route
                            path="/modules/*"
                            element={
                                <Routes>
                                    <Route path="/" element={<Modules />} />
                                    <Route path="/:module_id/:activity_id" element={<EditModule />} />
                                    <Route path="/:module_id" element={<EditModule />} />
                                </Routes>
                            }
                        />
                        <Route path="/users/*" element={<Users />} />
                        <Route path="/*" element={<Navigate to="/sessions" />} />
                    </Routes>
                </BackofficeProvider>
            </UsersProvider>
        </div>
    );
};
