import { CustomFormAutocomplete } from '@Components/CustomFormAutocomplete';
import { CustomInput } from '@Components/CustomInput';
import { Line } from '@Components/UI/Line';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { Formation } from '@Types/Formation';
import { Session } from '@Types/Session';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useUnmountEffect } from 'primereact/hooks';
import React, { useContext, useState } from 'react';
import { styled } from 'styled-components';

export type CreateSessionProps = {
    onQuit: () => void;
};
export const CreateSession: React.FC<CreateSessionProps> = (props) => {
    const { formations } = useContext(BackofficeContext);

    const api = useApi();

    const [onCreateSession, loading] = usePromise(async (session: Session) => {
        const res = await api.session_call_create({
            session,
        });

        if (res.result !== 'ok') throw new Error(res.result);

        props.onQuit();
        sessionFormik.resetForm();
    });

    const sessionFormik = useFormik<Session>({
        initialValues: {
            session_custom_id: '',
            created_at: '',
            session_id: '',
            formation_id: '',
            unitsConfig: [],
            end_date: '',
            start_date: '',
            archived: false,
        },
        onSubmit: onCreateSession,
    });

    useUnmountEffect(() => {
        sessionFormik.resetForm();
    });

    const itemTemplate = (item: Formation) => {
        return (
            <EllipsisText title={`[${item.andpc}] ${item.title}`}>
                <Badge value={item.andpc} className="mr-2" />
                {item.title}
            </EllipsisText>
        );
    };

    const [valueAutocomplete, setValueAutocomplete] = useState<Formation | null>(null);

    return (
        <div className="CreateSession">
            <div className="he-paragraph--medium gray-900">Créer une nouvelle session</div>
            <div className="mt-4">
                <CustomInput
                    label="Numéro de la session"
                    placeholder="Numéro de la session"
                    field="session_custom_id"
                    formik={sessionFormik}
                />
            </div>
            <div className="mt-3">
                <CustomFormAutocomplete<Formation>
                    values={formations}
                    label="Formation"
                    placeholder={'Sélectionner une formation par titre ou numéro ANDPC'}
                    labelField="title"
                    value={valueAutocomplete}
                    onChange={(formation) => {
                        setValueAutocomplete(formation);
                        sessionFormik.setFieldValue('formation_id', formation.formation_id, false);
                    }}
                    valueField="formation_id"
                    filterFields={['title', 'andpc']}
                    itemTemplate={itemTemplate}
                    selectedItemTemplate={itemTemplate}
                />
            </div>
            <div className="flex mt-3">
                <div className="flex-1 mr-4">
                    <div className="he-paragraph--regular gray-500">Date de début</div>
                    <Calendar
                        className="mt-2 w-full"
                        placeholder={'jj/mm/aaaa'}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.start_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('start_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
                <div className="flex-1">
                    <div className="he-paragraph--regular gray-500">Date de fin</div>
                    <Calendar
                        className="mt-2 w-full"
                        minDate={new Date(sessionFormik.values.start_date)}
                        placeholder={'jj/mm/aaaa'}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.end_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('end_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
            </div>
            <Line height={1} className="bg-gray-200 my-4" />
            <div className="flex justify-content-end">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-2"
                    label={'Annuler'}
                    onClick={props.onQuit}
                />
                <Button
                    className="he-button--primary--md"
                    label={'Créer la session'}
                    icon="pi pi-plus"
                    loading={loading}
                    onClick={sessionFormik.submitForm}
                />
            </div>
        </div>
    );
};

const EllipsisText = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
