import {Activity, ActivityType} from "@Types/Activity";
import {array, boolean, mixed, number, object, SchemaOf, string} from "yup";
import {Regexes} from "@Utils/regex.utils";

export const HypothesisSchema = object().shape({
	initial: string().when('initial_image', {
		is: (initial_image: string | null | undefined) => {
			return !initial_image;
		},
		then: schema => schema.required("Ce champ est obligatoire si vous n'avez pas d'image"),
		otherwise: schema => schema.notRequired()
	}),
	initial_image: string().nullable().default(null).notRequired(),
	information: string().when('information_image', {
		is: (information_image: string | null | undefined) => {
			return !information_image;
		},
		then: schema => schema.required("Ce champ est obligatoire si vous n'avez pas d'image"),
		otherwise: schema => schema.notRequired()
	}),
	information_image: string().nullable().default(null).notRequired(),
	answers: array().length(5).of(number().min(0).required()).required()
})

export const ActivitySchema: SchemaOf<Activity> = object().shape({
	activity_id: string().matches(Regexes.v4).notRequired(),
	type: mixed().oneOf(['course', 'video', 'mcq', 'ucq', 'tcs', 'freetext']).required(),
	title: string().required(),
	content: string().when('type', {
		is: (type: ActivityType) => type === 'course',
		then: schema => schema.required(),
		otherwise: schema => schema.notRequired()
	}),
	resource_pdf_url: string().nullable().notRequired(),
	description: string().when('type', {
		is: (type: ActivityType) => type === 'tcs',
		then: schema => schema.required(),
		otherwise: schema => schema.notRequired()
	}),
	labels: object().shape({
		hypothesis: string().notRequired(),
		new_information: string().notRequired(),
		0: string().notRequired(),
		1: string().notRequired(),
		2: string().notRequired(),
		3: string().notRequired(),
		4: string().notRequired(),
	}).notRequired(),
	hypothesis: array().of(HypothesisSchema).when('type', {
		is: (type: ActivityType) => type === 'tcs',
		then: schema => schema.min(1).required(),
		otherwise: schema => schema.notRequired()
	}),
	keywords: array().of(string().required()).when('type', {
		is: (type: ActivityType) => type === 'freetext',
		then: schema => schema.min(1).required(),
		otherwise: schema => schema.notRequired()
	}),
	video_link: string().when('type', {
		is: (type: ActivityType) => type === 'video',
		then: schema => schema.required(),
		otherwise: schema => schema.notRequired()
	}),
	question: string().when('type', {
		is: (type: ActivityType) => type === 'mcq' || type === 'ucq',
		then: schema => schema.nullable(),
		otherwise: schema => schema.notRequired()
	}),
	cover_image_url: array().of(string().nullable()).max(5).when("type", {
		is: (type: ActivityType) => ["mcq", "ucq", "tcs", "freetext"].includes(type),
		then: schema => schema.required(),
		otherwise: schema => schema.notRequired()
	}),
	cover_video_url: array().of(string().nullable()).max(5).when("type", {
		is: (type: ActivityType) => ["mcq", "ucq", "tcs", "freetext"].includes(type),
		then: schema => schema.default([null, null, null, null, null]).required(),
		otherwise: schema => schema.notRequired()
	}),
	is_response_img: boolean().when('type', {
		is: (type: ActivityType) => type === 'mcq' || type === 'ucq',
		then: schema => schema.required(),
		otherwise: schema => schema.notRequired()
	}),
	answer_explanation: string().notRequired(),
	answers: array().when('type', {
		is: (type: ActivityType) => type === 'mcq' || type === 'ucq',
		then: schema => schema.min(2).required().of(object().shape({
			text_or_image: string().required(), is_response: boolean().required(),
			other: boolean().notRequired().default(false),
		})),
		otherwise: schema => schema.notRequired()
	}),
});
