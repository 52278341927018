import {ContentLayout} from "@Pages/pages/layout/ContentLayout";
import {CustomTabView} from "@Components/CustomTabView";
import {useEffect, useState} from "react";
import {collection, doc, getDocs, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {ForumNotification} from "@Types/Notification";
import {Formation} from "@Types/Formation";
import {NotificationTable} from "@Pages/pages/Notifications/NotificationTable";
import {ForumSettings} from "@Pages/pages/Notifications/ForumSettings";
import {sortBy} from "lodash";

export const Notifications = () => {

    const [selected, setSelected] = useState<number>(0);
    const [notifications, setNotifications] = useState<ForumNotification[]>([]);

    const [formations, setFormations] = useState<Formation[]>([]);

    useEffect(() => {
        getDocs(collection(firestore.db, 'formations')).then(res => {
            const formations = res.docs.map(doc => doc.data() as Formation);
            setFormations(formations);
        })
    }, []);

    useEffect(() => {
        let q = query(
            collection(firestore.db, 'notifications'),
            where("status", "==", "Non traité")
        );

        if (selected === 1) {
            q = query(
                collection(firestore.db, 'notifications')
            );
        }

        return onSnapshot(q, (snapshot) => {
            const notifications = snapshot.docs.map(doc => doc.data() as ForumNotification);
            const sorted = sortBy(notifications, "created_at")
            setNotifications(sorted);
        });
    }, [selected]);

    const changeStatus = async (notification: ForumNotification, status: ForumNotification["status"]) => {
        try {
            await updateDoc(doc(firestore.db, 'notifications', notification.notification_id), {
                status
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
            <ContentLayout
                title={'Forum'}
                subtitle={"Vous pouvez consulter l'ensemble des informations relatives aux forums"}
            >
                <CustomTabView selected={selected} onChange={setSelected} items={[{
                    label: "Notifications non lues",
                    badge: notifications.filter(n => n.status === "Non traité").length || undefined
                }, {
                    label: "Toutes les notifications",
                }, {
                    label: "Paramètres des forums"
                }]} />
                <div className="p-3">
                    {selected < 2 &&
                        <NotificationTable notifications={notifications} onChangeStatus={changeStatus} formations={formations} />
                    }
                    {
                        selected === 2 &&
                        <ForumSettings />
                    }
                </div>
            </ContentLayout>
    );
};
