import {useEffect, useState} from "react";

export const useAsyncMemo = <T>(factory: () => Promise<T>, dependencies: any[]) => {

	const [data, setData] = useState<T>();

	useEffect(() => {
	    factory().then(res => {
			setData(res);
	    })
	}, dependencies);


	return data;
}
