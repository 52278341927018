import React, { useContext, useMemo, useRef, useState } from 'react';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Session } from '@Types/Session';
import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { CustomTable } from '@Components/CustomTable';
import { InputText } from 'primereact/inputtext';
import { ColumnSortEvent } from 'primereact/column';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { CustomDialog } from '@Components/CustomDialog';
import { ArchiveSession } from '@Pages/pages/components/ArchiveSession';
import { useNavigate } from 'react-router-dom';
import { fuzzSearch } from '@Utils/search.utils';
import { TieredMenu } from 'primereact/tieredmenu';

export type SessionCustom = Session & {
    participants: number;
};

export type ArchivedSessionProps = Record<string, never>;
export const ArchivedSession: React.FC<ArchivedSessionProps> = () => {
    const { sessions, formations, loading, participantsCount } = useContext(BackofficeContext);
    const [selection, setSelection] = useState<Session>();
    const [archiveVisible, setArchiveVisible] = useState<boolean>(false);

    const ref = useRef<TieredMenu>(null);

    const navigate = useNavigate();

    const sessionActions = useMemo<DropdownOption[]>(
        () => [
            {
                label: 'Voir le détail',
                icon: 'pi pi-eye',
                action: () => selection && navigate(`/sessions/${selection.session_id}`),
            },
            {
                label: 'Désarchiver',
                action: () => setArchiveVisible(true),
                icon: 'pi pi-refresh',
            },
        ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, formation: Session) => {
        setSelection(formation);
        ref.current?.toggle(e);
    };
    const SessionDropdown = (session: Session) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, session)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const [filter, setFilter] = useState<string>('');

    const filtered = useMemo(() => {
        return fuzzSearch(
            sessions
                .filter((s) => s.archived)
                .map((s) => ({
                    ...s,
                    formation: formations.find((f) => f.formation_id === s.formation_id),
                    participants: participantsCount[s.session_id],
                })),
            filter,
            ['session_custom_id', 'formation_id', 'formation.title', 'formation.andpc'],
            true
        );
    }, [sessions, filter, participantsCount]);

    return (
        <ContentLayout
            title={'Sessions de formation archivées'}
            subtitle={"Vous trouverez ici l'ensemble des sessions archivées."}
        >
            <CustomActionDropdown options={sessionActions} ref={ref} />
            {selection && archiveVisible && (
                <CustomDialog onHide={() => setArchiveVisible(false)}>
                    <ArchiveSession onQuit={() => setArchiveVisible(false)} session={selection} />
                </CustomDialog>
            )}
            <CustomTable
                dataKey={'session_id'}
                loading={loading}
                columns={[
                    {
                        header: 'N°',
                        size: 90,
                        filter: {
                            type: 'sort',
                            field: 'session_custom_id',
                        },
                        body: (session) => <div>{session.session_custom_id}</div>,
                    },
                    {
                        header: 'Nom de la formation',
                        size: 550,
                        filter: {
                            type: 'sort',
                            field: 'formation.title' as any,
                        },
                        body: (session) => (
                            <div>
                                {formations.find((f) => f.formation_id === session.formation_id)?.title || (
                                    <i className="pi pi-spin pi-spinner" />
                                )}
                            </div>
                        ),
                    },
                    {
                        header: 'Date de début',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'start_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.start_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Date de fin',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.end_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: "Nombre d'inscrits",
                        size: 100,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        sortFunction(e: ColumnSortEvent) {
                            return (e.data as Array<SessionCustom>).sort(
                                (a, b) => (e.order || 0) * (a.participants - b.participants)
                            );
                        },
                        align: 'center',
                        body: (session: SessionCustom) => <div>{session.participants}</div>,
                    },
                    {
                        frozen: true,
                        alignFrozen: 'right',
                        size: 100,
                        body: SessionDropdown,
                    },
                ]}
                values={filtered}
                header={{
                    title: 'Liste des sessions',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher une session"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                    ],
                }}
            />
        </ContentLayout>
    );
};
