import React from 'react';
import { Session, VirtualMeeting } from '@Types/Session';
import { useApi } from '@Hooks/api';
import { usePromise } from '@Hooks/promise';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { Line } from '@Components/UI/Line';
import { DateTime } from 'luxon';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomInput } from '@Components/CustomInput';

export type AddVirtualClassProps = {
    onQuit: () => void;
    session: Session;
};
export const AddVirtualClass: React.FC<AddVirtualClassProps> = (props) => {
    const api = useApi();
    const [createVirtualClass, loading] = usePromise(async (meeting: VirtualMeeting) => {
        const res = await api.virtualClass_call_create({
            session_id: props.session.session_id,
            virtual_class: meeting,
        });

        if (res.result !== 'ok') throw new Error(res.result);
        props.onQuit();
    });

    const meetingFormik = useFormik<VirtualMeeting>({
        initialValues: {
            virtual_class_id: '',
            date: DateTime.now().toISO(),
            replay_url: '',
            duration: 0,
            host_url: '',
            user_url: '',
            intervenant: '',
            email_intervenant: '',
        },
        onSubmit: createVirtualClass,
    });

    return (
        <CustomDialog onHide={props.onQuit}>
            <div className="he-header--h3 gray-900">Ajouter une classe virtuelle</div>
            <div className="mt-4">
                <label className="he-paragraph--regular gray-500 mandatory">Date et heure</label>
                <Calendar
                    className={'mt-2 w-full'}
                    value={
                        meetingFormik.values.date
                            ? DateTime.fromISO(meetingFormik.values.date, {
                                  zone: 'europe/paris',
                              }).toJSDate()
                            : null
                    }
                    placeholder={'Date et heure de la classe virtuelle'}
                    dateFormat="dd/mm/yy"
                    locale="fr"
                    showTime
                    onChange={(e) =>
                        e.value &&
                        meetingFormik.setFieldValue(
                            'date',
                            DateTime.fromJSDate(e.value).setZone('europe/paris').toISO()
                        )
                    }
                />
            </div>
            <div className="mt-3">
                <CustomInputNumber
                    label="Durée"
                    suffix=" minutes"
                    field="duration"
                    placeholder="Durée de la formation en minutes"
                    formik={meetingFormik}
                />
            </div>
            <div className="mt-3">
                <CustomInput
                    label="Nom et prénom de l'intervenant"
                    field="intervenant"
                    placeholder="ex. John Doe"
                    formik={meetingFormik}
                />
            </div>
            <div className="mt-3">
                <CustomInput
                    label="Email de l'intervenant"
                    field="email_intervenant"
                    placeholder="ex. john.doe@example.com"
                    formik={meetingFormik}
                />
            </div>
            <Line height={1} className={'bg-gray-200 my-4'} />
            <div className="flex justify-content-end">
                <Button
                    className="he-button--secondary-variant-nfb--md mr-2"
                    label="Annuler"
                    loading={loading}
                    onClick={props.onQuit}
                />
                <Button
                    loading={loading}
                    disabled={!meetingFormik.values.date}
                    className="he-button--primary--md"
                    icon="pi pi-plus"
                    label={'Créer la classe virtuelle'}
                    onClick={meetingFormik.submitForm}
                />
            </div>
        </CustomDialog>
    );
};
