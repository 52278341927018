import { ForumNotification } from '@Types/Notification';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { CustomTable } from '@Components/CustomTable';
import { DateTime } from 'luxon';
import { Formation } from '@Types/Formation';
import { deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '@Utils/config/firebase';
import { CustomDialog } from '@Components/CustomDialog';
import { UserInformation } from '@Pages/pages/components/UserInformation';
import { UsersContext } from '@Context/UsersContext';
import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { type TieredMenu } from 'primereact/tieredmenu';
import { Tag } from 'primereact/tag';
import { ThreadViewer } from '@Pages/pages/Notifications/components/ThreadViewer';
import { NavLink } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { useDebounce } from '@uidotdev/usehooks';
import { uuidToTag } from '@Utils/users.utils';

type Props = {
    notifications: ForumNotification[];
    formations: Formation[];
    onChangeStatus: (notification: ForumNotification, status: ForumNotification['status']) => void;
};
export const NotificationTable: React.FC<Props> = (props) => {
    const { users } = useContext(UsersContext);

    const [selectedNotification, setSelectedNotification] = useState<ForumNotification | null>(null);

    const selection = useMemo(
        () => (selectedNotification ? users.find((u) => u.user_id === selectedNotification.user_id) : null),
        [users, selectedNotification]
    );

    const [userVisible, setUserVisible] = useState<boolean>(false);
    const [replyVisible, setReplyVisible] = useState<boolean>(false);

    const ref = useRef<TieredMenu>(null);
    const onDeleteNotification = async (notification: ForumNotification) => {
        try {
            await deleteDoc(doc(firestore.db, 'notifications', notification.notification_id));
        } catch (e) {
            console.error(e);
        }
    };
    const onDeleteMessage = async (notification: ForumNotification) => {
        try {
            await deleteDoc(
                doc(firestore.db, 'formations', notification.formation_id, 'messages', notification.message_id)
            );
            await onDeleteNotification(notification);
        } catch (e) {
            console.error(e);
        }
    };

    const notificationActions: DropdownOption[] = useMemo(
        () =>
            selectedNotification
                ? [
                      {
                          icon: 'pi pi-times',
                          label: 'Marquer comme non traité',
                          action: () => {
                              props.onChangeStatus(selectedNotification, 'Non traité');
                          },
                      },
                      {
                          icon: 'pi pi-refresh',
                          label: 'Marquer comme en cours',
                          action: () => {
                              props.onChangeStatus(selectedNotification, 'En cours');
                          },
                      },

                      {
                          icon: 'pi pi-check',
                          label: 'Marquer comme traité',
                          action: () => {
                              props.onChangeStatus(selectedNotification, 'Traité');
                          },
                      },
                      {
                          icon: 'pi pi-reply',
                          label: 'Répondre au message',
                          action: () => {
                              setReplyVisible(true);
                          },
                      },
                      {
                          icon: 'pi pi-trash',
                          label: 'Supprimer la notification',
                          action: () => {
                              confirmDialog({
                                  header: 'Suppression de la notification',
                                  message: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
                                  acceptLabel: 'Oui',
                                  rejectLabel: 'Non',
                                  icon: 'pi pi-exclamation-triangle',
                                  accept() {
                                      onDeleteNotification(selectedNotification);
                                  },
                              });
                          },
                      },
                      {
                          icon: 'pi pi-exclamation-triangle',
                          label: 'Supprimer le message',
                          action: () => {
                              confirmDialog({
                                  header: 'Suppression du message',
                                  message:
                                      'Êtes-vous sûr de vouloir supprimer ce message et la notification associée ?',
                                  acceptLabel: 'Oui',
                                  rejectLabel: 'Non',
                                  icon: 'pi pi-exclamation-triangle',
                                  accept() {
                                      onDeleteMessage(selectedNotification);
                                  },
                              });
                          },
                      },
                  ]
                : [],
        [selectedNotification]
    );

    const [filter, setFilter] = useState<string>('');

    const filterDebounced = useDebounce(filter, 500);
    const filteredItems = useMemo(() => {
        if (filterDebounced) {
            const searchByPseudo = (notification: ForumNotification) => notification.username.includes(filterDebounced);
            const searchByUid = (notification: ForumNotification) => notification.user_id === filterDebounced;
            const searchByTag = (notification: ForumNotification) => {
                const tag = uuidToTag(notification.user_id);
                return tag.includes(filterDebounced);
            };
            const searchByTagAndPseudo = (notification: ForumNotification) => {
                const tag = uuidToTag(notification.user_id);
                const full = notification.username + '#' + tag;
                return full.includes(filterDebounced);
            };
            return props.notifications.filter(
                (n) => searchByPseudo(n) || searchByUid(n) || searchByTag(n) || searchByTagAndPseudo(n)
            );
        }
        return props.notifications;
    }, [filterDebounced, props.notifications]);

    return (
        <>
            <ConfirmDialog />
            {selection && userVisible && (
                <CustomDialog onHide={() => setUserVisible(false)} width={1340}>
                    <UserInformation user={selection} onQuit={() => setUserVisible(false)} />
                </CustomDialog>
            )}
            {replyVisible && selectedNotification && (
                <CustomDialog onHide={() => setReplyVisible(false)} closable width={900}>
                    <ThreadViewer notification={selectedNotification} onClose={() => setReplyVisible(false)} />
                </CustomDialog>
            )}
            <CustomActionDropdown options={notificationActions} ref={ref} />
            <CustomTable
                dataKey={'notification_id'}
                header={{
                    filters: [
                        <div className="flex justify-content-end w-full">
                            <span className="p-input-icon-right">
                                <InputText
                                    placeholder={'Rechercher une notification'}
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                />
                                <i className="pi pi-search" />
                            </span>
                        </div>,
                    ],
                }}
                columns={[
                    {
                        header: 'Envoyé le',
                        style: { width: '150px' },
                        headerStyle: { width: '150px' },
                        sortable: true,
                        field: 'created_at',
                        body: (notification: ForumNotification) => (
                            <div>{DateTime.fromISO(notification.created_at).toFormat('dd/MM/yy à HH:mm')}</div>
                        ),
                    },
                    {
                        header: 'Formation',
                        body: (notification: ForumNotification) => (
                            <NavLink
                                className="no-underline primary-600"
                                to={`/formations/${notification.formation_id}?tab=3`}
                            >
                                {props.formations.find((f) => f.formation_id === notification.formation_id)?.title}
                            </NavLink>
                        ),
                    },
                    {
                        header: 'Utilisateur',
                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                        body: (notification: ForumNotification) => (
                            <div
                                className="he-paragraph--regular cursor-pointer primary-600"
                                onClick={() => {
                                    setUserVisible(true);
                                    setSelectedNotification(notification);
                                }}
                            >
                                {notification.username + '#' + uuidToTag(notification.user_id)}
                            </div>
                        ),
                    },
                    {
                        header: 'Message',
                        body: (notification: ForumNotification) => (
                            <div style={{ whiteSpace: 'pre-line' }}>{notification.content}</div>
                        ),
                    },
                    {
                        header: 'Statut',
                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                        body: (notification: ForumNotification) => (
                            <Tag
                                severity={
                                    notification.status === 'Non traité'
                                        ? 'danger'
                                        : notification.status === 'En cours'
                                        ? 'warning'
                                        : 'success'
                                }
                                value={notification.status}
                            />
                        ),
                        filter: {
                            type: 'select',
                            field: 'status',
                            filterOptions: (items: ForumNotification[]) => {
                                const statuses = ['Non traité', 'En cours', 'Traité'];
                                return statuses.map((s) => ({ label: s, value: s }));
                            },
                            filterItemTemplate: (option: any) => (
                                <Tag
                                    severity={
                                        option.value === 'Non traité'
                                            ? 'danger'
                                            : option.value === 'En cours'
                                            ? 'warning'
                                            : 'success'
                                    }
                                    value={option.label}
                                />
                            ),
                        },
                    },
                    {
                        header: 'Actions',
                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                        body: (notification: ForumNotification) =>
                            ref.current && (
                                <div
                                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                                    onClick={(e) => {
                                        setSelectedNotification(notification);
                                        ref.current?.toggle(e);
                                    }}
                                >
                                    <div className="mr-1">Actions</div>
                                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                                </div>
                            ),
                    },
                ]}
                values={filteredItems}
            />
        </>
    );
};
