import { CustomActionDropdown } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { CustomTable } from '@Components/CustomTable';
import { UserBadge } from '@Components/UI/UserBadge';
import { BackofficeContext } from '@Context/BackofficeContext';
import { SessionContext } from '@Context/SessionContext';
import { UsersContext } from '@Context/UsersContext';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { AnswerDetails } from '@Pages/pages/components/AnswerDetails';
import { BanUser } from '@Pages/pages/components/BanUser';
import { SendScheduledAttestation } from '@Pages/pages/components/SendScheduledAttestation';
import { SendScheduledMessage } from '@Pages/pages/components/SendScheduledMessage';
import { UserInformation } from '@Pages/pages/components/UserInformation';
import { AddParticipant } from '@Pages/pages/Sessions/pages/components/AddParticipant';
import { ImportParticipants } from '@Pages/pages/Sessions/pages/components/ImportParticipants';
import { UpdateEndDate } from '@Pages/pages/Sessions/pages/components/UpdateEndDate';
import { ParticipantUnitTable } from '@Pages/pages/Sessions/pages/ParticipantList/ParticipantUnitTable';
import { Module } from '@Types/Module';
import { Session, SessionParticipant, SessionTimeReport } from '@Types/Session';
import { durationFromEstimated } from '@Utils/date.utils';
import { downloadFromURL, downloadUint8 } from '@Utils/download.utils';
import { applyFilters, fuzzSearch } from '@Utils/search.utils';
import { produce } from 'immer';
import { DateTime, Duration, Interval } from 'luxon';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { TieredMenu } from 'primereact/tieredmenu';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { SendRelanceVirtualClass } from './components/SendRelanceVirtualClass';

const infoMap = {
    access: "L'apprenant a terminé la formation",
    'missing-signatures': "L'apprenant a terminé la formation mais signatures manquantes",
    'missing-time': "Il manque du temps à l'apprenant pour terminer la formation",
    'no-access': "L'apprenant n'a pas accès à l'attestation",
    locked: "Le blocage de l'attestation a été manuellement forcée",
};

const iconMap = {
    access: 'pi-check color-green',
    'missing-signatures': 'pi-pencil color-orange',
    'missing-time': 'pi-clock color-orange',
    'no-access': 'pi-times color-red',
    locked: 'pi-lock color-red',
};

const HeaderContainer = styled.div`
    padding: 14px;
    background: #fcfcfd;
    border: 1px solid #eaecf0;
    border-radius: 14px;
`;

export type ExtendedParticipant = SessionParticipant & {
    elapsed_time?: number;
    last_activity?: number;
    last_connection?: number;
    progress?: number;
    timeReport?: SessionTimeReport;
    session_id?: string;
};

export type ParticipantListProps = {
    session: Session;
};
export const ParticipantList: React.FC<ParticipantListProps> = (props) => {
    const { getUserProgressInSession, formations, modules: allModules } = useContext(BackofficeContext);
    const { participants, usersProgress, loading } = useContext(SessionContext);
    const { users } = useContext(UsersContext);

    const groupedRef = useRef<TieredMenu>(null);

    const [groupedSelection, setGroupedSelection] = useState<ExtendedParticipant[]>([]);
    const [importUsersVisible, setImportUsersVisible] = useState<boolean>(false);
    const [addUserVisible, setAddUserVisible] = useState<boolean>(false);
    const [sendScheduledMessageVisible, setSendScheduledMessageVisible] = useState<boolean>(false);
    const [sendScheduledAttestationVisible, setSendScheduledAttestationVisible] = useState<boolean>(false);
    const [sendRelanceClassVisible, setSendRelanceClassVisible] = useState<boolean>(false);
    const [banUserVisible, setBanUserVisible] = useState<boolean>(false);
    const [answerDetailVisible, setAnswerDetailVisible] = useState<boolean>(false);
    const [endDateUpdateVisible, setEndDateUpdateVisible] = useState<boolean>(false);
    const [userInformationVisible, setUserInformationVisible] = useState<boolean>(false);

    const [filter, setFilter] = useState<string>('');
    const [startInf, setStartInf] = useState<Date | null>(null);
    const [startSup, setStartSup] = useState<Date | null>(null);
    const [minProgress, setMinProgress] = useState<number | null>(null);
    const [maxProgress, setMaxProgress] = useState<number | null>(null);
    const [minTime, setMinTime] = useState<number | null>(null);
    const [maxTime, setMaxTime] = useState<number | null>(null);

    const { meta } = useUser(true);
    const modules = useMemo(() => {
        const formation = formations.find((f) => f.formation_id === props.session.formation_id);
        if (!formation) return [];
        return formation.modules
            .map((m) => allModules.find((a) => a.module_id === m.module_id))
            .filter(Boolean) as Module[];
    }, [formations, allModules, props.session.formation_id]);
    const [downloadAttestations] = usePromise(
        async (user_ids: string[]) => {
            const res = await api.pdf_call_downloadAttestationsZip({
                queries: [
                    {
                        session_id: props.session.session_id,
                        participant_ids: user_ids,
                    },
                ],
            });

            if (res.result !== 'ok') throw new Error(res.result);

            const a = document.createElement('a');

            a.href = res.url;
            a.download = 'Attestation-sur-l-honneur.zip';
            a.click();
        },
        {
            pending: "Génération de l'archive en cours...",
            success: 'Génération terminée',
        }
    );

    const [groupedForcedAccessUpdate] = usePromise(
        async (participants: SessionParticipant[]) => {
            for (const participant of participants) {
                await api.participant_call_update({
                    participant: {
                        ...participant,
                        manual_attestation_lock: !participant.manual_attestation_lock,
                    },
                    session_id: props.session.session_id,
                });
            }
        },
        {
            pending: 'Mise à jour des accès en cours...',
            success: "Les permissions d'accès ont étés mis à jour",
        }
    );



    const groupedOptions = useMemo(
        () => [
            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: 'Débloquer l’accès à l’attestation de suivi DPC',
                          icon: 'pi pi-check-circle',
                          action: () => groupedAccesUpdate(groupedSelection, true),
                      },
                      {
                          label: 'Envoyer l’attestation de suivi DPC par mail',
                          icon: 'pi pi-envelope',
                          action: () => {
                              if (groupedSelection.length > 0)
                                  sendUserAttestation(groupedSelection.map((s) => s.participant_id));
                          },
                      },
                      {
                          label: "Prolonger l'accès si l'apprenant a terminé",
                          icon: 'pi pi-pencil',
                          action: () => setEndDateUpdateVisible(true),
                      },
                  ]
                : []),
            {
                label: 'Relances',
                icon: 'pi pi-send',
                items: [
                    {
                        label: "Relancer les classes virtuelles",
                        icon: 'pi pi-refresh',
                        action: () => {
                            setSendRelanceClassVisible(true);
                        }
                    },
                    {
                        label: 'Relancer les apprenants',
                        icon: 'pi pi-refresh',
                        action: () => setSendScheduledMessageVisible(true),
                    },
                    {
                        label: "Relancer les attestations sur l'honneur",
                        icon: 'pi pi-refresh',
                        action: () => setSendScheduledAttestationVisible(true),
                    },
                ],
            },

            ...(['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: 'Téléchargements',
                          icon: 'pi pi-download',
                          items: [
                              {
                                  label: 'Attestations de suivi DPC .pdf',
                                  icon: 'pi pi-file-pdf',
                                  action: () => {
                                      if (groupedSelection.length > 0) downloadUserAttestation(groupedSelection, 'pdf');
                                  },
                              },
                              {
                                  label: 'Attestations de suivi DPC .docx',
                                  icon: 'pi pi-file-word',
                                  action: () => {
                                      if (groupedSelection.length > 0)
                                          downloadUserAttestation(groupedSelection, 'docx');
                                  },
                              },
                              {
                                  label: "Attestations sur l'honneur",
                                  icon: 'pi pi-file-pdf',
                                  action: () => {
                                      if (groupedSelection.length > 0)
                                          downloadAttestations(groupedSelection.map((u) => u.participant_id));
                                  },
                              },
                              {
                                  label: 'Logs de temps',
                                  icon: 'pi pi-folder',
                                  action: () => {
                                      if (groupedSelection.length > 0) {
                                          downloadUsersLog(
                                              groupedSelection.map((p) => p.participant_id),
                                              props.session.unitsConfig.map((e) => e.unit_id)
                                          );
                                      }
                                  },
                              },
                          ],
                      },
                  ]
                : []),
        ],
        [groupedSelection]
    );

    const ref = useRef<TieredMenu>(null);

    const [groupedAccesUpdate] = usePromise(
        async (participants: SessionParticipant[], force?: boolean) => {
            for (const participant of participants) {
                await api.participant_call_update({
                    participant: {
                        ...participant,
                        attestation_access: force || !participant.attestation_access,
                    },
                    session_id: props.session.session_id,
                });
            }
        },
        {
            pending: 'Mise à jour des accès en cours...',
            success: "Les permissions d'accès ont étés mis à jour",
        }
    );

    const [onUpdateAccess] = usePromise(
        async (participant: SessionParticipant, force?: boolean) => {
            await api.participant_call_update({
                participant: {
                    ...participant,
                    attestation_access: force || !participant.attestation_access,
                },
                session_id: props.session.session_id,
            });
        },
        {
            pending: "Mise à jour de l'accès en cours...",
            success: "Les permissions d'accès ont étés mis à jour",
        }
    );

    const [downloadUserAttestation] = usePromise(
        async (participants: SessionParticipant[], format: 'pdf' | 'docx') => {
            const res = await api.session_call_getAttestation({
                queries: [
                    {
                        session_id: props.session.session_id,
                        user_ids: participants.map((p) => p.participant_id),
                    },
                ],
                format,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            if (res.mode === 'single') {
                const formation = formations.find((f) => f.formation_id === props.session.formation_id);
                if (formation && props.session) {
                    const session = props.session;
                    const participant = participants[0];
                    if (format === 'pdf')
                        downloadUint8(
                            res.file,
                            `Attestation suivi formation - ${formation.andpc} - Session ${session.session_custom_id} - ${participant.firstname} ${participant.lastname}.pdf`,
                            'application/pdf'
                        );
                    else
                        downloadUint8(
                            res.file,
                            `Attestation suivi formation - ${formation.andpc} - Session ${session.session_custom_id} - ${participant.firstname} ${participant.lastname}.docx`,
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        );
                }
            } else {
                downloadUint8(res.file, `Attestations suivi formation.zip`, 'application/zip');
            }
        },
        {
            pending: 'Génération en cours, cette opération peut prendre du temps...',
            success: 'Génération terminée, le téléchargement va démarrer',
        }
    );

    const [sendUserAttestation] = usePromise(
        async (user_ids: string[]) => {
            await api.session_call_sendAttestation({
                session_id: props.session.session_id,
                user_ids: user_ids,
            });
        },
        {
            pending: 'Envoi en cours, cette opération peut prendre du temps...',
            success: 'Envoi terminée, le téléchargement va démarrer',
        }
    );

    const [downloadUsersLog] = usePromise(
        async (user_ids: string[], unit_ids: string[]) => {
            const res = await api.session_call_getUserLogs({
                session_id: props.session.session_id,
                user_ids,
                unit_ids,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            await downloadFromURL(res.url);
        },
        {
            pending: 'Génération des logs en cours, cette opération peut prendre du temps...',
            success: 'Logs créer le téléchargement va démarrer',
        }
    );

    const [selection, setSelection] = useState<ExtendedParticipant>();

    const selectionOriginalUser = useMemo(() => {
        return users.find((u) => u.user_id === selection?.participant_id);
    }, [users, selection]);

    const options = useMemo(
        () =>
            !selection?.blocked
                ? [
                      {
                          label: 'Voir la fiche apprenant',
                          icon: 'pi pi-file',
                          action: () => setUserInformationVisible(true),
                      },
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: "Voir l'historique des réponses",
                                    icon: 'pi pi-eye',
                                    action: () => {
                                        setAnswerDetailVisible(true);
                                    },
                                },
                            ]
                          : []),
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: "Prolonger l'accès si l'apprenant a terminé",
                                    icon: 'pi pi-pencil',
                                    action: () => setEndDateUpdateVisible(true),
                                },
                            ]
                          : []),
                      ...(selection && selection.attestation_access && ['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: 'Bloquer l’accès à l’attestation de suivi DPC',
                                    icon: 'pi pi-ban',
                                    action: () => onUpdateAccess(selection),
                                },
                                ...(['author', 'admin'].includes(meta.role)
                                    ? [
                                          {
                                              label: !selection?.manual_attestation_lock
                                                  ? "Forcer le blocage de l'attestation"
                                                  : "Débloquer l'accès à l'attestation",
                                              icon: !selection?.manual_attestation_lock ? 'pi pi-lock' : 'pi pi-unlock',
                                              action: () => {
                                                  confirmDialog({
                                                      header: "Forcer le blocage de l'attestation",
                                                      message:
                                                          "Cette action aura pour effet d'empêcher le téléchargement de l'attestation de suivi DPC pour cet utilisateur. Êtes-vous sûr de vouloir continuer ?",
                                                      acceptLabel: 'Bloquer',
                                                      rejectLabel: 'Annuler',
                                                      visible: true,
                                                      accept() {
                                                          selection && groupedForcedAccessUpdate([selection]);
                                                      },
                                                  });
                                              },
                                          },
                                      ]
                                    : []),
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Envoyer l’attestation de suivi DPC par mail',
                                              icon: 'pi pi-envelope',
                                              action: () => {
                                                  if (selection) sendUserAttestation([selection.participant_id]);
                                              },
                                          },
                                      ]
                                    : []),
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Télécharger l’attestation de suivi DPC .pdf',
                                              icon: 'pi pi-download',
                                              action: () => {
                                                  if (selection) downloadUserAttestation([selection], 'pdf');
                                              },
                                          },
                                          {
                                              label: 'Télécharger l’attestation de suivi DPC .docx',
                                              icon: 'pi pi-download',
                                              action: () => {
                                                  if (selection) downloadUserAttestation([selection], 'docx');
                                              },
                                          },
                                      ]
                                    : []),
                            ]
                          : [
                                ...(['admin', 'author'].includes(meta.role)
                                    ? [
                                          {
                                              label: 'Autoriser l’accès à l’attestation de suivi DPC',
                                              icon: 'pi pi-check-circle',
                                              action: () => {
                                                  confirmDialog({
                                                      header: 'Autoriser l’accès à l’attestation de suivi DPC',
                                                      message:
                                                          'Êtes-vous sûr de vouloir donner l’accès à l’attestation de suivi DPC à cet utilisateur ?',
                                                      acceptLabel: 'Autoriser',
                                                      rejectLabel: 'Annuler',
                                                      acceptClassName: 'p-button-warning',
                                                      visible: true,
                                                      accept() {
                                                          selection && onUpdateAccess(selection);
                                                      },
                                                  });
                                              },
                                          },
                                      ]
                                    : []),
                                ...(['author', 'admin'].includes(meta.role)
                                    ? [
                                          {
                                              label: !selection?.manual_attestation_lock
                                                  ? "Forcer le blocage de l'attestation"
                                                  : "Débloquer l'accès à l'attestation",
                                              icon: !selection?.manual_attestation_lock ? 'pi pi-lock' : 'pi pi-unlock',
                                              action: () => {
                                                  confirmDialog({
                                                      header: "Forcer le blocage de l'attestation",
                                                      message:
                                                          "Êtes-vous sûr de vouloir bloquer l'accès à l'attestation de suivi DPC pour cet utilisateur ?",
                                                      acceptLabel: 'Bloquer',
                                                      rejectLabel: 'Annuler',
                                                      visible: true,
                                                      accept() {
                                                          selection && groupedForcedAccessUpdate([selection]);
                                                      },
                                                  });
                                              },
                                          },
                                      ]
                                    : []),
                            ]),
                      {
                          label: "Télécharger les attestations sur l'honneur",
                          icon: 'pi pi-download',
                          action: () => selection && downloadAttestations([selection.participant_id]),
                      },
                      ...(['admin', 'author'].includes(meta.role)
                          ? [
                                {
                                    label: 'Télécharger les logs',
                                    icon: 'pi pi-download',
                                    action: () => {
                                        if (selection)
                                            downloadUsersLog(
                                                [selection.participant_id],
                                                props.session.unitsConfig.map((u) => u.unit_id)
                                            );
                                    },
                                },
                            ]
                          : []),
                      {
                          label: "Bloquer l'apprenant de la session",
                          icon: 'pi pi-ban',
                          action: () => setBanUserVisible(true),
                      },
                  ]
                : [
                      {
                          label: 'Voir la fiche apprenant',
                          icon: 'pi pi-file',
                          action: () => setUserInformationVisible(true),
                      },
                      {
                          label: "Débloquer l'apprenant",
                          icon: 'pi pi-check-circle',
                          action: () => setBanUserVisible(true),
                      },
                  ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, participant: ExtendedParticipant) => {
        setSelection(participant);
        ref.current?.toggle(e);
    };

    const ParticipantDropdown = (participant: ExtendedParticipant) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, participant)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };
    const [reports, setReports] = useState<{ [k: string]: SessionTimeReport }>({});

    const [loadReports, loadingReports] = usePromise(async () => {
        const res = await api.session_call_sessionReport({ session_id: props.session.session_id });
        setReports(res.reports);
    });

    const completeParticipants: ExtendedParticipant[] = useMemo(() => {
        return participants.map((p) =>
            reports?.[p.participant_id]
                ? {
                      ...p,
                      last_activity: reports[p.participant_id].last_activity,
                      last_connection: reports[p.participant_id].last_connection,
                      elapsed_time: reports[p.participant_id].total,
                      timeReport: reports[p.participant_id],
                      progress: getUserProgressInSession(
                          p.participant_id,
                          usersProgress[p.participant_id] || [],
                          props.session.session_id
                      ),
                  }
                : p
        );
    }, [reports, participants, getUserProgressInSession, usersProgress]);

    const api = useApi();

    useEffect(() => {
        loadReports();
    }, [props.session.session_id]);

    const filterByInterval = (inf: Date | null, sup: Date | null) => {
        return (item: ExtendedParticipant) => {
            if (!item.last_activity) return false;

            const startD = DateTime.fromMillis(item.last_activity);
            if (!!inf && !!sup) {
                const infD = DateTime.fromJSDate(inf);
                const supD = DateTime.fromJSDate(sup);
                const interval = Interval.fromDateTimes(infD, supD);
                return interval.contains(startD);
            }
            if (!inf && !!sup) {
                const supD = DateTime.fromJSDate(sup);
                return startD.diff(supD, 'second').get('second') <= 0;
            }
            if (!!inf && !sup) {
                const infD = DateTime.fromJSDate(inf);
                return startD.diff(infD, 'second').get('second') >= 0;
            }
            return true;
        };
    };

    const filterByRange = (
        inf?: number | null,
        sup?: number | null,
        itemProcess?: (item: ExtendedParticipant) => undefined | number
    ) => {
        return (item: ExtendedParticipant) => {
            const value = itemProcess?.(item);
            if (!!inf && !!sup && value) {
                return inf <= value && value <= sup;
            }
            if (!inf && !!sup && value) {
                return value <= sup;
            }
            if (!!inf && !sup && value) {
                return value >= inf;
            }
            if (!value) return false;
            return true;
        };
    };

    const filtered = useMemo(() => {
        const items = fuzzSearch(completeParticipants, filter, ['firstname', 'lastname'], true);

        const result = applyFilters(items, [
            startInf || startSup ? filterByInterval(startInf, startSup) : undefined,
            minProgress || maxProgress
                ? filterByRange(minProgress, maxProgress, (item) =>
                      item.progress && !isNaN(+item.progress) ? +item.progress : undefined
                  )
                : undefined,
            minTime || maxTime
                ? filterByRange(minTime, maxTime, (item) =>
                      item.elapsed_time ? item.elapsed_time / 1000 / 60 : undefined
                  )
                : undefined,
        ]);

        return result.sort((a, b) => (b.last_activity || 0) - (a.last_activity || 0));
    }, [filter, completeParticipants, startInf, startSup, minProgress, maxProgress, minTime, maxTime]);

    const ParticipantExpandTemplate = (data: ExtendedParticipant) => {
        return (
            <ParticipantUnitTable
                participant={data}
                session={props.session}
                timeReport={reports[data.participant_id]}
            />
        );
    };

    const formation = formations.find((f) => f.formation_id === props.session.formation_id);

    const getUnitStatus = (participant: ExtendedParticipant, modules: Module[], unit_id: string) => {
        if (!formation) return 'no-access';
        const unitConfig = props.session.unitsConfig.find((u) => u.unit_id === unit_id);
        const unit = formation.units.find((e) => e.unit_id === unit_id);
        const unitMeta = participant.unitsMeta?.find((u) => u.unit_id === unit_id);

        if (!unitConfig || !unit || !unitMeta) return 'no-access';

        if (
            !unitConfig.start_date ||
            DateTime.fromISO(unitConfig.start_date)
                .setZone('Europe/Paris')
                .startOf('day')
                .diffNow()
                .as('milliseconds') > 0
        )
            return 'no-access';

        const unitModules = unit.modules_ids
            .map((m) => modules.find((e) => e.module_id === m))
            .filter(Boolean) as Module[];

        const totalUnitTime = unitModules.reduce((acc, m) => {
            return acc + (durationFromEstimated(m.minimal_duration, m.minimal_duration_unit)?.as('milliseconds') ?? 0);
        }, 0);
        const totalModuleTime =
            participant.timeReport?.modules.reduce((acc, m) => {
                if (!unit.modules_ids.includes(m.module_id)) return acc;
                const module = modules.find((e) => e.module_id === m.module_id);
                const maxModuleTime =
                    durationFromEstimated(module?.minimal_duration, module?.minimal_duration_unit)?.as(
                        'milliseconds'
                    ) ?? 0;
                return acc + Math.min(m.total || 0, maxModuleTime);
            }, 0) ?? 0;

        if (totalModuleTime < totalUnitTime) return 'missing-time';

        const hasMissingSignature = unitConfig.required_signature
            ? unitMeta.signature_id == null && unitMeta.access
            : false;

        if (hasMissingSignature) return 'missing-signatures';

        return unitMeta.access ? 'access' : 'no-access';
    };

    return (
        <div className="ParticipantList">
            <ConfirmDialog />
            <CustomActionDropdown options={groupedOptions} ref={groupedRef} />
            <CustomActionDropdown options={options} ref={ref} />
            {importUsersVisible && (
                <CustomDialog onHide={() => setImportUsersVisible(false)}>
                    <ImportParticipants onQuit={() => setImportUsersVisible(false)} session={props.session} />
                </CustomDialog>
            )}
            {addUserVisible && (
                <CustomDialog onHide={() => setAddUserVisible(false)}>
                    <AddParticipant onQuit={() => setAddUserVisible(false)} session={props.session} />
                </CustomDialog>
            )}
            {(selection || groupedSelection.length > 0) && endDateUpdateVisible && (
                <CustomDialog onHide={() => setEndDateUpdateVisible(false)}>
                    <UpdateEndDate
                        onQuit={() => setEndDateUpdateVisible(false)}
                        session={props.session}
                        participants={groupedSelection.length > 0 ? groupedSelection : selection ? [selection] : []}
                    />
                </CustomDialog>
            )}

            {selection && answerDetailVisible && (
                <CustomDialog onHide={() => setAnswerDetailVisible(false)}>
                    <AnswerDetails
                        onQuit={() => setAnswerDetailVisible(false)}
                        userProgress={usersProgress[selection.participant_id]}
                        session={props.session}
                        participant={selection}
                    />
                </CustomDialog>
            )}
            {selection && banUserVisible && (
                <CustomDialog onHide={() => setBanUserVisible(false)}>
                    <BanUser
                        onQuit={() => setBanUserVisible(false)}
                        session_id={props.session.session_id}
                        participant={selection}
                    />
                </CustomDialog>
            )}
            {groupedSelection.length > 0 && sendScheduledMessageVisible && (
                <CustomDialog onHide={() => setSendScheduledMessageVisible(false)}>
                    <SendScheduledMessage
                        onQuit={() => setSendScheduledMessageVisible(false)}
                        session={props.session}
                        participants={groupedSelection}
                    />
                </CustomDialog>
            )}
            {
                groupedSelection.length > 0 && sendRelanceClassVisible && (
                    <CustomDialog onHide={() => setSendRelanceClassVisible(false)}>
                        <SendRelanceVirtualClass
                            onQuit={() => setSendRelanceClassVisible(false)}
                            session={props.session}
                            participants={groupedSelection}
                        />
                    </CustomDialog>
                )
            }
            {groupedSelection.length > 0 && sendScheduledAttestationVisible && (
                <CustomDialog onHide={() => setSendScheduledAttestationVisible(false)}>
                    <SendScheduledAttestation
                        onQuit={() => setSendScheduledAttestationVisible(false)}
                        session={props.session}
                        participants={groupedSelection}
                    />
                </CustomDialog>
            )}
            {selection && selectionOriginalUser && userInformationVisible && (
                <CustomDialog width={1340} onHide={() => setUserInformationVisible(false)}>
                    <UserInformation user={selectionOriginalUser} onQuit={() => setUserInformationVisible(false)} />
                </CustomDialog>
            )}

            {['admin', 'author'].includes(meta.role) && (
                <HeaderContainer className="flex justify-content-between mb-3">
                    <Button
                        className={'he-button--primary--md'}
                        label="Inscrire un apprenant"
                        icon={'pi pi-user-plus'}
                        onClick={() => setAddUserVisible(true)}
                    />
                    <Button
                        className={'he-button--primary-nf--md'}
                        label="Importer des apprenants"
                        icon={'pi pi-upload'}
                        onClick={() => setImportUsersVisible(true)}
                    />
                </HeaderContainer>
            )}
            <CustomTable
                dataKey={'participant_id'}
                loading={loadingReports || loading}
                values={filtered}
                selection={groupedSelection}
                expandTemplate={ParticipantExpandTemplate}
                defaultRowsDisplayed={100}
                onSelectionChange={(sel) => {
                    setGroupedSelection(
                        sel.map(
                            produce((draft) => {
                                draft.session_id = props.session.session_id;
                            })
                        )
                    );
                }}
                header={{
                    title: 'Apprenants inscrits à la session',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher un apprenant"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                        <Button
                            disabled={groupedSelection.length === 0}
                            label="Actions groupées"
                            icon="pi pi-angle-down"
                            className="he-button--primary--md"
                            onClick={(e) => groupedRef.current?.toggle(e)}
                        />,
                    ],
                    filters: [
                        <div className="flex align-items-center he-paragraph--regular inter gray-600 mr-3">
                            Avancement
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Min"
                                value={minProgress}
                                onChange={(e) => setMinProgress(e)}
                            />
                            à
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Max"
                                value={maxProgress}
                                onChange={(e) => setMaxProgress(e)}
                            />
                        </div>,
                        <div className="flex align-items-center he-paragraph--regular inter gray-600">
                            Temps passé (minutes) :
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Min"
                                value={minTime}
                                onChange={(e) => setMinTime(e)}
                            />
                            à
                            <CustomInputNumber
                                className="w-4rem mx-2"
                                placeholder="Max"
                                value={maxTime}
                                onChange={(e) => setMaxTime(e)}
                            />
                        </div>,
                        <div className="flex align-items-center he-paragraph--regular inter gray-600">
                            Dernière activité
                            <Calendar
                                className="w-6rem mx-2"
                                placeholder="jj/mm/aaaa"
                                value={startInf}
                                onChange={(e) => setStartInf(e.value ?? null)}
                                dateFormat="dd/mm/yy"
                            />
                            à
                            <Calendar
                                className="w-6rem mx-2"
                                placeholder="jj/mm/aaaa"
                                value={startSup}
                                onChange={(e) => setStartSup(e.value ?? null)}
                                dateFormat="dd/mm/yy"
                            />
                        </div>,
                    ],
                }}
                columns={[
                    {
                        header: 'Nom / Prénom',
                        field: 'lastname',
                        filter: {
                            type: 'sort',
                            field: 'lastname',
                        },
                        size: 150,
                        body: (item) => <UserBadge user={item} email={false} />,
                    },
                    {
                        header: 'Avancement',
                        filter: {
                            type: 'sort',
                            field: 'progress',
                        },
                        size: 100,
                        body: (item) => (
                            <div>
                                {item.progress === -1
                                    ? ' - '
                                    : new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                          item.progress || 0
                                      )}{' '}
                                %
                            </div>
                        ),
                    },
                    {
                        header: 'Détail',
                        size: 100,
                        body: (item) => {
                            const attestation = item.attestation_access;

                            return (
                                <div className="flex align-items-center gap-2">
                                    {formation?.units.map((u) => {
                                        let status: keyof typeof infoMap = getUnitStatus(item, modules, u.unit_id);

                                        return (
                                            <i
                                                title={infoMap[status]}
                                                style={{ cursor: 'help' }}
                                                key={u.unit_id}
                                                className={classNames('pi', iconMap[status])}
                                            />
                                        );
                                    })}
                                    {!item.manual_attestation_lock ? (
                                        <i
                                            title={infoMap[attestation ? 'access' : 'no-access']}
                                            style={{ cursor: 'help' }}
                                            className={classNames('pi', iconMap[attestation ? 'access' : 'no-access'])}
                                        />
                                    ) : (
                                        <i
                                            title={infoMap['locked']}
                                            style={{ cursor: 'help' }}
                                            className={classNames('pi', iconMap['locked'])}
                                        />
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        header: 'Temps passé',
                        size: 80,
                        body: (item) => {
                            return (
                                <div>
                                    {item.elapsed_time
                                        ? Duration.fromMillis(item.elapsed_time).toFormat('hh:mm:ss')
                                        : '-'}
                                </div>
                            );
                        },
                    },
                    {
                        header: 'Date de fin',
                        size: 90,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        body: (item) => <div>{DateTime.fromISO(item.end_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Log de connexion',
                        size: 90,
                        filter: {
                            type: 'sort',
                            field: 'last_activity',
                        },
                        body: (item) => (
                            <div className="flex flex-column gap-1">
                                <div className="flex flex-column">
                                    <span className="he-paragraph--xs">Dernier accès</span>
                                    <span className="he-paragraph--small">
                                        {' '}
                                        {item.last_connection
                                            ? DateTime.fromMillis(item.last_connection)
                                                  .setZone('Europe/Paris')
                                                  .setLocale('fr')
                                                  .toFormat('dd/MM/yyyy')
                                            : '-'}{' '}
                                    </span>
                                </div>
                                <div className="flex flex-column">
                                    <span className="he-paragraph--xs">Dernière activité</span>
                                    <span className="he-paragraph--small">
                                        {' '}
                                        {item.last_activity
                                            ? DateTime.fromMillis(item.last_activity)
                                                  .setZone('Europe/Paris')
                                                  .setLocale('fr')
                                                  .toFormat('dd/MM/yyyy')
                                            : '-'}{' '}
                                    </span>
                                </div>
                            </div>
                        ),
                    },
                    {
                        header: 'Statut',
                        size: 100,
                        body: (item) =>
                            !item.blocked ? (
                                <Badge value="ACTIF" severity="success" />
                            ) : (
                                <Badge value="BLOQUÉ" severity="warning" />
                            ),

                        filter: {
                            type: 'select',
                            field: 'blocked',
                            filterOptions: (users) => [
                                {
                                    value: true,
                                    label: 'Bloqué',
                                },
                                {
                                    value: false,
                                    label: 'Actif',
                                },
                            ],
                            filterItemTemplate: (option) => <div>{option.value ? 'BLOQUÉ' : 'ACTIF'}</div>,
                        },
                    },
                    {
                        frozen: true,
                        size: 100,
                        alignFrozen: 'right',
                        headerStyle: { width: '100px' },
                        body: ParticipantDropdown,
                    },
                ]}
            />
        </div>
    );
};
