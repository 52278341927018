import { CustomDialog } from '@Components/CustomDialog';
import { Formation } from '@Types/Formation';
import { ScheduledEvent } from '@Types/ScheduledEvent';
import { Session } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { formatDate } from '@Utils/date.utils';
import { deleteDoc, doc } from 'firebase/firestore';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EditRecurringEvent } from './EditRecurringEvent';
interface IEventCardProps {
    event: ScheduledEvent & { session: Session & { formation: Formation } };
}

export const CardContainer = styled.div`
    cursor: pointer;
    &:not(:first-child) {
        margin-top: 1rem;
    }
`;

export const EventCard: FC<IEventCardProps> = ({ event }) => {
    const [editVisible, setEditVisible] = useState<boolean>(false);

    const getRelanceType = (type: number) => {
        switch (type) {
            case 0:
                return 'Relance';
            case 1:
                return "Relance fin d'unité";
            case 99:
                return 'Relance attestation';
            default:
                return 'Type inconnu';
        }
    };

    const onDeleteEvent = (event: ScheduledEvent) => {
        deleteDoc(doc(firestore.db, `events/${event.event_id}`));
    };

    const unitIndex = useMemo(
        () => ('unit_id' in event ? event.session.unitsConfig.findIndex((u) => u.unit_id === event.unit_id) : 0),
        [event]
    );

    return (
        <>
            {editVisible && event.type === 'recurring' && (
                <CustomDialog onHide={() => setEditVisible(false)}>
                    <EditRecurringEvent event={event} onQuit={() => setEditVisible(false)} session={event.session} />
                </CustomDialog>
            )}
            {event.type === 'single' && (
                <CardContainer>
                    <div className="shadow-1 transition-all transition-duration-200 transition-ease-in hover:shadow-2 p-2 border-round-md">
                        <div className="flex w-full align-items-center justify-content-start">
                            <div className={'he-paragraph--regular flex align-items-center'}>
                                <i className={'pi pi-clock mx-2'} /> Relance {formatDate(event.date, 'fff')}
                                <Tag value={`Unité ${unitIndex + 1}`} severity={'warning'} className={'ml-4 mr-2'} />
                                {event.delay == null && (
                                    <Tag
                                        value={`Apprenant(s) ${event.user_ids.length}`}
                                        severity={'info'}
                                        className={'mr-2'}
                                    />
                                )}
                                <Tag value={getRelanceType(event.mail_type)} />
                                {event.delay != null && <CustomTag className={'ml-2'}>Délai {event.delay}h</CustomTag>}
                            </div>
                            <div className="ml-auto flex">
                                <Button
                                    className="he-button--danger-nfb--lg--rounded"
                                    onClick={() => onDeleteEvent(event)}
                                >
                                    <i className="pi pi-trash" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContainer>
            )}
            {event.type === 'virtual_class' && (
                <CardContainer>
                    <div className="shadow-1 transition-all transition-duration-200 transition-ease-in hover:shadow-2 p-2 border-round-md">
                        <div className="flex w-full align-items-center justify-content-start">
                            <div className={'he-paragraph--regular flex align-items-center'}>
                                <i className={'pi pi-clock mx-2'} /> Relance classe virtuelle{' '}
                                {formatDate(event.date, 'fff')}
                                <Tag
                                    value={`Apprenant(s) ${event.user_ids.length}`}
                                    severity={'info'}
                                    className={'ml-4 mr-2'}
                                />
                                <Tag value="Relance CV" />
                            </div>
                            <div className="ml-auto flex">
                                <Button
                                    className="he-button--danger-nfb--lg--rounded"
                                    onClick={() => onDeleteEvent(event)}
                                >
                                    <i className="pi pi-trash" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContainer>
            )}
            {event.type === 'recurring' && (
                <CardContainer onClick={() => setEditVisible(true)}>
                    <div className="shadow-1 transition-all transition-duration-200 transition-ease-in hover:shadow-2 p-2 border-round-md">
                        <div className="flex w-full align-items-center justify-content-start">
                            <div className="flex ml-3">
                                {event.hour && (
                                    <div className="he-paragraph--regular gray-600">
                                        <i className="pi pi-clock" /> {formatDate(event.hour, 'HH:mm')}
                                    </div>
                                )}
                            </div>
                            <div className="flex ml-4">
                                {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classNames(
                                                'mr-3 he-paragraph--regular',
                                                event.days.includes(index) ? 'primary-600' : 'gray-300'
                                            )}
                                        >
                                            {day}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={'he-paragraph--regular'}>
                                <Tag value={`Unité ${unitIndex + 1}`} severity={'warning'} className={'ml-2 mr-2'} />
                                <Tag value={getRelanceType(event.mail_type)} />
                                <Tag
                                    value={
                                        event.delay
                                            ? `< ${event.delay.toString().padStart(2, '0')} heure(s)`
                                            : '-- heure(s)'
                                    }
                                    severity={'info'}
                                    className="ml-2"
                                />
                            </div>
                            <div className="ml-auto flex">
                                <Button
                                    className="he-button--danger-nfb--lg--rounded"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteEvent(event);
                                    }}
                                >
                                    <i className="pi pi-trash" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContainer>
            )}
        </>
    );
};

const CustomTag = styled(Tag)`
    background-color: darkgray;
`;
