import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import styled from "styled-components";
import {useUser} from "@Hooks/firebase";
import {LoginForm} from "@Pages/login/LoginForm";
import {ForgottenPassword} from "@Pages/login/ForgottenPassword";
import {ResetPassword} from "@Pages/login/ResetPassword";
import {RegisterForm} from "@Pages/login/RegisterForm";
import {DefaultLayout} from "@Pages/layouts/DefaultLayout";

const MobileApp = styled.div`
  width: 100%;
  height: 100%;
`;

export const App : React.FC = () => {

    const user = useUser();

    return (
        <MobileApp className="">
            {
                !user?.isConnected ?
                    <Routes>
                        <Route path="/login" element={<LoginForm />}/>
                        <Route path="/forgotten-password" element={<ForgottenPassword />} />
	                    <Route path="/reset/:code" element={<ResetPassword />} />
	                    <Route path="/register" element={<RegisterForm />} />
	                    <Route path="/*" element={<Navigate to={"/login"} />} />
                    </Routes>
                    :
                    <Routes>
	                    <Route path="/*" element={<DefaultLayout />}/>
                    </Routes>
            }
        </MobileApp>
    )
}
