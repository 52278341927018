import { FunctionItem1 } from "@Components/DevPanel/FunctionItem";
import { useUser } from "@Hooks/firebase";
import { ScheduledEvent, SingleEvent } from "@Types/ScheduledEvent";
import { User, UserProgress, UserProgressItem } from "@Types/User";
import { Maintenance as MaintenanceType } from "@Types/maintenance";
import { firestore, maintenanceRef } from "@Utils/config/firebase";
import { DocumentReference, collection, deleteField, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { chunk, entries } from "lodash";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import { v4 } from "uuid";
import { env } from "../env";
import packageJson from './../../package.json';
import {toast} from "react-toastify";
import success = toast.success;

interface Props {
}


export const DevPanel: React.FC<Props> = (props) => {


	const [visible, setVisible] = useState<boolean>(false);
	const [maintenance, setMaintenance] = useState<MaintenanceType.Maintenance | null>(null);
	const user = useUser()

	useEffect(() => {
		const unsubscribeMaintenance = maintenanceRef.onSnapshot(env.maintenanceKey, maintenanceDoc => {
			console.log(maintenanceDoc)
			if (maintenanceDoc.exists()) {
				setMaintenance(maintenanceDoc.data());
			}
		});
		return () => {
			unsubscribeMaintenance();
		};
	}, []);

	const handleChangeMaintenance = (maintenance: MaintenanceType.Maintenance) => {
		maintenanceRef.set(env.maintenanceKey, maintenance).then(() => maintenanceRef.get(env.maintenanceKey).then(maintenance => setMaintenance(maintenance)));
	};

	const maintenanceOption = [
		{label: 'Pas de maintenance', value: 'no'},
		{label: 'Maintenance avec Watermark', value: 'watermark'},
		{label: 'Maintenance accès hors admin bloqué', value: 'block'},
	];


	const cleanUserProgress = async () => {
		const session = "373b4034-2703-4c7a-8b3c-0b1792d87bcc";
		const user = "9b7d47d6-f266-46db-8574-ff9cf81c09cb";

		const allProgress = await getDocs(query(collection(firestore.db, `users/${user}/progress`), where("session_id", "==", session)));

		const chunks = chunk(allProgress.docs, 500);

		for (const chunk of chunks) {
			const batch = firestore.createBatch();
			for (const doc of chunk) {
				console.log("delete", doc.ref.path);
				batch.delete(doc.ref);
			}
			await batch.commit();
		}

	}

	const migrateEvents = async () => {
		getDocs(collection(firestore.db, "events")).then(res => {
			for (const doc of res.docs) {
				const data = doc.data() as ScheduledEvent;

				if (data.type === "single") {
					const date = DateTime.fromISO(data.date).setZone("Europe/Paris").set({hour: 9, minute: 0, second: 0}).toISO();
					if (date)
						updateDoc(doc.ref, {
							date
						} satisfies Partial<SingleEvent>);
				}
			}
		});
	}

	const migrateModules = async () => {
		const modules = await getDocs(collection(firestore.db, "modules"));
		const chunked = chunk(modules.docs, 500);
		for (const chunk of chunked) {
			const batch = firestore.createBatch();
			for (const module of chunk) {
				batch.update(module.ref, "quizz_type", "default");
			}
			await batch.commit();
		}

		success("Migration des modules terminée");
	}
	const migrateFormation = async () => {
		const formationRefs =  await getDocs(collection(firestore.db, "formations"));
		for (const formationRef of formationRefs.docs) {
			await updateDoc(formationRef.ref, "type", "Evaluation des pratiques professionnelles (EPP)");
		}
		success("Migration des formations terminée");
	}

	const flattenUserProgress = async () => {
		console.log("flattening start")
		const users = await firestore.collection<Omit<User, "progress"> & {progress: UserProgress}>("users").getAll();
		for (const user of users) {
			const flattenedProgress = entries(user.progress || {}).reduce<UserProgressItem[]>((progress, [session_id, sessionModules]) => {

				const flattendModule = entries(sessionModules).reduce<UserProgressItem[]>((moduleProgress, [module_id, moduleActivities]) => {

					const activityProgress = entries(moduleActivities).reduce<UserProgressItem[]>((activityProgress, [activity_id, activity]) => {

						if (activity)
							activityProgress.push({
								progress_id: v4(),
								session_id,
								module_id,
								activity_id,
								user_id: user.user_id,
								activity
							});

						return activityProgress;

					}, [])

					moduleProgress.push(...activityProgress);
					return moduleProgress;
				}, [])

				progress.push(...flattendModule);

				return progress;
			}, [])


			const chunkedEntries = chunk(flattenedProgress, 500);

			let i = 0;
			for (const chunk of chunkedEntries) {
				const batch = firestore.createBatch();
				console.log("Creation chunk", i++, "of", chunkedEntries.length, "for user", user.user_id, "with", chunk.length, "entries");
				for (const progress of chunk) {
					batch.set(doc(firestore.db, `users/${user.user_id}/progress/${progress.progress_id}`), progress);
				}
				await batch.commit()
			}

			console.log("delete progress");
			await updateDoc(doc(firestore.db, `users/${user.user_id}`), {
				progress: deleteField()
			})

		}

		// let i = 0;

		// const chunkedPromises = chunk(promises, 10);
		// console.log("Total promises", promises.length, "in", chunkedPromises.length, "chunks")
		// for (const promiseChunk of chunkedPromises) {
		// 	console.log("Executing chunk", i++, "of", chunkedPromises.length, "with", promiseChunk.length, "promises");
		// 	await Promise.allSettled(promiseChunk.map(p => p()));
		// }

	}

	const updateUserMailing = async () => {
		const users = await firestore.collection<User>("users").getAll();
		const chunkedUsers = chunk(users, 500);
		let i = 0;
		for (const chunk of chunkedUsers) {
			const batch = firestore.createBatch();
			console.log("Creation chunk", i++, "of", chunkedUsers.length, "with", chunk.length, "users");
			for (const user of chunk) {
				batch.update<User, User>(doc(firestore.db, `users/${user.user_id}`) as DocumentReference<User, User>, {
					accept_mailings: 3
				})
			}
			await batch.commit()
		}
	}

	return (
		<>
			{(env.isDev || user?.firebase?.email === "christopher.martin@mobileo.tech") &&
                <>
                    <Sidebar
                        position={"left"}
                        visible={visible}
                        onHide={() => setVisible(false)}
                        style={{width: 400}}
                        className={"component-DevFunctionPanel"}
                    >
                        <PanelBody>
                            <FunctionBody>
                                <CategoryLabel>Execute Function</CategoryLabel>
                                <FunctionItem1
                                    label={"Create Admin account"}
                                />
								<Button label="clean progress" onClick={cleanUserProgress} />
								<Button label={"Migrate events"} onClick={migrateEvents}/>
								<Button label="Flatten user progress" onClick={flattenUserProgress}/>
								<Button label="Migrate formations" onClick={migrateFormation} />
								<Button label="Migrate modules" onClick={migrateModules} />
								<Button label="Update user mailing" onClick={updateUserMailing}/>
                            </FunctionBody>
                            <PanelFooter>
								{maintenance &&
                                    <>
                                        <CategoryLabel className={"mb-3"}>Maintenance site</CategoryLabel>
                                        <InputField className="field">
                                            <SelectButton
                                                value={maintenance?.active}
                                                options={maintenanceOption}
                                                unselectable={false}
                                                onChange={(e) => handleChangeMaintenance({
													...maintenance,
													active: e.value as "no" | "block" | "watermark",
												})}
                                            />
                                        </InputField>
                                    </>
								}
                            </PanelFooter>
                        </PanelBody>
                    </Sidebar>
					{
						env.debugInfo &&
                        <ButtonHolder>
                            <Button
                                onClick={() => setVisible(true)}
                                icon={"pi pi-server"}
                                title={"Dev functions"}
                            />
                        </ButtonHolder>
					}
					{env.debugInfo && <VersionHolder>
                        <div className="flex flex-column">
                            <div>Projet Firebase : {env.firebaseEnv.projectId} {env.isFirebaseProductionProjet ?
								<Tag severity="danger" value="PROD"></Tag> :
								<Tag severity="info" value="DEV"></Tag>}
                            </div>
                            <div>RunTime : {env.isDev ? <Tag severity="info" value="Développement"></Tag> :
								<Tag severity="danger" value="PRODUCTION"></Tag>}</div>
                            <div>Version front : {packageJson.version}</div>
                        </div>
                    </VersionHolder>}
                </>}
		</>
	)
}

const PanelFooter = styled.footer`

`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;


const CategoryLabel = styled.p`
  margin: 0;
`;

const FunctionBody = styled.main`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  flex: 1;
`;

const PanelBody = styled.main`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: 100%;
`;

const ButtonHolder = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 20;

  .p-button {
    height: 30px;
    width: 30px;
  }
`;
const VersionHolder = styled.div`
  position: absolute;
  top: 0px;
  pointer-events: none;
  right: 0px;
  z-index: 9999;
`;
