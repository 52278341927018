import {produce} from "immer";

export const moveItemOrder = <T>(list: T[], identityFn: (a: T) => boolean, newIndex: number) => {
	const index = list.findIndex(e => identityFn(e));

	if (index === -1) return list;

	return produce(list, (draft: T[]) => {
		const item = list[index];
		if (index > newIndex) {
			draft.splice(newIndex, 0, item);
			draft.splice(index + 1, 1);
		} else {
			draft.splice(index, 1);
			draft.splice(newIndex, 0, item);
		}
	})
}
