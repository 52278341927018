import {useEffect, useState} from "react";
import {Message, MessageExtended} from "@Types/Message";
import {collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {Navigate} from "react-router-dom";
import styled from "styled-components";
import {MessageList} from "./Forum/MessageList";
import {MessageInputBox} from "./Forum/MessageInputBox";
import _ from "lodash";
import {Dropdown} from "primereact/dropdown";

type Props = {
    formation_id: string;
}
export const Forum: React.FC<Props> = (props) => {

    const [messages, setMessages] = useState<MessageExtended[]>([]);
    const [order, _setOrder] = useState<"asc" | "desc" | "likes">("desc");
    const [limitResults, setLimitResults] = useState<number>(50);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const q = query(
            collection(firestore.db, `formations/${props.formation_id}/messages`),
            where("formation_id", "==", props.formation_id),
            order !== "likes" ? orderBy('created_at', order) : orderBy('likes', 'desc'),
            limit(limitResults)
        );

        return onSnapshot(q, (snapshot) => {
            const docs = snapshot.docs.map(doc => doc.data() as Message);
            const queryTotal = query(collection(firestore.db, `formations/${props.formation_id}/messages`), where("formation_id", "==", props.formation_id))
            const total = getCountFromServer(queryTotal);
            total.then(t => setTotal(t.data().count));

            const messageWithReplies = docs.filter(message => message.replyTo !== null);
            const chunked = _.chunk(messageWithReplies, 10);
            Promise.all(chunked.map(chunk => {
                const sq = query(collection(firestore.db, `formations/${props.formation_id}/messages`),
                    where("formation_id", "==", props.formation_id),
                    where("message_id", "in", chunk.map(m => m.replyTo!))
                );
                return getDocs(sq);
            })).then(snapshotsArr => {
                const results = snapshotsArr.map(s => s.docs).flat().map(d => d.data() as Message);
                const extended = docs.map(message => {
                    const reply = results.find(result => result.message_id === message.replyTo);
                    return {
                        ...message,
                        reply: reply || null
                    }
                });
                setMessages(extended);
            })

        });

    }, [props, limitResults, order]);

    const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);

    const options = [
        {
            label: "Plus récent",
            value: "desc"
        },
        {
            label: "Plus ancien",
            value: "asc"
        },
        {
            label: "Popularité",
            value: "likes"
        }
    ];

    return (
        props.formation_id ?
            <div className="flex justify-content-center w-10 bg-primary-7 p-3" style={{height: 600}}>
                <Container className="border-round-md shadow-1">
                    <div className="flex gap-2 align-items-center px-3 py-2 justify-content-end">
                        <span className="he-paragraph--small gray-500">
                            Trier par
                        </span>
                        <Dropdown options={options} value={order} onChange={e => _setOrder(e.value)}/>
                    </div>
                    <MessageList
                        total={total}
                        order={order}
                        formation_id={props.formation_id}
                        messages={messages}
                        onLoadMore={() => setLimitResults(prev => Math.min(prev + 50, total))}
                        onReply={message => setSelectedMessage(message)}
                    />
                    <MessageInputBox
                        messages={messages}
                        formation_id={props.formation_id}
                        selectedMessage={selectedMessage}
                        setSelectedMessage={setSelectedMessage}
                    />
                </Container>
            </div>
            :
            <Navigate to={"/home"}/>
    );
};


const Container = styled.div`
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    .p-dropdown {
        border: 1px solid #e0e0e0;
        height: 26px;
        border-radius: 4px;
        &-label {
            padding: 8px;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 14px;
        }
        &-trigger {
            width: 1.5rem;
            
            svg.p-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
`
