import React, { useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import { Session } from '@Types/Session';
import { usePromise } from '@Hooks/promise';
import { useApi } from '@Hooks/api';
import { CustomInput } from '@Components/CustomInput';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { BackofficeContext } from '@Context/BackofficeContext';
import { DateTime } from 'luxon';
import { useUser } from '@Hooks/firebase';

export type SessionSettingsProps = {
    session: Session;
};
export const SessionSettings: React.FC<SessionSettingsProps> = (props) => {
    const api = useApi();
    const { formations } = useContext(BackofficeContext);
    const { meta } = useUser(true);

    const [updateSession, loading] = usePromise(
        async (session: Session) => {
            const res = await api.session_call_update({
                session: session,
            });

            if (res.result !== 'ok') throw new Error(res.result);
        },
        {
            pending: 'Mise à jour en cours',
            success: 'Session mise à jour !',
        }
    );

    const sessionFormik = useFormik({
        initialValues: props.session,
        enableReinitialize: true,
        onSubmit: updateSession,
    });

    const isPassed = useMemo(() => {
        return DateTime.fromISO(sessionFormik.values.start_date).toMillis() <= Date.now();
    }, [sessionFormik.values.start_date]);

    return (
        <div className="SessionSettings w-6">
            <div className="he-header--h3 gray-900">Informations générales</div>
            <div className="mt-4">
                <CustomInput
                    label="Numéro de la session"
                    placeholder="Numéro de la session"
                    field="session_custom_id"
                    disabled={!['admin', 'author'].includes(meta.role)}
                    formik={sessionFormik}
                />
            </div>
            <div className="mt-3">
                <CustomFormDropdown
                    options={formations.map((f) => ({ label: f.title, value: f.formation_id }))}
                    label="Formation"
                    field="formation_id"
                    disabled={isPassed}
                    placeholder={'Sélectionner une formation'}
                    filter
                    filterBy="label"
                    formik={sessionFormik}
                />
            </div>
            <div className="flex mt-3">
                <div className="flex-1 mr-4">
                    <div className="he-paragraph--regular gray-500">Date de début</div>
                    <Calendar
                        className="mt-2 w-full"
                        placeholder={'jj/mm/aaaa'}
                        disabled={isPassed}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.start_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('start_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
                <div className="flex-1">
                    <div className="he-paragraph--regular gray-500">Date de fin</div>
                    <Calendar
                        className="mt-2 w-full"
                        minDate={DateTime.fromISO(sessionFormik.values.start_date).toJSDate()}
                        placeholder={'jj/mm/aaaa'}
                        dateFormat={'dd/mm/yy'}
                        value={DateTime.fromISO(sessionFormik.values.end_date).toJSDate()}
                        onChange={(e) =>
                            e.value &&
                            sessionFormik.setFieldValue('end_date', DateTime.fromJSDate(e.value).toISO(), false)
                        }
                    />
                </div>
            </div>
            <Button
                className="he-button--primary--md mt-3"
                label={'Enregistrer'}
                icon="pi pi-save"
                loading={loading}
                onClick={sessionFormik.submitForm}
            />
        </div>
    );
};
