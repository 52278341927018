import { Line } from "@Components/UI/Line";
import { UsersContext } from "@Context/UsersContext";
import { useApi } from "@Hooks/api";
import { usePromise } from "@Hooks/promise";
import { ScheduledEvent } from "@Types/ScheduledEvent";
import { Session, SessionParticipant, SessionTimeReport } from "@Types/Session";
import { firestore } from "@Utils/config/firebase";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import _ from "lodash";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import {Calendar} from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import {RelanceHistory} from "@Types/Relance";
import {Nullable} from "primereact/ts-helpers";

const TextArea = styled.textarea`
  padding: 12px;
  resize: none;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
`

export type ExtendedParticipant = SessionParticipant & {
	elapsed_time?: number,
	last_activity?: number,
	progress?: number
	timeReport?: SessionTimeReport;
	session_id?: string;
}

export type SendScheduledMessageProps = {
	onQuit: () => void;
	participants: (SessionParticipant | ExtendedParticipant)[];
	session: Session;
	isFromSessionList?: boolean
}
export const SendScheduledMessage: React.FC<SendScheduledMessageProps> = props => {

	const [mailType, setMailType] = useState<number | null>(0);
	const [selectedUnit, setSelectedUnit] = useState<string | null>(props.session?.unitsConfig[0].unit_id ?? null);
	const [date, setDate] = useState<Nullable<Date>>(null);
	const [minDelay, setMinDelay] = useState<number | null>(null);
	const {users} = useContext(UsersContext);

	const [content, setContent] = useState<string>("");
	const api = useApi();

	const itemTemplate = (option: any) => {
		return <div className="he-paragraph--regular">{option.label}</div>
	}

	const [relances, setRelances] = useState<{[k: string]: RelanceHistory | undefined}>({});

	const [loadLastRelance, setloadLastRelance] = useState<boolean>(false);

	const loadRelanceForParticipants = async () => {
		const participantsIdsChunked = _.chunk(props.participants.map(p => p.participant_id), 30);

		const relances: RelanceHistory[] = [];

		for (const chunk of participantsIdsChunked) {
			const relancesRefs = await getDocs(query(collection(firestore.db, "relances"), where("user_id", "in", chunk), where("session_id", "==", props.session.session_id)));
			relances.push(...relancesRefs.docs.map(d => d.data() as RelanceHistory));
		}

		const relanceGroupedByParticipant = _.groupBy(relances, "user_id");
		console.log(relanceGroupedByParticipant);
		const participantLastRelance = _.mapValues(relanceGroupedByParticipant, relances => _.sortBy(relances, "date").at(-1));

		setRelances(participantLastRelance);
	}

	useEffect(() => {
		setloadLastRelance(true);
		loadRelanceForParticipants().finally(() => setloadLastRelance(false));
	}, [])

	const belongToUnit = useCallback((participant: SessionParticipant) => {

		const unitIndex = props.session.unitsConfig.findIndex(u => u.unit_id === selectedUnit);

		if (unitIndex === -1) return false;
		return props.session.unitsConfig.every((uc, i) => {
			if (i < unitIndex) {
				const participantMeta = participant.unitsMeta?.find(um => um.unit_id === uc.unit_id);
				return participantMeta?.access && !participant.blocked;
			} else if (i > unitIndex) {
				return !participant.blocked;
			} else {
				const hasAccess = participant.unitsMeta?.find(um => um.unit_id === uc.unit_id)?.access;
				const startDate = uc.start_date;
				let isBeforeStartDate = true;
				if (startDate) {
					const diff = DateTime.fromISO(startDate).diffNow('days').days;
					isBeforeStartDate = diff > 0;
				}
				return hasAccess === undefined ? false : (!hasAccess && !participant.blocked && !isBeforeStartDate);
			}

		})

	}, [props.session, selectedUnit]);

	const participants = useMemo(() => {
		return props.participants.map(p => ({
			...p,
			valid: belongToUnit(p),
			accept: users.find(u => u.user_id === p.participant_id)?.accept_mailings
		})).filter(p => {
			if (minDelay === null) return true;
			if (p.last_activity) {
				// last activity was less than minDelay ago
				console.log(p.last_activity, minDelay, minDelay * 60 * 60 * 1000, p.last_activity + minDelay * 60 * 60 * 1000, Date.now());
				if (p.last_activity + minDelay * 60 * 60 * 1000 > Date.now()) {
					return false;
				} else
					return true
			}
			return true;
		})
	}, [props.participants, belongToUnit, users, minDelay])


	const [onCreateEvent, loading] = usePromise(async (mailType: number, content: string, participants: (SessionParticipant & {valid: boolean})[], unit_id: string, date: Nullable<Date>, delay: number | null) => {

		const groupedBySession = _.groupBy(participants, "session_id");

		for (const [session_id, participants] of Object.entries(groupedBySession)) {
			const id = v4();
			if (date instanceof Date) {
				const formattedDate =  DateTime.fromJSDate(date).setZone("Europe/Paris").toISO();
				if (formattedDate) {
					await setDoc(doc(firestore.db, `events/${id}`), {
						event_id: id,
						session_id,
						mail_type: mailType,
						date: formattedDate,
						content,
						unit_id,
						delay,
						type: "single",
						user_ids: participants.filter(p => p.valid).map(p => p.participant_id),
					} satisfies ScheduledEvent)
				}
			}
		}

		props.onQuit();
	}, {
		pending: "Programmation des mails en cours...",
		success: "Les relances ont bien été programmées."
	});

	const [onSend, loadingSend] = usePromise(async (mailType: number, content: string, participants: (SessionParticipant & {valid: boolean})[], unit_id: string, minDelay) => {

		const groupedBySession = _.groupBy(participants, "session_id");

		for (const [session_id, participants] of Object.entries(groupedBySession)) {
			await api.participant_call_sendRelance({
				mail_type: mailType,
				session_id: session_id,
				participant_ids: participants.filter(p => p.valid).filter(p => {
					if (minDelay === null) return true;
					if (p.last_activity) {
						// last activity was less than minDelay ago
						console.log(p.last_activity, minDelay, minDelay * 60 * 60 * 1000, p.last_activity + minDelay * 60 * 60 * 1000, Date.now());
						if (p.last_activity + minDelay * 60 * 60 * 1000 > Date.now()) {
							return false;
						} else
							return true
					}
					return true;
				}).map(p => p.participant_id),
				unit_id,
				custom_content: content
			});
		}

		props.onQuit();
	}, {
		pending: "Envoi des mails en cours...",
		success: "Les mails ont correctement étés envoyés"
	});

	return (
		<div>
			<div className="he-header--h3">
				Relancer ou programmer une relance pour les apprenants sélectionnés
			</div>
			<div className="he-paragraph--regular gray-900 mt-4">
				<div>Souhaitez-vous relancer les apprenants inscrits sélectionnés par mail ?</div>
				<div className="mt-3">
					{
						loadLastRelance ? <i className="pi pi-spinner pi-spin"/> :
						participants
							.map(
							p => <li
								key={p.participant_id}
								className={(p.accept === 0 || p.accept === 2) ? "color-red" : !p.valid ? "gray-300 cursor-default" : ""}>
									{p.firstname} {p.lastname}&nbsp;
									<span className="gray-600">
										{
											relances[p.participant_id] && p.valid && selectedUnit && relances[p.participant_id]?.unit_id === selectedUnit ?
												(DateTime.fromISO(relances[p.participant_id]!.date)
												.setZone("Europe/Paris")
												.toFormat("('dernière relance le 'dd/MM/yy HH:mm)"))
												: ""
										}
									</span>
									</li>
						)
					}
				</div>
			</div>
			<div className="he-paragraph--regular mt-5 gray-900">
				Sélectionnez l'unité :
			</div>
			<SelectButton className="mt-3" value={selectedUnit} onChange={e => setSelectedUnit(e.value)}
			              options={props.session?.unitsConfig.map((e, i) => ({
				              label: `Unité ${i + 1}`,
				              value: e.unit_id
			              }))}
			              itemTemplate={itemTemplate}/>
			<div className="he-paragraph--regular mt-5 gray-900">
				Sélectionnez un type de relance :
			</div>
			<SelectButton className="mt-3" value={mailType} onChange={e => setMailType(e.value)}
			              options={[{label: "Relance", value: 0}, {label: "Relance fin d'unité", value: 1}]}
			              itemTemplate={itemTemplate}/>
			<div className="he-paragraph--regular mt-4">
				Message personnalisé <span className="gray-400 he-paragraph--xs">(optionnel)</span>
			</div>
			<TextArea value={content} onChange={e => setContent(e.target.value)} className="w-full mt-2" placeholder="Saisir ici les mentions supplémentaires à ajouter au mail."/>
			<div className="flex">
				<div className={"mr-4"}>
					<div className="he-paragraph--regular mt-4">
						Date d'envoi
					</div>
					<Calendar value={date} onChange={e => setDate(e.value)} showTime placeholder={"ex. 01/01/2023"} dateFormat={"dd/mm/yy"} className={"mt-2"} minDate={new Date()} />
				</div>
				{(!props.isFromSessionList || date != null) && <div>
					<div className="he-paragraph--regular mt-4">
						Contrainte activité <span className={"he-paragraph--xs gray-400"}>(optionnel)</span>
					</div>
					<InputNumber suffix=" heure(s)" value={minDelay} onChange={e => setMinDelay(e.value)} placeholder={"ex. 48 heures"} className={"mt-2"} />
				</div>}
			</div>
			<Line height={1} className={"bg-gray-200 my-4"}/>
			<div className="flex justify-content-end mt-4">
				<Button className="he-button--secondary-variant-nfb--md mr-3" label="Annuler" loading={loading || loadingSend}
				        onClick={props.onQuit}/>
				<Button disabled={date == null || mailType == undefined || selectedUnit == undefined} loading={loading} className="he-button--primary--md mr-3"
				        icon="pi pi-clock" label={"Programmer"}
				        onClick={() => selectedUnit && mailType !== null && onCreateEvent(mailType, content, participants, selectedUnit, date, minDelay)}/>
				<Button disabled={(minDelay != null && props.isFromSessionList) || mailType == undefined || selectedUnit == undefined} loading={loadingSend} className="he-button--primary--md"
					icon="pi pi-send" label={"Envoyer"}
					onClick={() => selectedUnit && mailType !== null && onSend(mailType, content, participants, selectedUnit, minDelay)} />
			</div>
		</div>
	)
}
