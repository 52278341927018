import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { classNames } from 'primereact/utils';
import { forwardRef, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import './CustomActionDropdown.scss';

export type DropdownOption =
    | undefined
    | null
    | {
          icon: string;
          label: string;
          loading?: boolean;
          action?: () => void;
          items?: DropdownOption[];
      };

const OverlayItem = styled.div`
    padding: 8px 8px;

    cursor: pointer;

    i {
        color: #667085 !important;
    }

    transition: all 0.2s linear;

    &:hover {
        background-color: #f8f8f8;
    }
`;

const OverlayItemGroup = styled.div`
    padding: 8px 8px;

    cursor: pointer;

    :first-child(i) {
        color: #667085 !important;
    }

    :last-child(i) {
        color: #585e6d !important;
        font-size: 15px;
    }

    transition: all 0.2s linear;

    &:hover {
        background-color: #f8f8f8;
    }
`;

export type CustomActionDropdownProps = {
    options: DropdownOption[];
};
export const CustomActionDropdown = forwardRef<TieredMenu, CustomActionDropdownProps>((props, ref) => {
    const optionToMenuItem = (option: DropdownOption, isLast: boolean = false): MenuItem => {
        if (!option) throw new Error('Option is undefined or null');

        if (option.items) {
            return {
                label: option.label,
                icon: option.icon,
                items: option.items
                    .map((item, i, arr) => [optionToMenuItem(item, i === arr.length - 1), { separator: true }])
                    .flat(),
                template: () => {
                    return (
                        <OverlayItemGroup className="he-paragraph--small gray-900 flex inter p-3">
                            <div>
                                <i className={classNames(option.icon, 'mr-2')} />
                            </div>
                            <div className="gray-900">{option.label}</div>
                            <div className="ml-auto">
                                <i className={classNames('pi pi-angle-right')} />
                            </div>
                        </OverlayItemGroup>
                    );
                },
            };
        } else {
            return {
                label: option.label,
                icon: option.icon,
                template: () => {
                    return (
                        <Fragment key={option.label}>
                            {option.loading ? (
                                <OverlayItem>
                                    <i className="pi pi-spinner pi-spin" />
                                </OverlayItem>
                            ) : (
                                <OverlayItem
                                    onClick={() => option.action?.()}
                                    className="he-paragraph--small gray-900 flex inter p-3"
                                >
                                    <div>
                                        <i className={classNames(option.icon, 'mr-2')} />
                                    </div>
                                    <div className="gray-600">{option.label}</div>
                                </OverlayItem>
                            )}
                        </Fragment>
                    );
                },
            };
        }
    };

    const menu = useMemo(
        () =>
            props.options
                .filter((option) => !!option)
                .map((option, i): MenuItem[] => {
                    return [optionToMenuItem(option, i === props.options.length - 1), { separator: true }];
                })
                .flat(),
        [props.options]
    );

    return <TieredMenu popup ref={ref} model={menu} />;
});
