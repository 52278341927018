import React, { useState } from 'react';
import {Hypothesis} from "@Types/Quizz";
import {CustomInput} from "@Components/CustomInput";
import {TCSActivity} from "@Types/Activity";
import {classNames} from "primereact/utils";
import {CustomInputNumber} from "@Components/CustomInputNumber";
import {useFormik} from "formik";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import { UploadButton } from '@Components/UploadButton';
import { usePromise } from '@Hooks/promise';
import { deleteResource, uploadResource } from '@Utils/import.utils';
import { HypothesisSchema } from '@Schemas/activity.schema';

const defaultScale = [
	"Fortement négatif",
	"Négatif",
	"Neutre",
	"Positif",
	"Fortement positif"
];

export type HypothesisFormProps = {
	tcs: TCSActivity;
	hypothesis: Hypothesis;
	onUpdate: (h: Hypothesis) => void;
	onCancel: () => void;
}
export const HypothesisForm: React.FC<HypothesisFormProps> = props => {

	const hypoFormik = useFormik({
		initialValues: props.hypothesis,
		validationSchema: HypothesisSchema,
		enableReinitialize: true,
		onSubmit: props.onUpdate
	})

	const [loadingRemove, setLoadingRemove] = useState<boolean>(false);

	const [onInitialUpload, loadingInitial] = usePromise(async (file: File, formik: ReturnType<typeof useFormik<Hypothesis>>) => {
		const id = await uploadResource(file);
		formik.setFieldValue(`initial_image`, id, false);
	});

	const [onInformationUpload, loadingInformation] = usePromise(async (file: File, formik: ReturnType<typeof useFormik<Hypothesis>>) => {
		const id = await uploadResource(file);
		formik.setFieldValue(`information_image`, id, false);
	});

	const onFileRemove = async (file_id: string, field: keyof Hypothesis) => {
		setLoadingRemove(true);
		await deleteResource(file_id);
		await hypoFormik.setFieldValue(field, null, false);
		setLoadingRemove(false);
	}

    return (
        <div className="HypothesisForm">
	        <div className="he-header--h3 gray-900">Modifier l'hypothèse</div>
	        <div className="mt-4 gap-2 flex flex-column">
		        <CustomInput label="Hypothèse" field={"initial"} formik={hypoFormik} placeholder={"Renseigner l'hypothèse ici"}/>
				<UploadButton
					label={"Ajouter une image"}
					buttonClassName="he-button--primary-nf--xs"
					file_id={hypoFormik.values.initial_image}
					showPreview
					accept={["image/png", "image/jpg", "image/jpeg"]}
					onChange={file => onInitialUpload(file, hypoFormik)}
					onRemove={(file_id) => file_id && onFileRemove(file_id, "initial_image")}
					loading={loadingInitial || loadingRemove}
					icon="pi pi-download"
				/>
	        </div>
	        <div className="mt-3 gap-2 flex flex-column">
		        <CustomInput label="Nouvelle information" field={"information"} formik={hypoFormik} placeholder={"Renseigner les nouvelles informations ici"}/>
				<UploadButton
					label={"Ajouter une image"}
					buttonClassName="he-button--primary-nf--xs"
					file_id={hypoFormik.values.information_image}
					showPreview
					accept={["image/png", "image/jpg", "image/jpeg"]}
					onChange={file => onInformationUpload(file, hypoFormik)}
					onRemove={(file_id) => file_id && onFileRemove(file_id, "information_image")}
					loading={loadingInformation || loadingRemove}
					icon="pi pi-download"
				/>
	        </div>
	        <div className="mt-4 he-paragraph--medium gray-900">
		        Nombre de réponse
	        </div>
	        <div className="he-paragraph--regular gray-500 mt-2">
		        <i className="pi pi-exclamation-circle"/> Indiquez le nombre de réponses données par les experts
	        </div>
	        <div className="flex mt-3">
		        {hypoFormik.values.answers.map((a,i) => {
					return <CustomInputNumber
						key={i}
						label={(props.tcs.labels as any | undefined)?.[i] || defaultScale[i]}
						className={classNames(i < 4 && "mr-3")}
						value={a}
						onChange={e => hypoFormik.setFieldValue(`answers.${i}`, e, false)}
					/>
		        })}
	        </div>
	        <Line height={1} className={"bg-gray-200 my-4"}/>
	        <div className="flex justify-content-end">
		        <Button className="he-button--secondary-variant-nfb--md mr-3" label="Annuler" onClick={props.onCancel}/>
		        <Button className="he-button--primary--md" icon="pi pi-save" label="Enregistrer" onClick={hypoFormik.submitForm}/>
	        </div>
        </div>
    )
}
