import React, { useContext, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useNavigate, useParams } from 'react-router-dom';
import { usePromise } from '@Hooks/promise';
import { useApi } from '@Hooks/api';
import { Formation } from '@Types/Formation';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { CustomInput } from '@Components/CustomInput';
import { CustomTextEditor } from '@Components/CustomTextEditor';
import { UploadButton } from '@Components/UploadButton';
import { CustomInputNumber } from '@Components/CustomInputNumber';
import { RadioGroup } from '@Components/RadioGroup';
import { deleteResource, uploadResource } from '@Utils/import.utils';
import { CustomDialog } from '@Components/CustomDialog';
import { AddModule } from '@Pages/pages/Formations/components/AddModule';
import { UpdateModule } from '@Pages/pages/Formations/components/UpdateModule';
import { produce } from 'immer';
import { moveItemOrder } from '@Utils/array.utils';
import { DeleteModule } from '@Pages/pages/Formations/components/DeleteModule';
import { CustomTabView } from '@Components/CustomTabView';
import { FormationUnit } from '@Pages/pages/Formations/EditFormation/FormationUnit';
import { DeleteUnit } from '@Pages/pages/Formations/components/DeleteUnit';
import { v4 } from 'uuid';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';

const durationOptions = [
    {
        label: 'Semaine(s)',
        value: 's',
    },
    {
        label: 'Jours(s)',
        value: 'j',
    },
    {
        label: 'Heure(s)',
        value: 'h',
    },
    {
        label: 'Minute(s)',
        value: 'm',
    },
];

const Container = styled.div`
    padding-top: 24px;
    width: 100%;
    max-width: 1220px;
    min-width: 1000px;
`;

const ContentContainer = styled.div`
    padding: 28px;
    background: #ffffff;
    border: 1px solid #eaecf0;
`;

const options: Formation['type'][] = [
    'Formation continue (FC)',
    'Evaluation des pratiques professionnelles (EPP)',
    'Evaluation des pratiques professionnelles (EPP) - Nouveau',
    'Parcours intégré',
];

export type EditFormationProps = Record<string, never>;
export const EditFormation: React.FC<EditFormationProps> = () => {
    const params = useParams<{ formation_id: string }>();
    const { formations, getSessionsOfFormation } = useContext(BackofficeContext);
    const [addModuleVisible, setAddModuleVisible] = useState<boolean>(false);
    const [updateModuleVisible, setUpdateModuleVisible] = useState<boolean>(false);
    const [deleteModuleVisible, setDeleteModuleVisible] = useState<boolean>(false);

    const [selectedModule, setSelectedModule] = useState<[{ module_id: string; name?: null | string }, number]>();

    const api = useApi();
    const navigate = useNavigate();

    const formation = useMemo(() => {
        if (!params.formation_id) return undefined;
        return formations.find((f) => f.formation_id === params.formation_id);
    }, [formations, params]);

    const [onUpdateFormation, loading] = usePromise(
        async (formation: Formation) => {
            const res = await api.formation_call_update({
                formation,
            });

            if (res.result !== 'ok') throw new Error(res.result);
        },
        {
            pending: 'Mise à jour de la formation en cours...',
            success: 'Formation mise à jour',
        }
    );

    const formationFormik = useFormik({
        initialValues: formation || {
            formation_id: '',
            title: '',
            estimated_duration: 0,
            estimated_duration_unit: 'j',
            updated_at: '',
            created_at: '',
            description: '',
            andpc: '',
            priority_orientation: '',
            modules: [],
            type: 'Evaluation des pratiques professionnelles (EPP)',
            units: [],
        },
        onSubmit: onUpdateFormation,
        enableReinitialize: true,
    });
    const sessionsForFormation = useMemo(() => {
        if (!formation) return [];
        return getSessionsOfFormation(formation?.formation_id);
    }, [formation, getSessionsOfFormation]);

    const onSave = () => {
        formationFormik.submitForm();
    };

    const onGoToSessions = () => {
        navigate(`/sessions?formation=${formationFormik.values.formation_id}`);
    };

    const onRemoveCover = (field: keyof Formation) => async () => {
        const id = formationFormik.values.cover_image_big_url;
        if (id) await deleteResource(id);
        await formationFormik.setFieldValue('cover_image_url', null, false);
        await formationFormik.submitForm();
    };

    const onRemoveSupport = async () => {
        const id = formationFormik.values.educational_support_url;
        if (id) await deleteResource(id);
        await formationFormik.setFieldValue('educational_support_url', null, false);
        await formationFormik.submitForm();
    };

    const [onUploadCover, loadingCover] = usePromise(async (file: File, field: keyof Formation) => {
        const id = await uploadResource(file);
        await formationFormik.setFieldValue(field, id, false);
    });

    const [onUploadEducationalSupport, loadingSupport] = usePromise(async (file: File) => {
        const id = await uploadResource(file);
        await formationFormik.setFieldValue('educational_support_url', id, false);
    });

    function onAddModule(unit_id: string, module_id: string, name?: string) {
        const old = [...formationFormik.values.modules];
        old.push({
            name,
            module_id,
            visible: true,
        });
        formationFormik.setFieldValue('modules', old, false);

        const updated = produce(formationFormik.values.units, (draft) => {
            const unit = draft.findIndex((u) => u.unit_id === unit_id);

            if (unit === -1) return draft;

            draft[unit] = {
                ...draft[unit],
                modules_ids: [...draft[unit].modules_ids, module_id],
            };

            return draft;
        });

        formationFormik.setFieldValue('units', updated, false);

        setAddModuleVisible(false);
    }

    const onUpdateModule = (module_id: string, name?: null | string, index?: number, visible?: boolean) => {
        const moduleUnitIndex = formationFormik.values.units.findIndex((m) => m.modules_ids.includes(module_id));
        let updatedModules = produce(formationFormik.values.modules, (draft) => {
            return draft.map((a) => {
                if (a.module_id !== module_id) return a;
                return {
                    ...a,
                    name,
                    visible: visible === undefined ? true : visible,
                };
            });
        });

        const updatedUnits = produce(formationFormik.values.units, (draft) => {
            if (moduleUnitIndex === -1 || index === undefined) return draft;
            draft[moduleUnitIndex].modules_ids = moveItemOrder(
                draft[moduleUnitIndex].modules_ids,
                (a) => a === module_id,
                index - 1
            );
            return draft;
        });

        formationFormik.setFieldValue('modules', updatedModules);
        formationFormik.setFieldValue('units', updatedUnits);
        setUpdateModuleVisible(false);
    };

    function onDeleteModule(module: { module_id: string; name?: null | string }) {
        formationFormik.setFieldValue(
            'modules',
            formationFormik.values.modules.filter((m) => m.module_id !== module.module_id)
        );
        formationFormik.setFieldValue(
            'units',
            produce(formationFormik.values.units, (draft) => {
                const unitsEntries = draft.entries();
                for (const [index, unit] of unitsEntries) {
                    draft[index].modules_ids = unit.modules_ids.filter((id) => id !== module.module_id);
                }
                return draft;
            }),
            false
        );
        setDeleteModuleVisible(false);
    }

    const [selectedUnitIndex, setSelectedUnitIndex] = useState<number>(0);

    function onAddUnit() {
        formationFormik.setFieldValue(
            'units',
            produce(formationFormik.values.units, (draft) => {
                draft.push({
                    unit_id: v4(),
                    modules_ids: [],
                });
                return draft;
            }),
            false
        );
    }

    const [deleteUnitVisible, setDeleteUnitVisible] = useState<boolean>(false);

    async function onDeleteUnit(unit_id: string) {
        if (selectedUnitIndex >= 1) setSelectedUnitIndex((prev) => prev - 1);

        const formationUpdated = produce(formationFormik.values, (draft) => {
            const unit = draft.units.find((u) => u.unit_id === unit_id);

            if (!unit) return draft;

            draft.modules = draft.modules.filter((m) => !unit.modules_ids.includes(m.module_id));
            return draft;
        });
        await formationFormik.setFieldValue('modules', formationUpdated.modules, false);
        await formationFormik.setFieldValue(
            'units',
            formationUpdated.units.filter((u) => u.unit_id !== unit_id),
            false
        );

        setDeleteUnitVisible(false);
    }

    const selectedUnit = useMemo(() => {
        return formationFormik.values.units[selectedUnitIndex];
    }, [formationFormik.values, selectedUnitIndex]);

    return formation ? (
        <Container className="w-full h-full overflow-auto">
            {addModuleVisible && (
                <CustomDialog width={950} onHide={() => setAddModuleVisible(false)}>
                    <AddModule
                        formik={formationFormik}
                        onSave={(module_id, name) => onAddModule(selectedUnit.unit_id, module_id, name)}
                        onCancel={() => setAddModuleVisible(false)}
                    />
                </CustomDialog>
            )}
            {deleteUnitVisible && (
                <CustomDialog onHide={() => setDeleteUnitVisible(false)}>
                    <DeleteUnit
                        onDelete={() => onDeleteUnit(selectedUnit.unit_id)}
                        onCancel={() => setDeleteUnitVisible(false)}
                        index={selectedUnitIndex}
                    />
                </CustomDialog>
            )}
            {selectedModule && updateModuleVisible && (
                <CustomDialog width={950} onHide={() => setUpdateModuleVisible(false)}>
                    <UpdateModule
                        module_id={selectedModule[0].module_id}
                        name={selectedModule[0].name}
                        position={selectedModule[1]}
                        onSave={onUpdateModule}
                        onCancel={() => setUpdateModuleVisible(false)}
                    />
                </CustomDialog>
            )}
            {selectedModule && deleteModuleVisible && (
                <CustomDialog onHide={() => setDeleteModuleVisible(false)}>
                    <DeleteModule
                        onDelete={() => onDeleteModule(selectedModule[0])}
                        onCancel={() => setDeleteModuleVisible(false)}
                        module_id={selectedModule[0].module_id}
                    />
                </CustomDialog>
            )}
            <div className="w-full flex justify-content-end">
                <Button
                    loading={loading}
                    label={`Voir les sessions associées (${sessionsForFormation.length})`}
                    iconPos="right"
                    icon="pi pi-external-link"
                    className="he-button--primary-nf--md ml-auto mr-3"
                    onClick={onGoToSessions}
                />
                <Button loading={loading} className="he-button--primary--md" onClick={onSave}>
                    <i className="pi pi-save" />
                </Button>
            </div>
            <ContentContainer className="h-auto flex flex-column mt-3">
                <div className="he-paragraph--medium inter gray-900">Modèle de formation</div>
                <div className="mt-2 he-paragraph--regular gray-500">
                    Vous créez ici un modèle de parcours, c'est à dire sa structure pédagogique Une fols le modèle
                    publié, vous pourrez créer des sessions, dans lesquelles vous gèrerez les dates, adresses, et
                    options de diffusion
                </div>
                <div className="mt-3">
                    <CustomInput label="Titre de la formation" field="title" formik={formationFormik} />
                </div>
                <div className="mt-3">
                    <CustomInput label="Numéro ANDPC de l'action de formation" field="andpc" formik={formationFormik} />
                </div>
                <div className="mt-3">
                    <CustomFormDropdown
                        options={options.map((o) => {
                            if (o === 'Evaluation des pratiques professionnelles (EPP)') {
                                return { label: 'Evaluation des pratiques professionnelles (EPP) - Ancien', value: o };
                            } else if (o === 'Evaluation des pratiques professionnelles (EPP) - Nouveau') {
                                return { label: 'Evaluation des pratiques professionnelles (EPP)', value: o };
                            }
                            return { label: o, value: o };
                        })}
                        label="Type de formation"
                        formik={formationFormik}
                        field={'type'}
                    />
                </div>
                <div className="mt-3">
                    <CustomInput
                        label="Orientation prioritaire"
                        field="priority_orientation"
                        formik={formationFormik}
                    />
                </div>
                <div className="mt-3">
                    <CustomTextEditor
                        label="Description"
                        field="description"
                        formik={formationFormik}
                        placeholder="Description de la formation"
                    />
                </div>
                <div className="flex align-items-center mt-3">
                    <UploadButton
                        icon="pi pi-download"
                        buttonClassName="he-button--primary-nf--md"
                        label="Ajouter une image de couverture (petite)"
                        loading={loadingCover}
                        showPreview
                        accept={['image/png', ' image/jpg', 'image/jpeg']}
                        file_id={formationFormik.values.cover_image_url}
                        onRemove={onRemoveCover('cover_image_url')}
                        onChange={(file) => onUploadCover(file, 'cover_image_url')}
                    />
                    <UploadButton
                        className="ml-3"
                        icon="pi pi-download"
                        buttonClassName="he-button--primary-nf--md"
                        label="Ajouter une image de couverture (large)"
                        loading={loadingCover}
                        showPreview
                        accept={['image/png', ' image/jpg', 'image/jpeg']}
                        file_id={formationFormik.values.cover_image_big_url}
                        onRemove={onRemoveCover('cover_image_big_url')}
                        onChange={(file) => onUploadCover(file, 'cover_image_big_url')}
                    />
                </div>
                <div className="he-paragraph--medium gray-900 mt-4">Durée estimée</div>
                <CustomInputNumber
                    className="mt-3 w-6"
                    label="Choisir une durée"
                    field="estimated_duration"
                    formik={formationFormik}
                />
                <RadioGroup
                    className="mt-2"
                    options={durationOptions}
                    name={'estimated_duration_unit'}
                    onChange={(value) => formationFormik.setFieldValue('estimated_duration_unit', value, false)}
                    value={formationFormik.values.estimated_duration_unit}
                />
                <div className="he-paragraph--medium gray-900 mt-4">Support pédagogique</div>
                <UploadButton
                    className="mt-3"
                    icon="pi pi-download"
                    buttonClassName="he-button--primary-nf--md"
                    label="Ajouter un support pédagogique"
                    accept={['application/pdf']}
                    loading={loadingSupport}
                    onRemove={onRemoveSupport}
                    file_id={formationFormik.values.educational_support_url}
                    onChange={onUploadEducationalSupport}
                />
            </ContentContainer>
            <div className="mt-4 he-header--h3 gray-900">Unités et modules</div>
            <div className="he-paragraph--regular gray-500 mb-3 mt-1">
                Vous pouvez réordonner les modules en unités comme bon vous semble afin de composer votre formation. Il
                est obligatoire d'avoir au moins 1 unité.
            </div>

            <ContentContainer>
                <CustomTabView
                    selected={selectedUnitIndex}
                    tab_id={'unit_tab'}
                    onChange={setSelectedUnitIndex}
                    className="mb-3"
                    items={[
                        ...formationFormik.values.units.map((unit, i) => ({
                            label: `Unité ${i + 1}`,
                        })),
                        {
                            label: 'Ajouter une unité',
                            icon: 'pi pi-plus-circle',
                            action: onAddUnit,
                        },
                    ]}
                />

                <FormationUnit
                    formation={formationFormik.values}
                    unit={formationFormik.values.units[selectedUnitIndex]}
                    onRemoveModule={(m, i) => {
                        setSelectedModule([m, i]);
                        setDeleteModuleVisible(true);
                    }}
                    onChange={onUpdateModule}
                    onModify={(m, i) => {
                        setSelectedModule([m, i + 1]);
                        setUpdateModuleVisible(true);
                    }}
                    onDeletUnit={() => {
                        setDeleteUnitVisible(true);
                    }}
                    onAddModule={() => {
                        setAddModuleVisible(true);
                    }}
                />
            </ContentContainer>
        </Container>
    ) : (
        <div className="w-full h-full">Aucune formation trouvé</div>
    );
};
