import { CustomInput } from '@Components/CustomInput';
import { Button } from 'primereact/button';
import { useMemo } from 'react';

type Props = {
    style?: React.CSSProperties;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    separator: string;
};
export const MultiTextInput: React.FC<Props> = (props) => {
    const values = useMemo(() => {
        return props.value.split(props.separator);
    }, [props.value, props.separator]);

    const onChange = (index: number, value: string) => {
        const newValues = [...values];
        newValues[index] = value;
        props.onChange(newValues.join(props.separator));
    };

    const onRemoveWord = (index: number) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        props.onChange(newValues.join(props.separator));
    };

    return (
        <div className="flex gap-3 w-full flex-wrap align-items-center" style={props.style}>
            {values.map((value, index) => (
                <>
                    <div className="flex align-items-center" style={{ width: 200 }}>
                        <CustomInput
                            placeholder={`Option ${index + 1}`}
                            value={value}
                            onChange={(value) => onChange(index, value)}
                            key={index}
                            {...(values.length > 1
                                ? {
                                      icon: 'pi pi-times-circle',
                                      iconPos: 'right',
                                  }
                                : {})}
                            onClickIcon={() => {
                                onRemoveWord(index);
                            }}
                        />
                    </div>
                    {index !== values.length - 1 && <span className="he-paragraph--small text-gray-700">OU</span>}
                </>
            ))}
            <Button className="he-button--primary-nf--md" onClick={() => props.onChange(props.value + '$')}>
                Option
                <i className="pi pi-plus-circle" />
            </Button>
        </div>
    );
};
