import React from "react";
import styled from "styled-components";
import {useFormik} from "formik";
import {User} from "@Types/User";
import {createUserSchema} from "@Schemas/user.schema";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";
import {Button} from "primereact/button";
import {CustomInput} from "@Components/CustomInput";
import {userRoleMap} from "@Utils/users.utils";
import {CustomFormDropdown} from "@Components/CustomFormDropdown";
import {useUser} from "@Hooks/firebase";
import {useOnUnmount} from "@Hooks/lifecycle";


const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, minmax(0, auto));
  grid-gap: 14px;
`;

const HalfItem = styled.div`
  grid-column: span 1;
`
const FullItem = styled.div`
  grid-column: span 2;
`

const initialUser: User = {
	user_id: "",
	verified: false,
	RPPS: "",
	lastname: "",
	email: "",
	firstname: "",
	role: "user",
	phone: "",
	address: "",
	accept_mailings: 3,
	exercise_mode: "",
	job: ""
}

export type AddUserProps = {
	onQuit: () => void
}
export const AddUser: React.FC<AddUserProps> = props => {

	const api = useApi();

	const user = useUser(true);

	const [createUser, loading] = usePromise(async (user: User) => {
		const res = await api.user_call_create({
			user
		});
		if (res.result !== "ok") throw new Error(res.result);
		props.onQuit();
	}, {
		pending: "Création de l'utilisateur en cours",
		success: "Utilisateur créé !",
	})

	const userFormik = useFormik({
		initialValues: initialUser,
		validationSchema: createUserSchema,
		onSubmit: createUser
	});

	useOnUnmount(userFormik.resetForm);

	return (
		<div className="w-full h-full">
			<div className="he-header--h3 inter mb-4">
				Ajouter un utilisateur
			</div>
			<FormContainer className="w-full">
				{/* LINE */}
				<HalfItem>
					<CustomFormDropdown
						disabled={user.meta.role !== "admin"}
						field="role"
						label="Rôle"
						formik={userFormik}
						options={Object.entries(userRoleMap).map(([role, translation]) => ({
							label: translation,
							value: role
						}))}
					/>
				</HalfItem>
				<HalfItem/>
				{/* LINE */}
				<HalfItem>
					<CustomInput placeholder={"ex. Dubois"} field={"lastname"} label={"Nom"} formik={userFormik}/>
				</HalfItem>
				<HalfItem>
					<CustomInput placeholder={"ex. Camille"} field={"firstname"} label={"Prénom"} formik={userFormik}/>
				</HalfItem>
				{/* LINE */}
				<HalfItem>
					<CustomInput placeholder={"ex. camille.dubois@mail.com"} field="email" formik={userFormik}
					             label="Adresse e-mail"/>
				</HalfItem>
				<HalfItem>
					<CustomInput placeholder={"ex. 06 06 20 14 00"} field="phone" formik={userFormik}
					             label="Numéro de téléphone"/>
				</HalfItem>
				{/* LINE */}
				<FullItem>
					<CustomInput placeholder={"ex. ABCDE000000"} field="RPPS" formik={userFormik}
					             label="Numéro RPPS / ADELI"/>
				</FullItem>
				{/* LINE */}
				<HalfItem>
					<CustomInput placeholder={"ex. Médecin"} field={"job"} label={"Profession"}
					             formik={userFormik}/>
				</HalfItem>
				<HalfItem>
					<CustomInput placeholder={"ex. libéral"} field={"exercise_mode"} label={"Mode d'exercice"}
					             formik={userFormik}/>
				</HalfItem>
				{/* LINE */}
				<FullItem>
					<CustomInput placeholder={"ex. 523, Grand Rue - 35000 Rennes"} field="address" formik={userFormik}
					             label="Adresse postale"/>
				</FullItem>
			</FormContainer>
			<div className="flex justify-content-end mt-4">
				<Button className="he-button--secondary-nfb--md mr-2" label="Annuler" loading={loading}
				        onClick={props.onQuit}/>
				<Button loading={loading} className="he-button--primary--md" label={"Créer l'utilisateur"}
				        onClick={userFormik.submitForm}/>
			</div>
		</div>
	)
}
