import React from "react";
import {Module} from "@Types/Module";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";
import {useNavigate} from "react-router-dom";

export type DeleteModuleProps = {
	module: Module;
	onQuit: () => void;
}
export const DeleteModule: React.FC<DeleteModuleProps> = props => {

	const api = useApi();
	const navigate = useNavigate();

	const [deleteModule, loading] = usePromise(async () => {

		await api.module_call_delete({
			module_id: props.module.module_id
		});
		navigate('/modules');
		props.onQuit();
	})

	return (
		<div className="DeleteModule">
			<div className="he-header--h3 inter gray-900 mb-4">
				Supprimer le module
			</div>
			<div className="he-paragraph--regular gray-900">
				Êtes vous sûr de vouloir supprimer le module ?
			</div>
			<Line height={1} className="my-4 bg-gray-200"/>
			<div className="flex justify-content-end align-items-center">
				<Button
					label="Annuler"
					className="he-button--secondary-variant-nfb--md mr-2"
					onClick={props.onQuit}
				/>
				<Button
					label="Supprimer"
					className="he-button--primary--md p-button-danger"
					icon="pi pi-trash"
					onClick={deleteModule}
					loading={loading}
				/>
			</div>
		</div>
	)
}
