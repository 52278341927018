import React from "react";
import styled from "styled-components";
import logo from "@Assets/images/logo-blue.svg";
import {Button} from "primereact/button";
import {Module} from "@Types/Module";
import {classNames} from "primereact/utils";
import {iconForActivity} from "@Utils/activity.utils";
import {useNavigate, useParams} from "react-router-dom";

const LeftMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 250px;
  border-right: 1px solid #EAECF0;
`

const ActivityItem = styled.div<{selected?: boolean}>`
  ${props => props.selected ? `
  background: #EBF4FC;
  border: 1px solid #9DC7F1;
  border-radius: 21px;
  ` : ""}
  padding: 4px 12px;
  cursor: ${props => props.selected ? "default" : "pointer"};
`

export type LeftMenuModuleProps = {
	module: Module;
	onAddActivity: () => void;
}
export const LeftMenuModule: React.FC<LeftMenuModuleProps> = props => {

	const navigate = useNavigate();
	const params = useParams<{activity_id: string}>();

	return (
		<LeftMenuContainer className="px-3 pb-3 h-full bg-white flex flex-column align-items-start overflow-auto">
			<div className="mt-5 mb-7">
				<img src={logo} style={{width: 185}}/>
			</div>
			<Button
				label="Informations du module"
				className={classNames("w-full", params.activity_id ? "he-button--secondary-nf--md" : "he-button--secondary--md")}
				icon="pi pi-cog"
				onClick={() => params.activity_id && navigate(`/modules/${props.module.module_id}`)}
			/>
			<div className="he-paragraph--medium primary-900 mt-4">
				Contenu du module
			</div>
			<div className="flex flex-column w-full">
				{props.module.activities.map((a,i) => {
					return <ActivityItem
						className="he-paragraph--regular gray-600 mt-3 w-full"
						key={a.activity_id}
						selected={a.activity_id === params.activity_id}
						onClick={() => a.activity_id !== params.activity_id && navigate(`/modules/${props.module.module_id}/${a.activity_id}`)}>
						<i className={classNames(iconForActivity(a.type), "mr-2")}/>
						{i+1} - {a.title}
					</ActivityItem>
				})}
			</div>
			<Button className="he-button--primary--xs w-full mt-4" label="Ajouter une activité" icon="pi pi-plus" onClick={() => {
				props.onAddActivity()
			}}/>
		</LeftMenuContainer>
	)
}
