import * as React from 'react';
import { useErrorBoundary } from "use-error-boundary"
import {Button} from "primereact/button";

interface Props {
    children: React.ReactNode;
}


const doOnError = (error: any, errorInfo: any) => {
    console.error(error)
}


export const Error: React.FC<Props> = (props) => {

    const {
        ErrorBoundary,
        didCatch,
    } = useErrorBoundary({onDidCatch: doOnError})

    return (
        <>
            {didCatch ? (
                <div className="h-full flex flex-column justify-content-between error-page">
                    <div className="flex mt-5 justify-content-start content-header">
                    </div>
                    <div className='flex flex-column align-items-center content-footer'>
                        <span className="mb-3 text-error">Erreur 500</span>
                        <span className="mb-5 text-description">Une erreur est survenue !</span>
                        <Button label="Revenir sur la page d'accueil" onClick={()=>{ window.location.href = '/' }} className={"btn-redirect"} />
                    </div>
                </div>
            ) : (
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            )}
        </>
    )
}
