import Fuse, {type IFuseOptions} from "fuse.js";
import _ from "lodash";

export const fuzzSearch = <T extends undefined | Record<string, any>>(list: T[], term?: string, fields?: ((keyof T extends string ? keyof T : never )| string)[], showOnUndefined: boolean = false) => {

	if (!term) return showOnUndefined ? list : [];

	const options: IFuseOptions<T> = {
		isCaseSensitive: false,
		threshold: 0.0,
		findAllMatches: true,
		ignoreLocation: true,
		getFn: (obj, path) => {
			const value = _.get(obj, path);
			return value ? value.normalize() : undefined
		},
		keys: fields
	}

	const fuse = new Fuse(list, options);

	const results = fuse.search(term.normalize());

	return results.length > 0 ? results.map(i => i.item) : [];

}

type Filter<T> = (item: T) => boolean
export const applyFilters = <T>(items: T[], filters: Array<Filter<T> | undefined>) => {
	return filters.reduce((acc, fn) => {
		return fn ? acc.filter(fn) : acc
	}, items);
}
