import {getStorage, deleteObject, ref, uploadBytes, getDownloadURL, UploadResult, connectStorageEmulator} from "firebase/storage";
import {FirebaseApp} from "firebase/app";
import {FirebaseStorage as TypeFirebaseStorage} from "firebase/storage";

export class FirebaseStorage {
    public storage: TypeFirebaseStorage

    constructor(app: FirebaseApp, emulator?: {port: number, host: string}) {
        this.storage = getStorage(app);
		if (emulator) {
			connectStorageEmulator(this.storage, emulator.host, emulator.port)
		}

    }

    /**
     *
     * @param pathName : name or path of file
     */

    child(pathName: string) {
        return {
            put: (file: File, fileName?: string) => this._put(file, pathName, fileName),
            getDownloadUrl: () => this.getDownloadUrl(pathName),
            delete: () => this.delete(pathName)
        }
    }

    /**
     *
     * @param file - file
     * @param fileName - optional
     */
    public put(file: File, fileName?: string): Promise<UploadResult> {
        const _fileName = fileName ? fileName : file.name
        const storageRef = ref(this.storage, _fileName);
        return uploadBytes(storageRef, file)
    }

    private _put(file: File, pathName: string, fileName?: string): Promise<UploadResult> {
        const _fileName = fileName ? fileName : file.name
        const storageRef = ref(this.storage, `${pathName}${_fileName}`);
        return uploadBytes(storageRef, file)
    }

    private getDownloadUrl(pathName: string): Promise<string> {
        return getDownloadURL(ref(this.storage, pathName))
    }

    private delete(pathName: string): Promise<void> {
        const desertRef = ref(this.storage, pathName);
        return deleteObject(desertRef)
    }
}
