import React, {useCallback, useContext, useMemo, useState} from "react";
import {Session, SessionParticipant} from "@Types/Session";
import {SelectButton} from "primereact/selectbutton";
import {Line} from "@Components/UI/Line";
import {usePromise} from "@Hooks/promise";
import {Button} from "primereact/button";
import _ from "lodash";
import {Calendar} from "primereact/calendar";
import {v4} from "uuid";
import {doc, setDoc} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {DateTime} from "luxon";
import {ScheduledEvent} from "@Types/ScheduledEvent";
import { UsersContext } from "@Context/UsersContext";
import { useApi } from "@Hooks/api";
import {Nullable} from "primereact/ts-helpers";

export type SendScheduledAttestationProps = {
    onQuit: () => void;
    participants: SessionParticipant[];
    session: Session;
}
export const SendScheduledAttestation: React.FC<SendScheduledAttestationProps> = props => {

    const [selectedUnit, setSelectedUnit] = useState<string | null>(props.session?.unitsConfig[0].unit_id ?? null);
    const [date, setDate] = useState<Nullable<Date>>();
    const {users} = useContext(UsersContext);

    const itemTemplate = (option: any) => {
        return <div className="he-paragraph--regular">{option.label}</div>
    }

    const api = useApi();


    const belongToUnit = useCallback((participant: SessionParticipant) => {

        const unitIndex = props.session.unitsConfig.findIndex(u => u.unit_id === selectedUnit);

        if (unitIndex === -1) return false;
        return props.session.unitsConfig.every((uc, i) => {
            if (i < unitIndex) {
                const participantMeta = participant.unitsMeta?.find(um => um.unit_id === uc.unit_id);
                return (uc.required_signature ? participantMeta?.signature_id : true) && participantMeta?.access && !participant.blocked;
            } else if (i > unitIndex) {
                return !participant.blocked;
            } else {
                const participantUnitMeta = participant.unitsMeta?.find(um => um.unit_id === uc.unit_id);
                const startDate = uc.start_date;
                let isBeforeStartDate = true;
                if (startDate) {
                    const diff = DateTime.fromISO(startDate).diffNow('days').days;
                    isBeforeStartDate = diff > 0;
                }
                return participantUnitMeta === undefined ? false :
                    (participantUnitMeta?.access && !participant.blocked && (uc.required_signature ? !participantUnitMeta.signature_id : false) && !isBeforeStartDate);
            }
        })

    }, [props.session, selectedUnit]);

    const participants = useMemo(() => {
        return props.participants.map(p => ({
            ...p,
            valid: belongToUnit(p),
			accept: users.find(u => u.user_id === p.participant_id)?.accept_mailings
        }));
    }, [props.participants, belongToUnit])

    const [onCreateEvent, loading] = usePromise(async (participants: (SessionParticipant & {
        valid: boolean
    })[], unit_id: string, date: Nullable<Date>) => {

        console.log(props);

        const groupedBySession = _.groupBy(participants, "session_id");

        for (const [session_id, participants] of Object.entries(groupedBySession)) {
            const id = v4();
            if (date instanceof Date) {

                const formattedDate = DateTime.fromJSDate(date).setZone("Europe/Paris").toISO();

                if (formattedDate) {
                await setDoc(doc(firestore.db, `events/${id}`), {
                    event_id: id,
                    session_id,
                    type: "single",
                    mail_type: 99,
                    date: formattedDate,
                    content: "",
                    unit_id,
                    delay: null,
                    user_ids: participants.filter(p => p.valid).map(p => p.participant_id)
                } satisfies ScheduledEvent)
                }

            }
        }

        props.onQuit();
    }, {
        pending: "Programmation des mails en cours...",
        success: "Les relances ont bien été programmées."
    });
    const [onSend, loadingSend] = usePromise(async (participants: (SessionParticipant & {
		valid: boolean
	})[], unit_id: string) => {

		const groupedBySession = _.groupBy(participants, "session_id");

		for (const [session_id, participants] of Object.entries(groupedBySession)) {
			await api.participant_call_sendRelanceAttestation({
				session_id: session_id,
				participant_ids: participants.filter(p => p.valid).map(p => p.participant_id),
				unit_id,
			});
		}

		props.onQuit();
	}, {
		pending: "Envoi des mails en cours...",
		success: "Les mails ont correctement étés envoyés"
	});
    return (
        <div>
            <div className="he-header--h3">
                Relancer les apprenants inscrits sélectionnés
            </div>
            <div className="he-paragraph--regular gray-900 mt-4">
                <div>Souhaitez-vous relancer les apprenants inscrits sélectionnés par mail ?</div>
                <div className="mt-3">
                    {
                        participants.map(
                            p => <li key={p.participant_id}
							         className={(p.accept === 0 || p.accept === 1) ? "color-red" : !p.valid ? "gray-300 cursor-default" : ""}>{p.firstname} {p.lastname}</li>
                        )
                    }
                </div>
            </div>
            <div className="he-paragraph--regular mt-5 gray-900">
                Sélectionnez l'unité :
            </div>
            <SelectButton className="mt-3" value={selectedUnit} onChange={e => setSelectedUnit(e.value)}
                          options={props.session?.unitsConfig.map((e, i) => ({
                              label: `Unité ${i + 1}`,
                              value: e.unit_id
                          }))}
                          itemTemplate={itemTemplate}/>
            <Line height={1} className={"bg-gray-200 my-4"}/>
            <div className="he-paragraph--regular mt-4">
                Date d'envoi
            </div>
            <Calendar value={date} onChange={e => setDate(e.value)} showTime placeholder={"ex. 01/01/2023"}
                      dateFormat={"dd/mm/yy"} className={"mt-2"} minDate={new Date()}/>
            <Line height={1} className={"bg-gray-200 my-4"}/>
            <div className="flex justify-content-end mt-4">
                <Button className="he-button--secondary-variant-nfb--md mr-3" label="Annuler" loading={loading}
                        onClick={props.onQuit}/>
                <Button disabled={date == null || selectedUnit == undefined} loading={loading || loadingSend}
                        className="he-button--primary--md mr-3"
                        icon="pi pi-clock" label={"Programmer"}
                        onClick={() => selectedUnit && onCreateEvent(participants, selectedUnit, date)}/>
                <Button disabled={selectedUnit == undefined} loading={loadingSend} className="he-button--primary--md"
				        icon="pi pi-send" label={"Envoyer"}
				        onClick={() => selectedUnit && onSend(participants, selectedUnit)}/>

            </div>
        </div>
    )
}
