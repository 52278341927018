import {
	collection,
	collectionGroup,
	connectFirestoreEmulator,
	deleteDoc,
	doc,
	DocumentData,
	DocumentSnapshot,
	Firestore,
	getDoc,
	getDocs,
	initializeFirestore,
	onSnapshot,
	persistentLocalCache,
	persistentMultipleTabManager,
	Query,
	query,
	QueryConstraint,
	QuerySnapshot,
	setDoc,
	Unsubscribe,
	updateDoc,
	where,
	WhereFilterOp,
	writeBatch,
} from "firebase/firestore";
import {FirebaseApp} from "firebase/app";


type Where<T> = {
	get(): Promise<T[]>;
	where: (field: string, condition: WhereFilterOp, match: any) => Where<T>;
	onSnapshot: (onNext: (snapshot: QuerySnapshot<T>) => void) => Unsubscribe
}


export class FirebaseFirestore {

	public db: Firestore

	constructor(app: FirebaseApp, emulator?: {
		host: string,
		port: number
	}) {
		this.db = initializeFirestore(app, {
			localCache: persistentLocalCache({
				cacheSizeBytes: 100 * 1024 * 1024,
				tabManager: persistentMultipleTabManager()
			})
		});
		if (emulator) {
			connectFirestoreEmulator(this.db, emulator.host, emulator.port);
		}
	}


	createBatch() {
		return writeBatch(this.db);
	}

	collection<T extends Record<string, any>>(ref: string) {
		return new FirebaseCollection<T>(this.db, ref);
	}

	collectionGroup<T extends Record<string, any>>(ref: string, contraints: {
		where: [keyof T extends string ? keyof T : never, WhereFilterOp, string]
	} | null, next: (snapshot: QuerySnapshot<T>) => void) {

		let q: Query;

		if (contraints)
			q = query(collectionGroup(this.db, ref), where(...contraints.where))
		else
			q = collectionGroup(this.db, ref)
		return onSnapshot(q as Query<T>, next);
	}

	collectionGroupRef<T>(ref: string) {

		return {
			getAll: () => getDocs(query(collectionGroup(this.db, ref)) as Query<T>),
		}
	}

}

export class FirebaseCollection<T extends DocumentData = DocumentData> {

	private readonly internalRef: string;

	constructor(private db: Firestore, ref: string) {
		this.internalRef = ref
	}

	/***
	 * @param docUid - document id.
	 * @param data - object<T> set.
	 ***/
	public set(docUid: string, data: T) {
		return setDoc(doc(this.db, this.internalRef, docUid), data)
	}

	public add(data: (id: string) => T) {
		const docRef = doc(collection(this.db, this.ref));
		return setDoc(docRef, data(docRef.id));
	}

	get ref() {
		return this.internalRef;
	}

	public doc(docUid: string) {
		return doc(this.db, this.internalRef, docUid);
	}

	public collection<T extends DocumentData>(...paths: string[]) {
		const ref = collection(this.db, this.internalRef, ...paths);

		return {
			get: () => getDocs<T, T>(query(ref) as Query<T ,T>),
			ref
		}
	}

	/***
	 * @param field - The path to compare.
	 * @param condition - The operation string (e.g “", "=", "==", ">“, “>=“).
	 * @param match - The value for comparison.
	 * @Returns The created Query.
	 ***/
	where(field: string, condition: WhereFilterOp, match: string | number, old: QueryConstraint[] = []): Where<T> {
		const constraints: QueryConstraint[] = [...old, where(field, condition, match)]
		return {
			get: () => this._get(constraints),
			where: (field: string, condition: WhereFilterOp, match: string) => this.where(field, condition, match, constraints),
			onSnapshot: (onNext: (snapshot: QuerySnapshot<T>) => void) => this._onSnapshot(constraints, onNext),
		}
	}

	private _get(customWhere: QueryConstraint[]): Promise<T[]> {
		const q = query(collection(this.db, this.internalRef), ...customWhere)
		return getDocs(q).then(querySnapshot => {
			return querySnapshot.docs.map((doc) => {
				return doc.data() as T
			})
		})
	}

	/***
	 * Return Promise with list of document<T>
	 ***/
	public getAll(): Promise<T[]> {
		return getDocs(collection(this.db, this.internalRef)).then(snap => {
				return snap.docs.map((d) => {
					return d.data() as T
				})
			}
		)
	}

	/***
     Params: Document ID of collection.
     Returns: A Promise with document data - type <T>.
	 ***/
	public get(docUid: string): Promise<T> {
		return getDoc(doc(this.db, this.internalRef, docUid)).then(res => res.data() as T)
	}

	/***
	 * @returns Unsubscribe callback.
	 ***/
	private _onSnapshot(customWhere: QueryConstraint[], onNext: (snapshot: QuerySnapshot<T>) => void): Unsubscribe {
		const q = query<T, T>(collection(this.db, this.internalRef) as unknown as Query<T, T>, ...customWhere)
		const unsub = onSnapshot<T, T>(q, onNext)
		return unsub
	}

	/***
	 * @param docUid - document id listen.
	 * Return Unsubscribe
	 ***/
	public onSnapshot(docUid: string, onNext: (snapshot: DocumentSnapshot<T>) => void): Unsubscribe {
		const unsub = onSnapshot<T, T>(doc(collection(this.db, this.internalRef), docUid) as any, onNext as any)
		return unsub
	}

	public onSnapshotAll<T extends DocumentData>(path: string, onNext: (snapshot: QuerySnapshot<T>) => void): Unsubscribe {
		return onSnapshot<T, T>(collection(this.db, path) as Query<T, T>, onNext)
	}


	/***
	 * @param docUid - document id.
	 ***/
	public delete(docUid: string) {
		return deleteDoc(doc(this.db, this.internalRef, docUid))
	}

	/***
	 * @param docUid - document id.
	 * @param data - object<T> update.
	 ***/
	public update(docUid: string, data: T) {
		return updateDoc(doc(this.db, this.internalRef, docUid), data)
	}

}
