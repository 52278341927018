import { Roles } from "@Types/User";
import React from "react";
import styled from "styled-components";
import {userRoleMap} from "@Utils/users.utils";

const colorMap: {
	[k in Roles]: [bg: string, fg: string]
} = {
	user: ["#EEE2F3", "#8E44AD"],
	admin: ["#F8E0DE", "#C0392B"],
	animator: ["#DBFAF4", "#16A085"],
	author: ["#DEEDF8", "#2980B9"]
}

const Badge = styled.div<{bg: string; fg: string}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: ${props => props.bg};
  color: ${props => props.fg};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  width: max-content;
  text-transform: uppercase;
  cursor: default;
`

export type UserRoleBadgeProps = {
	role: Roles
}
export const UserRoleBadge: React.FC<UserRoleBadgeProps> = ({role}) => {

    return (
        <Badge fg={colorMap[role][1]} bg={colorMap[role][0]} className="inter">
	        {userRoleMap[role]}
        </Badge>
    )
}
