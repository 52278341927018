import React from "react";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {CustomInput} from "@Components/CustomInput";
import {loginSchema} from "@Schemas/auth.schema";
import {auth} from "@Utils/config/firebase";
import { useToast } from "@Hooks/toast";

interface InitialValues {
	email: string;
	password: string;
}

const initialValues: InitialValues = {
	email: "",
	password: "",
};

export type LoginFormProps = Record<string, never>
export const LoginForm: React.FC<LoginFormProps> = () => {

	const navigate = useNavigate();
	const toast = useToast();

	const handleSubmit = async (values: InitialValues) => {
		toast.promise(auth.signInWithEmailAndPassword(values.email, values.password).then(res => {
			navigate('/')
			return res;
		}), {
			pending: "Connexion en cours...",
			error: "Une erreur est survenue lors de votre connexion",
			success: "Connexion réussie !"
		}).catch(err => {
			console.error(err);
		});
	}

	const formik = useFormik<InitialValues>({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: handleSubmit
	});

	const onForgotten = () => navigate('/forgotten-password')


	return (
		<div className="Login flex h-full w-full flex-column justify-content-center align-items-center">
			<LoginFormWrapper className="flex flex-column justify-content-center px-4 bg-white">
				<div className="he-header--h1 text-center mb-6 primary-900">
					Connexion
				</div>
				<CustomInput
					formik={formik}
					field="email"
					label="Adresse e-mail"
					placeholder="Adresse e-mail"
					icon="pi pi-at"
					iconPos="right"
					className="mb-3"
				/>
				<CustomInput
					formik={formik}
					field="password"
					label="Mot de passe"
					placeholder="Mot de passe"
					type="password"
					className="mb-3"
				/>
				<div className="mb-5 text-right">
					<span className="he-paragraph--regular--underlined primary-100 cursor-pointer" onClick={onForgotten}>Mot de passe oublié ?</span>
				</div>
				<Button className="he-button--primary--lg" onClick={() => {
					console.log(formik.errors);
					formik.submitForm()
				}}>
					<div className="flex align-items-center">
						<i className="pi pi-sign-in mr-2"/> <div> Connexion </div>
					</div>
				</Button>
			</LoginFormWrapper>
		</div>
	)
}


const LoginFormWrapper = styled.div`
  width: 450px;
  height: auto;
  padding: 28px;
`;
