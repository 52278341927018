import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomTable } from '@Components/CustomTable';
import { BackofficeContext } from '@Context/BackofficeContext';
import { useUser } from '@Hooks/firebase';
import { ArchiveSession } from '@Pages/pages/components/ArchiveSession';
import { CreateSession } from '@Pages/pages/components/CreateSession';
import { SendScheduledAttestation } from '@Pages/pages/components/SendScheduledAttestation';
import { SendScheduledMessage } from '@Pages/pages/components/SendScheduledMessage';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { Formation } from '@Types/Formation';
import { Session, SessionParticipant } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { formatDate } from '@Utils/date.utils';
import { applyFilters, fuzzSearch } from '@Utils/search.utils';
import { collection, getDocs } from 'firebase/firestore';
import { DateTime, Interval } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ColumnSortEvent } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type SessionCustom = Session & {
    participants: number;
    start_date_custom: string;
    formation?: Formation;
};

export type SessionsProps = Record<string, never>;
export const Sessions: React.FC<SessionsProps> = () => {
    const { sessions, formations, participantsCount, loading } = useContext(BackofficeContext);
    const [selection, setSelection] = useState<Session>();
    const [archiveVisible, setArchiveVisible] = useState<boolean>(false);
    const [createVisible, setCreateVisible] = useState<boolean>(false);

    const navigate = useNavigate();
    const ref = useRef<TieredMenu>(null);
    const { meta } = useUser(true);

    const [sendScheduledMessageVisible, setSendScheduledMessageVisible] = useState<boolean>(false);
    const [sendScheduledAttestationVisible, setSendScheduledAttestationVisible] = useState<boolean>(false);

    const [participantsOfSession, setParticipantOfSession] = useState<SessionParticipant[]>([]);
    const [loadingParticipants, setLoadingParticipants] = useState<boolean>(false);

    const sessionActions = useMemo<DropdownOption[]>(
        () => [
            {
                label: 'Voir le détail',
                icon: 'pi pi-eye',
                action: () => {
                    if (selection) navigate(`/sessions/${selection.session_id}/participants`);
                },
            },
            meta.role === 'author'
                ? {
                      label: 'Modifier la session',
                      action: () => {
                          if (selection) navigate(`/sessions/${selection.session_id}/settings`);
                      },
                      icon: 'pi pi-pencil',
                  }
                : undefined,
            ...(['admin', 'author'].includes(meta.role)
                ? [
                      {
                          label: 'Archiver la session',
                          action: () => {
                              setArchiveVisible(true);
                          },
                          icon: 'pi pi-inbox',
                      },
                      {
                          label: 'Relancer les apprenants',
                          icon: 'pi pi-refresh',
                          loading: loadingParticipants,
                          action: () => setSendScheduledMessageVisible(true),
                      },
                      {
                          label: "Relancer les attestations sur l'honneur",
                          icon: 'pi pi-refresh',
                          loading: loadingParticipants,
                          action: () => setSendScheduledAttestationVisible(true),
                      },
                  ]
                : []),
        ],
        [selection, meta.role, loadingParticipants]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, formation: Session) => {
        setSelection(formation);
        ref.current?.toggle(e);
    };
    const SessionDropdown = (session: Session) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onActionClick(e, session);
                    }}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const filterByInterval = (inf: Date | null, sup: Date | null, field: 'start_date' | 'end_date') => {
        return (item: Session) => {
            const startD = DateTime.fromISO(item[field]).set({ hour: 10 });
            if (!!inf && !!sup) {
                const infD = DateTime.fromJSDate(inf).startOf('day');
                const supD = DateTime.fromJSDate(sup).endOf('day');
                const interval = Interval.fromDateTimes(infD, supD);
                return interval.contains(startD);
            }
            if (!inf && !!sup) {
                const supD = DateTime.fromJSDate(sup);
                return startD.diff(supD, 'second').get('second') <= 0;
            }
            if (!!inf && !sup) {
                const infD = DateTime.fromJSDate(inf);
                return startD.diff(infD, 'second').get('second') >= 0;
            }
            return true;
        };
    };

    /* FILTERING */

    const [params] = useSearchParams();

    const [filter, setFilter] = useState<string>('');
    const [startInf, setStartInf] = useState<Date | null>(null);
    const [startSup, setStartSup] = useState<Date | null>(null);
    const [endInf, setEndInf] = useState<Date | null>(null);
    const [endSup, setEndSup] = useState<Date | null>(null);

    const filtered = useMemo<SessionCustom[]>(() => {
        const items = fuzzSearch(
            sessions
                .filter((s) => !s?.archived)
                .map((s) => ({
                    ...s,
                    start_date_custom: formatDate(s.start_date),
                    formation: formations.find((f) => f.formation_id === s.formation_id),
                    participants: participantsCount[s.session_id] || 0,
                }))
                .filter((s) => !!s.formation) as SessionCustom[],
            filter,
            ['session_custom_id', 'formation_id', 'formation.title', 'formation.andpc', 'start_date_custom'],
            true
        );

        const formationFilter = params.get('formation');

        return applyFilters(items, [
            formationFilter ? (item: Session) => item.formation_id === formationFilter : undefined,
            filterByInterval(startInf, startSup, 'start_date'),
            filterByInterval(endInf, endSup, 'end_date'),
        ]).sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    }, [params, filter, sessions, startInf, startSup, endInf, endSup, participantsCount]);

    useEffect(() => {
        if (selection) {
            setLoadingParticipants(true);
            getDocs(
                collection(
                    firestore.db,
                    'formations',
                    selection.formation_id,
                    'sessions',
                    selection.session_id,
                    'participants'
                )
            )
                .then((snap) => setParticipantOfSession(snap.docs.map((d) => d.data() as SessionParticipant)))
                .finally(() => setLoadingParticipants(false));
        }
    }, [selection]);

    return (
        <ContentLayout title={'Sessions de formation'} subtitle={'Vous pouvez gérer vos sessions de formation'}>
            {selection && archiveVisible && (
                <CustomDialog onHide={() => setArchiveVisible(false)}>
                    <ArchiveSession onQuit={() => setArchiveVisible(false)} session={selection} />
                </CustomDialog>
            )}
            {selection && participantsOfSession.length > 0 && sendScheduledMessageVisible && (
                <CustomDialog onHide={() => setSendScheduledMessageVisible(false)}>
                    <SendScheduledMessage
                        onQuit={() => setSendScheduledMessageVisible(false)}
                        isFromSessionList={true}
                        session={selection}
                        participants={
                            participantsOfSession.map((p) => ({ ...p, session_id: selection.session_id })) || []
                        }
                    />
                </CustomDialog>
            )}
            {selection && participantsOfSession.length > 0 && sendScheduledAttestationVisible && (
                <CustomDialog onHide={() => setSendScheduledAttestationVisible(false)}>
                    <SendScheduledAttestation
                        onQuit={() => setSendScheduledAttestationVisible(false)}
                        session={selection}
                        participants={
                            participantsOfSession.map((p) => ({ ...p, session_id: selection.session_id })) || []
                        }
                    />
                </CustomDialog>
            )}
            {createVisible && (
                <CustomDialog onHide={() => setCreateVisible(false)}>
                    <CreateSession onQuit={() => setCreateVisible(false)} />
                </CustomDialog>
            )}
            <CustomActionDropdown options={sessionActions} ref={ref} />
            <CustomTable
                dataKey={'session_id'}
                loading={loading}
                onRowClick={{
                    click: (item) => navigate(`/sessions/${item.session_id}`),
                    ctrlClick: (item) => window.open(`/sessions/${item.session_id}`, '_blank'),
                }}
                rowHover
                rowClassName="cursor-pointer"
                columns={[
                    {
                        header: 'N°',
                        size: 90,
                        filter: {
                            type: 'sort',
                            field: 'session_custom_id',
                        },
                        body: (session) => <div>{session.session_custom_id}</div>,
                    },
                    {
                        header: 'Nom de la formation',
                        size: 550,
                        filter: {
                            type: 'sort',
                            field: 'formation.title' as any,
                        },
                        body: (session) => (
                            <div>
                                {formations.find((f) => f.formation_id === session.formation_id)?.title || (
                                    <i className="pi pi-spin pi-spinner" />
                                )}
                            </div>
                        ),
                    },
                    {
                        header: 'Date de début',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'start_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.start_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Date de fin',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        body: (session) => <div>{DateTime.fromISO(session.end_date).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: "Nombre d'inscrits",
                        size: 100,
                        filter: {
                            type: 'sort',
                            field: 'end_date',
                        },
                        sortFunction(e: ColumnSortEvent) {
                            return (e.data as Array<SessionCustom>).sort(
                                (a, b) => (e.order || 0) * (a.participants - b.participants)
                            );
                        },
                        align: 'center',
                        body: (session: SessionCustom) => <div>{session.participants}</div>,
                    },
                    {
                        frozen: true,
                        alignFrozen: 'right',
                        size: 100,
                        body: SessionDropdown,
                    },
                ]}
                values={filtered}
                header={{
                    title: 'Liste des sessions',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher une session"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                        ['author', 'admin'].includes(meta.role) ? (
                            <Button
                                label="Créer une session"
                                icon="pi pi-plus"
                                className={'he-button--primary--md'}
                                onClick={(_) => setCreateVisible(true)}
                            />
                        ) : (
                            <></>
                        ),
                    ],
                    filters: [
                        <div className="flex align-items-center he-paragraph--regular inter gray-600 mr-3">
                            Début entre le
                            <Calendar
                                className="w-6rem mx-2"
                                dateFormat="dd/mm/yy"
                                placeholder="jj/mm/aaaa"
                                showButtonBar
                                locale="fr"
                                clearButtonClassName="he-button--secondary-variant-nfb--md"
                                value={startInf}
                                onChange={(e) => setStartInf(e.value ?? null)}
                            />
                            et le
                            <Calendar
                                className="w-6rem mx-2"
                                dateFormat="dd/mm/yy"
                                placeholder="jj/mm/aaaa"
                                showButtonBar
                                locale={'fr'}
                                clearButtonClassName="he-button--secondary-variant-nfb--md"
                                value={startSup}
                                onChange={(e) => setStartSup(e.value ?? null)}
                            />
                        </div>,
                        <div className="flex align-items-center he-paragraph--regular inter gray-600">
                            Fin entre le
                            <Calendar
                                className="w-6rem mx-2"
                                dateFormat="dd/mm/yy"
                                placeholder="jj/mm/aaaa"
                                showButtonBar
                                locale={'fr'}
                                clearButtonClassName="he-button--secondary-variant-nfb--md"
                                value={endInf}
                                onChange={(e) => setEndInf(e.value ?? null)}
                            />
                            et le
                            <Calendar
                                className="w-6rem mx-2"
                                dateFormat="dd/mm/yy"
                                placeholder="jj/mm/aaaa"
                                showButtonBar
                                locale={'fr'}
                                clearButtonClassName="he-button--secondary-variant-nfb--md"
                                value={endSup}
                                onChange={(e) => setEndSup(e.value ?? null)}
                            />
                        </div>,
                        <div className="flex-1"></div>,
                    ],
                }}
            />
        </ContentLayout>
    );
};
