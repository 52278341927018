import { BackofficeContext } from '@Context/BackofficeContext';
import { Satisfaction } from '@Types/Satisfaction';
import { SessionParticipant } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { collection, collectionGroup, getDocs, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import AnswerDetails from './components/AnswerDetails';
import ScoreDetails from './components/ScoreDetails';
import { Button } from 'primereact/button';
import XLSX from 'sheetjs-style';
import { downloadUint8 } from '@Utils/download.utils';

const Container = styled.div`
    padding: 32px 24px;
    width: 100%;
    max-width: 1220px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background: #ffffff;
    font-family: 'roboto';
    border: 1px solid #eaecf0;
    border-radius: 10px;
`;
const SmallContainer = styled.div<{
    color: string;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 6px;
    width: 346px;
    height: 129px;
    font-family: 'roboto';
    background: ${(props) => props.color};
    border-radius: 12px;
`;

const BigNumber = styled.div`
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    leading-trim: both;
    text-edge: cap;
    color: #000000;
`;

const satisfactionLabels = {
    formation: [
        'Communication des objectifs et du programme avant la formation',
        'Organisation et déroulement de la formation',
        'Adéquation des moyens matériels mis à disposition',
        'Conformité de la formation dispensée au programme',
        'Clarté du contenu',
        'Qualité des supports pédagogiques',
        'Animation de la formation par le ou les intervenants',
        'Progression de la formation (durée, rythme, alternance théorie/pratique)',
    ],
    format: ['Le format e-learning est-il idéal pour vous ?', 'Que pensez-vous de la durée de la formation ?'],
    you: [
        'Participez-vous souvent à des formations DPC ?',
        'Comment connaissez-vous les formations auxquelles vous participez ?',
        'Quelle thématique souhaiteriez-vous pour une prochaine formation ?',
    ],
};

const satisfactionScale = {
    formation: ['1', '2', '3', '4'],
    format: [
        ['yes', 'no'],
        ['ok', 'long', 'short'],
    ],
    youAndFormation: [
        ['rarely', 'sometimes', 'often', 'regularly'],
        ['mail', 'magazine', 'colleague', 'other'],
    ]
};

const satisfactionAnswersLabels: {
    formation: Record<string, string>;
    format: Record<string, string>;
    youAndFormation: [
        Record<string, string>,
        Record<string, string>,
    ]
} = {
    formation: {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        "null": 'N/A',
    },
    format: {
            yes: 'Oui',
            no: 'Non',
            ok: 'Correcte',
            long: 'Trop longue',
            short: 'Trop courte',
            null: 'N/A',
    },
    youAndFormation: [
        {
            rarely: 'Peu',
            sometimes: 'Parfois',
            often: 'Souvent',
            regularly: 'Régulièrement',
            undefined: 'N/A'
        },
        {
            mail: 'E-mailing',
            magazine: 'Magazines spécialisés',
            colleague: 'Confrères',
            other: 'Autre',
            null: 'N/A',
        }
    ]
}

export type SatisfactionProps = Record<string, never>;
export const SatisfactionFormation: React.FC<SatisfactionProps> = () => {
    const { formations, loading } = useContext(BackofficeContext);

    const params = useParams();

    const formation = useMemo(
        () => formations.find((f) => f.formation_id === params.formation_id),
        [formations, params.formation_id]
    );

    const [participants, setParticipants] = useState<(SessionParticipant & {satisfaction: Satisfaction})[]>([]);
    const [loadingSatisfaction, setloadingSatisfaction] = useState<boolean>(false);

    const getMean = (satisfaction: Satisfaction) => {
        return satisfaction.formation.reduce((acc, curr) => acc + curr, 0) / satisfaction.formation.length;
    };

    useEffect(() => {
        if (formation) {
            setloadingSatisfaction(true);
            (async () => {
                const formationSessions = await getDocs(
                    query(
                        collectionGroup(firestore.db, 'sessions'),
                        where('formation_id', '==', formation.formation_id)
                    )
                );

                const allParticipants: (SessionParticipant & {satisfaction: Satisfaction})[] = [];
                for (const session of formationSessions.docs) {
                    const sessionParticipant = await getDocs(collection(session.ref, 'participants'));
                    allParticipants.push(
                        ...sessionParticipant.docs
                            .map((d) => d.data() as any)
                            .filter((s: SessionParticipant) => !!s.satisfaction)
                    );
                }
                setParticipants(allParticipants);
            })()
                .finally(() => setloadingSatisfaction(false));
        }
    }, [formation]);

    const exportXlsx = () => {
        if (!formation) return;
        if (!participants) return;
        const groupBy = (satisfaction: any[], scale: string[]) => {
            return satisfaction.reduce<{ [key: string]: number }>((acc, note) => {
                const key = note === null ? 'null' : note.toString();
                if (!acc[key]) acc[key] = 0;
                acc[key]++;
                return acc;
            }, scale.reduce<{ [key: string]: number }>((acc, note) => ({ ...acc, [note]: 0 }), {}));
        }

        const evaluation = satisfactionLabels.formation.map((label, i) => {
            const res: Record<string, any> = {
                "Question": label,
            };
            const satisfactionFormation = participants.map((p) => p.satisfaction.formation[i])
            const groupedByNote = groupBy(satisfactionFormation, satisfactionScale.formation);
            Object.entries(groupedByNote).forEach(([note, count]) => {
                const labelForNote = satisfactionAnswersLabels.formation[note.toString()];
                res[labelForNote] = count;
            })
            return res;
        })

        const format = satisfactionLabels.format.map((label, i) => {

            const res: Record<string, any> = {
                "Question": satisfactionLabels.format[i],
            };

            const satisfactionFormat = participants.map((p) => p.satisfaction.format[i])
            const groupedByNote = groupBy(satisfactionFormat, satisfactionScale.format[i]);
            Object.entries(groupedByNote).forEach(([note, count]) => {
                const labelForNote = satisfactionAnswersLabels.format[note.toString()];
                res[labelForNote] = count;
            })

            return res;
        })

        const youAndFormationFirst: Record<string, any> = {
            "Question": satisfactionLabels.you[0],
        };

        const youAndFormationSecond: Record<string, any> = {
            "Question": satisfactionLabels.you[1],
        };

        const satisfactionYouAndFormation = participants.map((p) => p.satisfaction.youAndFormation[0].option);
        const satisfactionYouAndFormationSecond = participants.map((p) => p.satisfaction.youAndFormation[1].option);

        const groupedByAnswersFirst = groupBy(satisfactionYouAndFormation, satisfactionScale.youAndFormation[0]);
        const groupedByAnswersSecond = groupBy(satisfactionYouAndFormationSecond, satisfactionScale.youAndFormation[1]);

        Object.entries(groupedByAnswersFirst).forEach(([note, count]) => {
            const labelForNote = satisfactionAnswersLabels.youAndFormation[0][note.toString()];
            youAndFormationFirst[labelForNote] = count;
        })

        Object.entries(groupedByAnswersSecond).forEach(([note, count]) => {
            const labelForNote = satisfactionAnswersLabels.youAndFormation[1][note.toString()];
            youAndFormationSecond[labelForNote] = count;
        })

        const formationSheet = XLSX.utils.json_to_sheet(evaluation, {
            header: ['Question', ...Object.values(satisfactionAnswersLabels.formation)],
        });

        const formatSheet = XLSX.utils.aoa_to_sheet([
            ['Question', 'Oui', 'Non', 'N/A'],
            [format[0]['Question'], format[0]['Oui'], format[0]['Non'], format[0]['N/A']],
            [],
            ['Question', 'Correcte', 'Trop longue', 'Trop courte', 'N/A'],
            [format[1]['Question'], format[1]['Correcte'], format[1]['Trop longue'], format[1]['Trop courte'], format[1]['N/A']],
        ]);

        const youAndFormationSheet = XLSX.utils.aoa_to_sheet([
            ['Question', 'Peu', 'Parfois', 'Souvent', 'Régulièrement'],
            [youAndFormationFirst['Question'], youAndFormationFirst['Peu'], youAndFormationFirst['Parfois'], youAndFormationFirst['Souvent'], youAndFormationFirst['Régulièrement']],
            [],
            ['Question', 'E-mailing', 'Magazines spécialisés', 'Confrères', 'Autre', 'N/A'],
            [youAndFormationSecond['Question'], youAndFormationSecond['E-mailing'], youAndFormationSecond['Magazines spécialisés'], youAndFormationSecond['Confrères'], youAndFormationSecond['Autre'], youAndFormationSecond['N/A']],
            [],
            ["Quelle thématique souhaiteriez-vous pour une prochaine formation ?"],
            ["Participant", "Réponse"],
            ...participants
                .filter((p) => Boolean(p.satisfaction.youAndFormation[2].content))
                .map(p => ({
                    answer: p.satisfaction.youAndFormation[2].content || "",
                    participant: p
                }))
                .map(p => [`${p.participant.firstname} ${p.participant.lastname}`, p.answer]),
        ]);

        const buff = XLSX.write({
            Sheets: {
                'Evaluation de la formation': formationSheet,
                'Format': formatSheet,
                'Vous et les formations': youAndFormationSheet,
            },
            SheetNames: ['Evaluation de la formation', 'Format', 'Vous et les formations'],
        }, {
            bookType: 'xlsx',
            type: 'array',
        });

        downloadUint8(new Uint8Array(buff), `Statistiques - ${formation.title}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');


    }

    return formation && !loadingSatisfaction ? (
        <Container className="w-full h-full overflow-auto">
            <ContentContainer>
                <SmallContainer color="#EBF4FC">
                    <BigNumber className="flex align-items-center">
                        {participants.length > 0
                            ? (
                                  participants.reduce((acc, curr) => acc + getMean(curr.satisfaction), 0) / participants.length
                              ).toFixed(1)
                            : ' - '}
                        <p className="he-header--h3 gray-400 ml-2">/ 4</p>
                    </BigNumber>
                    <div>Note générale</div>
                </SmallContainer>
                <SmallContainer color="#EEFBF4">
                    <BigNumber>{participants.length}</BigNumber>
                    <div>Réponses reçues</div>
                </SmallContainer>
            </ContentContainer>
            <ContentContainer className="mt-3 flex flex-column">
                <div className="he-header--h2 flex align-items-center gap-3">Evaluation de la formation <Button className={"he-button--primary-nf--xs"} icon={"pi pi-file-excel"} label={"Exporter les statistiques"} onClick={exportXlsx}/></div>
                {satisfactionLabels.formation.map((label, i) => {
                    const satisfactionFormation = participants.map((p) => p.satisfaction.formation[i]);
                    return (
                        <ScoreDetails
                            key={i}
                            label={label}
                            satisfaction={satisfactionFormation}
                            answersLabels={satisfactionAnswersLabels.formation}
                            scale={satisfactionScale.formation}
                        />
                    );
                })}
                <AnswerDetails
                    label="Vos commentaires"
                    answers={
                        participants
                            .filter((p) => Boolean(p.satisfaction.formationFree))
                            .map(p => ({
                                answer: p.satisfaction.formationFree || "",
                                participant: p
                            }))
                    }
                />
            </ContentContainer>
            <ContentContainer className="mt-3 flex flex-column">
                <div className="he-header--h2">Format</div>
                {satisfactionLabels.format.map((label, i) => {
                    const satisfactionFormation = participants.map((p) => p.satisfaction.format[i]);
                    return (
                        <ScoreDetails
                            key={i}
                            label={label}
                            satisfaction={satisfactionFormation}
                            answersLabels={satisfactionAnswersLabels.format}
                            scale={satisfactionScale.format[i]}
                        />
                    );
                })}
            </ContentContainer>
            <ContentContainer className="mt-3 flex flex-column">
                <div className="he-header--h2">Vous et les formations</div>
                <ScoreDetails
                    label={satisfactionLabels.you[0]}
                    answersLabels={satisfactionAnswersLabels.youAndFormation[0]}
                    satisfaction={participants.map((p) => p.satisfaction.youAndFormation[0].option)}
                    scale={satisfactionScale.youAndFormation[0]}
                />
                <ScoreDetails
                    label={satisfactionLabels.you[1]}
                    answersLabels={satisfactionAnswersLabels.youAndFormation[1]}
                    satisfaction={participants.map((p) => p.satisfaction.youAndFormation[1].option)}
                    scale={satisfactionScale.youAndFormation[1]}
                />
                <AnswerDetails
                    answers={
                        participants
                            .filter((p) => (p.satisfaction.youAndFormation[1].option === 'other' && Boolean(p.satisfaction.youAndFormation[1].content)))
                            .map(p => ({
                                answer: p.satisfaction.youAndFormation[1].content || "",
                                participant: p
                            }))
                    }
                />
                <AnswerDetails
                    label="Quelle thématique souhaiteriez-vous pour une prochaine formation ?"
                    answers={
                        participants
                            .filter((p) => Boolean(p.satisfaction.youAndFormation[2].content))
                            .map(p => ({
                                answer: p.satisfaction.youAndFormation[2].content || "",
                                participant: p
                            }))
                    }
                />
            </ContentContainer>
        </Container>
    ) : (
        <Container className="w-full h-full overflow-auto flex justify-content-center align-items-center">
            {loading || loadingSatisfaction ? <i className="pi pi-spin pi-spinner" /> : 'Aucune formation trouvée'}
        </Container>
    );
};
