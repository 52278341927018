import React, { useState } from 'react';
import { User, UserNote, UserNoteGroup } from '@Types/User';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CustomTextEditor } from '@Components/CustomTextEditor';

export type EditCommentProps = {
    note: UserNote;
    group: UserNoteGroup;
    user: User;
    onSave: (note: UserNote) => void;
    onCancel: () => void;
}
export const EditComment: React.FC<EditCommentProps> = (props) => {

    const [note, setNote] = useState<UserNote>(props.note);

    const onSaveNote = () => {
        props.onSave(note);
    }

    return (
        <div className="EditComment flex flex-column gap-4">
            <div className="he-header--h2"> Modifier un commentaire </div>
            <div className='flex flex-column gap-2'>
                <InputText value={note.label} onChange={(e) => setNote(prev => ({
                    ...prev,
                    label: e.target.value
                }))}/>
                <CustomTextEditor
                    debounce={0}
                    value={note.note}
                    placeholder={"Saisissez ici votre commentaire"}
                    onChange={(value) => setNote(prev => ({
                        ...prev,
                        note: value
                    }))}
                    height={"5cm"}
                    buttonList={[
                        ['bold', 'underline', 'italic', 'fontColor'],
                    ]}
                />
            </div>
            <div className='flex justify-content-end gap-2'>
                <Button label={"Annuler"} className="he-button--secondary-variant-nfb--md" onClick={props.onCancel}/>
                <Button label={"Sauvegarder"} onClick={onSaveNote} />
            </div>
        </div>
    )
}
