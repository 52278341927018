import React, { useState } from 'react';
import { useFormik } from 'formik';
import { CustomInput } from '@Components/CustomInput';
import {produce} from 'immer';
import { Hypothesis } from '@Types/Quizz';
import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import _ from 'lodash';
import styled from 'styled-components';
import { CustomDialog } from '@Components/CustomDialog';
import { HypothesisForm } from '@Pages/pages/Modules/EditModule/ActivityInformation/components/HypothesisForm';
import { TCSActivity } from '@Types/Activity';
import {
	HypothesisLabelsForm,
} from '@Pages/pages/Modules/EditModule/ActivityInformation/components/HypothesisLabelsForm';
import { usePromise } from '@Hooks/promise';
import { uploadResource } from '@Utils/import.utils';
import { UploadButton } from '@Components/UploadButton';
import { getUrlForResource } from '@Utils/resource.utils';
import { CustomImage } from '@Components/CustomImage';
import {firestore, storage} from "@Utils/config/firebase";
import {UploadBigFileButton} from "@Components/UploadBigFileButton";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 250px repeat(5, 1fr) 52px;
  grid-template-rows: minmax(42px, auto);
  grid-auto-rows: minmax(42px, auto);
`;

const GridSpan = styled.div<{ span: number, border?: Partial<{up: boolean, right: boolean, bottom: boolean, left: boolean}>}>`
  grid-column: span ${props => props.span};
  display: flex;
  align-items: center;
  padding: 14px 12px;
  ${props => {
    let res = "";
    if (!props.border) return "";
    const {left, up, bottom, right} = props.border;
    if (left) res += `border-left: 1px solid #CED4D4;\n`;
    if (right) res += `border-right: 1px solid #CED4D4;\n`;
    if (up) res += `border-top: 1px solid #CED4D4;\n`;
    if (bottom) res += `border-bottom: 1px solid #CED4D4;\n`;
	return res;
  }}
`;

export type TcsActivityEditorProps = {
	formik: ReturnType<typeof useFormik<any>>,
}
export const TcsActivityEditor: React.FC<TcsActivityEditorProps> = props => {

	const onChangeHypothesisNumber = (n: number) => {
		const updatedArray = produce(props.formik.values.hypothesis, (hypothesis: Hypothesis[]) => {
			const itemDiff = n - hypothesis.length;
			if (itemDiff < 0) {
				hypothesis.splice(n, -1 * itemDiff);
			} else {
				for (let i = 0; i < itemDiff; i++) {
					hypothesis.push({
						answers: [0, 0, 0, 0, 0],
						initial_image: null,
						initial: "",
						information: "",
						information_image: null
					});
				}
			}
			return hypothesis;
		});
		props.formik.setFieldValue('hypothesis', updatedArray, false)
	}

	const [selection, setSelection] = useState<[Hypothesis, number]>();


	function onHypothesisUpdate(h: Hypothesis, index: number) {
		setHypothesisFormVisible(true);
		setSelection([h, index]);
	}

	const [hypothesisFormVisible, setHypothesisFormVisible] = useState<boolean>(false);
	const [labelsFormVisible, setLabelsFormVisible] = useState<boolean>(false);

	const onUpdateHypothesis = (hypothesis: Hypothesis, index: number) => {
		setHypothesisFormVisible(false);
		props.formik.setFieldValue(`hypothesis.${index}`, hypothesis)
	}

	const onUpdateLabels = (labels: TCSActivity["labels"]) => {
		setLabelsFormVisible(false);
		props.formik.setFieldValue(`labels`, labels, false);
	}

	const [onQuestionImageUpload, loadingQuestion] = usePromise(async (file: File, index: number, formik: ReturnType<typeof useFormik<any>>) => {
		const id = await uploadResource(file);

		const [c1, c2, c3, c4, c5] = formik.values.cover_image_url || [];
		const data = [c1, c2, c3, c4, c5];
		data[index] = id;

		formik.setFieldValue(`cover_image_url`, data, false);
	})

	const [onCoverVideoRemove, loadingCoverVideoRemove] = usePromise(async (file_id: string, index: number) => {
		await firestore.collection('resources').delete(file_id);
		await storage.child(`resources/${file_id}`).delete();
		const data = [...props.formik.values.cover_video_url] ?? [null, null, null, null, null];
		data[index] = null;
		props.formik.setFieldValue(`cover_video_url`, data, false);
	}, {
		success: "Vidéo correctement supprimée",
		pending: "Suppression en cours..."
	});

	const onCoverVideoUpload = async (resource_id: string, index: number) => {
		const data = props.formik.values.cover_video_url ?? [null, null, null, null, null];
		data[index] = resource_id;
		props.formik.setFieldValue(`cover_video_url`, data, false);
	}

		return (
			<div className="mt-4">
				{selection && hypothesisFormVisible && <CustomDialog width={950} onHide={() => setHypothesisFormVisible(false)}>
					<HypothesisForm
						hypothesis={selection[0]}
						onUpdate={hypothesis => onUpdateHypothesis(hypothesis, selection[1])}
						onCancel={() => {
							setHypothesisFormVisible(false);
							setSelection(undefined);
						}}
						tcs={props.formik.values}
					/>
				</CustomDialog>}
				{labelsFormVisible && <CustomDialog onHide={() => setLabelsFormVisible(false)}>
					<HypothesisLabelsForm
						onUpdate={onUpdateLabels}
						onCancel={() => {
							setLabelsFormVisible(false);
							setSelection(undefined);
						}}
						tcs={props.formik.values}
					/>
				</CustomDialog>}
				<div className="he-paragraph--medium gray-900">Situation clinique</div>
				<div className="mt-3">
					<CustomInput placeholder="Saisir ici la description du cas clinique, maximum 300 caractères"
								 type="textarea" field={"description"} formik={props.formik}
								 label="Description du cas clinique" height={75} maxLength={300}/>

					<div className="he-paragraph--medium gray-900 mt-4">Images d'illustrations</div>
					<div className="flex mt-3 align-items-center w-full gap-3">
						{
							_.range(0, 5).map(i => {
								return <UploadButton
									key={i}
									label="Ajouter une image"
									icon="pi pi-image"
									loading={loadingQuestion}
									className="flex-1"
									accept={["image/png", "image/jpg", "image/jpeg"]}
									onChange={(file) => onQuestionImageUpload(file, i, props.formik)}
									file_id={props.formik.values.cover_image_url?.[i]}
									buttonClassName="he-button--primary-nf--xs w-full"
								/>
							})
						}
					</div>
				</div>
				<div className="he-paragraph--medium gray-900 mt-4">Vidéos d'illustrations</div>
				<div className="flex mt-3 align-items-center w-full gap-3">
					{
						_.range(0, 5).map(i => {
							return <UploadBigFileButton
								key={i}
								label="Ajouter une vidéo"
								icon="pi pi-video"
								loading={loadingQuestion || loadingCoverVideoRemove}
								className="flex-1"
								accept={["video/*"]}
								onUploaded={(file) => onCoverVideoUpload(file, i)}
								onRemove={(id) => id && onCoverVideoRemove(id, i)}
								file_id={props.formik.values.cover_video_url?.[i]}
								buttonClassName="he-button--primary-nf--xs w-full"
							/>
						})
					}
				</div>
				<div className="he-paragraph--medium gray-900 mt-3">Hypothèses</div>
				<div className="mt-3 w-4">
					<CustomFormDropdown label="Nombre de réponses" options={_.range(1, 11).map(v => ({
						value: v,
						label: v.toString()
					}))} value={props.formik.values.hypothesis.length} onChange={onChangeHypothesisNumber}/>
				</div>
				<GridContainer className="mt-3">
					<GridSpan span={1} border={{bottom: true}}
							  className="bg-gray-100 he-paragraph--small-bold gray-600">Hypothèse</GridSpan>
					<GridSpan span={1} border={{bottom: true, right: true}}
							  className="bg-gray-100 he-paragraph--small-bold gray-600">Nouvelle information</GridSpan>
					<GridSpan span={6} border={{bottom: true}}
							  className="bg-gray-100 he-paragraph--small-bold gray-600">
						L’impact de cette information sur votre hypothèse est…
					</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.hypothesis || "Si vous pensiez à..."}</GridSpan>
					<GridSpan border={{bottom: true, right: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.new_information || "Et que vous trouvez que/qu'..."}</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.[0] || "Fortement négatif"}</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.[1] || "Négatif"}</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.[2] || "Neutre"}</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.[3] || "Positif"}</GridSpan>
					<GridSpan border={{bottom: true}} className="bg-gray-100 he-paragraph--small gray-600"
							  span={1}>{props.formik.values.labels?.[4] || "Fortement positif"}</GridSpan>
					<GridSpan border={{bottom: true, left: true}}
							  className="bg-gray-100 he-paragraph--small gray-600 justify-content-center" span={1}>
						<i className="pi pi-pencil cursor-pointer" onClick={() => setLabelsFormVisible(true)}/>
					</GridSpan>
					{
						props.formik.values.hypothesis.map((h: Hypothesis, i: number) => <React.Fragment key={i}>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900 gap-1"
									  span={1}>
								{h.initial || !h.initial_image &&
									<span className="primary-100 font-italic"><i className="pi pi-exclamation-circle"/> Hypothèse incomplète</span>}
								{h.initial_image && <CustomImage
									urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)}
									width="40"/>}
							</GridSpan>
							<GridSpan border={{bottom: true, right: true}}
									  className="he-paragraph--small inter gray-900" span={1}>
								{h.information}
								{h.information_image && <CustomImage
									urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)}
									width="40"/>}
							</GridSpan>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900"
									  span={1}>{h.answers[0]}</GridSpan>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900"
									  span={1}>{h.answers[1]}</GridSpan>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900"
									  span={1}>{h.answers[2]}</GridSpan>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900"
									  span={1}>{h.answers[3]}</GridSpan>
							<GridSpan border={{bottom: true}} className="he-paragraph--small inter gray-900"
									  span={1}>{h.answers[4]}</GridSpan>
							<GridSpan border={{bottom: true, left: true}}
									  className="justify-content-center he-paragraph--small inter gray-900" span={1}>
								<i className="pi pi-pencil cursor-pointer" onClick={() => onHypothesisUpdate(h, i)}/>
							</GridSpan>
						</React.Fragment>)
					}
				</GridContainer>
			</div>
		)
	}
