import { Line } from "@Components/UI/Line";
import { BackofficeContext } from "@Context/BackofficeContext";
import { ModuleViewer } from "@Pages/pages/Formations/components/ModuleViewer";
import { Formation } from "@Types/Formation";
import { Module } from "@Types/Module";
import { useFormik } from "formik";
import _ from "lodash";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";

const ModuleContainer = styled.div`
  padding: 21px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
`

export type AddModuleProps = {
	onSave: (module_id: string, name?: string) => void;
	onCancel: () => void;
	formik: ReturnType<typeof useFormik<Formation>>
}
export const AddModule: React.FC<AddModuleProps> = props => {

	const {modules} = useContext(BackofficeContext);

	const [filtered, setFiltered] = useState<Module[]>(modules);

	const [selectedModule, setSelectedModule] = useState<{label: string, value: string}>();

	const moduleTemplate = (m: {label: string, value: string}) => <ModuleContainer key={m.value}>
		<ModuleViewer module_id={m.value}/>
	</ModuleContainer>

	const completeMethod = useCallback((query: string) => {
		setFiltered(_.sortBy(modules.filter( m => m.title.match(new RegExp(query, "i"))), "title"));
	}, [modules]);

	const alreadyAdded = useMemo(() => props.formik.values.modules.some(m => m.module_id === selectedModule?.value), [props.formik.values.modules, selectedModule]);

	return (
		<div>
			<div className="he-paragraph--medium gray-900">Ajouter un module</div>
			<div className="he-paragraph--regular gray-500 mt-4">Rechercher un module</div>
			<AutoComplete
				value={selectedModule}
				field="label"
				suggestions={filtered.map(m => ({label: m.title, value: m.module_id}))}
				itemTemplate={moduleTemplate}
				completeMethod={e => completeMethod(e.query)}
				onChange={e => {
					setSelectedModule(e.value);
				}}
				placeholder="Entrez le nom du module"
				className="w-full mt-2"
			/>
			{
				selectedModule?.value &&
				<ModuleContainer className="mt-3">
					<ModuleViewer module_id={selectedModule.value}/>
				</ModuleContainer>
			}
			<Line height={1} className="bg-gray-200 my-4" />
			<div className="flex justify-content-end align-items-center">
				{
					alreadyAdded && <div className="he-paragraph--medium color-red mr-auto">
						Module déjà ajouté
					</div>
				}
				<Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" onClick={props.onCancel}/>
				<Button
					disabled={alreadyAdded}
					className="he-button--primary--md" icon="pi pi-plus" label="Ajouter" onClick={() => selectedModule && props.onSave(selectedModule.value)}/>
			</div>
		</div>
	)
}
