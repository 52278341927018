import React from "react";
import {Session} from "@Types/Session";
import {UnitSetting} from "@Pages/pages/Sessions/pages/components/UnitSetting";
import {Line} from "@Components/UI/Line";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import {produce} from "immer";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";

export type SessionUnitSettingsProps = {
	session: Session
}
export const SessionUnitSettings: React.FC<SessionUnitSettingsProps> = (props) => {

	const api = useApi();
	const [updateSession, loading] = usePromise(async (session: Session) => {
		const res = await api.session_call_update({
			session: session
		});

		if (res.result !== "ok") throw new Error(res.result);

	}, {
		pending: "Mise à jour en cours",
		success: "Session mise à jour !"
	});

	const sessionFormik = useFormik(
		{
			initialValues: props.session,
			enableReinitialize: true,
			onSubmit: updateSession
		}
	)

	return (
		<div className="SessionUnitSettings">
			{
				props.session.unitsConfig.map((unitSetting, i) => {
					return <React.Fragment key={unitSetting.unit_id}>
						<UnitSetting
							unitSettings={unitSetting}
							session_id={props.session.session_id}
							index={i}
							onUpdateUnit={settings => {
								const units = sessionFormik.values.unitsConfig;
								const updatedUnits = produce(units, prev => {
									return prev.map(config => {
										if (config.unit_id !== settings.unit_id) return config;
										return settings;
									});
								});
								sessionFormik.setFieldValue('unitsConfig', updatedUnits, false)
							}}
						/>
						{i < props.session.unitsConfig.length && <Line height={1} className={"bg-gray-200 my-5"}/>}
					</React.Fragment>
				})
			}
			<Button loading={loading} onClick={sessionFormik.submitForm} className="he-button--primary--md" label="Enregistrer les modifications" icon={"pi pi-save"}/>
		</div>
	)
}
