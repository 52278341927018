import styled from "styled-components";
import {User} from "@Types/User";
import {MessageExtended} from "@Types/Message";
import {classNames} from "primereact/utils";
import {uuidToTag} from "../utils/messages.utils";
import {DateTime} from "luxon";
import {SpeedDial} from "primereact/speeddial";

type Props = {
    m: MessageExtended;
    i: number;
    showName: boolean;
    showTime: boolean;
    isMentionned: boolean;
    isReply: boolean;
    isMine: boolean;
    meta: User;
    onLike: (message: MessageExtended) => void;
    onDelete: (message: MessageExtended) => void;
    onReply: (message: MessageExtended) => void;
};
export const Message: React.FC<Props> = (
    {
        m,
        i,
        isReply,
        meta,
        showName,
        showTime,
        isMentionned,
        isMine,
        onDelete,
        onReply
    }
) => {

    const formatMessageDate = (date: string) => {
        const dateTime = DateTime.fromISO(date).setLocale("fr");

        if (dateTime.hasSame(DateTime.now(), "day")) {
            return dateTime.toFormat("HH:mm");
        }
        if (dateTime.hasSame(DateTime.now().minus({day: 1}), "day")) {
            return dateTime.toFormat("'Hier à' HH:mm");
        }

        if (dateTime.hasSame(DateTime.now(), "week")) {
            return dateTime.toFormat("ccc à HH:mm");
        }

        return dateTime.toFormat("dd/MM/yy à HH:mm");
    }

    const menuItems = [
        {
            label: "Répondre",
            icon: "pi pi-reply",
            command: () => onReply(m)
        },
        {
            label: "Supprimer",
            icon: "pi pi-trash",
            className: "bg-red-25 red-100",
            command: () => onDelete(m)
        }
    ]

    return (
        <>
            <MessageContainer
                className={classNames(
                    isMine ? "mine" : "others",
                    (isMentionned || isReply) && "mentionned",
                    showName && "mt-2",
                    (showTime || m.likes.length > 0) && "mb-1"
                )}
            >

                {showName && <span className="roboto he-paragraph--medium--bold gray-600">
                            {m.username} <span className="he-paragraph--medium primary-75">#{uuidToTag(m.user_id)}</span>
                        </span>}
                <MessageTemplate
                    tabIndex={2+i}
                    className={classNames(isMine ? "flex-row" : "flex-row-reverse")}
                >
                   <Options
                        className={classNames("options", isMine ? "justify-content-end" : "justify-content-start")}
                    >
                        <SpeedDial model={menuItems} direction={isMine ? "left" : "right"} showIcon={"pi pi-ellipsis-v"} hideIcon={"pi pi-times"}/>
                    </Options>
                    <div
                        className="flex flex-column gap-2 msg-content hover:shadow-1 gray-700"
                    >
                        {
                            m.replyTo &&
                            <ReplyTemplate>
                                <div className="he-paragraph--small">Réponse à&nbsp;
                                    <span className="font-bold underline">{m.reply ? m.reply.username+"#"+uuidToTag(m.reply.user_id) : "Inconnu"}</span>
                                </div>
                                {m.reply ? m.reply.content : "Message supprimé"}
                                <span className="he-paragraph--xs">{m.reply ? formatMessageDate(m.reply.created_at) : "-"}</span>
                            </ReplyTemplate>
                        }
                        <div className="he-paragraph--regular-16">
                            {m.content}
                        </div>
                        {m.mentions?.length > 0 && <div>
                            {m.mentions.map(m => {
                                return <div key={m.user_id} className="he-paragraph--regular--underlined secondary-100">
                                    @{m.username}
                                </div>
                            })}
                        </div>}
                    </div>
                </MessageTemplate>
                <div className={classNames("w-max flex gap-2 align-items-center justify-content-between", isMine ? "flex-row-reverse" : "flex-row")}>
                    {(showTime || i === 0)  && <span className="he-paragraph--small gray-400 flex align-items-center">{formatMessageDate(m.created_at)}</span>}
                    {m.likes.length > 0 && <span className="he-paragraph--small green-100 flex gap-1 align-items-center h-full">{m.likes.length} <i className={m.likes.includes(meta.user_id) ? "pi pi-thumbs-up-fill" : "pi pi-thumbs-up"}/></span>}
                </div>
            </MessageContainer>
        </>
    );
};

const MessageTemplate = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    width: 75%;

    &:hover {
        .options {
            opacity: 1;
            pointer-events: all;
        }
    }

    .options {
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s;
    }

    .msg-content {
        max-width: calc(100% - 44px);
        padding: 12px 14px;
        transition: background-color 0.1s;
        white-space: pre-line;
        font-family: "roboto", "sans-serif";
    }
`

const Options = styled.div`
    display: flex;
    align-items: center;

    .p-speeddial {
        &-button.p-button {
            height: 28px;
            width: 28px;
            .pi {
                font-size: 12px;
                color: #333;
            }
            background-color: #f5f5f5;
            border: none;
            &:hover {
                background-color: #e5e5e5;
            }
        }

        &-action {
            height: 28px;
            width: 28px;
            .pi {
                font-size: 12px;
            }
        }
    }

    .p-speeddial-button.p-button, .p-speeddial-action {
    }
`

const MessageContainer = styled.div`

    cursor: default;
    width: 100%;
    gap: 6px;

    &.mine {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .msg-content {
            background-color: rgba(108, 198, 255, 0.4);

            &:hover {
                background-color: rgba(90, 171, 222, 0.4);
            }

            border-radius: 12px 12px 0 12px;
        }
    }

    &.mentionned {
        border-radius: 8px;
        background-color: rgb(255, 237, 235);
        padding: 8px;

        .msg-content {
            background: #ffffff !important;
        }
    }

    &.others {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .msg-content {
            background-color: #efefef;

            &:hover {
                background-color: #e6e6e6;
            }

            border-radius: 12px 12px 12px 0;
        }
    }

`

const ReplyTemplate = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "roboto", "sans-serif";
    font-size: 14px;
    white-space: pre-line;
    border-left: 3px solid #f4874e;
    transition: background-color 0.1s;
`
