import {useMemo} from "react";
import {FormikValues, useFormik} from "formik";
import {classNames} from "primereact/utils";
import {InputNumber} from "primereact/inputnumber";
import styled from "styled-components";

const InputNumberContainer = styled.div<{
	width?: string | number;
}>`
	.p-inputnumber .p-inputtext {
		height: 40px;
		${({width}) => width ? `width: ${width};` : ""};
	}
`

export type CustomInputNumberProps<T extends FormikValues> = {
	field?: string;
	formik?: ReturnType<typeof useFormik<T>>;
	value?: number | null;
	onChange?: (num: number | null) => void;
	label?: string;
	placeholder?: string;
	min?: number;
	max?: number;
	className?: string | (boolean | string | undefined)[];
	showButtons?: boolean,
	buttonLayout?: "horizontal" | "vertical"
	disabled?: boolean;
	suffix?: string;
	prefix?: string;
	style?: React.CSSProperties;
}
export const CustomInputNumber = function <T extends FormikValues>(props: CustomInputNumberProps<T>) {

	const defaultProps = useMemo<CustomInputNumberProps<T>>(() => ({
		className: [], ...props,
	}), [props]);

	return (<div
			className={classNames("flex flex-column", ...(typeof defaultProps.className === "string" ? [defaultProps.className] : (defaultProps.className ?? [])))}>
			{defaultProps.label && <label className="he-paragraph--regular gray-500 mb-2"
                                          htmlFor={defaultProps.field}>{defaultProps.label}</label>}
			<InputNumberContainer
				className={classNames("w-full")}
				width={defaultProps.style?.width}
			>
				<InputNumber
					id={defaultProps.field}
					name={defaultProps.field}
					showButtons={defaultProps.showButtons}
					buttonLayout={defaultProps.buttonLayout}
					placeholder={defaultProps.placeholder}
					min={defaultProps.min}
					max={defaultProps.max}
					value={(defaultProps.formik && defaultProps.field ? defaultProps.formik.values[defaultProps.field] : props.value)}
					className="w-full"
					decrementButtonClassName="he-button--primary--md mr-2"
					incrementButtonClassName="he-button--primary--md ml-2"
					disabled={props.disabled}
					suffix={props.suffix}
					prefix={props.prefix}
					style={props.style}
					onChange={(event) => (defaultProps.formik && defaultProps.field) ? defaultProps.formik.setFieldValue(defaultProps.field, event.value, false) : props.onChange?.(event.value)}
				/>
			</InputNumberContainer>
		</div>)

}
