import * as React from 'react';
import {useState} from 'react';
import {CustomDialog} from "@Components/CustomDialog";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

interface Props {
    onSave: (keywords: string[]) => void;
    onHide: () => void;
}
export const AddMultipleKeywordsDialog: React.FC<Props> = (props) => {

    const [text, setText] = useState<string>("");

    const keywords = text.split("\n").map(line => line.trim()).filter(line => line.length > 0);

    return (
        <CustomDialog onHide={props.onHide}>
            <div className="he-header--h3">Ajouter un ou plusieurs mots-clés</div>
            <InputTextarea
                value={text}
                onChange={e => setText(e.target.value)}
                className="mt-3"
                style={{height: 300}}
                placeholder="Saisissez les mots-clés, un par ligne"
            />
            <div className="flex mt-3 justify-content-end gap-3">
                <Button className="he-button--secondary-variant-nfb--md" onClick={props.onHide}> Annuler </Button>
                <Button
                    className="he-button--primary--md"
                    onClick={() => {
                        props.onSave(keywords);
                    }}
                    disabled={keywords.length === 0}
                >
                    Ajouter {keywords.length} mot{keywords.length > 1 ? "s" : ""} <i className={PrimeIcons.PLUS}/>
                </Button>
            </div>
        </CustomDialog>
    );

}
