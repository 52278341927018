import { sanitizeString } from '@Utils/string.utils';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { useRef, useState } from 'react';
import styled from 'styled-components';

type CustomFormAutocompleteProps<T> = {
    values: T[];
    value: T | null;
    onChange: (value: T) => void;
    label?: string;
    placeholder: string;
    className?: string | (boolean | string | undefined)[];
    labelField: keyof T;
    valueField: keyof T;
    disabled?: boolean;
    filterFields?: (keyof T)[];
    showClear?: boolean;
    itemTemplate?: (item: T) => JSX.Element;
    selectedItemTemplate?: (item: T) => JSX.Element;
};
export function CustomFormAutocomplete<T>(props: CustomFormAutocompleteProps<T>) {
    const [filteredValues, setFilteredValues] = useState<T[]>([]);

    const ref = useRef<HTMLDivElement>(null);

    const search = (event: AutoCompleteCompleteEvent) => {
        if (!event.query) setFilteredValues(props.values);

        setFilteredValues(
            props.values.filter((v) => {
                for (const field of props.filterFields ?? []) {
                    const value = v[field];
                    if (typeof value !== 'string') continue;
                    const normalizedString = sanitizeString(value).toLocaleLowerCase();
                    const normalizedSearch = sanitizeString(event.query).toLocaleLowerCase();
                    if (normalizedString.includes(normalizedSearch)) return true;
                }
                return false;
            })
        );
    };

    const itemTemplate = (item: T) => {
        const complete = props.values.find((v) => v[props.valueField] === item[props.valueField]);
        if (!complete) return '-';
        return props.itemTemplate ? (
            props.itemTemplate(complete)
        ) : (
            <EllipsisText title={item[props.labelField] as string}>{item[props.labelField] as string}</EllipsisText>
        );
    };

    return (
        <AutoCompleteWrapper className={classNames(props.className ?? [])} ref={ref}>
            <AutoComplete
                dropdown
                panelStyle={{ maxWidth: (ref.current?.clientWidth ?? 400) - 40 }}
                disabled={props.disabled}
                className="w-full"
                value={props.value}
                suggestions={filteredValues}
                placeholder={props.placeholder}
                completeMethod={search}
                field={props.labelField as string}
                itemTemplate={itemTemplate}
                onChange={(e) => {
                    props.onChange(e.value);
                }}
            />
        </AutoCompleteWrapper>
    );
}

const AutoCompleteWrapper = styled.div`
    .p-autocomplete-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-autocomplete-dropdown {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 40px;
    }
`;

const EllipsisText = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
