import React, {useContext, useEffect, useMemo, useState} from "react";
import {CustomDialog} from "@Components/CustomDialog";
import {Session, SessionParticipant} from "@Types/Session";
import {BackofficeContext} from "@Context/BackofficeContext";
import {AutoComplete} from "primereact/autocomplete";
import {Formation} from "@Types/Formation";
import {fuzzSearch} from "@Utils/search.utils";
import {Tag} from "primereact/tag";
import _ from "lodash";
import {collection, getDocs} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {SendScheduledMessage} from "@Pages/pages/components/SendScheduledMessage";

type SessionItem = {
    title: string,
    session: Session,
    formation: Formation
}

export type RelanceDialogProps = {
    onHide: () => void;
}
export const RelanceDialog: React.FC<RelanceDialogProps> = (props) => {

    const {sessions, formations} = useContext(BackofficeContext);

    const items = useMemo(() => {
        return sessions
            .filter(s => !s.archived)
            .map(s => {

            const formation = formations.find(f => f.formation_id === s.formation_id);

            if (!formation) return null;

            return {
                title: `${s.session_custom_id} - ${formation.title}`,
                session: s,
                formation
            }
        }).filter(item => !!item) as SessionItem[]
    }, [sessions, formations]);

    const [filtered, setFiltered] = useState<SessionItem[]>([]);

    const [selectedItem, setSelectedItem] = useState<{label: string, value?: SessionItem} | null>(null);
    const completeMethod = (query: string) => {
        if (!query) return setFiltered(items);
        const results = _.sortBy(fuzzSearch(items, query, ['title', "session.session_custom_id", "formation.title", "formation.andpc"]), "title");
        setFiltered(results);
    }

    const sessionTemplate = (option: {
        label: string,
        value: SessionItem
    }) => {
        return <div className="flex flex-column gap-1" style={{width: 500, height: "auto"}}>
            <div className="flex gap-2">
                <Tag className="px-2 py-1">{option.value.session.session_custom_id}</Tag>
                <Tag severity={"info"}>{option.value.formation.andpc}</Tag>
            </div>
            <div
                title={option.value.formation.title}
                className="he-paragraph--regular w-full"
                style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}>{option.value.formation.title}</div>
        </div>
    }

    const [participants, setParticipants] = useState<SessionParticipant[]>([]);
    const [loadingParticipants, setLoadingParticipants] = useState<boolean>(false);

    useEffect(() => {
        if (!selectedItem?.value?.session) return;
        setLoadingParticipants(true);
        getDocs(
            collection(
                firestore.db,
                'formations',
                selectedItem.value.session.formation_id,
                'sessions',
                selectedItem.value.session.session_id,
                'participants'
            )
        )
            .then((snap) => setParticipants(snap.docs.map((d) => d.data() as SessionParticipant)))
            .finally(() => setLoadingParticipants(false));
    }, [selectedItem]);

    return (
        <CustomDialog
            onHide={() => props.onHide()}
        >
            <div className="he-header--h2 mb-4 flex align-items-center">
                Programmer une nouvelle relance
            </div>
            <div className="flex flex-column gap-2">
                <div className="he-paragraph--medium">
                    Sélectionner une session
                </div>
                <AutoComplete
                    value={selectedItem}
                    field="label"
                    suggestions={filtered.map(m => ({label: m.title, value: m}))}
                    completeMethod={e => completeMethod(e.query)}
                    onChange={e => {
                        setSelectedItem(e.value || null);
                    }}
                    itemTemplate={sessionTemplate}
                    style={{
                        width: "100%"
                    }}
                    dropdown
                    placeholder="Choisir une session"
                    disabled={loadingParticipants}
                    className="w-full mb-1"
                />
                {
                    selectedItem?.value?.session && <>
                        {loadingParticipants && <div className="he-paragraph--regular">
                            Récupération des participants <i className={"pi pi-spin pi-spinner"}/>
                        </div>}
                        {participants.length > 0 && !loadingParticipants ? <SendScheduledMessage
                            onQuit={() => props.onHide()}
                            isFromSessionList={true}
                            session={selectedItem.value.session}
                            participants={
                                participants.map((p) => ({ ...p, session_id: selectedItem.value?.session.session_id })) || []
                            }
                        /> : <div className="he-paragraph--regular">
                            Aucun participant n'est inscrit à cette session
                        </div>}
                    </>
                }
            </div>
        </CustomDialog>
    )
}
