import { BackofficeContext } from "@Context/BackofficeContext";
import { ActivityPreview } from "@Pages/pages/Formations/PreviewFormation/ActivityPreview";
import { Activity } from "@Types/Activity";
import { Module } from "@Types/Module";
import { Unit } from "@Types/Unit";
import { iconForActivity } from "@Utils/activity.utils";
import { BreadCrumb } from "primereact/breadcrumb";
import { Menu } from "primereact/menu";
import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
  padding: 32px 24px;
  width: 100%;
  max-width: 1220px;
`;


const MenuContainer = styled.div`
  .p-slidemenu {
    .selected {
      .p-menuitem-icon, .p-menuitem-text {
        color: #3c90e3 !important;
      }
    }
  }

  .p-slidemenu-wrapper {
    padding-bottom: 42px;
  }
`

export type PreviewFormationProps = Record<string, never>
export const PreviewFormation: React.FC<PreviewFormationProps> = () => {

    const {formations, loading, modules} = useContext(BackofficeContext);
    const params = useParams();

    const formation = useMemo(() => formations.find(f => f.formation_id === params.formation_id), [formations, params.formation_id]);

    const [selectedActivity, setSelectedActivity] = useState<Activity>();

    const [selectedUnit, setSelectedUnit] = useState<[number, Unit]>();
    const [selectedModule, setSelectedModule] = useState<[string, Module]>();

    const unitMenuData = useMemo(() => {
        return formation?.units.map((u, i) => {
            return {
                label: `Unité ${i + 1}`,
                command: () => {
                    setSelectedUnit([i, u]);
                },
            }
        }) || [];
    }, [formation])

    const moduleMenuData = useMemo(() => {
        return (formation && selectedUnit?.[1].modules_ids.map(m_id => {
            const module = modules.find(m => m.module_id === m_id);
            const label = formation.modules.find(m => m.module_id === m_id)?.name || module?.title || "Aucun nom trouvé";
            return ({
                label,
                command: () => {
                    if (module) {
                        setSelectedModule([label, module]);
                        setSelectedActivity(module.activities[0]);
                    }
                },
            })
        })) || []
    }, [selectedUnit, formation, modules])

    const activityMenuData = useMemo(() => {
        return (formation && selectedUnit && selectedModule && selectedModule?.[1].activities.map(a => ({
            label: a.title,
            icon: iconForActivity(a.type),
            className: selectedActivity?.activity_id === a.activity_id ? "active" : "",
            command: () => {
                setSelectedActivity(a)
            }
        }))) || []
    }, [selectedUnit, selectedModule, selectedActivity, formation, modules])

    const breadCrumbData = useMemo(() => {
        return [
            selectedUnit && {label: `Unité ${(selectedUnit[0] || 0) + 1}`, command: () => {setSelectedUnit(undefined); setSelectedModule(undefined); setSelectedActivity(undefined)}},
            selectedModule && {label: selectedModule[0], command: () => {setSelectedModule(undefined); setSelectedActivity(undefined)}},
            selectedActivity && {label: selectedActivity.title, icon: iconForActivity(selectedActivity.type)}
        ].filter(l => Boolean(l)) as { label: string }[]
    }, [selectedUnit, selectedModule, selectedActivity])

    return (
        formation ? <Container className="w-full h-full overflow-auto">
                <div className="w-full flex flex-column">
                    <div className="w-full mb-3">
                        <BreadCrumb className="border-none" model={breadCrumbData}/>
                    </div>
                    <div className="w-full flex">
                        <MenuContainer className="w-18rem">
                            <CustomMenu model={
                                (!selectedUnit && !selectedModule && !selectedActivity) ? unitMenuData :
                                    (selectedUnit && !selectedModule && !selectedActivity) ? moduleMenuData :
                                        (selectedUnit && selectedModule) ? activityMenuData : []
                            } className="p-2 w-full border-none" />
                        </MenuContainer>
                        <div className="ml-3 flex-1 bg-white border-round p-3">
                            {selectedActivity && <ActivityPreview activity={selectedActivity}/>}
                        </div>
                    </div>
                </div>
            </Container> :
            <Container className="w-full h-full overflow-auto flex justify-content-center align-items-center">
                {loading ? <i className="pi pi-spin pi-spinner"/> : "Aucune formation trouvée"}
            </Container>
    )
}

const CustomMenu = styled(Menu)`
  
  .p-menuitem:not(.active) {
    .p-menuitem-link {
      span {
        color: #888 !important;
      }
    }
  }
  .p-menuitem.active {
    .p-menuitem-link {
      span {
        color: #0b5599 !important;
      }
    }
  }
  
`
