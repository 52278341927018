import { getBlob, ref } from 'firebase/storage';
import { storage } from './config/firebase';

export const downloadUint8 = function (data: Uint8Array, fileName: string, mimeType: string) {
    const blob = new Blob([new Uint8Array(Object.values(data))], {
        type: mimeType,
    });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();

    setTimeout(() => {
        return URL.revokeObjectURL(url);
    }, 1000);
};

export const downloadFromURL = async function (url: string) {
    const fileRef = ref(storage.storage, url);
    const filename = fileRef.name;
    const blob = await getBlob(fileRef);
    const a = document.createElement('a');
    const file = new File([blob], filename, { type: 'application/data' });
    const uri = window.URL.createObjectURL(file);
    a.href = uri;
    a.download = filename;
    a.click();
};
