import {Theme} from '@Types/Theme';
import * as React from 'react';
import {useMemo, useState} from 'react';
import {sanitizeString} from "@Utils/string.utils";
import {deleteDoc, doc, setDoc} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {useToast} from "@Hooks/toast";
import {v4} from "uuid";
import {DateTime} from "luxon";
import {CustomInput} from "@Components/CustomInput";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {Line} from "@Components/UI/Line";
import {getTextColor} from "@Utils/misc.utils";
import {classNames} from "primereact/utils";
import {CustomInputNumber} from "@Components/CustomInputNumber";
import {InputSwitch} from "primereact/inputswitch";
import styled from "styled-components";
import {ColorPicker} from "primereact/colorpicker";

interface Props {
    themes: Theme[];
    formation_id: string;
}
export const ThemeConfig: React.FC<Props> = (props) => {

    /* STATES */

    const [selection, setSelection] = useState<Theme | null>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");

    const filteredThemes = useMemo(() => {
        if (!search.trim()) return props.themes;
        return props.themes.filter(theme => {
            const sanitizedSearch = sanitizeString(search.toLowerCase()).trim();
            const sanitizedLabel = sanitizeString(theme.label.toLowerCase()).trim();
            return sanitizedLabel.includes(sanitizedSearch);
        })
    }, [props.themes, search])

    const {error, success} = useToast();
    const onNewTheme = async () => {
        const theme: Theme = {
            theme_id: v4(),
            label: "Nouvelle thématique",
            scale: [{
                threshold: 10,
                color: "#FF0000",
                text: ""
            }],
            color: "#c8c8c8",
            formation_id: props.formation_id,
            activity_ids: [],
            created_at: DateTime.now().setZone("utc").toISO() ?? new Date().toISOString()
        };

        const collectionRef = doc(firestore.db, "formations", props.formation_id, "themes", theme.theme_id);

        try {
            // Add the new theme to the collection
            await setDoc(collectionRef, theme);
            success("Thématique créée avec succès");
        } catch (e) {
            console.error(e);
            error("Erreur lors de la création de la thématique");
        }

    }
    const onDeleteTheme = async (theme: Theme) => {
        const collectionRef = doc(firestore.db, "formations", props.formation_id, "themes", theme.theme_id);
        try {
            await deleteDoc(collectionRef);
            success("Thématique supprimée avec succès");
        } catch (e) {
            error("Erreur lors de la suppression de la thématique");
        }
    }
    const onSaveTheme = async (theme: Theme) => {
        const collectionRef = doc(firestore.db, "formations", props.formation_id, "themes", theme.theme_id);
        try {
            await setDoc(collectionRef, theme);
            success("Modifications enregistrées avec succès");
            setSelection(null);
        } catch (e) {
            error("Erreur lors de l'enregistrement des modifications");
        }
    }

    const onAddNewStep = (theme: Theme) => {
        const previousMin = Math.min(...theme.scale.map(scale => scale.threshold), 0);
        const newTheme: Theme = {
            ...theme,
            scale: [...theme.scale, {
                threshold: previousMin + 10,
                color: "#FF0000",
                text: ""
            }]
        }
        setSelection(newTheme);
    }

    const onRemoveStep = (theme: Theme, index: number) => {
        const newTheme: Theme = {
            ...theme,
            scale: theme.scale.filter((_, i) => i !== index)
        }
        setSelection(newTheme);
    }
    /* EFFECTS */

    /* EVENT HANDLERS */

    /* RENDERING */

    return (
        <>
            <div className="card p-2 border-round-md bg-gray-100 flex align-items-center w-full gap-3">
                <CustomInput icon="pi pi-search" placeholder="Rechercher une thématique" value={search} onChange={setSearch}/>
                <label className="he-paragraph--medium gray-600 flex align-items-center gap-2">
                    Voir le détails
                    <InputSwitch checked={showDetails} onChange={e => setShowDetails(e.value)}/>
                </label>
                <Button className="he-button--primary--md ml-auto" onClick={onNewTheme}>
                    Nouvelle thématique <i className={PrimeIcons.PLUS}/>
                </Button>
            </div>
            {
                filteredThemes.map(theme => {
                    return (
                        <ThemeContainer key={theme.theme_id}>
                            <div className="w-full flex gap-2 align-items-center">
                                {
                                    selection && selection.theme_id === theme.theme_id ?
                                        <>
                                            <CustomInput
                                                value={selection.label}
                                                placeholder={"Nom de la thématique"}
                                                onChange={str => {
                                                    setSelection({...selection, label: str});
                                                }}
                                            />
                                            <label className="he-paragraph--small gray-500"> Choix couleur </label>
                                            <div className="flex align-items-center">
                                                <CustomColorPicker
                                                    value={selection.color?.replace('#', '')}
                                                    onChange={e => setSelection({
                                                        ...selection,
                                                        color: typeof e.value === "string" ? `#${e.value}` : "#c8c8c8"
                                                    })}
                                                />
                                                <CustomInput
                                                    value={selection.color}
                                                    onChange={e => setSelection({
                                                        ...selection,
                                                        color: e
                                                    })}
                                                    className="ml-2"
                                                    height={32}
                                                    placeholder={"Code couleur"}
                                                />
                                            </div>
                                            <Button className="he-button--secondary-nf--xs ml-auto" onClick={() => onSaveTheme(selection)}>
                                                Enregistrer
                                                <i className={classNames(PrimeIcons.SAVE)} />
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <ThemeTag
                                                background={theme.color}
                                                color={getTextColor(theme.color)}
                                            >
                                                {theme.label}
                                            </ThemeTag>
                                            <span className="he-paragraph--small-bold gray-600">
                                                 {`${theme.activity_ids.length} ${theme.activity_ids.length > 1 ? 'activités associées' : 'activité associée'}`}
                                            </span>
                                            <i className={classNames(PrimeIcons.PENCIL, "ml-auto", "cursor-pointer", "p-2")}
                                               onClick={() => setSelection(theme)}/>
                                            <i className={classNames(PrimeIcons.TRASH, "color-red", "cursor-pointer", "p-2")}
                                               onClick={() => onDeleteTheme(theme)}/>
                                        </>
                                }
                            </div>
                            {(showDetails || (selection?.theme_id === theme.theme_id)) && <>
                                <Line height={1} style={{background: "#d9dade"}} className="w-12 mb-4 my-3"/>
                                <div className="w-full flex flex-column align-items-center gap-3">
                                    {theme.scale.length === 0 && !selection &&
                                        <div className="he-paragraph--small gray-400 p-2">Aucun palier défini pour cette
                                            thématique</div>
                                    }
                                    {(selection && selection.theme_id === theme.theme_id ? selection : theme).scale.map((scale, index) => {
                                        return (
                                            !selection || selection?.theme_id !== theme.theme_id ?
                                                <div key={theme.theme_id + index}
                                                     className="w-full flex align-items-start gap-4">
                                                    <ScaleInfo>
                                                        Palier&nbsp;{index + 1}
                                                    </ScaleInfo>
                                                    <div className="w-full flex flex-column pt-2 gap-1">
                                                    <span className="he-paragraph--medium gray-800">
                                                        Score ≤ {scale.threshold}%
                                                    </span>
                                                        <TextAreaDisplay className="gray-600">
                                                            {scale.text}
                                                        </TextAreaDisplay>
                                                    </div>
                                                </div>
                                                :
                                                <div key={selection.theme_id + index}
                                                     className="w-full flex align-items-start gap-4">
                                                    <i className="pi pi-times color-red pt-2 pl-2 cursor-pointer"
                                                       onClick={() => {
                                                           onRemoveStep(selection, index);
                                                       }}/>
                                                    <ScaleInfo>
                                                        Palier&nbsp;{index + 1}
                                                    </ScaleInfo>
                                                    <CustomInputNumber
                                                        label={"Seuil (en %)"}
                                                        value={scale.threshold}
                                                        placeholder={"Saisir un seuil"}
                                                        min={0}
                                                        max={100}
                                                        onChange={str => {
                                                            const newScale = [...selection.scale];
                                                            newScale[index] = {...scale, threshold: str ?? 0};
                                                            setSelection({...selection, scale: newScale});
                                                        }}
                                                        prefix={"≤ "}
                                                        style={{width: "100px"}}
                                                    />
                                                    <CustomInput
                                                        label={"Texte explicatif"}
                                                        value={scale.text}
                                                        type={"textarea"}
                                                        placeholder={"Texte à afficher lorsque le résultat est inférieur à ce seuil"}
                                                        onChange={str => {
                                                            const newScale = [...selection.scale];
                                                            newScale[index] = {...scale, text: str};
                                                            setSelection({...selection, scale: newScale});
                                                        }}
                                                        className={"w-full"}
                                                        height={128}
                                                    />
                                                </div>
                                        )
                                    })}
                                    {
                                        selection &&
                                        <Button className="he-button--secondary-variant-nf--xs"
                                                onClick={() => onAddNewStep(selection)}>Ajouter un palier</Button>
                                    }
                                </div>
                            </>}
                        </ThemeContainer>
                    )
                })
            }
        </>
    );

}
const ThemeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    background: var(--gray-50);
    border: 1px solid var(--gray-200);
    width: 100%;
    margin-top: 8px;
`;

const ThemeTag = styled.div<{
    background?: string;
    color?: string;
}>`
    padding: 8px 16px;
    border-radius: 10px;
    background: ${props => props.background || "#c8c8c8"};
    color: ${props => props.color || "#000000"};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'roboto';
`;

const CustomColorPicker = styled(ColorPicker)`
    width: 32px;
    height: 32px;
    input {
        width: 32px;
        height: 32px;
    }
`
const ScaleInfo = styled.div`
    font-size: 14px;
    height: 68px;
    font-family: 'roboto';
    font-weight: 500;
    color: var(--gray-700);
    padding: 8px 16px 8px 0;
    border-right: 1px solid var(--gray-700);
`

const TextAreaDisplay = styled.div`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
    width: 100%;
`
