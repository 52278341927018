declare const GCLOUD_PROJECT: string;

export const firebaseConfigProd = {
    apiKey: 'AIzaSyCE_32diccdUjXsmnkcXSJXZI2IqkNHERk',
    authDomain: 'health-events-dev.firebaseapp.com',
    projectId: 'health-events-dev',
    storageBucket: 'health-events-dev.appspot.com',
    messagingSenderId: '204933489081',
    appId: '1:204933489081:web:9e7ae6088e6ea14d8ad668',
};

export const firebaseConfigDev = {
    apiKey: 'AIzaSyCE_32diccdUjXsmnkcXSJXZI2IqkNHERk',
    authDomain: 'health-events-dev.firebaseapp.com',
    projectId: 'health-events-dev',
    storageBucket: 'health-events-dev.appspot.com',
    messagingSenderId: '204933489081',
    appId: '1:204933489081:web:9e7ae6088e6ea14d8ad668',
};

const firebaseEnv = GCLOUD_PROJECT === firebaseConfigProd.projectId ? firebaseConfigProd : firebaseConfigDev;

const isProd = true;
const useFunctionEmulator = false;

const isFirebaseProductionProjet = GCLOUD_PROJECT === firebaseConfigProd.projectId;

const host = isProd ? 'https://backoffice.healthevents.fr' : 'localhost:3001';
const lmsHost = isProd ? 'https://lms.healthevents.fr' : 'localhost:3000';
export const env = {
    firebaseEnv,
    host,
    lmsHost,
    isFirebaseProductionProjet,
    debugInfo: true,
    useFunctionEmulator,
    maintenanceKey: 'maintenance',
    isDev: !isProd,
    functionRegion: 'europe-west3',
};
