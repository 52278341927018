import React, { useContext, useMemo, useRef, useState } from 'react';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { BackofficeContext } from '@Context/BackofficeContext';
import { Formation } from '@Types/Formation';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { useNavigate } from 'react-router-dom';
import { DeleteFormation } from '@Pages/pages/components/DeleteFormation';
import { CustomTable } from '@Components/CustomTable';
import { DateTime } from 'luxon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { usePromise } from '@Hooks/promise';
import { useApi } from '@Hooks/api';
import { ColumnSortEvent } from 'primereact/column';
import { fuzzSearch } from '@Utils/search.utils';
import { useUser } from '@Hooks/firebase';
import { v4 } from 'uuid';
import { TieredMenu } from 'primereact/tieredmenu';

export type FormationsProps = Record<string, never>;
export const Formations: React.FC<FormationsProps> = () => {
    const { formations } = useContext(BackofficeContext);
    const [selection, setSelection] = useState<Formation>();
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

    const navigate = useNavigate();
    const ref = useRef<TieredMenu>(null);

    const formationActions = useMemo<DropdownOption[]>(
        () => [
            {
                label: 'Modifier la formation',
                action: () => {
                    if (selection) navigate(`/formations/${selection.formation_id}`);
                },
                icon: 'pi pi-pencil',
            },
            {
                label: 'Prévisualiser la formation',
                action: () => {
                    if (selection) navigate(`/formations/${selection.formation_id}/preview`);
                },
                icon: 'pi pi-eye',
            },
            {
                label: 'Supprimer la formation',
                action: () => {
                    setDeleteVisible(true);
                },
                icon: 'pi pi-trash',
            },
        ],
        [selection]
    );

    const [onDeleteFormation, loadingDelete] = usePromise(
        async (formation_id: string) => {
            const res = await api.formation_call_delete({
                formation_id,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            setDeleteVisible(false);
        },
        {
            pending: 'Suppression en cours...',
            success: 'Formation supprimée !',
        }
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, formation: Formation) => {
        setSelection(formation);
        ref.current?.toggle(e);
    };
    const FormationDropdown = (formation: Formation) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onActionClick(e, formation);
                    }}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const { meta } = useUser(true);

    const api = useApi();

    const [onCreateFormation, loading] = usePromise(async () => {
        const res = await api.formation_call_create({
            formation: {
                formation_id: '',
                modules: [],
                title: 'Nouvelle formation',
                estimated_duration: 0,
                estimated_duration_unit: 'j',
                description: undefined,
                andpc: '-',
                type: 'Evaluation des pratiques professionnelles (EPP)',
                units: [
                    {
                        unit_id: v4(),
                        modules_ids: [],
                    },
                ],
                priority_orientation: '-',
                created_at: '',
                updated_at: '',
            },
        });

        if (res.result !== 'ok') throw new Error(res.result);

        navigate(`/formations/${res.formation_id}`);
    });

    const [filter, setFilter] = useState<string>('');

    const filtered = useMemo(() => {
        const result = fuzzSearch(formations, filter, ['title', 'andpc'], true);
        return result.sort((a, b) => (a.title < b.title ? -1 : 1));
    }, [filter, formations]);

    return (
        <ContentLayout title={'Gestion des formations'} subtitle={'Vous pouvez gérer vos formations'}>
            {selection && deleteVisible && (
                <CustomDialog onHide={() => setDeleteVisible(false)}>
                    <DeleteFormation
                        loading={loadingDelete}
                        formation={selection}
                        onQuit={() => setDeleteVisible(false)}
                        onDelete={() => onDeleteFormation(selection.formation_id)}
                    />
                </CustomDialog>
            )}
            <CustomActionDropdown options={formationActions} ref={ref} />
            <CustomTable
                dataKey={'formation_id'}
                rowHover
                rowClassName="cursor-pointer"
                onRowClick={{
                    click: (item) => navigate(`/formations/${item.formation_id}`),
                    ctrlClick: (item) => window.open(`/formations/${item.formation_id}`, '_blank'),
                }}
                columns={[
                    {
                        header: 'Nom de la formation',
                        size: 450,
                        filter: {
                            type: 'sort',
                            field: 'title',
                        },
                        body: (formation) => <div>{formation.title}</div>,
                    },
                    {
                        header: 'Modules',
                        size: 120,
                        sortFunction(e: ColumnSortEvent) {
                            return (e.data as Formation[]).sort(
                                (a, b) => (e.order || 0) * (a.modules.length - b.modules.length)
                            );
                        },
                        filter: {
                            type: 'sort',
                            field: 'modules',
                        },
                        body: (formation) => <div>{formation.modules.length}</div>,
                    },
                    {
                        header: 'Créé le',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'created_at',
                        },
                        body: (formation) => <div>{DateTime.fromISO(formation.created_at).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        header: 'Modifié le',
                        size: 120,
                        filter: {
                            type: 'sort',
                            field: 'updated_at',
                        },
                        body: (formation) => <div>{DateTime.fromISO(formation.updated_at).toFormat('dd/MM/yyyy')}</div>,
                    },
                    {
                        size: 100,
                        body: meta.role === 'admin' ? FormationDropdown : () => null,
                    },
                ]}
                values={filtered}
                header={{
                    title: 'Liste des formations',
                    actions: [
                        <span className="p-input-icon-left mr-3">
                            <i className="pi pi-search" />
                            <InputText
                                placeholder="Rechercher une formation"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </span>,
                        meta.role === 'admin' ? (
                            <Button
                                label="Créer une formation"
                                icon="pi pi-plus"
                                className={'he-button--primary--md'}
                                onClick={onCreateFormation}
                                loading={loading}
                            />
                        ) : (
                            <></>
                        ),
                    ],
                }}
            />
        </ContentLayout>
    );
};
