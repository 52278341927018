import React, {useEffect, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {ForumSetting} from "@Types/Settings";
import {CustomTable} from "@Components/CustomTable";
import {Button} from "primereact/button";
import {CustomDialog} from "@Components/CustomDialog";
import {InputTextarea} from "primereact/inputtextarea";
import _ from "lodash";
import {useToast} from "@Hooks/toast";

export type ForumSettingsProps = Record<string, never>
export const ForumSettings: React.FC<ForumSettingsProps> = () => {

    const [wordlist, setWordlist] = useState<string[]>([]);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const [newWords, setNewWords] = useState<string>("");

    useEffect(() => {
        onSnapshot(doc(firestore.db, "settings", "forum"), snapshot => {
            const data = snapshot.data() as ForumSetting;
            setWordlist(data.ban_words);
        });

    }, []);

    const newWordsArray = newWords.split("\n").filter(w => w.trim().length > 0);

    const toast = useToast();

    const onAddWords = async () => {
        try {
            await firestore.collection("settings").update("forum", {
                ban_words: _.uniq([...wordlist, ...newWordsArray])
            });
            setDialogVisible(false);
            toast.success("Mots ajoutés avec succès");
        } catch(e) {
            console.error(e);
            toast.error("Erreur lors de l'ajout des mots");
        }
    }

    const onDeleteWord = async (word: string) => {
        try {
            await firestore.collection("settings").update("forum", {
                ban_words: wordlist.filter(w => w !== word)
            });
            toast.success("Mot supprimé avec succès");
        } catch(e) {
            console.error(e);
            toast.error("Erreur lors de la suppression du mot");
        }

    }

    return (
        <div className="flex flex-column w-full gap-2">
            {
                dialogVisible && <CustomDialog onHide={() => setDialogVisible(false)}>
                <div className="flex flex-column w-full gap-2">
                    <div className="he-header--h4">Ajouter de nouveaux mots</div>
                    <div className="flex gap-2">
                        <InputTextarea
                            value={newWords}
                            rows={15}
                            style={{height: 200}}
                            onChange={(e) => setNewWords(e.currentTarget.value)}
                            placeholder={"Passer à la ligne pour ajouter plusieurs mots\nex. \nmot1\nmot2\nmot3\n..."}
                        />
                    </div>
                    <Button className="he-button--primary--xs" disabled={newWordsArray.length === 0} onClick={onAddWords}>
                        Ajouter les {newWordsArray.length} mots
                    </Button>
                </div>
                </CustomDialog>
            }
            <div className="he-header--h3">
                Liste des mots interdits
            </div>
            <div className="flex gap-2">
                <CustomTable
                    header={{
                        title: "",
                        actions: [(values) => {
                            return <Button className="he-button--secondary-variant--xs" onClick={() => setDialogVisible(true)}>
                                <i className="pi pi-plus"/> Nouvelles entrées
                            </Button>
                        }]
                    }}
                    columns={[
                        {
                            header: "N°",
                            body: (word, options) => <div>{options.rowIndex+1}</div>,
                            style: {
                                width: "2rem"
                            },
                            headerStyle: {
                                width: "2rem"
                            }
                        },
                        {
                            header: "Mot",
                            sortable: true,
                            body: (word) => <div>{word.word}</div>
                        },
                        {
                            header: "Actions",
                            style: {
                                width: "3rem"
                            },
                            headerStyle: {
                                width: "3rem"
                            },
                            body: (word) => <div>
                                <Button className="he-button--danger--xs" onClick={() => onDeleteWord(word.word)}>
                                    <i className="pi pi-trash"/>
                                </Button>
                            </div>
                        }
                ]} values={wordlist.map(word => ({word}))} />
            </div>
        </div>
    )
}
