import React from "react";
import {ModuleViewer} from "@Pages/pages/Formations/components/ModuleViewer";
import {Button} from "primereact/button";
import {Formation} from "@Types/Formation";
import {Unit} from "@Types/Unit";
import styled from "styled-components";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const ModuleContainer = styled.div`
  padding: 21px;
  border: 1px solid #EAECF0;
  background: #fff;
  border-radius: 10px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`
export type FormationUnitProps = {
	formation: Formation;
	unit: Unit;
	onAddModule: () => void;
	onChange: (module_id: string, name: string, order: number, visible: boolean) => void;
	onModify: (module: {module_id: string, name?: null | string}, index: number) => void;
	onRemoveModule: (module: {module_id: string, name?: null | string}, index: number) => void;
	onDeletUnit: () => void;
}
export const FormationUnit: React.FC<FormationUnitProps> = (props) => {

    return (
		<DragDropContext
			onDragEnd={e => {
				const current = props.formation.modules.find(m => m.module_id === e.draggableId)!;
				console.log(current, e);
				props.onChange(e.draggableId, current.name || "", (e.destination?.index || 0) + 1, current.visible)
			}}
		>
			{
				props.unit &&
				<Droppable droppableId={props.unit.unit_id}>
					{provided => <div className="FormationUnit" ref={provided.innerRef} {...provided.droppableProps}>
						{
							props.unit.modules_ids.map(mid => props.formation.modules.find(m => mid === m.module_id)).filter(m => Boolean(m)).map((m, i) => m &&
								<Draggable draggableId={m.module_id} index={i} key={m.module_id}>
									{
										providedDraggabble =>
											<ModuleContainer key={m.module_id} className="flex align-items-center" ref={providedDraggabble.innerRef} {...providedDraggabble.draggableProps}>
												<div className=" mr-4" {...providedDraggabble.dragHandleProps}>
													<i className="pi pi-bars gray-400"/>
												</div>
												<ModuleViewer module_id={m.module_id} title={m.name}/>
												<Button className="he-button--secondary-variant-nfb--xs--rounded ml-auto"
														tooltip={`${m.visible ? "Visible" : "Invisible"} dans logs`}
														tooltipOptions={{position: "top"}}
														onClick={() => {
															props.onChange(m.module_id, m.name || "", i+1, !m.visible);
														}}>
													<i className={m.visible ? "pi pi-eye" : "pi pi-eye-slash"}/>
												</Button>
												<Button className="he-button--primary-nfb--xs--rounded ml-1"
														tooltip={"Modifier le module"}
														tooltipOptions={{position: "top"}}
														onClick={() => {
															props.onModify({
																module_id: m.module_id,
																name: m.name
															}, i);
														}}>
													<i className="pi pi-pencil"/>
												</Button>
												<Button className="he-button--secondary-nfb--xs--rounded ml-1 color-red"
														tooltip={"Supprimer le module"}
														tooltipOptions={{position: "top"}}
														onClick={() => {
															props.onRemoveModule({
																module_id: m.module_id,
																name: m.name
															}, i);
														}}
												>
													<i className="pi pi-trash" />
												</Button>
											</ModuleContainer>
									}
								</Draggable>
							)
						}
						{provided.placeholder}
						<div className="flex mt-3">
							<Button disabled={props.formation.units.length === 0} label="Ajouter un module" className="he-button--primary--xs mr-3" icon="pi pi-plus"
									onClick={props.onAddModule}/>
							<Button label="Supprimer l'unité" disabled={props.formation.units.length <= 1} className="he-button--danger-nf--xs" icon="pi pi-trash"
									onClick={props.onDeletUnit}/>
						</div>
					</div>}
				</Droppable>
			}
		</DragDropContext>
    )
}
