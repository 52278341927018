import React, {useContext, useMemo} from "react";
import {Formation} from "@Types/Formation";
import {Line} from "@Components/UI/Line";
import {Button} from "primereact/button";
import {BackofficeContext} from "@Context/BackofficeContext";

export type DeleteFormationProps = {
	formation: Formation;
	onQuit: () => void;
	onDelete: () => Promise<void>;
	loading: boolean;
}
export const DeleteFormation: React.FC<DeleteFormationProps> = props => {

	const {getSessionsOfFormation} = useContext(BackofficeContext);
	const hasSession = useMemo(() => {
		return getSessionsOfFormation(props.formation.formation_id)
	}, [getSessionsOfFormation, props.formation])

	return (
		<div className="DeleteFormation">
			<div className="he-header--h3 inter gray-900 mb-4">
				Supprimer la formation
			</div>
			{
				hasSession.length > 0 ?
					<div className="he-paragraph--regular gray-900">
						<div className="color-red mr-3">
							<i className="pi pi-exclamation-circle mr-2"/>
							Action impossible !
						</div>
						<div className="mt-2">
							La formation est utilisée dans {hasSession.length} sessions !
						</div>
					</div>
					:
					<div className="he-paragraph--regular gray-900">
						Êtes vous sûr de vouloir supprimer le module ?
					</div>
			}
			<Line height={1} className="my-4 bg-gray-200"/>
			<div className="flex justify-content-end align-items-center">
				{
					hasSession.length > 0 ?
						<>
							<Button
								label="Retour"
								className="he-button--secondary-variant-nfb--md mr-2"
								onClick={props.onQuit}
							/>
						</>:
						<>
							<Button
								label="Annuler"
								className="he-button--secondary-variant-nfb--md mr-2"
								onClick={props.onQuit}
							/>
							<Button
								loading={props.loading}
								label="Supprimer"
								className="he-button--primary--md p-button-danger"
								icon="pi pi-trash"
								onClick={props.onDelete}
							/>
						</>
				}
			</div>
		</div>
	)
}
