import React, { PropsWithChildren } from 'react';
import { Module } from '@Types/Module';
import { LeftMenuModule } from '@Pages/pages/components/LeftMenuModule';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';

const Container = styled.div`
    padding: 32px 20px;
    max-width: 1120px;
`;

const ContentContainer = styled.div`
    padding: 28px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
`;

export type ModuleEditorLayoutProps = {
    module?: Module;
    onSave: () => void;
    onAddActivity: () => void;
    isActivity?: boolean;
    onDuplicateActivity: () => void;
    loading: boolean;
};
export const ModuleEditorLayout: React.FC<PropsWithChildren<ModuleEditorLayoutProps>> = (props) => {
    const navigate = useNavigate();

    const onBack = () => {
        navigate('/modules');
    };

    return props.module ? (
        <Container className="w-full h-full overflow-y-auto overflow-x-hidden">
            <LeftMenuModule module={props.module} onAddActivity={props.onAddActivity} />
            <Button
                icon="pi pi-angle-left"
                label="Retour"
                className="he-button--secondary-variant-nfb--xs"
                onClick={onBack}
            />
            <div className="flex align-items-center justify-content-start mb-4">
                <div className="he-header--h2 gray-900">{props.module.title}</div>

                {props.isActivity && (
                    <Button
                        loading={props.loading}
                        label="Dupliquer l'activité"
                        icon="pi pi-clone"
                        className="he-button--secondary-variant-nfb--xs ml-auto"
                        onClick={props.onDuplicateActivity}
                    />
                )}
                <Button
                    loading={props.loading}
                    label="Sauvegarder les changements"
                    icon="pi pi-save"
                    className={classNames('he-button--primary--xs', props.isActivity ? 'ml-3' : 'ml-auto')}
                    onClick={props.onSave}
                />
            </div>
            <ContentContainer className="h-auto flex flex-column">{props.children}</ContentContainer>
        </Container>
    ) : (
        <div className="w-full h-full">Aucun module trouvé</div>
    );
};
