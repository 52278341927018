import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import {resetPasswordSchema} from "@Schemas/auth.schema";
import {CustomInput} from "@Components/CustomInput";
import {Button} from "primereact/button";
import styled from "styled-components";
import {PasswordCheck} from "@Components/PasswordCheck";
import {usePromise} from "@Hooks/promise";
import { useApi } from "@Hooks/api";

export type ResetPasswordProps = Record<string, never>
export const ResetPassword: React.FC<ResetPasswordProps> = () => {


	const navigate = useNavigate();
	const params = useParams<{code: string}>();
	const api = useApi();

	const [onReset, loading] = usePromise(async (values: { password: string }) => {
		try {
			const code = params.code;
			console.log(params);
			if (code) {
				const result = await api.user_call_changePassword({
					password: values.password,
					code
				});
				if (result.result !== "ok") throw new Error(result.result);
				navigate('/login');
			}
		} catch (e) {
			console.table(e);
			throw e;
		}
	}, {
		success: "Votre mot de passe a bien été modifié !",
		error: (err: Error) => err.message,
		pending: "Modification du mot de passe en cours..."
	});



	const formik = useFormik<{ password: string, confirm: string }>({
		initialValues: {
			password: "",
			confirm: ''
		},
		validationSchema: resetPasswordSchema,
		onSubmit: onReset
	});


	function onGoBack() {
		navigate('/login');
	}

	return (
		<div className="w-full h-full flex flex-column justify-content-center align-items-center">
			<FormWrapper className="bg-white">
				<div className="he-header--h1 primary-900 mb-3 text-center">
					Définir un nouveau mot de passe
				</div>
				<div className="he-paragraph--regular gray-600 text-center mb-6">
					Tapez votre nouveau mot de passe ci-dessous.
				</div>
				<CustomInput
					field={"password"}
					formik={formik}
					label={"Mot de passe"}
					placeholder={"Mot de passe"}
					type="password"
					showInlineError={false}
					className={"mb-3"}
				/>
				<CustomInput
					field={"confirm"}
					formik={formik}
					label={"Confirmer le mot de passe"}
					placeholder={"Confirmer le mot de passe"}
					type="password"
					className={"mb-4"}
				/>
				<PasswordCheck password={formik.values.password} />
				<Button
					className="he-button--primary--md w-full mb-3"
					label="Définir comme nouveau mot de passe"
					onClick={() => {
						console.log(formik.errors);
						formik.submitForm();
					}}
					loading={loading}
					disabled={loading}
				/>
				<Button
					className="he-button--secondary-variant-nfb--xs"
					disabled={loading}
					onClick={onGoBack}
				>
					<i className={"pi pi-arrow-left"}/> Retour à la connexion
				</Button>
			</FormWrapper>
		</div>
	)
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 450px;
  height: auto;
  padding: 28px;
`;
