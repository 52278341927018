import React, {useMemo} from "react";
import './PasswordCheck.scss';
export type PasswordCheckProps = {
	password: string;
}
export const PasswordCheck: React.FC<PasswordCheckProps> = (props) => {

	const valid = useMemo(() => {
		return {
			min: /^.{8}/.test(props.password),
			low: /[a-z]/.test(props.password),
			upp: /[A-Z]/.test(props.password),
			num: /\d/.test(props.password),
			spe: /[!@#$%^&*]/.test(props.password)
		}
	}, [props.password]);

    return (
        <div className="PasswordCheck he-paragraph--regular mb-3 gray-600">
	        <p className="mb-1">Votre mot de passe doit comporter :</p>
	        <p className={valid.min ? 'valid' : 'invalid'}>&bull; Au moins 8 caractères </p>
	        <p className={(valid.upp && valid.low) ? 'valid' : 'invalid'}> &bull; Au moins une majuscule et une minuscule</p>
	        <p className={valid.num ? 'valid' : 'invalid'}> &bull; Au moins un chiffre </p>
	        <p className={valid.spe ? 'valid' : 'invalid'}> &bull; Au moins un caractère spécial parmi : ! @ # $ % ^ & *</p>
        </div>
    )
}
