import {CustomTabView} from "@Components/CustomTabView";
import {BackofficeContext} from "@Context/BackofficeContext";
import {Button} from "primereact/button";
import React, {useContext, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {EditFormation} from "./FormationDetails/EditFormation";
import {PreviewFormation} from "./FormationDetails/PreviewFormation";
import {SatisfactionFormation} from "./FormationDetails/SatisfactionFormation";
import {Forum} from "@Pages/pages/Formations/FormationDetails/Forum";
import {Thematique} from "@Pages/pages/Formations/Thematiques/Thematique";

const Container = styled.div`
  padding: 32px 20px;
  width: 100%;
  max-width: 1220px;
	min-width: 1000px;
`;

export type FormationDetailsProps = Record<string, never>
export const FormationDetails: React.FC<FormationDetailsProps> = () => {

	const params = useParams<{ formation_id: string }>();
	const {formations } = useContext(BackofficeContext);
    const navigate = useNavigate();

	const formation = useMemo(() => {
		if (!params.formation_id) return undefined;
		return formations.find(f => f.formation_id === params.formation_id);
	}, [formations, params])


	const onBack = () => {
		navigate('/formations');
	}

    const [tab, setTab] = useState(0);

	return (formation ? <Container className="w-full">
			<Button icon="pi pi-angle-left" label="Retour" className="he-button--secondary-variant-nfb--xs"
			        onClick={onBack}/>
			<div className="flex align-items-center justify-content-start mb-4">
				<div className="he-header--h2 gray-900">
					{formation.title}
				</div>
			</div>
            <CustomTabView
				items={[{
					label: "Informations générales",
				}, {
					label: "Prévisualisation",
				}, {
					label: "Satisfaction"
				}, {
					label: "Forum"
				}, {
					label: "Thématiques"
				}]}
				onChange={setTab}
				selected={tab}
			/>
		{tab === 0 && <EditFormation />}
		{tab === 1 && <PreviewFormation />}
		{tab === 2 && <SatisfactionFormation />}
		{tab === 3 && <Forum formation_id={formation.formation_id}/>}
		{tab === 4 && <Thematique formation_id={formation.formation_id}/>}
		</Container> : <div className="w-full h-full">
			Aucune formation trouvé
		</div>)
}
