import { CustomTabView } from "@Components/CustomTabView";
import { BackofficeContext } from "@Context/BackofficeContext";
import { SessionProvider } from "@Context/SessionContext";
import { useUser } from "@Hooks/firebase";
import { ParticipantList } from "@Pages/pages/Sessions/pages/ParticipantList";
import { ScheduledEvents } from "@Pages/pages/Sessions/pages/ScheduledEvents";
import { SessionSettings } from "@Pages/pages/Sessions/pages/SessionSettings";
import { SessionUnitSettings } from "@Pages/pages/Sessions/pages/SessionUnitSettings";
import { VirtualClasses } from "@Pages/pages/Sessions/pages/VirtualClasses";
import { DateTime } from "luxon";
import { Button } from "primereact/button";
import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: 32px 20px;
  width: 100%;
  max-width: 1120px;
`;

const ContentContainer = styled.div`
  padding: 28px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
`;

const tabMap = {
	participants: 0,
	classes: 1,
	settings: 2,
	units: 3,
	events: 4,
} as const;

export type SessionDetailsProps = {
	page: keyof typeof tabMap;
}
export const SessionDetails: React.FC<SessionDetailsProps> = props => {

	const { sessions, formations, loading} = useContext(BackofficeContext);
	const params = useParams<{ session_id: string }>();
	const [tab, setTab] = useState<number>(tabMap[props.page]);
	const { meta } = useUser(true);


	const navigate = useNavigate();

	const session = useMemo(() => {
		return sessions.find(s => s.session_id === params.session_id);
	}, [sessions])

	const formation = useMemo(() => {
		return formations.find(f => f.formation_id === session?.formation_id);
	}, [formations, session])

	const onBack = () => {
		navigate("/sessions");
	}

	const onTabChange = (page: number) => {
		const pages = Object.fromEntries(Object.entries(tabMap).map(([k, v]) => [v, k]))
		navigate(`/sessions/${params.session_id}/${pages[page]}`);
		setTab(page);
	}

	return (
		(
			loading ? <div className={"w-full h-full flex justify-content-center align-items-center"}>
				<i className="pi pi-spin pi-spinner gray-500" style={{ fontSize: "3rem" }} />
			</div> : (session && formation ?
				<SessionProvider session={session}>
					<Container className="w-full h-full overflow-auto">
						<Button icon="pi pi-angle-left" label="Retour" className="he-button--secondary-variant-nfb--xs"
							onClick={onBack} />
						<div className="align-items-center justify-content-start mb-4">
							<div className="he-header--h2 gray-900">
								{formation.title}
							</div>
							<div className="he-paragraph--regular gray-500 mt-2">
								{formation.andpc} &#x2022;&nbsp;
								{session.session_custom_id} &#x2022;
								Du {DateTime.fromISO(session.start_date).toFormat("dd-MM-yyyy")} au {DateTime.fromISO(session.end_date).toFormat("dd-MM-yyyy")} &#x2022;&nbsp;
								{formation.priority_orientation}
							</div>
						</div>
						<CustomTabView selected={tab} onChange={onTabChange} items={[{
							label: "Apprenants"
						}, ["author", "admin"].includes(meta.role) ? {
							label: "Classes virtuelles"
						} : undefined, ["author", "admin"].includes(meta.role) ? {
							label: "Configuration de la session"
						} : undefined, {
							label: "Configuration des unités"
						}, ["author", "admin"].includes(meta.role) ? {
							label: "Relances automatiques"
						} : undefined]} />
						<ContentContainer className="h-auto flex flex-column mt-4">
							{
								tab === 0 && <ParticipantList session={session} />
							}
							{
								tab === 1 && <VirtualClasses session={session} />
							}
							{
								tab === 2 && <SessionSettings session={session} />
							}
							{
								tab === 3 && <SessionUnitSettings session={session} />
							}
							{
								tab === 4 && <ScheduledEvents session={session} />
							}
						</ContentContainer>
					</Container>
				</SessionProvider>
				: <div className="w-full h-full">
					Aucune formation trouvé
				</div>
			)
		)
	)
}
