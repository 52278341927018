import { UsersContext } from "@Context/UsersContext";
import { Formation } from "@Types/Formation";
import { Module } from "@Types/Module";
import { Session } from "@Types/Session";
import { UserProgress, UserProgressItem } from "@Types/User";
import { firestore } from "@Utils/config/firebase";
import { collection, getCountFromServer } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useState } from "react";


export interface BackofficeContextI {
	modules: Module[],
	sessions: Session[],
	formations: Formation[],

	loading: boolean,

	participantsCount: {[session_id: string]: number},

	getSessionsOfFormation : (formation_id: string) => Session[]

	getModulesOfFormation : (formation_id: string) => Module[]

	getFormationsOfModule : (module_id: string) => Formation[]

	getSessionsOfModule : (module_id: string) => Session[]

	getParticipantProgress: (participant_id: string) => UserProgress | undefined
	getUserProgressInSession: (user_id: string, progress: UserProgressItem[], session_id: string) => number
}

export const BackofficeContext = React.createContext<BackofficeContextI>({
	modules: [],
	formations: [],
	sessions: [],
	participantsCount: {},
	loading: true,
	getFormationsOfModule: () => [],
	getModulesOfFormation: () => [],
	getSessionsOfModule: () => [],
	getSessionsOfFormation: () => [],
	getUserProgressInSession: () => -1,
	getParticipantProgress: (participant_id: string) => undefined
});

export const BackofficeProvider: React.FC<React.PropsWithChildren> = (props) => {

	const {users} = useContext(UsersContext);

	const [modules, setModules] = useState<Module[]>([]);
	const [formations, setFormations] = useState<Formation[]>([]);
	const [sessions, setSessions] = useState<Session[]>([]);
	const [sessionLoading, setSessionLoading] = useState<boolean>(false);
	const [moduleLoading, setModuleLoading] = useState<boolean>(false);
	const [formationLoading, setFormationLoading] = useState<boolean>(false);
	const [participantsCount, setparticipantsCount] = useState<{[session_id: string]: number}>({});
	const [loadingParticipantCount, setloadingParticipantCount] = useState<boolean>(false);

	const loading = sessionLoading || formationLoading || moduleLoading || loadingParticipantCount;


	useEffect(() => {
		setloadingParticipantCount(true);
		for (const session of sessions.filter(s => !s.archived)) {
			getCountFromServer(collection(firestore.db, "formations", session.formation_id, "sessions", session.session_id, "participants"))
			.then(count => setparticipantsCount(prev => ({...prev, [session.session_id]: count.data().count}))).finally(() => setloadingParticipantCount(false))
		}
	}, [sessions])


	useEffect(() => {

		const subs: Array<() => void> = [];

		setSessionLoading(true);
		setModuleLoading(true);
		setFormationLoading(true);

		const unsubSession = firestore.collectionGroup<Session>("sessions", null, snapshot => {

			const docs = snapshot.docs;
			setSessions(docs.map(s => s.data()));
			setSessionLoading(false);
		});
		const unsubModules = firestore.collection("modules").onSnapshotAll<Module>("modules", (snapshot) => {
			if (snapshot.empty) return;
			const moduleList = snapshot.docs.map(doc => doc.data());
			setModules(moduleList);
			setModuleLoading(false);
		});

		const unsubFormations = firestore.collection("formations").onSnapshotAll<Formation>("formations", (snapshot) => {
			if (snapshot.empty) return;
			const moduleList = snapshot.docs.map(doc => doc.data());
			setFormations(moduleList);
			setFormationLoading(false);
		});

		return () => {
			unsubFormations();
			unsubSession();
			unsubModules();
			for (const unsub of subs) {
				unsub();
			}
		}
	}, []);

	const getSessionsOfFormation = useCallback((formation_id: string) => {
		return sessions.filter(s => s.formation_id === formation_id);
	}, [formations, sessions])

	const getModulesOfFormation = useCallback((formation_id: string) => {
		const formation = formations.find(f => f.formation_id === formation_id);
		if (!formation) return [];
		const _modules: Module[] = [];
		formation.modules.forEach(m => {
			const module = _modules.find(mextended => m.module_id === mextended.module_id);
			if (module)
				_modules.push(module);
		})
		return _modules;
	}, [modules, formations]);

	const getFormationsOfModule = useCallback((module_id: string) => {
		return formations.filter(f => f.modules.some(m => m.module_id === module_id));
	}, [formations])

	const getSessionsOfModule = useCallback((module_id: string) => {
		const formationsWithModule = formations.filter(f => f.modules.some(m => m.module_id === module_id)).map(f => f.formation_id);
		return sessions.filter(s => formationsWithModule.includes(s.formation_id));
	}, [formations, modules, sessions])

	const getUserProgressInSession = useCallback((user_id: string, progress: UserProgressItem[], session_id: string) => {

		if (progress.length === 0) return -1;

		const session = sessions.find(s => s.session_id === session_id);
		if (!session) return -1;

		const formation = formations.find(f => f.formation_id === session.formation_id);
		if (!formation) return -1;

		const user = users.find(u => u.user_id === user_id);
		if (!user) return -1;

		const moduleIds = formation.modules.map(m => m.module_id);

		const totalActivities = formation.modules.reduce((a, c) => a + (modules.find(m => m.module_id === c.module_id)?.activities.length || 0) , 0)
		const totalActivitiesDone = progress.filter(p => moduleIds.includes(p.module_id)).filter(p => p.activity.done);

		return Math.min(100 * totalActivitiesDone.length / totalActivities, 100);
	}, [users, formations, sessions, modules])

	const getParticipantProgress = useCallback((participant_id: string) => {
		console.log(participant_id);
		const progress = users.find(user => user.user_id === participant_id)?.progress
		console.log("progress", progress);
		return progress;
	}, [users])

    return <BackofficeContext.Provider value={{
		modules,
	    sessions,
	    formations,
		participantsCount,
	    getSessionsOfFormation,
	    getSessionsOfModule,
	    getModulesOfFormation,
	    getFormationsOfModule,
	    getUserProgressInSession,
	    getParticipantProgress,
	    loading
    }}>
        {props.children}
    </BackofficeContext.Provider>;
}
