import React from "react";
import {useFormik} from "formik";
import {CustomTextEditor} from "@Components/CustomTextEditor";
import {UploadButton} from "@Components/UploadButton";
import {usePromise} from "@Hooks/promise";
import {uploadResource} from "@Utils/import.utils";

export type CourseActivityEditorProps = {
	formik: ReturnType<typeof useFormik<any>>,
}
export const CourseActivityEditor: React.FC<CourseActivityEditorProps> = props => {

	const [onUploadImage, loading] = usePromise(async (file: File) => {
		const id = await uploadResource(file);
		props.formik.setFieldValue('resource_pdf_url', id, false);
	});

	return (
		<div className="mt-4">
			<div className="he-paragraph--medium gray-900">Contenu</div>
			{props.formik && <CustomTextEditor field={"content"} formik={props.formik} className="mt-3"/>}
			<UploadButton
				label="Ajouter un document pdf"
				className="mt-3"
				buttonClassName="he-button--primary-nf--xs"
				icon="pi pi-download"
				file_id={props.formik.values.resource_pdf_url}
				accept={['application/pdf']}
				loading={loading}
				onChange={onUploadImage}
			/>
		</div>
	)
}
