import * as React from 'react';
import {useEffect, useState} from 'react';
import {Theme} from "@Types/Theme";
import {CustomTabView} from "@Components/CustomTabView";
import {collection, onSnapshot} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {sortBy} from "lodash";
import styled from "styled-components";
import {ThemeConfig} from "@Pages/pages/Formations/Thematiques/ThemeConfig";
import {ThemeAssociation} from "@Pages/pages/Formations/Thematiques/ThemeAssociation";

interface Props {
    formation_id: string;
}
export const Thematique: React.FC<Props> = (props) => {

    const [loadingThemes, setLoadingThemes] = useState<boolean>(true);
    const [themes, setThemes] = useState<Theme[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const tabs = [{
        label: "Configuration thématiques"
    }, {
        label: "Association activités"
    }]

    useEffect(() => {
        const themeRef = collection(firestore.db, "formations", props.formation_id, "themes");
        return onSnapshot(themeRef, snapshot => {
            const themes = snapshot.docs.map(doc => doc.data() as Theme);
            setThemes(sortBy(themes, "created_at"))
            setLoadingThemes(false);
        });
    }, [props.formation_id]);

    return (
        <Container className="w-full h-full overflow-auto">
            <ContentContainer className="flex-column">
                <div className="he-header--h3">Thématiques de la formation</div>
                <span className="he-paragraph--small gray-400">Vous pouvez configurer ici les thématiques et les rattacher à vos activités.</span>
                <CustomTabView tab_id="theme_tab" selected={selectedTab} onChange={setSelectedTab} items={tabs}
                               className="mb-2"/>
                {loadingThemes && <div className="w-full text-center p-6">
                    <i className="pi pi-spin pi-spinner"/>
                </div>}
                {selectedTab === 0 && <ThemeConfig themes={themes} formation_id={props.formation_id}/>}
                {selectedTab === 1 && <ThemeAssociation themes={themes} formation_id={props.formation_id}/>}
            </ContentContainer>
        </Container>
    );

}

const Container = styled.div`
    padding: 32px 24px;
    width: 100%;
    max-width: 1220px;
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    background: #ffffff;
    font-family: 'roboto';
    border: 1px solid #eaecf0;
    border-radius: 10px;
`;

