import { CustomFormDropdown } from '@Components/CustomFormDropdown';
import { CustomInput } from '@Components/CustomInput';
import { useUser } from '@Hooks/firebase';
import { useOnUnmount } from '@Hooks/lifecycle';
import { createUserSchema } from '@Schemas/user.schema';
import { User } from '@Types/User';
import { userRoleMap } from '@Utils/users.utils';
import { useFormik } from 'formik';
import { forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, minmax(0, auto));
    grid-gap: 14px;
    max-width: 800px;
`;

const HalfItem = styled.div`
    grid-column: span 1;
`;
const FullItem = styled.div`
    grid-column: span 2;
`;

export type UpdateUserProps = {
    user: User;
    onUpdateUser: (user: User) => void;
};
export const UpdateUser = forwardRef<{ submit: () => void }, UpdateUserProps>((props, ref) => {
    const user = useUser(true);

    const userFormik = useFormik({
        initialValues: props.user,
        validationSchema: createUserSchema,
        onSubmit: (value) => {
            console.log(value);
            props.onUpdateUser(value);
        },
        enableReinitialize: true,
    });

    useImperativeHandle(ref, () => ({
        submit() {
            console.log('Submit request received');
            userFormik.submitForm().catch((e) => {
                console.error(e);
            });
            console.log(userFormik.errors);
        },
    }));

    useOnUnmount(userFormik.resetForm);
    return (
        <FormContainer className="w-full mt-3">
            {/* LINE */}
            <HalfItem>
                <CustomFormDropdown
                    disabled={user.meta.role !== 'admin'}
                    field="role"
                    label="Rôle"
                    formik={userFormik}
                    options={Object.entries(userRoleMap).map(([role, translation]) => ({
                        label: translation,
                        value: role,
                    }))}
                />
            </HalfItem>
            <HalfItem>
                <CustomFormDropdown
                    label="Autorisation relance"
                    formik={userFormik}
                    field="accept_mailings"
                    options={[
                        {
                            label: 'Toutes',
                            value: 3,
                        },
                        {
                            label: 'Relance classe virtuelle',
                            value: 4,
                        },
                        {
                            label: 'Relance attestation',
                            value: 2,
                        },
                        {
                            label: 'Relance session',
                            value: 1,
                        },
                        {
                            label: 'Aucune',
                            value: 0,
                        },
                    ]}
                />
            </HalfItem>
            <HalfItem>
                <CustomInput placeholder={'ex. Dubois'} field={'lastname'} label={'Nom'} formik={userFormik} />
            </HalfItem>
            <HalfItem>
                <CustomInput placeholder={'ex. Camille'} field={'firstname'} label={'Prénom'} formik={userFormik} />
            </HalfItem>
            {/* LINE */}
            <HalfItem>
                <CustomInput
                    placeholder={'ex. camille.dubois@mail.com'}
                    field="email"
                    formik={userFormik}
                    label="Adresse e-mail"
                />
            </HalfItem>
            <HalfItem>
                <CustomInput
                    placeholder={'ex. 06 06 20 15 00'}
                    field="phone"
                    formik={userFormik}
                    label="Numéro de téléphone"
                />
            </HalfItem>
            {/* LINE */}
            <HalfItem>
                <CustomInput
                    disabled
                    placeholder={'ex. ABCDE000000'}
                    field="RPPS"
                    formik={userFormik}
                    label="Numéro RPPS / ADELI"
                />
            </HalfItem>
            <HalfItem />
            {/* LINE */}
            <HalfItem>
                <CustomInput placeholder={'ex. Médecin'} field={'job'} label={'Profession'} formik={userFormik} />
            </HalfItem>
            <HalfItem>
                <CustomInput
                    placeholder={'ex. libéral'}
                    field={'exercise_mode'}
                    label={"Mode d'exercice"}
                    formik={userFormik}
                />
            </HalfItem>
            {/* LINE */}
            <FullItem>
                <CustomInput
                    placeholder={'ex. 523, Grand Rue - 35000 Rennes'}
                    field="address"
                    formik={userFormik}
                    label="Adresse postale"
                />
            </FullItem>
        </FormContainer>
    );
});
