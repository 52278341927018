import XLSX from "sheetjs-style";
import {downloadUint8} from "@Utils/download.utils";
import {SessionParticipant} from "@Types/Session";
import {Satisfaction} from "@Types/Satisfaction";


const satisfactionLabels = {
    formation: [
        'Communication des objectifs et du programme avant la formation',
        'Organisation et déroulement de la formation',
        'Adéquation des moyens matériels mis à disposition',
        'Conformité de la formation dispensée au programme',
        'Clarté du contenu',
        'Qualité des supports pédagogiques',
        'Animation de la formation par le ou les intervenants',
        'Progression de la formation (durée, rythme, alternance théorie/pratique)',
    ],
    format: ['Le format e-learning est-il idéal pour vous ?', 'Que pensez-vous de la durée de la formation ?'],
    you: [
        'Participez-vous souvent à des formations DPC ?',
        'Comment connaissez-vous les formations auxquelles vous participez ?',
        'Quelle thématique souhaiteriez-vous pour une prochaine formation ?',
    ],
};

const satisfactionScale = {
    formation: ['1', '2', '3', '4'],
    format: [
        ['yes', 'no'],
        ['ok', 'long', 'short'],
    ],
    youAndFormation: [
        ['rarely', 'sometimes', 'often', 'regularly'],
        ['mail', 'magazine', 'colleague', 'other'],
    ]
};

const satisfactionAnswersLabels: {
    formation: Record<string, string>;
    format: Record<string, string>;
    youAndFormation: [
        Record<string, string>,
        Record<string, string>,
    ]
} = {
    formation: {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        "null": 'N/A',
    },
    format: {
        yes: 'Oui',
        no: 'Non',
        ok: 'Correcte',
        long: 'Trop longue',
        short: 'Trop courte',
        null: 'N/A',
    },
    youAndFormation: [
        {
            rarely: 'Peu',
            sometimes: 'Parfois',
            often: 'Souvent',
            regularly: 'Régulièrement',
            undefined: 'N/A'
        },
        {
            mail: 'E-mailing',
            magazine: 'Magazines spécialisés',
            colleague: 'Confrères',
            other: 'Autre',
            null: 'N/A',
        }
    ]
}
export const generateGlobalStatistics = (participants: (SessionParticipant & {satisfaction: Satisfaction})[]) => {
    if (!participants) return;
    const groupBy = (satisfaction: any[], scale: string[]) => {
        return satisfaction.reduce<{ [key: string]: number }>((acc, note) => {
            const key = note === null ? 'null' : note.toString();
            if (!acc[key]) acc[key] = 0;
            acc[key]++;
            return acc;
        }, scale.reduce<{ [key: string]: number }>((acc, note) => ({ ...acc, [note]: 0 }), {}));
    }



    const evaluation = satisfactionLabels.formation.map((label, i) => {
        const res: Record<string, any> = {
            "Question": label,
        };
        const satisfactionFormation = participants.map((p) => p.satisfaction.formation[i])
        const groupedByNote = groupBy(satisfactionFormation, satisfactionScale.formation);
        Object.entries(groupedByNote).forEach(([note, count]) => {
            const labelForNote = satisfactionAnswersLabels.formation[note.toString()];
            res[labelForNote] = count;
        })
        return res;
    })

    const format = satisfactionLabels.format.map((label, i) => {

        const res: Record<string, any> = {
            "Question": satisfactionLabels.format[i],
        };

        const satisfactionFormat = participants.map((p) => p.satisfaction.format[i])
        const groupedByNote = groupBy(satisfactionFormat, satisfactionScale.format[i]);
        Object.entries(groupedByNote).forEach(([note, count]) => {
            const labelForNote = satisfactionAnswersLabels.format[note.toString()];
            res[labelForNote] = count;
        })

        return res;
    })

    const getMean = (satisfaction: Satisfaction) => {
        return satisfaction.formation.reduce((acc, curr) => acc + curr, 0) / satisfaction.formation.length;
    };

    const satisfactionGlobale = [
        ["Note globale", "Nombre de participants"],
        [
            new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
            participants
            .reduce((acc, curr) => acc + getMean(curr.satisfaction), 0) / participants.length)
            ,
            participants.length
        ],
    ]

    const youAndFormationFirst: Record<string, any> = {
        "Question": satisfactionLabels.you[0],
    };

    const youAndFormationSecond: Record<string, any> = {
        "Question": satisfactionLabels.you[1],
    };

    const satisfactionYouAndFormation = participants.map((p) => p.satisfaction.youAndFormation[0].option);
    const satisfactionYouAndFormationSecond = participants.map((p) => p.satisfaction.youAndFormation[1].option);

    const groupedByAnswersFirst = groupBy(satisfactionYouAndFormation, satisfactionScale.youAndFormation[0]);
    const groupedByAnswersSecond = groupBy(satisfactionYouAndFormationSecond, satisfactionScale.youAndFormation[1]);

    Object.entries(groupedByAnswersFirst).forEach(([note, count]) => {
        const labelForNote = satisfactionAnswersLabels.youAndFormation[0][note.toString()];
        youAndFormationFirst[labelForNote] = count;
    })

    Object.entries(groupedByAnswersSecond).forEach(([note, count]) => {
        const labelForNote = satisfactionAnswersLabels.youAndFormation[1][note.toString()];
        youAndFormationSecond[labelForNote] = count;
    })

    const formationSheet = XLSX.utils.json_to_sheet(evaluation, {
        header: ['Question', ...Object.values(satisfactionAnswersLabels.formation)],
    });

    const formatSheet = XLSX.utils.aoa_to_sheet([
        ['Question', 'Oui', 'Non', 'N/A'],
        [format[0]['Question'], format[0]['Oui'], format[0]['Non'], format[0]['N/A']],
        [],
        ['Question', 'Correcte', 'Trop longue', 'Trop courte', 'N/A'],
        [format[1]['Question'], format[1]['Correcte'], format[1]['Trop longue'], format[1]['Trop courte'], format[1]['N/A']],
    ]);

    const youAndFormationSheet = XLSX.utils.aoa_to_sheet([
        ['Question', 'Peu', 'Parfois', 'Souvent', 'Régulièrement'],
        [youAndFormationFirst['Question'], youAndFormationFirst['Peu'], youAndFormationFirst['Parfois'], youAndFormationFirst['Souvent'], youAndFormationFirst['Régulièrement']],
        [],
        ['Question', 'E-mailing', 'Magazines spécialisés', 'Confrères', 'Autre', 'N/A'],
        [youAndFormationSecond['Question'], youAndFormationSecond['E-mailing'], youAndFormationSecond['Magazines spécialisés'], youAndFormationSecond['Confrères'], youAndFormationSecond['Autre'], youAndFormationSecond['N/A']],
    ]);

    const buff = XLSX.write({
        Sheets: {
            'Satisfaction globale': XLSX.utils.aoa_to_sheet(satisfactionGlobale),
            'Evaluation de la formation': formationSheet,
            'Format': formatSheet,
            'Vous et les formations': youAndFormationSheet,
        },
        SheetNames: ["Satisfaction globale", 'Evaluation de la formation', 'Format', 'Vous et les formations'],
    }, {
        bookType: 'xlsx',
        type: 'array',
    });

    return downloadUint8(new Uint8Array(buff), `Statistiques globales.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

}
