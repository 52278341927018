import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomTable } from '@Components/CustomTable';
import { UserBadge } from '@Components/UI/UserBadge';
import { UserRoleBadge } from '@Components/UI/UserRoleBadge';
import { UsersContext } from '@Context/UsersContext';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { UserCopyButton } from '@Pages/pages/components/UserCopyButton';
import { UserInformation } from '@Pages/pages/components/UserInformation';
import { ContentLayout } from '@Pages/pages/layout/ContentLayout';
import { AddUser } from '@Pages/pages/Users/AddUser';
import { ImportUsers } from '@Pages/pages/Users/ImportUsers';
import { User } from '@Types/User';
import { useDebounce } from '@uidotdev/usehooks';
import { fuzzSearch } from '@Utils/search.utils';
import { userRoleMap } from '@Utils/users.utils';
import _ from 'lodash';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

export type UsersProps = Record<string, never>;
export const Users: React.FC<UsersProps> = () => {
    const { users } = useContext(UsersContext);

    const [selection, setSelection] = useState<User>();
    const [userAddVisible, setUserAddVisible] = useState<boolean>(false);
    const [importUserVisible, setImportUserVisible] = useState<boolean>(false);
    const [userInformationVisible, setUserInformationVisible] = useState<boolean>(false);

    const api = useApi();

    const ref = useRef<TieredMenu>(null);
    const { meta } = useUser(true);

    const [onSendResetPassword] = usePromise(
        async (user: User) => {
            await api.user_call_reset({
                email: user.email,
            });
        },
        {
            pending: 'Envoi du mail de réinitialisation en cours ...',
            success: 'Mail de réinitialisation envoyé',
        }
    );

    const [onCancelInvite] = usePromise(
        async (user: User) => {
            await api.user_call_cancelInvite({
                user_id: user.user_id,
            });
        },
        {
            pending: "Suppression de l'utilisateur en cours ...",
            success: 'Utilisateur supprimé !',
        }
    );

    const [onResendPassword] = usePromise(
        async (user: User) => {
            await api.user_call_resendInvitation({
                user_id: user.user_id,
            });
        },
        {
            pending: "Envoi du mail d'inscription en cours ...",
            success: 'Mail envoyé',
        }
    );

    const [onSendNewPassword] = usePromise(
        async (user: User) => {
            await api.user_call_setRandomPassword({
                user_id: user.user_id,
            });
        },
        {
            pending: 'Envoi des identifiants par défaut en cours ...',
            success: 'Identifiants envoyés',
        }
    );

    const usersActions: DropdownOption[] = useMemo(
        () => [
            {
                label: "Voir la fiche de l'apprenant",
                action: () => {
                    setUserInformationVisible(true);
                },
                icon: 'pi pi-file',
            },
            ...(selection && !selection.verified && ['author', 'admin'].includes(meta.role)
                ? [
                      {
                          label: "Annuler l'invitation",
                          icon: 'pi pi-trash',
                          action: () => {
                              confirmDialog({
                                  header: "Annuler l'invitation",
                                  message: "Êtes-vous sûr de vouloir annuler l'invitation de cet utilisateur ?",
                                  acceptLabel: "Annuler l'invitation",
                                  rejectLabel: 'Non',
                                  acceptClassName: 'p-button-danger',
                                  visible: true,
                                  accept() {
                                      selection && onCancelInvite(selection);
                                  },
                              });
                          },
                      },
                  ]
                : []),
            {
                label: 'Réinitialiser le mot de passe',
                action: () => {
                    confirmDialog({
                        header: 'Réinitialiser le mot de passe',
                        message: 'Êtes-vous sûr de vouloir réinitialiser le mot de passe de cet utilisateur ?',
                        acceptLabel: 'Réinitialiser',
                        rejectLabel: 'Non',
                        acceptClassName: 'p-button-danger',
                        visible: true,
                        accept() {
                            selection && onSendResetPassword(selection);
                        },
                    });
                },
                icon: 'pi pi-refresh',
            },
            {
                label: "Renvoyer le mail d'inscription",
                action: () => {
                    confirmDialog({
                        header: "Renvoyer le mail d'inscription",
                        message: "Êtes-vous sûr de vouloir renvoyer le mail d'inscription à cet utilisateur ?",
                        acceptClassName: 'p-button-warning',
                        acceptLabel: 'Envoyer',
                        rejectLabel: 'Non',
                        visible: true,
                        accept() {
                            selection && onResendPassword(selection);
                        },
                    });
                },
                icon: 'pi pi-envelope',
            },
            {
                label: 'Envoyer des identifiants par défaut',
                icon: 'pi pi-envelope mt-1',
                action: () => {
                    confirmDialog({
                        header: 'Envoyer des identifiants par défaut',
                        message:
                            "Cet action va envoyer un mail à l'utilisateur avec ses identifiants par défaut. Êtes-vous sûr de vouloir continuer ?",
                        acceptClassName: 'p-button-warning',
                        acceptLabel: 'Envoyer',
                        rejectLabel: 'Non',
                        visible: true,
                        accept() {
                            selection && onSendNewPassword(selection);
                        },
                    });
                },
            },
        ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, user: User) => {
        setSelection(user);
        ref.current?.toggle(e);
    };

    const UserDropdown = (user: User) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, user)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const [filter, setFilter] = useState<string>('');
    const debounce = useDebounce(filter, 700);

    const [filtered, setFiltered] = useState<User[]>([]);

    useEffect(() => {
        setFiltered(
            fuzzSearch(users, filter, ['RPPS', 'job', 'email', 'firstname', 'lastname', 'exercise_mode'], true)
        );
    }, [debounce, users]);

    return (
        <ContentLayout title={'Gestion des utilisateurs'} subtitle={'Vous pouvez gérer vos utilisateurs'}>
            <CustomActionDropdown options={usersActions} ref={ref} />
            {userAddVisible && (
                <CustomDialog onHide={() => setUserAddVisible(false)}>
                    <AddUser onQuit={() => setUserAddVisible(false)} />
                </CustomDialog>
            )}
            {importUserVisible && (
                <CustomDialog onHide={() => setImportUserVisible(false)}>
                    <ImportUsers onQuit={() => setImportUserVisible(false)} />
                </CustomDialog>
            )}
            <ConfirmDialog />
            {selection && userInformationVisible && (
                <CustomDialog onHide={() => setUserInformationVisible(false)} width={1340}>
                    <UserInformation user={selection} onQuit={() => setUserInformationVisible(false)} />
                </CustomDialog>
            )}
            <CustomTable
                dataKey={'user_id'}
                values={filtered}
                header={{
                    title: 'Liste des utilisateurs',
                    actions: [
                        (items) => (
                            <div className="mr-3">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText
                                        placeholder="Rechercher un utilisateur"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                </span>
                            </div>
                        ),
                        () =>
                            ['admin', 'author'].includes(meta.role) ? (
                                <div className="flex">
                                    <Button
                                        className="he-button--primary-nf--md mr-3"
                                        label="Importer des utilisateurs"
                                        onClick={() => setImportUserVisible(true)}
                                        icon="pi pi-plus"
                                    />
                                    <Button
                                        className="he-button--primary--md"
                                        label="Ajouter un utilisateur"
                                        onClick={() => setUserAddVisible(true)}
                                        icon="pi pi-plus"
                                    />
                                </div>
                            ) : (
                                <></>
                            ),
                    ],
                }}
                columns={[
                    {
                        header: "Nom de l'utilisateur",
                        body: (user) => <UserBadge user={user} />,
                        size: 250,
                        filter: {
                            type: 'sort',
                            field: 'firstname',
                        },
                    },
                    {
                        header: 'Statut utilisateur',
                        size: 120,
                        body: (item) =>
                            item.verified ? (
                                <Badge value="INSCRIT" severity="success" />
                            ) : (
                                <Badge value="EN ATTENTE" severity="info" />
                            ),
                        filter: {
                            type: 'sort',
                            field: 'verified',
                        },
                    },
                    {
                        header: "Rôle de l'utilisateur",
                        size: 130,
                        body: (user) => <UserRoleBadge role={user.role} />,
                        filter: {
                            type: 'select',
                            field: 'role',
                            filterOptions: (users) =>
                                _.uniqBy<{ value: any; label: string }>(
                                    users.map((user) => ({
                                        label: userRoleMap[user.role],
                                        value: user.role,
                                    })),
                                    'value'
                                ),
                            filterItemTemplate: (option) => <UserRoleBadge role={option.value} />,
                        },
                    },
                    {
                        header: 'Liens',
                        size: 150,
                        body: (user) => <UserCopyButton user={user} />,
                    },
                    {
                        header: 'Numéro RPPS',
                        size: 150,
                        body: (user) => <span className="he-paragraph--regular gray-600">{user.RPPS}</span>,
                        filter: {
                            type: 'select',
                            field: 'RPPS',
                            filterOptions: (users) =>
                                users.map((user) => ({
                                    label: user.RPPS,
                                    value: user.RPPS,
                                })),
                            searchInput: true,
                            filterItemTemplate: (option) => <div>{option.value}</div>,
                        },
                    },
                    {
                        size: 100,
                        frozen: true,
                        alignFrozen: 'right',
                        body: UserDropdown,
                    },
                ]}
            />
        </ContentLayout>
    );
};
