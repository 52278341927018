import React, {useState} from "react";
import {SelectButton} from "primereact/selectbutton";

import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {RecurringEvent} from "@Types/ScheduledEvent";
import {produce} from "immer";
import {Session} from "@Types/Session";
import {Line} from "@Components/UI/Line";
import {usePromise} from "@Hooks/promise";
import {doc, setDoc} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {Calendar} from "primereact/calendar";
import {DateTime} from "luxon";
import {InputNumber} from "primereact/inputnumber";
import {Nullable} from "primereact/ts-helpers";

const days = ["L", "M", "M", "J", "V", "S", "D"];

export type EditRecurringEventProps = {
    event: RecurringEvent;
    session: Session;
    onQuit: () => void;
}
export const EditRecurringEvent: React.FC<EditRecurringEventProps> = (props) => {

    const [eventType, setEventType] = useState<number>(props.event.mail_type === 99 ? 1 : 0);
    const [mailType, setMailType] = useState<number | null>(props.event.mail_type === 99 ? null : props.event.mail_type);
    const [selectedUnit, setSelectedUnit] = useState<string | null>(props.event.unit_id || null);
    const [minDelay, setMinDelay] = useState<number | null>(props.event.delay || null);

    const itemTemplate = (option: any) => {
        return <div className="he-paragraph--regular">{option.label}</div>
    }

    const [event, setEvent] = useState<RecurringEvent>(props.event);

    const [onCreateEvent, loading] = usePromise(async (unit_id: string, eventType: number, mailType: number | null, event: RecurringEvent, hour: Nullable<Date>, minDelay: number | null) => {

        await setDoc(doc(firestore.db, `events/${event.event_id}`), {
            ...event,
            unit_id,
            delay: minDelay,
            hour: (hour instanceof Date) ? DateTime.fromJSDate(hour).setZone("Europe/Paris").toISO() : null,
            mail_type: eventType === 0 ? (mailType ?? 0) : 99,
        } satisfies RecurringEvent)

        props.onQuit();
    }, {
        pending: "Programmation des mails en cours...",
        success: "Les relances ont bien été programmées."
    });


    const [hour, setHour] = useState<Nullable<Date>>(event.hour ? DateTime.fromISO(event.hour).setZone("Europe/Paris").toJSDate() : DateTime.now().setZone("Europe/Paris").set({ hour: 9, minute: 0 }).toJSDate());

    return (
        <div className="EditRecurringEvent">
            <div className="he-header--h3">
                Modifier l'évènement
            </div>
            <div className="he-paragraph--regular mt-5 gray-900">
                Sélectionnez le type de relance :
            </div>
            <SelectButton className="mt-3" value={eventType} onChange={e => setEventType(e.value)}
                options={[{
                    label: `Relance session`,
                    value: 0
                }, {
                    label: "Relance attestation",
                    value: 1
                }]}
                itemTemplate={itemTemplate} />
            <Line height={1} className={"bg-gray-200 my-4"} />
            {
                eventType === 0 && <>
                    <div className="he-paragraph--regular gray-900">
                        Sélectionnez l'unité :
                    </div>
                    <SelectButton className="mt-3" value={selectedUnit} onChange={e => setSelectedUnit(e.value)}
                        options={props.session?.unitsConfig.map((e, i) => ({
                            label: `Unité ${i + 1}`,
                            value: e.unit_id
                        }))}
                        itemTemplate={itemTemplate} />
                    <div className="he-paragraph--regular mt-5 gray-900">
                        Sélectionnez un type de relance :
                    </div>
                    <SelectButton className="mt-3" value={mailType} onChange={e => setMailType(e.value)}
                        options={[{ label: "Relance", value: 0 }, { label: "Relance fin d'unité", value: 1 }]}
                        itemTemplate={itemTemplate} />
                </>
            }
            {
                eventType === 1 && <>
                    <div className="he-paragraph--regular gray-900">
                        Sélectionnez l'unité :
                    </div>
                    <SelectButton className="mt-3" value={selectedUnit} onChange={e => setSelectedUnit(e.value)}
                        options={props.session?.unitsConfig.map((e, i) => ({
                            label: `Unité ${i + 1}`,
                            value: e.unit_id
                        }))}
                        itemTemplate={itemTemplate} />
                </>
            }
            <Line height={1} className={"bg-gray-200 my-4"} />
            <div className="he-paragraph--regular">
                Jours de relance
            </div>
            <div className="flex justify-content-between pr-8">
                {days.map((d, i) => {
                    return <div className="flex flex-column align-items-center mt-4" key={d + i}>
                        <div className="mb-2 gray-500">
                            {d}
                        </div>
                        <div>
                            <Checkbox checked={event.days.includes(i)} onChange={() => setEvent(produce(draft => {
                                if (draft.days.includes(i)) {
                                    draft.days = draft.days.filter(d => d !== i);
                                } else {
                                    draft.days.push(i);
                                }
                            }))} />
                        </div>
                    </div>
                })}
            </div>
                <Line height={1} className={"bg-gray-200 my-4"} />
            <div className="flex">
                <div>
                    <div className="he-paragraph--regular">
                        Heure de la relance
                    </div>
                <Calendar timeOnly showTime hourFormat={"24"} dateFormat={""} className={"mt-2"} value={hour} onChange={e => setHour(e.value)} stepMinute={10} />
                </div>
                <div className="ml-3">
                    <div className="he-paragraph--regular">
                        Contrainte activité <span className={"he-paragraph--xs gray-400"}>(optionnel)</span>
                    </div>
                    <InputNumber suffix=" heure(s)" value={minDelay} onChange={e => setMinDelay(e.value)} placeholder={"ex. 48 heures"} className={"mt-2"} />
                </div>
            </div>
            <div className="flex justify-content-end mt-4">
                <Button className="he-button--secondary-variant-nfb--md mr-2" loading={loading} label="Annuler"
                    onClick={props.onQuit} />
                <Button
                    disabled={eventType === 0 ? (mailType === null || selectedUnit === null) : selectedUnit === null}
                    loading={loading}
                    className="he-button--primary--md"
                    icon="pi pi-clock" label={"Programmer"}
                    onClick={() => (eventType === 0 ? (selectedUnit !== null && mailType !== null) : selectedUnit !== null) && onCreateEvent(selectedUnit!, eventType, mailType, event, hour, minDelay)} />
            </div>
        </div>
    )
}
