import React from "react";
import {useFormik} from "formik";
import {VideoActivity} from "@Types/Activity";
import {CustomInput} from "@Components/CustomInput";
import ReactPlayer from "react-player";

export type VideoActivityEditorProps = {
	formik: ReturnType<typeof useFormik<any>>,
}
export const VideoActivityEditor: React.FC<VideoActivityEditorProps> = props => {

	return (
		<div className="mt-4">
			<div className="he-paragraph--medium gray-900">Insérer une vidéo</div>
			<CustomInput placeholder={"ex: https://vimeo.com/64760211 ou 64760211"} field={"video_link" satisfies keyof VideoActivity} formik={props.formik} className="mt-3"/>
			{props.formik.values.video_link &&
                <ReactPlayer url={props.formik.values.video_link} className="mt-3" onError={err => {
					console.table(err)
				}} controls/>}
		</div>
	)
}
