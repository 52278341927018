import { classNames } from "primereact/utils";
import {RadioButton} from "primereact/radiobutton";

export type RadioGroupProps<T> = {
	options: {
		label: string;
		value: T;
	}[];
	name: string;
	onChange: (selected: T) => void;
	value: T
	className?: string;
}
export const RadioGroup = function<T>(props: RadioGroupProps<T>) {

    return (
	    <div className={classNames("flex", props.className)}>
		    {
				props.options.map((o,i) =>
					<div className="mr-3" key={i+o.label}>
						<RadioButton className="mr-2" id={props.name+props.value} value={o.value} name={props.name} onChange={e => props.onChange(e.value)} checked={o.value === props.value}/>
						<label className="he-paragraph--regular gray-700" htmlFor={props.name+props.value}> {o.label} </label>
					</div>
				)
		    }
	    </div>
    )
}
