import React from "react";
import {CustomInput} from "@Components/CustomInput";
import {useFormik} from "formik";
import {CustomInputNumber} from "@Components/CustomInputNumber";
import {Line} from "@Components/UI/Line";
import {ModuleViewer} from "@Pages/pages/Formations/components/ModuleViewer";
import {Button} from "primereact/button";

export type UpdateModuleProps = {
	module_id: string;
	name?: null | string;
	position?: number;
	onSave: (module_id: string, name?: null | string, position?: number) => void;
	onCancel: () => void;
}
export const UpdateModule: React.FC<UpdateModuleProps> = (props) => {

	const moduleFormik = useFormik({
		initialValues: {
			module_id: props.module_id,
			name: props.name || "",
			order: props.position
		},
		enableReinitialize: true,
		onSubmit: (value) => props.onSave(value.module_id, value.name, value.order)
	})

    return (
        <div className="UpdateModule">
	        <div className="he-paragraph--medium gray-900"> Modifier le module </div>
	        <div className="mt-3">
		        <CustomInput
			        label="Titre du module dans la formation"
			        field="name"
			        formik={moduleFormik}
			        />
	        </div>
	        <div className="mt-3">
		        <CustomInputNumber
			        label="Position dans la liste des modules"
			        field="order"
			        formik={moduleFormik}
		        />
	        </div>
	        <Line height={1} className="bg-gray-200 my-4" />
	        <div className="he-paragraph--medium gray-900">
		        Aperçu
	        </div>
	        <div className="w-full mt-3">
		        <ModuleViewer extended module_id={moduleFormik.values.module_id}/>
	        </div>
	        <Line height={1} className="bg-gray-200 my-4" />
	        <div className="flex justify-content-end">
		        <Button className="he-button--secondary-variant-nfb--md mr-2" label="Annuler" onClick={props.onCancel}/>
		        <Button className="he-button--primary--md" icon="pi pi-save" label="Modifier" onClick={moduleFormik.submitForm}/>
	        </div>
        </div>
    )
}
