import {useCallback, useRef, useState} from "react";
import {Flip, toast} from "react-toastify";

type PromiseGenerator<T, A extends Array<any>> = (...args: A) => Promise<T>

export const usePromise = <T, A extends Array<any>>(promise: PromiseGenerator<T, A>, toastMessages?: Partial<{
	success: string,
	error: string | ((err: Error) => string),
	pending: string
}>): [promise: (...args: A) => Promise<T>, loading: boolean] => {

	const [loading, setLoading] = useState<boolean>(false);
	const toastId = useRef<any>(null);

	const cb = useCallback((...args: A) => {

		if (toastMessages?.pending) {
			toastId.current = toast(toastMessages.pending, {
				position: "top-right",
				autoClose: false,
				closeButton: false,
				type: "info",
				theme: 'colored',
				transition: Flip,
			});
		}

		setLoading(true);
		return promise(...args).then(res => {
			if (toastMessages?.success) {
				toast.update(toastId.current, {
					type: "success",
					autoClose: 2500,
					closeButton: true,
					render: toastMessages?.success
				})
			}
			return res;
		}).catch(err => {
			if (toastMessages?.error) {
				if (typeof toastMessages.error === "string") {
					toast.update(toastId.current, {
						type: "error",
						autoClose: 5000,
						closeButton: true,
						render: toastMessages?.error
					})
				} else {
					toast.update(toastId.current, {
						type: "error",
						autoClose: 5000,
						closeButton: true,
						render: toastMessages.error(err.message)
					})
				}
			} else {
				toast.update(toastId.current, {
					type: "error",
					autoClose: 5000,
					closeButton: true,
					render: err.message
				})
			}
			throw err;
		}).finally(() => {
			setLoading(false);
		});
	}, [])

	return [cb, loading]

}
