import {User, UserNoteGroup as UserNoteGroupType} from '@Types/User';
import {InputTextarea} from 'primereact/inputtextarea';
import React, {useEffect, useState} from 'react';
import {collection, doc, onSnapshot} from 'firebase/firestore';
import {firestore} from '@Utils/config/firebase';
import {produce} from 'immer';
import {useDebounce} from '@uidotdev/usehooks';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {v4} from 'uuid';
import {DateTime} from 'luxon';
import _ from "lodash";
import {UserNoteGroup} from '@Pages/pages/components/UserInformation/UserNotes/UserNoteGroup';
import {NoteTitlesDialog} from "@Pages/pages/components/UserInformation/UserNotes/NoteTitlesDialog";
import {useUser} from "@Hooks/firebase";

type UserNotesProps = {
    user: User;
}
export const UserNotes: React.FC<UserNotesProps> = props => {

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingDefault, setLoadingDefault] = useState<boolean>(false);
    const [loadingNewGroup, setLoadingNewGroup] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = useState<UserNoteGroupType | null>(null);
    const [showNoteTitlesEdit, setShowNoteTitlesEdit] = useState<boolean>(false);


    const {meta} = useUser(true)

    const [defaultGroup, setDefaultGroup] = useState<UserNoteGroupType>({
        group_id: "default",
        label: "Note générale",
        description: "",
        created_at: ""
    });

    const [groups, setGroups] = useState<UserNoteGroupType[]>([]);
    useEffect(() => {
        setLoading(true);
        return onSnapshot(
            collection(firestore.db, 'users', props.user.user_id, 'notes'),
            snapshot => {
                setLoadingDefault(true);
                const fetchedGroups = snapshot.docs.map(doc => doc.data() as UserNoteGroupType);
                const foundDefaultGroup = fetchedGroups.find(g => g.group_id === "default");
                const otherGroups = _.sortBy(fetchedGroups.filter(g => g.group_id !== "default"), "created_at").reverse();

                if (foundDefaultGroup) {
                    setDefaultGroup(foundDefaultGroup);
                }
                if (!initialized) {
                    setInitialized(true);
                    setSelectedGroup(otherGroups[0] || null);
                }
                setLoadingDefault(false);
                setGroups(otherGroups);
                setLoading(false);
            },
        );
    }, [props.user]);

    const onUpdateDefault = async () => {
        setLoading(true);
        await firestore.collection(`users/${props.user.user_id}/notes`).set(defaultGroup.group_id, defaultGroup);
        setLoading(false);
    }

    const [noteTitles, setNoteTitles] = useState<string[]>([]);


    useEffect(() => {
        onSnapshot(doc(firestore.db, "settings/noteTitles"), snapshot => {
            setNoteTitles(snapshot.data()?.formations ?? []);
        });
    }, [])


    const debouncedDefaultGroup = useDebounce(defaultGroup, 3000);
    useEffect(() => {
        if (debouncedDefaultGroup && initialized) {
            onUpdateDefault().finally(() => {
                setLoadingDefault(false);
            });
        }
    }, [debouncedDefaultGroup, initialized]);

    const onCreateGroup = async () => {
        setLoadingNewGroup(true);
        const newGroup: UserNoteGroupType = {
            group_id: v4(),
            label: `Note ${groups.length + 1}`,
            description: "",
            created_at: DateTime.now().toISO() ?? Date().toString()
        };
        await firestore.collection(`users/${props.user.user_id}/notes`).set(newGroup.group_id, newGroup);
        setSelectedGroup(newGroup);
        setLoadingNewGroup(false);
    }



    return (
        <div className='flex flex-column p-3 gap-3'>
            {
                showNoteTitlesEdit && <NoteTitlesDialog
                    titles={noteTitles}
                    onClose={() => setShowNoteTitlesEdit(false)}
                />
            }
            <div className='flex flex-column'>
                <div className='he-header--h2 gray-600 mb-3 align-items-center'> Note générale <i className={classNames("ml-2", (loading || loadingDefault) ? "pi pi-spin pi-spinner" : "pi pi-check-circle color-green")}/> </div>
                <InputTextarea
                    rows={2}
                    style={{height: 55}}
                    value={defaultGroup.description}
                    onChange={(e) => {
                        if (!loadingDefault)
                            setLoadingDefault(true)
                        setDefaultGroup(produce(defaultGroup, draft => {
                            draft.description = e.target.value;
                            return draft;
                        }));
                    }}
                    placeholder={"Saisissez ici une note générale pour cet apprenant"}
                />
            </div>
            <div className='flex flex-column gap-3'>
                <div className='he-header--h2 gray-600'>
                    Notes et commentaires
                    {["admin", "author"].includes(meta.role) && <i className="ml-2 pi pi-cog cursor-pointer" onClick={() => setShowNoteTitlesEdit(true)}/>}
                </div>
                <div className='w-full flex gap-3'>
                    <div className='w-2 flex flex-column gap-2 overflow-auto p-2' style={{maxHeight: 400}}>
                        <Button className='he-button--secondary-variant--lg' onClick={onCreateGroup} loading={loadingNewGroup} disabled={loadingNewGroup}>
                            Nouvelle note <i className={'pi pi-plus ml-2'} />
                        </Button>
                        {
                            groups.map(group => {
                                return <Button
                                    key={group.group_id}
                                    className={classNames(
                                        'he-button--secondary-variant--md',
                                        'flex justify-between items-center',
                                        'cursor-pointer',
                                        'p-2 rounded-md',
                                        'text-xs',
                                        'shadow-3',
                                        {
                                            'bg-primary-500 text-white': selectedGroup?.group_id === group.group_id,
                                            'bg-white text-gray-800 hover:text-white': selectedGroup?.group_id !== group.group_id,
                                        },
                                    )} onClick={() => {
                                    setSelectedGroup(group);
                                }}>
                                    {group.label}
                                </Button>;
                            })
                        }

                    </div>
                    {
                        selectedGroup && <UserNoteGroup
                            user={props.user}
                            group={selectedGroup}
                            key={selectedGroup.group_id}
                            titles={noteTitles}
                        />
                    }
                </div>
            </div>
        </div>
    )

}
