import {Roles} from "@Types/User";

export const userRoleMap: {
	[k in Roles]: string
} = {
	user: "APPRENANT",
	admin: "ADMINISTRATEUR",
	author: "AUTEUR",
	animator: "ANIMATEUR"
}
export const uuidToTag=  (uuid: string) => {
	return uuid.replaceAll(/[a-fA-F\-]/g, '').padEnd(4,"-").substring(0,4)
}
