import {v4} from "uuid";
import {firestore, storage} from "@Utils/config/firebase";
import {getDownloadURL, ref, uploadBytesResumable, type UploadTaskSnapshot, type StorageError} from "firebase/storage";
import {Resource} from "@Types/Resource";

export const getBase64 = async (file: File): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			const base64data = reader.result as string;
			resolve(base64data)
		}
		reader.onerror = (ev) => {
			reject(ev)
		}
	})
}

export const uploadResource = async (file: File) => {
	const id = v4();
	const upload = await storage.put(file, `resources/${id}`);
	const url = await getDownloadURL(upload.ref);
	await firestore.collection<Resource>("resources").set(id, {
		resource_name: file.name,
		resource_url: url,
		size: file.size,
		resource_id: id
	});
	return id;
}

export const uploadBigResource = (file: File, callbacks: {
	onStateChange: (snapshot: UploadTaskSnapshot) => unknown,
	onError: (error: StorageError) => void,
	onComplete: (id: string) => void
}) => {
	const id = v4();
	const storageRef = ref(storage.storage, `resources/${id}`)
	const task = uploadBytesResumable(storageRef, file);

	task.on('state_changed', callbacks.onStateChange, callbacks.onError, async () => {
		const url = await getDownloadURL(task.snapshot.ref);
		await firestore.collection<Resource>("resources").set(id, {
			resource_name: file.name,
			resource_url: url,
			size: file.size,
			resource_id: id
		});
		callbacks.onComplete(id)
	});

	return task;
}


export const deleteResource = async (resource_id: string) => {
	await firestore.collection<Resource>('resources').delete(resource_id);
	await storage.child(`resources/${resource_id}`).delete();
}
