import { CustomActionDropdown, DropdownOption } from '@Components/CustomActionDropdown';
import { CustomDialog } from '@Components/CustomDialog';
import { CustomTable } from '@Components/CustomTable';
import { SessionContext } from '@Context/SessionContext';
import { useUser } from '@Hooks/firebase';
import { useToast } from '@Hooks/toast';
import { AddVirtualClass } from '@Pages/pages/Sessions/pages/components/AddVirtualClass';
import { DeleteVirtualClass } from '@Pages/pages/Sessions/pages/components/DeleteVirtualClass';
import { UpdateVirtualClass } from '@Pages/pages/Sessions/pages/components/UpdateVirtualClass';
import { Session, VirtualMeeting } from '@Types/Session';
import { DateTime, Duration } from 'luxon';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { env } from 'src/env';
import { InviteIntervenant } from './components/InviteIntervenant';

export type VirtualClassesProps = {
    session: Session;
};
export const VirtualClasses: React.FC<VirtualClassesProps> = (props) => {
    const { virtualClasses } = useContext(SessionContext);

    const ref = useRef<TieredMenu>(null);

    const [selection, setSelection] = useState<VirtualMeeting>();
    const [createVisible, setCreateVisible] = useState<boolean>(false);
    const [updateVisible, setUpdateVisible] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [inviteVisible, setInviteVisible] = useState<boolean>(false);
    const { meta } = useUser(true);
    const { success } = useToast();

    const meetingActions = useMemo<DropdownOption[]>(
        () => [
            {
                label: 'Modifier la classe virtuelle',
                icon: 'pi pi-pencil',
                action: () => setUpdateVisible(true),
            },
            {
                label: 'Supprimer la classe virtuelle',
                action: () => setDeleteVisible(true),
                icon: 'pi pi-trash',
            },
            {
                label: "Inviter l'intervenant",
                icon: 'pi pi-envelope',
                action: () => setInviteVisible(true),
            },
        ],
        [selection]
    );

    const onActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, meeting: VirtualMeeting) => {
        setSelection(meeting);
        ref.current?.toggle(e);
    };
    const MeetingDropdown = (meeting: VirtualMeeting) => {
        return (
            ref.current && (
                <div
                    className="he-paragraph--small gray-900 flex align-items-center cursor-pointer"
                    onClick={(e) => onActionClick(e, meeting)}
                >
                    <div className="mr-1">Actions</div>
                    <i className="pi pi-angle-down gray-400" style={{ fontSize: 9 }} />
                </div>
            )
        );
    };

    const onCopyLink = (link: string) => {
        navigator.clipboard.writeText(link).then(() => {
            success('Lien copié !');
        });
    };

    return (
        <div className="VirtualClasses">
            <CustomActionDropdown options={meetingActions} ref={ref} />
            {createVisible && <AddVirtualClass onQuit={() => setCreateVisible(false)} session={props.session} />}
            {updateVisible && selection && (
                <CustomDialog onHide={() => setUpdateVisible(false)}>
                    <UpdateVirtualClass
                        onQuit={() => setUpdateVisible(false)}
                        session={props.session}
                        meeting={selection}
                    />
                </CustomDialog>
            )}
            {deleteVisible && selection && (
                <CustomDialog onHide={() => setDeleteVisible(false)}>
                    <DeleteVirtualClass
                        onQuit={() => setDeleteVisible(false)}
                        session={props.session}
                        meeting={selection}
                    />
                </CustomDialog>
            )}
            {inviteVisible && selection && (
                <CustomDialog onHide={() => setInviteVisible(false)}>
                    <InviteIntervenant onQuit={() => setInviteVisible(false)} selectedVirtualClass={selection} />
                </CustomDialog>
            )}
            <CustomTable
                dataKey={'virtual_class_id'}
                header={{
                    title: 'Liste des classes virtuelles',
                    actions: [
                        meta.role === 'admin' ? (
                            <Button
                                label="Ajouter une classe virtuelle"
                                icon="pi pi-plus"
                                className="he-button--primary--md"
                                onClick={() => setCreateVisible(true)}
                            />
                        ) : (
                            <></>
                        ),
                    ],
                }}
                columns={[
                    {
                        header: 'Date',
                        field: 'date',
                        filter: {
                            type: 'sort',
                            field: 'date',
                        },
                        body: (item) => (
                            <div>{item.date ? DateTime.fromISO(item.date).toFormat('dd/MM/yyyy') : ' - '}</div>
                        ),
                        style: { width: '120px' },
                        headerStyle: { width: '120px' },
                    },
                    {
                        header: 'Heure',
                        field: 'date',
                        filter: {
                            type: 'sort',
                            field: 'date',
                        },
                        body: (item) => <div>{item.date ? DateTime.fromISO(item.date).toFormat('HH:mm') : ' - '}</div>,

                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                    },
                    {
                        header: 'Durée',
                        field: 'duration',
                        filter: {
                            type: 'sort',
                            field: 'duration',
                        },
                        body: (item) => (
                            <div>
                                {item.duration
                                    ? Duration.fromMillis(item.duration * 60 * 1000).toFormat('hh:mm')
                                    : ' - '}
                            </div>
                        ),
                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                    },
                    {
                        header: 'Intervenant',
                        body: (item) => {
                            return item.intervenant && item.email_intervenant ? (
                                <div className="flex flex-column">
                                    <div>{item.intervenant}</div>
                                    <div className="he-paragraph--small gray-500">{item.email_intervenant}</div>
                                </div>
                            ) : (
                                <div className="he-paragraph--small text-red-300">Non renseigné</div>
                            );
                        },
                    },
                    {
                        header: 'Lien intervenant',
                        body: (item) => (
                            <div>
                                {item.host_url ? (
                                    <div
                                        className="cursor-pointer primary-100 flex"
                                        onClick={() => onCopyLink(item.host_url!)}
                                    >
                                        Copier le lien <i className="pi pi-copy ml-2" />
                                    </div>
                                ) : (
                                    '-'
                                )}{' '}
                            </div>
                        ),
                    },
                    {
                        header: 'Lien apprenant',
                        body: (item) => {
                            const link = `${env.lmsHost}/session/${props.session.session_id}/virtualroom/${item.virtual_class_id}`;

                            return (
                                <div
                                    className="cursor-pointer primary-100 flex"
                                    onClick={() => onCopyLink(link)}
                                    title="Cliquez pour copier le lien"
                                >
                                    Copier le lien <i className="pi pi-copy ml-2" />
                                </div>
                            );
                        },
                    },
                    {
                        header: 'Lien du replay',
                        body: (item) => (
                            <div>
                                {item.replay_url ? (
                                    <Badge value={'Disponible'} severity={'success'} />
                                ) : (
                                    <Badge value={'Non saisi'} severity="warning" />
                                )}
                            </div>
                        ),
                    },
                    {
                        style: { width: '100px' },
                        headerStyle: { width: '100px' },
                        body: meta.role === 'admin' ? MeetingDropdown : () => null,
                    },
                ]}
                values={virtualClasses}
            />
        </div>
    );
};
