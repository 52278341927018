import React, {useEffect, useState} from "react";
import {User} from "@Types/User";
import { firestore } from "@Utils/config/firebase";

export interface UsersContextI {
    users: User[]
}

export const UsersContext = React.createContext<UsersContextI>({
    users: []
});

export const UsersProvider: React.FC<React.PropsWithChildren> = (props) => {

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
       return firestore.collection("users").onSnapshotAll<User>("users", (snapshot) => {
       	if (snapshot.empty) return;
       	const userList = snapshot.docs.map(doc => doc.data());
       	setUsers(userList);
       });
    }, []);


    return <UsersContext.Provider value={{
        users
    }}>
        {props.children}
    </UsersContext.Provider>;
}
